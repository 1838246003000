import styles from "./TopBar.module.scss";

import "antd/dist/reset.css";

export interface ITopBarProps {
  headerValue: string;
}

const TopBar = ({ headerValue }: ITopBarProps) => {
  return (
    <header className={`${styles["top-bar"]}`}>
      <h4 className={`${styles["top-bar__header"]}`}>{headerValue}</h4>
    </header>
  );
};

export default TopBar;
