import { createSlice } from "@reduxjs/toolkit";

import { uiModels } from "../models";
import { SliceNames } from "../../data/config";

const initialState: uiModels.UiState = {
  openMenu: true,
  applicationMode: "worker",
};

const uiSlice = createSlice({
  name: SliceNames.UI,
  initialState,
  reducers: {
    setOpenMenu: (state, { payload }) => {
      state.openMenu = payload;
    },
    setApplicationMode: (state, { payload }) => {
      state.applicationMode = payload;
    },
  },
});

export const { 
  setOpenMenu, 
  setApplicationMode 
} = uiSlice.actions;

export default uiSlice.reducer;
