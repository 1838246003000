import React, { useState, useEffect, ReactElement, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { serialize } from "object-to-formdata";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";
import { suggestionModels } from "../../../__store/models";
import { modalActions } from "../../../__store/slices";
import { suggestionThunk } from "../../../__store/thunks";

import { displayErrorMessage, validateInputLength } from "../../../data/validation/suggestionValidation";

import { Icon } from "../../components/ui";

import styles from "./AcceptSuggestionForm.module.scss";

import { IconTypes, ModalClassnames, RoutesPaths, UserRoles } from "../../../data/config";
import { suggestionsData } from "../../../data/constants";

import MainStatuses, { ReportSubstatuses, AllStatuses } from "../../../data/config/suggestionStatuses.data";

import { ISuggestionForm } from "../types/SuggestionsProps.types";

import { Button, Input, Upload, UploadFile, UploadProps } from "antd";

const { TextArea } = Input;
const { COMMENT_MAX_LENGTH } = suggestionsData;
const { CORRECTED_SUGGESTION } = ModalClassnames;

const CorrectSuggestionForm: FunctionComponent<ISuggestionForm> = ({
  text,
  mainStatus,
  secondStatus,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const role = useAppSelector((state) => state.auth.role);
  const userID = useAppSelector((state) => state.auth.user_id);

  const suggestion = useAppSelector((state) => state.forms.formInfoData);
  const consultRequired = useAppSelector((state) => state.forms.formInfoData?.is_consulted_required);
  const pickedSuggestionId = useAppSelector((state) => state.forms.pickedSuggestionId);
  const consultationStatus = useAppSelector((state) => state.forms.formInfoData?.is_consulted);

  const [attachments, setAttachments] = useState<UploadFile[]>([]);

  const [updateSuggestion, setUpdateData] = useState<suggestionModels.SuggestionUpdatePayload>({
    comment: "",
    suggestion_id: pickedSuggestionId,
    status: MainStatuses.REPORT,
    substatus: ReportSubstatuses.WITHDRAWN,
    userRole: "",
    is_consulted: consultationStatus
  });

  useEffect(() => {
    setUpdateData({
      ...updateSuggestion,
      suggestion_id: pickedSuggestionId,
      status: mainStatus,
      substatus: secondStatus,
    });
  }, [pickedSuggestionId]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setUpdateData({ ...updateSuggestion, comment: e.currentTarget.value });

  const onSubmitHandler = (e: any): void => {
    e.preventDefault();

    if (updateSuggestion.comment) {
      if (attachments.length) {
        const data = {
          suggestion_id: pickedSuggestionId,
          status: mainStatus,
          substatus: secondStatus,
          type: "FLOW",
          user_id: userID,
        };

        const object = {
          attachments: attachments,
          data: JSON.stringify(data),
        };

        const formData = serialize(object);

        dispatch(suggestionThunk.uploadAttachments(formData));
      }

      dispatch(
        suggestionThunk.updateSuggestionStatus({
          comment: updateSuggestion.comment,
          suggestion_id: pickedSuggestionId,
          status: MainStatuses.REPORT,
          substatus: AllStatuses.CORRECTED,
          userRole: UserRoles.WORKER,
          is_consulted: updateSuggestion.is_consulted
        })
      );
    }
    
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(CORRECTED_SUGGESTION));

    navigate(RoutesPaths.SUGGESTIONS.MY_SUGGESTIONS_PATH);
  };

  const handleOnClose = (): void => {
    dispatch(modalActions.closeModal());
  };

  const isDisabled =
    updateSuggestion.comment === "" ||
    validateInputLength(updateSuggestion.comment, COMMENT_MAX_LENGTH);

  const propsForAttachments: UploadProps = {
    onRemove: (attachment: UploadFile) => {
      const index = attachments.indexOf(attachment);
      const newAttachmentsList = attachments.slice();
      newAttachmentsList.splice(index, 1);
      setAttachments(newAttachmentsList);
    },
    beforeUpload: (attachment: UploadFile) => {
      setAttachments([...attachments, attachment]);

      return false;
    },
    fileList: attachments
  };
  
  return (
    <form className={styles["evaluate"]} onSubmit={onSubmitHandler}>
      <div className={styles["evaluate__section"]}>
        <h4>Dodaj komentarz</h4> 
        <hr />
        <div className={styles["evaluate__section--textareas"]}>
          <div className={styles["textarea__section"]}>
            <TextArea
              id="commentContent"
              autoSize={{ minRows: 5, maxRows: 7 }}
              name="commentContent"
              onChange={(e) => onChangeHandler(e)}
              className={styles["textarea"]}
              placeholder="Napisz komentarz..."
            />
            {validateInputLength(
              updateSuggestion.comment,
              COMMENT_MAX_LENGTH
             ) && (
              <span className={styles["error"]}>
                {displayErrorMessage(
                  COMMENT_MAX_LENGTH,
                  updateSuggestion.comment.length
                )}
              </span>
            )}
            <Upload
              {...propsForAttachments}
              className={styles["upload"]}
              itemRender={(originNode, file) => (
                <span className={styles["upload__file"]}>
                  {" "}
                  <span className={styles["upload__file--names"]}>
                    <Icon type={IconTypes.ATTACH} /> {file.name}{" "}
                  </span>
                  <Icon
                    type={IconTypes.CROSS}
                    onClick={() => {
                      if (propsForAttachments.onRemove) propsForAttachments.onRemove(file);
                    }}
                  />
                </span>
              )}
            >
              <Button
                className={styles["upload__button"]}
                icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
              />
            </Upload>
          </div>
        </div>

        <div className={styles["buttons-section"]}>
          <Button
            size="large"
            type="primary"
            htmlType="reset"
            onClick={handleOnClose}
            className={styles["secondary-button"]}
          >
            Anuluj
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!!isDisabled}
            className={styles["primary-button"]}
          >
            Dodaj komentarz
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CorrectSuggestionForm;
