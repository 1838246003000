import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { uiActions } from "../../../__store/slices";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { authThunk } from "../../../__store/thunks";
import { applicationSettingsActions } from "../../../__store/slices";

import { Icon } from ".";

import { Avatar } from "antd";

import styles from "./NavBar.module.scss";

import {
  IconTypes,
  RoutesPaths,
  UserRoles,
  authStatuses,
} from "../../../data/config";
import { adminData, appHeaders } from "../../../data/constants";

import { CLIENT_NAME } from "../../../data/config/globalContentVariables.data";

const Logo =
  require(`../../../assets/images/${CLIENT_NAME}/logo-2.png`);
const Program =
  require(`../../../assets/files/${CLIENT_NAME}/Program.pdf`);
const Guide = require(`../../../assets/files/${CLIENT_NAME}/Guide.pdf`);

const { ADMIN, ASSESSMENT, AUTHORIZATION, PROFILE, RANKS, SUGGESTIONS } =
  RoutesPaths;

const { Permission } = authStatuses;
const { avatarStyles } = adminData;

interface LinkState extends MobileLinkState {
  name: string;
  appMode?: string;
}

interface MobileLinkState {
  path: string;
  icon: React.ReactElement;
  hiddenRole: string[];
  permission?: authStatuses.Permission;
}

const NavBar = () => {
  let location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dispatch = useAppDispatch();

  const userRole = useAppSelector((state) => state.auth.role);
  const user = useAppSelector((state) => state.users.authUser);
  const user_id = useAppSelector((state) => state.auth.user_id);
  const permissions = useAppSelector((state) => state.auth.permissions);

  const appMode = useAppSelector((state) => state.ui.applicationMode);
  const assignNameClassName = (className: string) =>
    appMode === "worker" ? className : `${className}--extended`;

  const {
    all_suggestions_preview_permission,
    finance_permission,
    payment_permission,
    structure_permission,
    hr_permission,
  } = permissions;

  const bgColor = appMode === "worker" ? "#391580" : "#3fa2ff";

  const showEvaluationPages = CLIENT_NAME === "dimograf" ? false : useAppSelector(
    (state) => state.applicationSettings.shouldShowEvaluationPages
  );
  const openMenu = useAppSelector((state) => state.ui.openMenu);

  const hasAllSuggestionsPreviewPermission =
    permissions.all_suggestions_preview_permission;

  const logoutHandler = () => {
    dispatch(authThunk.logout(user_id as number));
    dispatch(uiActions.setApplicationMode("worker"));
  };

  useEffect(() => {
    dispatch(applicationSettingsActions.getApplicationSettings());
  }, []);

  const LINKS: LinkState[] = [
    {
      path: RANKS.RANKING_PATH,
      icon: <Icon type={IconTypes.RANKING} />,
      name: appHeaders.RANKS.RANKING_HEADER,
      hiddenRole: [UserRoles.WORKER, UserRoles.ADMIN],
      appMode: "extended"
    },
    {
      path: RANKS.BONUS_WORKER_PATH,
      icon: <Icon type={IconTypes.MONEY_BOX} />,
      name: appHeaders.RANKS.POINTS_HEADER,
      hiddenRole: [],
      appMode: "worker"
    },
    {
      path: ADMIN.STRUCTURE_PATH,
      icon: <Icon type={IconTypes.STRUCTURE} />,
      name: appHeaders.ADMIN.STRUCTURE_HEADER,
      hiddenRole: [],
      permission: Permission.structure_permission,
      appMode: "extended"
    },
  ];

  const links = LINKS.filter((link) => {
    let isValid = true;
    link.hiddenRole.forEach((item) => {
      if (item === userRole) {
        isValid = false;
      }
    });

    if (link.permission) {
      if (permissions[`${link.permission}`]) {
        isValid = true;
      } else {
        isValid = false;
      }
    }

    if (link.appMode === appMode) return isValid;
  }).map((link) => {
    return (
      <Link
        key={link.name}
        to={link.path}
        className={`${styles["link"]} ${
          location.pathname === link.path && styles[assignNameClassName("active")]
        }`}
      >
        {location.pathname === link.path && styles["active"] ? (
          <span className={styles["link--active-selector"]} />
        ) : null}{" "}
        {link.icon} <span className={styles["link__name"]}>{link.name}</span>
      </Link>
    );
  });

  const handleDrawerOpen = () => {
    dispatch(uiActions.setOpenMenu(true));
  };

  const handleDrawerClose = () => {
    dispatch(uiActions.setOpenMenu(false));
  };

  return (
    <>
      <button 
        className={styles["app-bar__button"]} 
        aria-label="open drawer" 
        onClick={() => handleDrawerOpen()}
      >
        <Icon type={IconTypes.MENU} />
      </button>
      <button
          className={styles[
            openMenu 
            ? "app-bar__button" 
            : windowWidth < 912 
              ? "app-bar__button--hidden" 
              : "app-bar__button"
            ]}
          onClick={() => handleDrawerClose()}
        >
          <Icon type={IconTypes.CHEVRON_LEFT} />
      </button>

      <nav className={styles[assignNameClassName(
        openMenu 
          ? "nav-bar" 
          : windowWidth < 912 
            ? "nav-bar__closed" 
            : "nav-bar"
          )]}
        >

        <div className={styles["nav-bar__logo"]}>
          <img src={Logo} alt="logo" className={styles["nav-bar__logo__img"]} />
        </div>
        <div className={styles["nav-bar__user"]}>
          <div className={styles["nav-bar__user__icon"]}>
            <Avatar style={avatarStyles( user?.user.isActive, bgColor)}>
              {`${user?.name.charAt(0)}${user?.surname.charAt(0)}`}
            </Avatar>
          </div>
          <div className={styles["nav-bar__user__info"]}>
            <div 
              className={styles["nav-bar__user__info__container"]}
            >
              <div
                className={styles["nav-bar__user__info__container__details"]}
              >
                <span className={styles["name"]}>
                  {user?.name} {user?.surname}
                </span>
                <></>
              </div>
              <span
                className={
                  styles["nav-bar__user__info__container__job-position"]
                }
              >
                {user?.job_position.name}
              </span>
            </div>
          </div>
        </div>

      {(userRole === "MANAGER" ||
        userRole === "BOSS" ||
        userRole === "ADMIN" ||
        all_suggestions_preview_permission ||
        finance_permission ||
        payment_permission ||
        structure_permission ||
        hr_permission) && (
            <div className={styles["button-group"]}>
              <button
                className={
                  appMode === "worker"
                    ? styles[assignNameClassName("button__active")]
                    : styles["button"]
                }
                onClick={() => {
                  dispatch(uiActions.setApplicationMode("worker"));
                }}
              >
                Pracownik
              </button>
              <button
                className={
                  appMode === "extended"
                    ? styles[assignNameClassName("button__active")]
                    : styles["button"]
                }
                onClick={() => {
                  dispatch(uiActions.setApplicationMode("extended"));
                }}
              >
                Manager
              </button>
            </div>
          )}

        <div className={styles["nav-bar__links"]}>
          {appMode === "worker" && (<Link
            to={SUGGESTIONS.CREATE_SUGGESTION_PATH}
            className={`${styles["link"]}
              ${
                location.pathname === SUGGESTIONS.CREATE_SUGGESTION_PATH &&
                styles[assignNameClassName("active")]
              }
            `}
          >
            {location.pathname === SUGGESTIONS.CREATE_SUGGESTION_PATH &&
            styles[assignNameClassName("active")] ? (
              <span className={styles["link--active-selector"]} />
            ) : null}
            <Icon type={IconTypes.SUGGESTION_BOX} />
            <span className={styles["link__name"]}>
              {appHeaders.SUGGESTIONS.CREATE_SUGGESTION}
            </span>
          </Link>)}
          {userRole === UserRoles.WORKER || userRole === UserRoles.ADMIN ? (
            <Link
              to={PROFILE.YOUR_PROFILE_PATH}
              className={`${styles["link"]}
              ${
                (location.pathname === PROFILE.YOUR_PROFILE_PATH ||
                  location.pathname === PROFILE.WORKER_PROFILE_PATH) &&
                styles[assignNameClassName("active")]
              }
            `}
            >
              {(location.pathname === PROFILE.YOUR_PROFILE_PATH ||
                location.pathname === PROFILE.WORKER_PROFILE_PATH) &&
              styles[assignNameClassName("active")] ? (
                <span className={styles["link--active-selector"]} />
              ) : null}
              <Icon type={IconTypes.DASHBOARD} />
              <span className={styles["link__name"]}>
                {appHeaders.PROFILE.DASHBOARD_HEADER}
              </span>
            </Link>
          ) : (
            <>
              <Link
                to={appMode === "worker" 
                  ? PROFILE.YOUR_PROFILE_PATH 
                  : PROFILE.WORKER_PROFILE_PATH}
                className={`${styles["link"]}
              ${
                (location.pathname === PROFILE.YOUR_PROFILE_PATH ||
                  location.pathname === PROFILE.WORKER_PROFILE_PATH) &&
                styles["active"]
              }
            `}
              >
                <Icon type={IconTypes.DASHBOARD} />
                <span className={styles["link__name"]}>
                  {appHeaders.PROFILE.DASHBOARD_HEADER}
                </span>
              </Link>
            </>
          )}

          {userRole === UserRoles.WORKER || userRole === UserRoles.ADMIN ? (   
            <>
              <Link
                to={appMode === "worker" 
                  ? SUGGESTIONS.MY_SUGGESTIONS_PATH 
                  : all_suggestions_preview_permission ? SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH : SUGGESTIONS.MY_SUGGESTIONS_PATH
                }
                className={`${styles["link"]}
                  ${styles["link__suggestion"]}
                  ${(
                    location.pathname === SUGGESTIONS.MY_SUGGESTIONS_PATH ||
                    location.pathname === SUGGESTIONS.WORKER_SUGGESTIONS_PATH ||
                    location.pathname ===
                      SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH ||
                    location.pathname === SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH ||
                    location.pathname ===
                      SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH) &&
                      styles[assignNameClassName("active")]
                  }
                `}
                >
                  {(location.pathname === SUGGESTIONS.MY_SUGGESTIONS_PATH ||
                  location.pathname === SUGGESTIONS.WORKER_SUGGESTIONS_PATH ||
                  location.pathname === SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH ||
                  location.pathname === SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH ||
                  location.pathname ===
                    SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH) &&
                    styles[assignNameClassName("active")] ? (
                      <span className={styles["link--active-selector"]} />
                  ) : null}
                <Icon type={IconTypes.SUGGESTION} />{" "}
                <span className={styles["link__name"]}>
                  {appHeaders.SUGGESTIONS.SUGGESTIONS_HEADER}
                </span>
              </Link>
            </>
          ) : (
            <>
              <Link
                to={appMode === "worker" 
                  ? SUGGESTIONS.MY_SUGGESTIONS_PATH 
                  : SUGGESTIONS.WORKER_SUGGESTIONS_PATH}
                className={`${styles["link"]}
              ${
                (location.pathname === SUGGESTIONS.MY_SUGGESTIONS_PATH ||
                  location.pathname === SUGGESTIONS.WORKER_SUGGESTIONS_PATH ||
                  location.pathname ===
                    SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH ||
                  location.pathname ===
                    SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH ||
                  location.pathname ===
                    SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH) &&
                styles["active"]
              }
            `}
              >
                <Icon type={IconTypes.SUGGESTION} />
                <span className={styles["link__name"]}>
                  {appHeaders.SUGGESTIONS.SUGGESTIONS_HEADER}
                </span>
              </Link>

              {(userRole !== UserRoles.WORKER && appMode === "extended") && (location.pathname === SUGGESTIONS.MY_SUGGESTIONS_PATH ||
                location.pathname === SUGGESTIONS.WORKER_SUGGESTIONS_PATH ||
                location.pathname === SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH ||
                location.pathname ===
                  SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH ||
                location.pathname ===
                  SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH) && (
                <div className={styles["nav-bar__links__suggestions"]}>
                  <Link
                    to={SUGGESTIONS.WORKER_SUGGESTIONS_PATH}
                    className={`${styles["nav-bar__links__suggestions__link"]}
                      ${location.pathname === SUGGESTIONS.WORKER_SUGGESTIONS_PATH &&styles["active"]}`}
                  >
                    {userRole === UserRoles.BOSS
                      ? appHeaders.SUGGESTIONS
                          .WORKER_SUGGESTIONS_HEADER_FOR_BOSS
                      : appHeaders.SUGGESTIONS.WORKER_SUGGESTIONS_HEADER}
                  </Link>

                  {userRole !== UserRoles.BOSS && (
                    <Link
                      to={SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH}
                      className={`${styles["nav-bar__links__suggestions__link"]}
                      ${location.pathname ===SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH && styles["active"]} `}
                    >
                      {appHeaders.SUGGESTIONS.DEPARTMENT_SUGGESTIONS_HEADER}
                    </Link>
                  )}

                  {userRole !== UserRoles.BOSS && (
                    <Link
                      to={SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH}
                      className={`${styles["nav-bar__links__suggestions__link"]}
                      ${location.pathname === SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH &&styles["active"]}`}
                    >
                      {appHeaders.SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_HEADER}
                    </Link>
                  )}
                  {hasAllSuggestionsPreviewPermission && (
                    <Link
                      to={SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH}
                      className={`${styles["nav-bar__links__suggestions__link"]}
                      ${location.pathname === SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH && styles["active"]}`}
                    >
                      {appHeaders.SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_HEADER}
                    </Link>
                  )}
                </div>
              )}
            </>
          )}
          {links}

          {appMode === "extended" && (userRole === UserRoles.ADMIN || permissions.payment_permission) ? (
            <>
              <Link
                to={RANKS.BONUS_MANAGER_PATH}
                className={`${styles["link"]}
                ${
                  (location.pathname === RANKS.POINTS_PATH ||
                    location.pathname === RANKS.BONUS_WORKER_PATH ||
                    location.pathname === RANKS.BONUS_MANAGER_PATH) &&
                  styles[assignNameClassName("active")]
                }
              `}
              >
                {(location.pathname === RANKS.POINTS_PATH ||
                  location.pathname === RANKS.BONUS_WORKER_PATH ||
                  location.pathname === RANKS.BONUS_MANAGER_PATH) &&
                styles["active"] ? (
                  <span className={styles["link--active-selector"]} />
                ) : null}
                <Icon type={IconTypes.MONEY_BOX} />
                <span className={styles["link__name"]}>
                  {appHeaders.RANKS.POINTS_HEADER}
                </span>
              </Link>
            </>
          ) : null}

          {appMode === "extended" && userRole === UserRoles.ADMIN || permissions.admin_permission ? (
            <>
              <Link
                to={ADMIN.ADMIN_PATH}
                className={`${styles["link"]}
                ${
                  (location.pathname === ADMIN.ADMIN_PATH) &&
                  styles[assignNameClassName("active")]
                }
              `}
              >
                {(location.pathname === ADMIN.ADMIN_PATH) &&
                styles["active"] ? (
                  <span className={styles["link--active-selector"]} />
                ) : null}
                <Icon type={IconTypes.ADMIN} />
                <span className={styles["link__name"]}>
                  {appHeaders.ADMIN.ADMIN_HEADER}
                </span>
              </Link>
            </>
          ) : null}

          {userRole === UserRoles.WORKER || userRole === UserRoles.ADMIN ? (
            <>
              {showEvaluationPages && (
                <Link
                  to={ASSESSMENT.MY_ASSESSMENT_PATH}
                  className={`${styles["link"]}
                ${styles["link__suggestion"]}
                ${
                  (location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH ||
                    location.pathname === ASSESSMENT.CREATE_ASSESSMENT_PATH ||
                    location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH_INFO ||
                    location.pathname === ASSESSMENT.REVIEW_ASSESSMENT_PATH ||
                    location.pathname ===
                      ASSESSMENT.REVIEW_USER_ASSESSMENT_PATH) &&
                  styles[assignNameClassName("active")]
                }
              `}
                >
                {(location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH ||
                  location.pathname === ASSESSMENT.CREATE_ASSESSMENT_PATH ||
                  location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH_INFO ||
                  location.pathname === ASSESSMENT.REVIEW_ASSESSMENT_PATH ||
                  location.pathname === ASSESSMENT.REVIEW_USER_ASSESSMENT_PATH) &&
                    styles[assignNameClassName("active")] && (
                      <span className={styles["link--active-selector"]} />
                    )}
                  <Icon
                    type={IconTypes.ASSESSMENT}
                    className={styles["assessment-icon"]}
                  />{" "}
                  <span className={styles["link__name"]}>
                    {appHeaders.ASSESSMENT.ASSESSMENT_HEADER}
                  </span>
                </Link>
              )}
            </>
          ) : (
            <>
              {" "}
              {showEvaluationPages && (
                <>
                  <Link
                    to={
                      appMode === "worker"
                        ? userRole === UserRoles.BOSS
                          ? ASSESSMENT.CREATE_ASSESSMENT_PATH
                          : ASSESSMENT.MY_ASSESSMENT_PATH
                        : ASSESSMENT.CREATE_ASSESSMENT_PATH
                    }
                    className={`${styles["link"]}
              ${(
                location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH ||
                location.pathname === ASSESSMENT.REVIEW_ASSESSMENT_PATH ||
                location.pathname === ASSESSMENT.CREATE_ASSESSMENT_PATH) && styles["active"]}`}
                  >
                    <Icon
                      type={IconTypes.ASSESSMENT}
                      className={styles["assessment-icon"]}
                    />
                    <span className={styles["link__name"]}>
                      {appHeaders.ASSESSMENT.ASSESSMENT_HEADER}
                    </span>
                  </Link>
                  {(appMode === "extended") && (
                    location.pathname === ASSESSMENT.MY_ASSESSMENT_PATH ||
                    location.pathname === ASSESSMENT.REVIEW_ASSESSMENT_PATH ||
                    location.pathname === ASSESSMENT.CREATE_ASSESSMENT_PATH
                  ) && (   
                    <div className={styles["nav-bar__links__assessments"]}>
                      <Link
                        to={ASSESSMENT.CREATE_ASSESSMENT_PATH}
                        className={`${styles["nav-bar__links__assessments__link"]}
                          ${location.pathname === ASSESSMENT.CREATE_ASSESSMENT_PATH && styles["active"]}`}
                      >
                        {appHeaders.ASSESSMENT.CREATE_ASSESSMENT_HEADER}
                      </Link>
                      <Link
                        to={ASSESSMENT.REVIEW_ASSESSMENT_PATH}
                        className={`${styles["nav-bar__links__assessments__link"]}
                          ${location.pathname === ASSESSMENT.REVIEW_ASSESSMENT_PATH && styles["active"]}`}
                      >
                        {appHeaders.ASSESSMENT.REVIEW_ASSESSMENT_HEADER}
                      </Link>
                  </div>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className={styles["nav-bar__links--utils-field"]}>
          <div className={styles["separation-line"]}></div>
          <Link
            to={Guide}
            target="_blank"
            rel="noreferrer"
            className={`${styles["link"]}`}
          >
            <Icon type={IconTypes.HELP} />
            <span className={styles["link__name"]}>
              {appHeaders.GUIDE_HEADER}
            </span>
          </Link>
          <Link
            to={Program}
            target="_blank"
            rel="noreferrer"
            className={`${styles["link"]}`}
          >
            <Icon type={IconTypes.PAPER_ICON} />
            <span className={styles["link__name"]}>
              {appHeaders.PROGRAM_HEADER}
            </span>
          </Link>
          <Link
            to={ADMIN.SETTINGS_PATH}
            className={`${styles["link"]}`}
          >
            <Icon type={IconTypes.GEAR} />
            <span className={styles["link__name"]}>
              {appHeaders.SETTINGS_HEADER}
            </span>
          </Link>

          <Link
            onClick={logoutHandler}
            to={AUTHORIZATION.LOGOUT_PATH}
            className={`${styles["link"]}
             `}
          >
            <Icon type={IconTypes.LOGOUT} />
            <span className={styles["link__name"]}>
              {appHeaders.AUTHORIZATION.LOGOUT_HEADER}
            </span>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
