import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { modalActions, usersActions, authActions } from "../../../__store/slices";
import { adminThunk } from "../../../__store/thunks";

import { Button, Icon } from "../../components/ui";
import UserValidation from "../../../data/validation/addUserValidation";

import styled from "./AdminUpdateCredential.module.scss";

import { InputTypes, usersData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";
const { 
  updateCredentialMessages, 
  LOGIN_MIN_LENGTH, 
  updateCredentialFormLabels, 
  credentialFormInputNames, 
  updateCredentialFormButtons 
} = usersData;

interface AdminChangeCredentialProps {
  text: string;
}

type FormState = {
  password: string;
  repeatPassword: string;
};

const AdminChangeCredential: FunctionComponent<
  AdminChangeCredentialProps
> = ({ text }):ReactElement => {
  const dispatch = useAppDispatch();
  const validation = new UserValidation();
  const [loginError, setLoginError] = useState({ status: false, message: "" });
  const [loginForm, setLoginForm] = useState("");
  const [passwordError, setPasswordError] = useState({
    status: false,
    message: "",
  });

  const [passwordState, setPasswordState] = useState<FormState>({
    password: "",
    repeatPassword: "",
  });
  const user = useAppSelector((state) => state.users.user);
  const errorMessage = useAppSelector((state) => state.users.errorMessage);
  useEffect(() => {
    user && user.user.login && setLoginForm(user.user.login);
  }, [user]);

  useEffect(() => {
    if (errorMessage) {
      setLoginError({
        status: true,
        message: errorMessage,
      });
    }
  }, [errorMessage]);

  const handleOnChange = (e: any) => {
    setPasswordState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const changePasswordHandler = () => {
    const isPasswordMatch = validation.isMatchedPassword(
      passwordState.password,
      passwordState.repeatPassword
    );

    if (!isPasswordMatch) {
      setPasswordError({
        status: true,
        message: updateCredentialMessages.PASSWORD_MATCH_ERROR,
      });
      return;
    }

    const isPasswordValid = validation.isPasswordValid(passwordState.password.trim());
    if (!isPasswordValid.isValid) {
      setPasswordError({
        status: true,
        message: isPasswordValid.message,
      });
      return;
    }

    setPasswordError({
      status: false,
      message: "",
    });

    dispatch(
      adminThunk.updateUser({
        password: passwordState.password.trim(),
        user_id: user?.user_id,
        firstLogin: true,
        anotherPasswordDate: new Date()
      })
    );
  };

  const changeLoginHandler = (e: any) => {
    setLoginForm(e.target.value);
  };

  const changeLoginHandlerSubmit = () => {
    if (loginForm.trim() === user?.user.login) {
      setLoginError({ status: true, message: updateCredentialMessages.SAME_LOGIN_ERROR });
      return;
    }

    if (!(loginForm.trim().length >= LOGIN_MIN_LENGTH)) {
      setLoginError({
        status: true,
        message: updateCredentialMessages.LOGIN_TOO_SHORT_ERROR,
      });
      return;
    }

    setLoginError({ status: false, message: "" });
    dispatch(adminThunk.updateUser({ login: loginForm.trim(), user_id: user?.user_id }));
  };

  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
    dispatch(usersActions.setErrorMessage(""));
    dispatch(
      authActions.setPasswordVerification({
        verified: false,
        message: "",
      })
    );
  };

  return (
    <div className={styled["form"]}>
      <div className={styled["form__header"]}>
        <div className={styled["form__header__text"]}>
          <div className={styled["form__header__text__icon"]}>
            <Icon type={IconTypes.AVATAR} width={"100%"} height={"100%"} />
          </div>
          <span>{text}</span>
        </div>
        <div className={styled["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={handleOnClose} />
        </div>
      </div>
      <div className={styled["form__context"]}>
        <div className={styled["form__context__login"]}>
          <div className={styled["form__context__login__label"]}>
            <span>{updateCredentialFormLabels.NEW_LOGIN}</span>
          </div>
          <div className={styled["form__context__login__input"]}>
            <input
              name={credentialFormInputNames.INPUT_NEW_LOGIN}
              value={loginForm}
              type={InputTypes.TEXT}
              className={styled["input"]}
              placeholder={updateCredentialFormLabels.INPUT_NEW_LOGIN}
              onChange={changeLoginHandler}
            />
          </div>
          <div className={styled["form__context__login__error"]}>
            <span>{loginError.status && loginError.message}</span>
          </div>
          <div className={styled["form__context__login__button"]}>
            <Button
              buttonFunction={changeLoginHandlerSubmit}
              textValue={updateCredentialFormButtons.CHANGE_LOGIN_BUTTON}
              hiddenStyles=""
              buttonClassName="submit-button"
            />
          </div>
        </div>
        <div className={styled["form__context__password"]}>
          <div className={styled["form__context__password__input-container"]}>
            <div
              className={
                styled["form__context__password__input-container__label"]
              }
            >
              <span>{updateCredentialFormLabels.NEW_PASSWORD}</span>
            </div>
            <input
              name={credentialFormInputNames.INPUT_NEW_PASSWORD}
              type={InputTypes.PASSWORD}
              value={passwordState.password}
              className={styled["input"]}
              placeholder={updateCredentialFormLabels.INPUT_NEW_PASSWORD}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div
            className={`${styled["form__context__password__input-container"]} ${styled["last"]}`}
          >
            <div className={styled["form__context__password__input-container__label"]}>
              <span>{updateCredentialFormLabels.NEW_PASSWORD_REPEAT}</span>
            </div>
            <input
              name={credentialFormInputNames.INPUT_NEW_PASSWORD_REPEAT}
              type={InputTypes.PASSWORD}
              value={passwordState.repeatPassword}
              className={styled["input"]}
              placeholder={updateCredentialFormLabels.INPUT_NEW_PASSWORD_REPEAT}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className={styled["form__context__login__error"]}>
            <span>
              {passwordError.status && passwordError.message}
            </span>
          </div>
          <div className={styled["form__context__password__btn-container"]}>
            <div
              className={styled["form__context__password__btn-container__btn"]}
            >
              <Button
                buttonFunction={handleOnClose}
                textValue={updateCredentialFormButtons.CANCEL_BUTTON}
                hiddenStyles=""
                buttonClassName="cancel"
              />
            </div>
            <Button
              buttonFunction={changePasswordHandler}
              textValue={updateCredentialFormButtons.CHANGE_PASSWORD_BUTTON}
              hiddenStyles=""
              buttonClassName="submit-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChangeCredential;
