import { ReactElement } from 'react';
import { useAppSelector } from '../../../__store/tools/hooks';

import { NavBar, InactiveAccount } from "../../components/ui";
import { TopBar, SuggestionsDashboard } from ".";
import { UtilNotification } from "../../components/ui";

import styles from "./SuggestionsPageTemplate.module.scss";
import { ISuggestionsPageTemplateProps } from '../types/SuggestionsProps.types';

function SuggestionsPageTemplate(
  { suggestionsMap, suggestionsCount, userRole, readOnly } : ISuggestionsPageTemplateProps) 
  : ReactElement {

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);
  
  return (
    <div className={styles["suggestions-page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["dashboard"]}>
        <TopBar />
        <UtilNotification />
        <SuggestionsDashboard
          suggestionsCount={suggestionsCount}
          suggestionsMap={suggestionsMap}
          userRole={userRole}
          readOnly={readOnly}
        />
      </div>) : <InactiveAccount />}
    </div>
  );
}

export default SuggestionsPageTemplate;
