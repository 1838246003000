import { createSlice } from "@reduxjs/toolkit";

import { helpersModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: helpersModels.HelperState = {
  suggestionBtn: "worker-btn",
};

const helpersSlice = createSlice({
  name: SliceNames.HELPERS,
  initialState,
  reducers: {
    setSuggestionBtn: (state, { payload }) => {
      state.suggestionBtn = payload;
    },
    setInitialState: () => initialState,
  },
});

export const { 
  setSuggestionBtn, 
  setInitialState 
} = helpersSlice.actions;

export default helpersSlice.reducer;
