import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { departmentThunk } from "../../../__store/thunks";

import { NavBar, TopBar, InactiveAccount } from "../../components/ui";
import { YourProfileDashboard } from "../components";
import { UtilNotification } from "../../components/ui";

import styles from "./YourProfilePage.module.scss";

import { profileData } from "../../../data/constants";
const { yourProfileHeader } = profileData;

const YourProfilePage = ():ReactElement => {
  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  return (
    <div className={styles["your-profile"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["dashboard"]}>
        <TopBar headerValue={yourProfileHeader} />
        <UtilNotification />
        <YourProfileDashboard />
      </div>) : <InactiveAccount />}
    </div>
  );
};

export default YourProfilePage;
