import { ReactElement, useState } from "react";
import { format } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { useAssessmentDisplayData } from "../components/hooks/useAssessmentDisplayData";

import { evaluationModels } from "../../../__store/models";
import { modalActions } from "../../../__store/slices";

import styles from "./DisplayAssessmentStatus.module.scss";

import { AddResponseToAssessment } from "../components";
import { AttachmentComponent, AttachmentView, Icon } from "../../components/ui";

import { IconTypes } from "../../../data/config";
import { AttachmentModal } from "../../components/Modal";

const DisplayAssessmentStatus = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const { evaluationTranslationMap, evaluationTitleTranslationMap } =
    useAssessmentDisplayData();
  const reviewingEvaluationId = useAppSelector(
    (state) => state.evaluation.reviewingEvaluationId
  );
  const userId = useAppSelector((state) => state.auth.user_id);
  const reviewingEvaluation = useAppSelector((state) =>
    state.evaluation.userRates?.find(
      (el) => el.evaluation_id === reviewingEvaluationId
    )
  ) as evaluationModels.UserRates;

  const attachmentPath = useAppSelector((state) => state.modal.attachmentPath);
  const attachmentDescription = useAppSelector(
    (state) => state.modal.attachmentDescription
  );

  const { evaluation_comments, evaluated_user_id } = reviewingEvaluation;

  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  };

  const displayAddCommentButton = () => {
    const returnButton = () => (
      <button
        className={styles["assessment-status__show-dialog"]}
        type="button"
        onClick={() => setShowResponseDialog(true)}
      >
        Dodaj odpowiedź
      </button>
    );

    if (
      !evaluation_comments?.length &&
      !showResponseDialog &&
      userId === evaluated_user_id
    ) {
      return returnButton();
    } else if (
      evaluation_comments?.length === 1 &&
      !showResponseDialog &&
      userId === reviewingEvaluation.evaluating_user_id
    ) {
      return returnButton();
    }
  };

  return (
    <>
      <AttachmentModal>
        <AttachmentView
          path={attachmentPath}
          description={attachmentDescription}
        />
      </AttachmentModal>
      <div className={styles["assessment-status"]}>
        <header className={styles["assessment-status__header"]}>
          <h2>{evaluationTranslationMap[reviewingEvaluation?.type]}</h2>
          <Icon
            type={IconTypes.ASSESSMENT_CROSS}
            className={styles["assessment-status__header--close"]}
            onClick={handleOnClose}
          />
        </header>
        <span className={styles["assessment-status__date"]}>
          {format(
            new Date(reviewingEvaluation.evaluation_for_date),
            "MM-dd-yyyy HH:mm"
          )}
        </span>
        <span className={styles["assessment-status__topic"]}>
          Tytuł:{" "}
          {reviewingEvaluation.custom_category !== ""
            ? reviewingEvaluation.custom_category
            : evaluationTitleTranslationMap[
                reviewingEvaluation.category as evaluationModels.EvaluationCategory
              ]}
        </span>
        <article className={styles["assessment-status__description"]}>
          {reviewingEvaluation.content}
        </article>

        {reviewingEvaluation.evaluationFilePath &&
          reviewingEvaluation.evaluationFileName &&
          reviewingEvaluation.evaluationFilePath.length > 0 && (
            <>
              <hr />
              <div className={styles["assessment-status__container"]}>
                <div>
                  {/* <AttachmentComponent
                    filepath={reviewingEvaluation.evaluationFilePath}
                    filenames={reviewingEvaluation.evaluationFileName}
                    description="Załącznik po weryfikacji sugestii"
                  /> */}
                </div>
              </div>
            </>
          )}

        <div className={styles["assessment-status__comments"]}>
          {evaluation_comments?.map((el) => (
            <article className={styles["comment"]}>
              <h3>
                {el.commenter_id === evaluated_user_id
                  ? "Odpowiedź pracownika"
                  : "Odpowiedź przełożonego"}
              </h3>
              <p>{el.content}</p>
            </article>
          ))}
        </div>
        {displayAddCommentButton()}
        {showResponseDialog ? <AddResponseToAssessment /> : null}
      </div>
    </>
  );
};

export default DisplayAssessmentStatus;
