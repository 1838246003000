import { DndProvider } from "react-dnd";
import { ReactElement, useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useAppSelector, useAppDispatch } from "../../../../__store/tools/hooks";

import { Icon } from "../../../components/ui";

import { departmentModels } from "../../../../__store/models";
import { departmentActions, modalActions } from "../../../../__store/slices";
import { adminThunk, departmentThunk } from "../../../../__store/thunks";

import { Area, Department, DepartmentsList, DepartmentDetails } from ".";

import styles from "./DashboardStructure.module.scss";
import { IconTypes } from "../../../../data/config";

import { ModalClassnames } from "../../../../data/config";

const { 
  EDIT_AREA
} = ModalClassnames;

function DashboardStructure():ReactElement {
  const dispatch = useAppDispatch();
  const [selectedDepartmentLocal, setSelectedDepartmentLocal] = useState<number | null>(
    null
  );

  const mainDepartments = useAppSelector(
    (state) => state.departments.mainDepartments
  );
  const superVisorDepartment = useAppSelector(
    (state) => state.departments.superVisorDepartment
  );

  const areas = useAppSelector(
    (state) => state.departments.areas
  );

  useEffect(() => {
    dispatch(departmentThunk.getSuperVisorDepartment());
    dispatch(departmentThunk.getMainDepartments());
    dispatch(adminThunk.fetchUsers());
  }, []);

  function selectArea({ areaId }: { areaId: number }) {
    dispatch(departmentActions.setSelectedArea(areaId));

    dispatch(modalActions.setModalClassName(EDIT_AREA));
    dispatch(modalActions.openModal());
  }

  function selectDepartment({ departmentId, breadcrumbs }: { departmentId: number; breadcrumbs: departmentModels.StructureDepartment[] }) {
    dispatch(departmentActions.setSelectedDepartment(departmentId));
    setSelectedDepartmentLocal(departmentId);

    if (breadcrumbs.length === 0) {
      if (departmentId === 1) {
        dispatch(
          departmentActions.setBreadcrumbs([
            {
              department_id: departmentId,
              name: superVisorDepartment?.name,
              parent_id: superVisorDepartment?.parent_id,
            },
          ])
        );
      } else {
        const filteredDepartment = mainDepartments.filter(
          (mainDepartment) => mainDepartment.department_id === departmentId
        );

        dispatch(departmentActions.setBreadcrumbs(filteredDepartment));
      }
    } else {
      dispatch(departmentActions.setBreadcrumbs(breadcrumbs));
    }

    dispatch(departmentThunk.getDepartmentById(departmentId));
  }

  return (
    <div
      className={`${styles["dashboard"]} ${
        selectedDepartmentLocal && styles["selected"]
      }`}
    >
      {!selectedDepartmentLocal ? (
        <>
          {areas && (
            <>
              <h2>Obszary</h2>
              <ul className={styles["dashboard__areas"]}>
                {areas.map((area) => (
                  <Area
                    key={area.area_id}
                    area={area}
                    handleClick={selectArea}
                  />
                ))}
              </ul>
            </>
          )}
          <hr />
          {superVisorDepartment && (
            <>
              <h2>Działy</h2>
              <ul className={styles["dashboard__supervisor"]}>
                <li
                  className={`${styles["dashboard__supervisor__department"]} ${
                    superVisorDepartment && styles["supervisor"]
                  } `}
                  onClick={() =>
                    selectDepartment({
                      departmentId: superVisorDepartment.department_id,
                      breadcrumbs: [],
                    })
                  }
                >
                  <div className={styles["department__icon"]}>
                    <Icon type={IconTypes.BUSINESS} />
                  </div>
                  <span className={styles["department__name"]}>
                    {superVisorDepartment.name}
                  </span>
                </li>
              </ul>
            </>
          )}
          <ul className={styles["dashboard__departments"]}>
            {mainDepartments.map((department) => (
              <Department
                key={department.department_id}
                department={department}
                handleClick={selectDepartment}
              />
            ))}
          </ul>
        </>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <>
            <div className={styles["dashboard__selected-departments"]}>
              {superVisorDepartment && (
                <DepartmentsList
                  handleClick={selectDepartment}
                  supervisorDepartment={superVisorDepartment}
                  departments={mainDepartments}
                  selectedDepartmentId={selectedDepartmentLocal}
                />
              )}
            </div>
            <DepartmentDetails selectDepartment={selectDepartment} />
          </>
        </DndProvider>
      )}
    </div>
  );
}

export default DashboardStructure;
