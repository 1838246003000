import { useAppSelector } from "../__store/tools/hooks";
import { Navigate } from "react-router-dom";

import { AccessDenied } from "../pages/Authorization/views";

import { RoutesPaths, authStatuses } from "../data/config";

const { ROOT_PATH } = RoutesPaths;

interface PrivateRoutesProps {
  component: React.ComponentType;
  roles: Array<string>;
  requiredPermission?: authStatuses.Permission;
}

const PrivateRoute = ({
  component: RouteComponent,
  roles,
  requiredPermission,
}: PrivateRoutesProps) => {
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const userRole = useAppSelector((state) => state.auth.role);
  const permissions = useAppSelector((state) => state.auth.permissions);

  const userHasRequiredRole = roles.includes(userRole);

  if (isAuth && userHasRequiredRole) {
    if (requiredPermission) {
      if (permissions[`${requiredPermission}`]) {
        return <RouteComponent />;
      } else {
        return <AccessDenied />;
      }
    }
    return <RouteComponent />;
  }
  if (isAuth && !userHasRequiredRole) {
    return <AccessDenied />;
  }
  return <Navigate to={ROOT_PATH} />;
};

export default PrivateRoute;
