import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, ReactElement, FormEvent } from "react";
import { ValidationCrossIcon, ValidationTickIcon } from "../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { Form, Input, Button } from "antd";
import { authActions } from "../../../__store/slices";
import { authThunk } from "../../../__store/thunks";
import { KeyInputIcon } from "../../../assets/icons/";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import styles from "./NewPassword.module.scss";

import { RoutesPaths } from "../../../data/config";
import { authData } from "../../../data/constants";

const { ROOT_PATH } = RoutesPaths;
const { newPasswordTexts, changePasswordTexts } = authData;

export interface ValidationErrors {
  message: string;
  isValid: boolean;
}

const NewPassword = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<ValidationErrors[]>([
    { message: "minmum 8 znaków", isValid: false },
    { message: "1 wielką literę", isValid: false },
    { message: "1 cyfrę", isValid: false },
    { message: "1 znak specjalny", isValid: false },
  ]);
  const [passwords, setPasswords] = useState<{
    newPassword: string;
    repatedNewPassword: string;
  }>({
    newPassword: "",
    repatedNewPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState<{
    newPassword: boolean;
    repatedNewPassword: boolean;
  }>({
    newPassword: false,
    repatedNewPassword: false,
  });

  const { AUTHORIZATION } = RoutesPaths;
  const { SUCCESSFULL_CHANGING_PASSWORD_PATH } = AUTHORIZATION;
  const [form] = Form.useForm();

  const newPasswordError = useAppSelector(
    (state) => state.auth.newPasswordErrorMessage
  );

  const [errorMessage, setErrorMessage] = useState<string>("");

  const firstLoginCredentials = useAppSelector(
    (state) => state.auth.firsLoginCreddentials
  );
  const firstLogin = useAppSelector((state) => state.auth.firstLogin);
  const changePassword = useAppSelector((state) => state.auth.changePassword);

  const { login, password } = firstLoginCredentials || {};

  const location = useLocation();

  const handleInputOnFocus = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (e.currentTarget["name"] === "newPassword") {
      itarateThroughErrors(passwords.newPassword);
      setPasswordErrors({ newPassword: true, repatedNewPassword: false });
    } else {
      itarateThroughErrors(passwords.repatedNewPassword);
      setPasswordErrors({ newPassword: false, repatedNewPassword: true });
    }
  };

  const validateInputBorder = () => {
    switch (validateInputsLabels()) {
      case "error":
        return styles["new-password__input--error"];
      case "warning":
        return styles["new-password__input--warning"];
      case "validating":
        return styles["new-password__input"];
      case "success":
        return styles["new-password__input--success"];
      default:
        return styles["new-password__input"];
    }
  };

  const validateInputsLabels = ():
    | "error"
    | "warning"
    | "success"
    | "validating" => {
    const errorslength = errors.length;

    if (
      errors.filter((error) => error.isValid).length === errorslength &&
      passwords.newPassword === passwords.repatedNewPassword
    ) {
      return "success";
    } else if (
      (errors.filter((error) => !error.isValid).length === errorslength ||
        passwords.newPassword !== passwords.repatedNewPassword) &&
      passwords.newPassword !== ""
    ) {
      return "error";
    } else if (
      errors.some((error) => !error.isValid) &&
      passwords.newPassword === passwords.repatedNewPassword &&
      passwords.newPassword !== "" &&
      passwords.repatedNewPassword !== ""
    ) {
      return "warning";
    }

    return "validating";
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget["name"];

    itarateThroughErrors(e.currentTarget.value);

    setPasswords({ ...passwords, [name]: e.currentTarget.value });
  };
  useEffect(() => {
    setErrorMessage(newPasswordError);
  }, [newPasswordError]);

  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const handleInputOnBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const name = e.currentTarget["name"];

    setPasswordErrors({ ...passwordErrors, [name]: false });
  };

  const itarateThroughErrors = (iterator: string) => {
    errors.forEach((error, index) => {
      switch (index) {
        case 0:
          iterator.length < 8
            ? (error.isValid = false)
            : (error.isValid = true);
          break;
        case 1:
          /[A-Z]/.test(iterator)
            ? (error.isValid = true)
            : (error.isValid = false);
          break;
        case 2:
          /\d/.test(iterator)
            ? (error.isValid = true)
            : (error.isValid = false);
          break;
        case 3:
          /[!@#$%^&*?+=\-_(){}]/.test(iterator)
            ? (error.isValid = true)
            : (error.isValid = false);
          break;
      }
    });
  };
  useEffect(() => {
    if (isAuth) {
      navigate(SUCCESSFULL_CHANGING_PASSWORD_PATH);
    }
  }, [isAuth, dispatch, navigate]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (location.pathname === AUTHORIZATION.NEW_PASSWORD_PATH) {
      dispatch(
        authThunk.newPassword({
          login,
          password,
          newPassword: passwords.newPassword,
        })
      );
    } else {
      dispatch(
        authThunk.changePasswordMethod({ 
          login,
          password, 
          newPassword: passwords.newPassword 
        })
      );
    }
  };

  useEffect(() => {
    dispatch(authActions.clearRecoveryStatus());
  }, [dispatch]);

  useEffect(() => {
    if (!firstLogin && !changePassword) {
      navigate(ROOT_PATH);
    }
  }, [firstLogin, changePassword]);

  return (
    <Form
      form={form}
      onSubmitCapture={handleSubmit}
      className={styles["new-password"]}
      layout="vertical"
    >
      <h2 
        className={styles["new-password__header"]}
        data-testid="new-password__header"
      >
        {newPasswordTexts.NEW_PASSWORD_HEADER}
      </h2>

      <div className={styles["new-password__description"]}>
        {validateInputsLabels() === "error" && passwords.newPassword !== "" ? (
          <Form.Item>
            <h3 className={styles["new-password__description__error-header"]}>
              {passwords.newPassword !== passwords.repatedNewPassword
                ? changePasswordTexts.PASSWORDS_NOT_THE_SAME_ERROR
                : changePasswordTexts.SOMETHING_IS_NOT_OK_WITH_PASSWORD_ERROR}
            </h3>
          </Form.Item>
        ) : (
          <p className={styles["new-password__description__text"]}>
            {location.pathname === AUTHORIZATION.NEW_PASSWORD_PATH
              ? newPasswordTexts.NEW_PASSWORD_DESCRIPTION
              : changePasswordTexts.CHANGE_PASSWORD_DESCRIPTION}
          </p>
        )}
      </div>

      <Form.Item
        label={<label className={validateInputBorder()}>Nowe hasło</label>}
      >
        <Input.Password
          value={passwords.newPassword}
          size="large"
          onFocus={(e) => {
            handleInputOnFocus(e);
          }}
          onChange={(e) => {
            handleOnInputChange(e);
          }}
          name="newPassword"
          onBlur={(e) => {
            handleInputOnBlur(e);
          }}
          data-testid="new-password__input"
          className={validateInputBorder()}
          placeholder="podaj nowe hasło"
          prefix={<KeyInputIcon />}
        />
      </Form.Item>
      {validateInputsLabels() === "warning" && passwords.newPassword !== "" ? (
        <p className={styles["new-password__warning-pargraph"]}>
          {changePasswordTexts.SOMETHING_IS_NOT_OK_WITH_PASSWORD_WARNING}
        </p>
      ) : (
        <></>
      )}

      {passwordErrors.newPassword ? (
        <Form.Item>
          <p>Nowe hasło powinno zawierać:</p>
          <ul className={styles["new-password__paragraphs"]}>
            {errors.map((error) => (
              <li
                className={
                  error.isValid
                    ? styles["new-password__paragraphs--valid-paragrah"]
                    : styles["new-password__paragraphs--error-paragraph"]
                }
              >
                {error.isValid ? (
                  <ValidationTickIcon />
                ) : (
                  <ValidationCrossIcon />
                )}{" "}
                {error.message}
              </li>
            ))}
          </ul>
        </Form.Item>
      ) : (
        <></>
      )}

      <Form.Item
        label={
          <label className={validateInputBorder()}>Powtórz nowe hasło</label>
        }
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          size="large"
          value={passwords.repatedNewPassword}
          name="repatedNewPassword"
          onFocus={(e) => {
            handleInputOnFocus(e);
          }}
          onChange={(e) => {
            handleOnInputChange(e);
          }}
          onBlur={(e) => {
            handleInputOnBlur(e);
          }}
          data-testid="new-password__repeat-input"
          className={validateInputBorder()}
          placeholder="powtórz nowe hasło"
          prefix={<KeyInputIcon />}
        />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          disabled={
            errors.find((error) => !error.isValid) ||
            passwords.newPassword !== passwords.repatedNewPassword
              ? true
              : false
          }
          htmlType="submit"
          data-testid="new-password__submit-button"
          className={styles["new-password__submit-button"]}
        >
          Zmień hasło
        </Button>
      </Form.Item>
      <p className={styles["new-password__error-pargraph"]}>{errorMessage}</p>
    </Form>
  );
};

export default NewPassword;
