import { ReactElement } from "react";

import { 
  BonusInstructions, 
  BonusWorkerTable, 
  BonusWorkerPaymentTable, 
  BonusManagerTable,
  BonusGeneralReport
} from ".";

import { bonusData } from "../../../data/constants";

interface IBonusContent {
  selectedSection: bonusData.BonusSections;
}

const BonusContent = (
    { selectedSection } : IBonusContent
  ): ReactElement => {

    let selectedSectionComponent;

    switch (selectedSection) {
      case bonusData.BonusSections.WORKER_BONUSES:
        selectedSectionComponent = <BonusWorkerTable />
        break;

      case bonusData.BonusSections.WORKER_PAYMENTS:
        selectedSectionComponent = <BonusWorkerPaymentTable />
        break;

      case bonusData.BonusSections.MANAGER_GENERATION:
        selectedSectionComponent = <BonusManagerTable />
        break;

      case bonusData.BonusSections.MANAGER_AUTOMATION:
        selectedSectionComponent = <div>Automatyzacja premii</div>
        break;
      
      case bonusData.BonusSections.MANAGER_REPORT:
        selectedSectionComponent = <BonusGeneralReport />
        break;

      case bonusData.BonusSections.INSTRUCTIONS:
        selectedSectionComponent = <BonusInstructions />
        break;
    
      default:
        selectedSectionComponent = <div>Premie</div>
        break;
    };

    return selectedSectionComponent;
  };

export default BonusContent;
