import { evaluationModels } from "../../models";
import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";
import axiosFilesInstance from "../../tools/axiosFiles";

const { 
  EVALUATIONS, 
  EVALUATION_USERS, 
  EVALUATION_COMMENTS, 
  EVALUATION_STATUSES
} = ServerPaths;

export const getUsers = async () => {
  return await axiosInstance.get(EVALUATION_USERS);
};

export const postEvaluation = async (payload: any) => {
  return await axiosFilesInstance.post(EVALUATIONS, payload);
};

export const postEvaluationComment = async (
  payload: evaluationModels.CreateEvaluationCommentData
) => {
  return await axiosInstance.post(EVALUATION_COMMENTS, { data: payload });
};
export const getUserStatuses = async () => {
  return axiosInstance.get(EVALUATION_STATUSES);
};

export const getFilteredUserStatuses = async (search: string) => {
  return axiosInstance.get(`${EVALUATION_STATUSES}?search=${search}`);
};

export const getFilterdUsersToEvaluate = async (search: string) => {
  return await axiosInstance.get(`${EVALUATION_USERS}?search=${search}`);
};

export const getUserRates = async (id: number) => {
  return axiosInstance.get(`${EVALUATIONS}/${id}`);
};

export const getFilteredEvaluations = async ({
  type,
  search,
  user_id,
}: evaluationModels.FilterEvaluationParams) => {
  return axiosInstance.get(
    `${EVALUATIONS}/${user_id}?type=${type}&search=${search}`
  );
};
