import React, { ReactElement, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button, Icon, SubmitButton } from "../../components/ui";

import styles from "./EditAreaForm.module.scss";

import { structureData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";

const {
  DEPARTMENT_NAME_MAX_LENGTH,
  DEPARTMENT_NAME_MIN_LENGTH,
  areaInputNames,
  departmentMessages,
  editAreaButtons,
  editAreaLabels,
} = structureData;

interface EditAreaFormValues {
  area_name: string;
}

function EditAreaForm(): ReactElement {
  const dispatch = useAppDispatch();
  const selectedArea = useAppSelector(
    (state) => state.departments.selectedArea
  );

  const [formValues, setFormValues] = useState<EditAreaFormValues>({
    area_name: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function onSubmitHandler(event: React.FormEvent) {
    event.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (formValues.area_name.length < DEPARTMENT_NAME_MIN_LENGTH) {
      newErrors[areaInputNames.NAME] = departmentMessages.NAME_TOO_SHORT;
    } else if (formValues.area_name.length > DEPARTMENT_NAME_MAX_LENGTH) {
      newErrors[areaInputNames.NAME] = departmentMessages.NAME_TOO_LONG;
    } else if (formValues.area_name.trim() === "") {
      newErrors[areaInputNames.NAME] = departmentMessages.NAME_REQUIRED;
    }

    if (Object.keys(newErrors).length === 0) {
      dispatch(
        departmentThunk.editArea({
          area_id: selectedArea,
          area_name: formValues.area_name.trim(),
        })
      );
    } else {
      setErrors(newErrors);
    }
  }

  function onCancelHandler() {
    dispatch(modalActions.closeModal());
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  return (
    <div className={styles["form"]}>
      <div className={styles["form__header"]}>
        <div className={styles["form__header__text"]}>
          <span>{editAreaLabels.TITLE}</span>
        </div>
        <div className={styles["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={onCancelHandler} />
        </div>
      </div>
      <form className={styles["form__body"]} onSubmit={onSubmitHandler}>
        <div className={styles["form-group"]}>
          <input
            type="text"
            name={areaInputNames.NAME}
            placeholder={editAreaLabels.NAME}
            className={styles["form-group__input"]}
            value={formValues.area_name}
            onChange={handleInputChange}
          />
          {errors[areaInputNames.NAME] && (
            <div className={styles["form-group__error"]}>
              {errors[areaInputNames.NAME]}
            </div>
          )}
        </div>

        <div className={styles["form-buttons"]}>
          <Button
            textValue={editAreaButtons.CANCEL_BUTTON}
            buttonClassName="cancel"
            buttonFunction={onCancelHandler}
            hiddenStyles=""
            type="button"
          />
          <SubmitButton
            buttonClassName="submit-button"
            value={editAreaButtons.SEND_BUTTON}
          />
        </div>
      </form>
    </div>
  );
}

export default EditAreaForm;
