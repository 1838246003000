import { createSlice } from "@reduxjs/toolkit";

import { SliceNames } from "../../data/config";
import { migrationModels } from "../models";

const initialState: migrationModels.MigrationState = {
  migratingSuggestionsEvaluatorId: null,
  temporaryEvaluatorId: null,
  revertingSuggestionsEvaluatorId: null,
  departmentWorkers: [],
  parentSupervisors: [],
};

const migrationSlice = createSlice({
  name: SliceNames.MIGRATION,
  initialState,
  reducers: {
    setMigratingEvaluatorId: (state, { payload }) => {
      state.migratingSuggestionsEvaluatorId = payload;
    },
    setTemporaryEvaluatorId: (state, { payload }) => {
      state.temporaryEvaluatorId = payload;
    },
    setRevertingEvaluatorId: (state, { payload }) => {
      state.revertingSuggestionsEvaluatorId = payload;
    },
    setDepartmentWorkers: (state, { payload }) => {
      state.departmentWorkers = payload;
    },
    setParentSupervisors: (state, { payload }) => {
      state.parentSupervisors = payload;
    },
  },
});

export default migrationSlice.reducer;

export const {
  setMigratingEvaluatorId,
  setTemporaryEvaluatorId,
  setRevertingEvaluatorId,
  setParentSupervisors,
  setDepartmentWorkers,
} = migrationSlice.actions;
