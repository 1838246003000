import { useAppSelector } from "../../../__store/tools/hooks";
import styles from "./Button.module.scss";

import { UserRoles } from "../../../data/config";

interface ButtonProps {
  textValue: string;
  buttonClassName: string;
  hiddenStyles: string;
  buttonFunction: () => void;
  disabled?: boolean;
  type?: "submit" | "button";
}
const Button = ({
  textValue,
  buttonClassName,
  buttonFunction,
  hiddenStyles,
  disabled = false,
  type = "submit",
}: ButtonProps) => {
  const userRole = useAppSelector((state) => state.auth.role);

  return (
    <button
      disabled={disabled}
      className={
        (userRole === UserRoles.ADMIN || userRole === UserRoles.WORKER) &&
        buttonClassName === "button"
          ? `${styles[buttonClassName]} ${styles[hiddenStyles]} `
          : `${styles[buttonClassName]} ${styles[hiddenStyles]}  ${styles["manager-button"]}`
      }
      type={type}
      onClick={() => buttonFunction()}
    >
      {textValue}
    </button>
  );
};

export default Button;
