import { AxiosResponse } from "axios";
import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";

const { USER } = ServerPaths;

export const getUsers = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${USER}/`);

export const getUser = (payload: any): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${USER}/${payload}/`);

export const postUser = (payload: any): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${USER}`, {
    ...payload,
  });
};
export const deleteUser = (payload: any): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.delete(`${USER}/`, { data: { ...payload } });
};

export const patchUser = (
  updatePayload: any
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.patch(`${USER}/`, { ...updatePayload });
};

export const toFormData = (dataObject: any, dataObject1?: any): FormData => {
  const formData = new FormData();
  for (const k in dataObject) {
    if (dataObject.hasOwnProperty(k)) {
      if (dataObject[k] && dataObject[k].files) {
        const file = dataObject[k].files[0];
        formData.append(k, file, file.name);
      } else if (dataObject[k] && dataObject[k] instanceof File) {
        formData.append(k, dataObject[k], dataObject[k].name);
      } else if (Array.isArray(dataObject[k])) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else if (
        typeof dataObject[k] === "object" &&
        !(dataObject[k] instanceof File)
      ) {
        formData.append(k, JSON.stringify(dataObject[k]));
      } else {
        formData.append(k, dataObject[k]);
      }
    }
  }
  if (dataObject1) {
    for (const k in dataObject1) {
      if (dataObject1.hasOwnProperty(k)) {
        if (dataObject1[k] && dataObject1[k].files) {
          const file = dataObject1[k].files[0];
          formData.append(k, file, file.name);
        } else if (dataObject1[k] && dataObject1[k] instanceof File) {
          formData.append(k, dataObject1[k], dataObject1[k].name);
        } else if (Array.isArray(dataObject1[k])) {
          formData.append(k, JSON.stringify(dataObject1[k]));
        } else if (
          typeof dataObject[k] === "object" &&
          !(dataObject[k] instanceof File)
        ) {
          formData.append(k, JSON.stringify(dataObject1[k]));
        } else {
          formData.append(k, dataObject1[k]);
        }
      }
    }
  }

  return formData;
};
