import { createSlice } from "@reduxjs/toolkit";

import { departmentModels } from "../models";

import { SliceNames, UserRoles } from "../../data/config";

export const initialState: departmentModels.DepartmentState = {
  superVisor: {
    supervisors: [],
  },
  reportForWorker: true,
  areas: [],
  departments: [],
  formUsers: [],
  positionList: [],
  newDepartmentsList: [],
  mainDepartments: [],
  structureDepartments: [],
  structureChildDepartments: [],
  breadcrumbs: [],
  superVisorDepartment: null,
  department: null,
  draggedDepartment: 0,
  selectedDepartment: 0,
  formError: "",
  jobPositionId: 0,
  departmentsWithSupervisors: {},
  area: null,
  selectedArea: 0,
};

const departmentSlice = createSlice({
  name: SliceNames.DEPARTMENTS,
  initialState,
  reducers: {
    setDepartments: (state, { payload }) => {
      state.departments = payload.sort(
        (a: departmentModels.Department, b: departmentModels.Department) =>
          a.name.localeCompare(b.name)
      );
      state.newDepartmentsList = payload
        .map((item: departmentModels.Department) => {
          return {
            department_id: item.department_id,
            name: item.name,
          };
        })
        .sort(
          (a: departmentModels.Department, b: departmentModels.Department) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        );
    },
    setSuperVisors: (state, { payload }) => {
      state.superVisor = payload;
    },
    setAreas: (state, { payload }) => {
      state.areas = payload;
    },
    setArea: (state, { payload }) => {
      state.area = payload;
    },
    setSelectedArea: (state, { payload }) => {
      state.selectedArea = payload;
    },
    setFormUsers: (state, { payload }) => {
      state.formUsers = payload;
    },
    setPositionsList: (state, { payload }) => {
      state.positionList = payload.sort(
        (a: departmentModels.Department, b: departmentModels.Department) =>
          a.name.localeCompare(b.name)
      );
    },
    setReportForWorker: (state, { payload }) => {
      state.reportForWorker = payload;
    },
    setStructureDepartment: (state, { payload }) => {
      state.structureDepartments = payload;
    },
    setMainDepartments: (state, { payload }) => {
      state.mainDepartments = payload;
    },
    setSuperVisorDepartment: (state, { payload }) => {
      state.superVisorDepartment = payload;
    },
    setStructureChildDepartments: (state, { payload }) => {
      state.structureChildDepartments = payload;
    },
    setDepartment: (state, { payload }) => {
      state.department = payload;
      if (state.department)
        state.department.job_position = payload.job_position.sort(
          (a: { role: string }, b: { role: string }) =>
            a.role === UserRoles.MANAGER
              ? -1
              : b.role === UserRoles.MANAGER
              ? 1
              : 0
        );
    },
    setDraggedDepartment: (state, { payload }) => {
      state.draggedDepartment = payload;
    },
    setBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload;
    },
    setSelectedDepartment: (state, { payload }) => {
      state.selectedDepartment = payload;
    },
    setFormError: (state, { payload }) => {
      state.formError = payload;
    },
    clearFormError: (state) => {
      state.formError = "";
    },
    setJobPositionId: (state, { payload }) => {
      state.jobPositionId = payload;
    },
    setDepartmentsWithSupervisors: (state, { payload }) => {
      state.departmentsWithSupervisors = payload;
    },
    setDepartmentInitialState: () => initialState,
  },
  extraReducers: (builder) => null,
});

export const {
  setDepartments,
  setSuperVisors,
  setFormUsers,
  setReportForWorker,
  setPositionsList,
  setSuperVisorDepartment,
  setMainDepartments,
  setStructureChildDepartments,
  setDepartment,
  setAreas,
  setArea,
  setSelectedArea,
  setDraggedDepartment,
  setStructureDepartment,
  setBreadcrumbs,
  setSelectedDepartment,
  setFormError,
  clearFormError,
  setJobPositionId,
  setDepartmentsWithSupervisors,
  setDepartmentInitialState,
} = departmentSlice.actions;

export default departmentSlice.reducer;
