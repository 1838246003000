import {
  MainStatuses, 
  AllStatuses, 
  ReportSubstatuses, 
  RealizationSubstatuses, 
  VerificationSubstatuses, 
  CreationSubstatuses, 
  RejectionSubstatuses
} from "../../../data/config/suggestionStatuses.data";

import { Tag } from "antd";

type SuggestionFlowMatrix = {
  [key in MainStatuses]: {
    [key: string]: { name: string, style: string }
  }
};

const suggestionFlowMatrix: SuggestionFlowMatrix = {
  [MainStatuses.REPORT]: {
    [ReportSubstatuses.UNMARKED]: { name: "nowa", style: "green" },
    [ReportSubstatuses.WITHDRAWN]: { name: "cofnięta", style: "orange" },
    [ReportSubstatuses.REDIRECTED]: { name: "przekierowana", style: "green" },
    [ReportSubstatuses.CORRECTED]: { name: "poprawiona", style: "green" },
  },
  [MainStatuses.REALIZATION]: {
    [RealizationSubstatuses.DURING_REALIZATION]: { name: "realizowana", style: "blue" },
    [RealizationSubstatuses.RETURNED_FROM_VERIFICATION]: { name: "do poprawy", style: "orange" },
  },
  [MainStatuses.VERIFICATION]: {
    [VerificationSubstatuses.SUBMITTED_FOR_VERIFICATION]: { name: "sprawdzana", style: "violet" },
  },
  [MainStatuses.CREATION]: {
    [CreationSubstatuses.COMPLETED]: { name: "wdrożona", style: "green" },
    [CreationSubstatuses.RESULT]: { name: "rezultat", style: "grey" },
  },
  [MainStatuses.REJECTION]: {
    [RejectionSubstatuses.REVOCABLE]: { name: "odrzucona", style: "red" },
    [RejectionSubstatuses.CLOSED]: { name: "zamknięta", style: "red" },
  },
  [MainStatuses.APPEALED]: {
    [AllStatuses.APPEALED]: { name: "odwołana", style: "orange" },
  },
};

const SuggestionStatusTag = ({ status, substatus }: { status: MainStatuses, substatus: any }) => {
  const getTag = () => {
    if (substatus === AllStatuses.NOTE) {
      return <Tag color="grey">Notatka</Tag>;
    } else if (substatus === AllStatuses.EXTERNAL_COMMENT) {
      return <Tag color="blue">Komentarz</Tag>;
    } else if (suggestionFlowMatrix[status]) {
      if (status === MainStatuses.REPORT && !substatus) {
        const { name, style } = suggestionFlowMatrix[status][ReportSubstatuses.UNMARKED];
        return <Tag color={style}>{name}</Tag>;
      } else if (suggestionFlowMatrix[status][substatus]) {
        const { name, style } = suggestionFlowMatrix[status][substatus];
        return <Tag color={style}>{name}</Tag>;
      }
    } else {
      return <Tag color="default">Nieznany status</Tag>;
    }
  };

  return <>{getTag()}</>;
};

export default SuggestionStatusTag;
