// @ts-nocheck
// check token type
import axios, { AxiosRequestConfig } from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";
import dayjs from "dayjs";

import ServerPaths from "../../data/config/serverPaths.data";
import LOCAL_STORAGE_KEYS from "../../data/config/localStorage.data";

const { BASE, TOKEN, LOGOUT } = ServerPaths;
const { ACCESS_TOKEN, REFRESH_TOKEN } = LOCAL_STORAGE_KEYS;

const jwt = localStorage.getItem(ACCESS_TOKEN);
let token = jwt ? JSON.parse(jwt) : "";

const axiosInstance = axios.create({
  baseURL: BASE,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : null,
  },
});

export async function checkToken(req: AxiosRequestConfig) {
  const jwt = localStorage.getItem(ACCESS_TOKEN);
  let token = jwt ? JSON.parse(jwt) : "";
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  if (!token) return req;
  const user: JwtPayload & { user_id: number } = jwtDecode(token);
  const isExpired = dayjs.unix(user.exp as number).diff(dayjs()) < 1;
  if (!isExpired) return req;
  const refresh = localStorage.getItem(REFRESH_TOKEN);
  const refreshToken = refresh ? JSON.parse(refresh) : "";
  try {
    const response = await axios.post(`${BASE}${TOKEN}`, {
      refreshToken,
    });
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.accessToken));
    req.headers.Authorization = `Bearer ${response.data.accessToken}`;
    return req;
  } catch (err) {
    await axios.post(`${BASE}${LOGOUT}`, {
      user_id: user.user_id,
    });
    window.location.replace(LOGOUT);
  }
}

axiosInstance.interceptors.request.use(checkToken);

export default axiosInstance;
