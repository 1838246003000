// USER contract types - types with translation map

export enum ContractTypes {
  EMPLOYMENT_INDEFINITE = "EMPLOYMENT_INDEFINITE",
  MANDATE = "MANDATE",
  B2B = "B2B",
  CONTRACT = "CONTRACT",
  UNSPECIFIED = "UNSPECIFIED",
};

export const ContractTypesTranslated = new Map([
  [ContractTypes.UNSPECIFIED, "Niesprecyzowana"],
  [ContractTypes.EMPLOYMENT_INDEFINITE, "Umowa o pracę"],
  [ContractTypes.MANDATE, "Umowa zlecenie"],
  [ContractTypes.B2B, "Umowa B2B"],
  [ContractTypes.CONTRACT, "Umowa o dzieło"],
]);

// USER employment statuses - types with translation map

export enum EmploymentStatuses {
  WORKING = "WORKING",
  FIRED = "FIRED",
  SICKNESS = "SICKNESS"
};

export const EmploymentStatusesTranslated = new Map([
  [EmploymentStatuses.WORKING, "Zatrudniony"],
  [EmploymentStatuses.SICKNESS, "L4"],
  [EmploymentStatuses.FIRED, "Zwolniony"],
]);

// ADMIN DASHBOARD data

// AVATAR STYLES TO UPDATE
export const userProfileAvatarStyles = (isActive?: boolean) => {
  return {
    backgroundColor: isActive ? "#391580" : "gray",
    borderRadius: "50%",
    height: "60%",
    width: "60%",
    fontSize: "60px",
    aspectRatio: "auto",
    fontWeight: "600",
    display: "flex",
    fontFamily: "Roboto, sans-serif",
    alignItems: "center",
  };
};

export const avatarStyles = (isActive?: boolean, bgColor?: string, ) => {
  return {
    backgroundColor: isActive ? bgColor ? bgColor : "#391580" : "gray",
    verticalAlign: "middle",
  };
};

export const iconStyles = { cursor: "pointer" };

export const dashboardAdminLabels = {
  ALL_WORKERS: "Wszyscy pracownicy",
  WORKERS_LIST: "Lista pracowników",
  WORKER_COLUMN: "Pracownik",
  DEPARTMENT_COLUMN: "Dział",
  STATUS_COLUMN: "Status",
  ACTIONS_COLUMN: "Edycja",
  NONE_DEPARTMENT: "Brak",
};

export const dashboardAdminButtons = {
  FILTER_RESET_BUTTON: "Resetuj",
  FILTER_SEARCH_BUTTON: "Filtruj",
  EDIT_USER_DATA_BUTTON: "Edytuj dane",
  EDIT_USER_CREDENTIAL_BUTTON: "Edytuj hasło i login",
  SWITCH_ACTIVITY_BUTTON: "Zarządzaj aktywnością",
};

// SETTINGS data

export const settingsAvatarStyles = { backgroundColor: "#2256bb" };
export const settingsIconStyles = { paddingRight: "10%" };
export const settingsIconHeight = "70%";

// STRUCTURE components data

export const departmentDetailsLabels = {
  NONE_DEPARTMENTS: "Nie znaleziono działów",
  NONE_SUPERVISOR: "Brak przełożonego",
  POSITIONS_LIST: "Lista stanowisk",
};

export const departmentsListHeader = "Działy";

export const structurePageHeader = "Struktura";
export const structurePageButtons = {
  ADD_AREA_BUTTON: "Dodaj obszar",
  ADD_DEPARTMENT_BUTTON: "Dodaj dział",
  ADD_POSITION_BUTTON: "Dodaj stanowisko",
};
