import { ReactElement } from "react";

import styled from "../UpdateUserForm.module.scss";
import { Alert } from "antd";

import {
  ActivationBoxTags,
  ActivationSwitch,
  DecisionRadioBox,
  MultiUsersSelect,
} from "."

import { UserRoles } from "../../../../data/config";

import { IActivationDecisionResult } from "./activation.types";
import { activationData } from "../../../../data/constants";

const { ACTIVATION_MESSAGES } = activationData;

const ActivationDecisionResult = ({ 
    userID, 
    userRole, 
    printedUserRole,
    isActuallyActive, 
    willBeActive, 
    handleChangeAccountActivity,
    actualEmploymentStatus,
    targetEmploymentStatus,
    handleChangeEmploymentStatus,
    actualDepartment, 
    parentSupervisors,
    departmentWorkers,
    selectedSupervisor,
    selectedWorkerToTempManager,
    selectSupervisorHandler,
    selectWorkerToTempHandler,
    selectedDecision,
    selectDecisionHandler,
    revertTempManager,
    handleRevertingTempManager,
  } : IActivationDecisionResult) : ReactElement => {

    let decisionInfo = "";
    let decisionSelect = null;

    switch (userRole) {
      case UserRoles.ADMIN:
        decisionInfo = ACTIVATION_MESSAGES.ADMIN_DEACTIVATION_FORBIDDEN;
        break;

      case UserRoles.WORKER:
        if (isActuallyActive) decisionInfo = ACTIVATION_MESSAGES.WORKER_DEACTIVATION;
        else decisionInfo = ACTIVATION_MESSAGES.WORKER_REACTIVATION;
        break;

      case UserRoles.BOSS:
      case UserRoles.MANAGER:
          if (!isActuallyActive) {
            if (actualDepartment?.supervisors.length === 1) {
              decisionInfo = ACTIVATION_MESSAGES.MANAGER_REACTIVATION_TEMP_MANAGER_DECISION;

              decisionSelect = (
              <Alert
                message={null}
                description={
                  <ActivationSwitch 
                    isDisabled={willBeActive === isActuallyActive}
                    revertTempManager={revertTempManager}
                    handleRevertingTempManager={handleRevertingTempManager}
                  />}
                type="info"
              />)
            } else {
              decisionInfo = ACTIVATION_MESSAGES.MANAGER_VANILLA_REACTIVATION;
            }
          } else if (isActuallyActive) {
            if (actualDepartment?.supervisors.length === (0 || 1)) {

              decisionInfo = ACTIVATION_MESSAGES.ONLY_MANAGER_DEACTIVATION;

              decisionSelect = (
              <>
                <span className={styled["activation-description"]}>{ACTIVATION_MESSAGES.REDIRECT_SUGGESTIONS_RADIO}</span>
                <DecisionRadioBox 
                  userID={userID}
                  workersList={departmentWorkers}
                  parentsList={parentSupervisors}
                  decisionSection={selectedDecision} 
                  decisionHandler={selectDecisionHandler} 
                  selectedSupervisor={selectedSupervisor}
                  selectedWorkerToTempManager={selectedWorkerToTempManager}
                  selectSupervisorHandler={selectSupervisorHandler}
                  selectWorkerToTempHandler={selectWorkerToTempHandler}
                  isDisabled={willBeActive === isActuallyActive}
                />
              </>);

            } else if (actualDepartment?.supervisors.length === 2) {

              decisionInfo = ACTIVATION_MESSAGES.MANAGER_DEACTIVATION_WITH_SECOND_EXISTS;

              decisionSelect = (
                <span className={styled["activation-description"]}>{ACTIVATION_MESSAGES.REDIRECT_SUGGESTIONS} 
                  <strong>{actualDepartment?.supervisors
                    .filter(supervisor => supervisor.user_id != userID)
                    .map(supervisor => `${supervisor.profile.name} ${supervisor.profile.surname}`)}.
                  </strong>
                </span>);

            } else {

              decisionInfo = ACTIVATION_MESSAGES.MANAGER_DEACTIVATION_WITH_MANY_OTHER_EXIST;

              decisionSelect = (
              <div className={styled["activation-select"]}>
                <span className={styled["activation-description"]}>{ACTIVATION_MESSAGES.REDIRECT_SUGGESTIONS}</span>
                <MultiUsersSelect 
                  userID={userID}
                  usersArray={actualDepartment?.supervisors} 
                  selectedUser={selectedSupervisor}
                  selectHandler={selectSupervisorHandler}  
                  isDisabled={willBeActive === isActuallyActive}
                />
              </div>);
            }
          }
        break;

      default:
        decisionInfo = ACTIVATION_MESSAGES.OPERATION_NOT_HANDLED;
        break;
    }

    return (
      <div className={styled["form__context__activation-box"]}>
        <div className={styled["activation-box__body"]}>
          <div className={styled["activation-box__section"]}>
            <Alert message={null} description={<span>{decisionInfo}</span>} type="warning" showIcon />
            <ActivationBoxTags
                userRole={userRole}
                printedUserRole={printedUserRole}
                isActuallyActive={isActuallyActive}
                willBeActive={willBeActive}
                handleChangeAccountActivity={handleChangeAccountActivity}
                actualEmploymentStatus={actualEmploymentStatus}
                targetEmploymentStatus={targetEmploymentStatus}
                handleChangeEmploymentStatus={handleChangeEmploymentStatus}
            />
          </div>
        </div>  
        <div className={styled["activation-box__footer"]}>
          <div className={styled["activation-box__action"]}>{decisionSelect}</div>
        </div>
      </div>
    );
  };

  export default ActivationDecisionResult;
