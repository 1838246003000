import React, { useEffect, useState } from "react";
import { serialize } from "object-to-formdata";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { evaluationModels } from "../../../__store/models";
import { evaluationThunk } from "../../../__store/thunks";

import { Upload, Button as AntButton } from "antd";
import { Icon } from "../../components/ui";

import styles from "./CreateAssessmentForm.module.scss";

import { IconTypes } from "../../../data/config";
import { suggestionsData } from "../../../data/constants";

const { EvaluationCategory } = evaluationModels;
const { FILE_SIZE_MAX, FILE_SIZE_TOO_BIG } = suggestionsData;

export interface CreateAssessmentFromState {
  category: evaluationModels.EvaluationCategory | undefined;
  content: string;
  custom_category?: string;
}

const CreateAssessmentForm = () => {
  const dispatch = useAppDispatch();

  const evaluationType = useAppSelector(
    (state) => state.evaluation.evaluationType
  );

  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );
  const user_id = useAppSelector((state) => state.auth.user_id);
  const evaluated_user_id = useAppSelector(
    (state) => state.evaluation.evalautingUserId
  );

  const [evaluationFiles, setEvaluationFiles] = useState<any>([]);

  const [evaluationFileSize, setEvaluationFileSize] = useState(0);

  const [assessmentCategories, setAssessmentCategories] = useState<
    Array<
      { name: string; value: evaluationModels.EvaluationCategory } | undefined
    >
  >([]);

  const [formValues, setFormValues] = useState<CreateAssessmentFromState>({
    category:
      evaluationType === "POSITIVE"
        ? evaluationModels.EvaluationCategory.INITIATIVE
        : evaluationModels.EvaluationCategory.HEALTH_AND_SAFETY_VIOLATION,
    content: "",
    custom_category: "",
  });

  useEffect(() => {
    evaluationType === "POSITIVE"
      ? setAssessmentCategories([
          { name: "Inicjatywa", value: EvaluationCategory.INITIATIVE },
          {
            name: "Wykonanie zadania",
            value: EvaluationCategory.PERFORMING_THE_TASK,
          },
          { name: "Pomoc innym", value: EvaluationCategory.HELP_OTHERS },
          {
            name: "Usprawnienie procesu",
            value: EvaluationCategory.STREAMLINING_THE_PROCESS,
          },
          { name: "Komunikacja", value: EvaluationCategory.COMMUNICATION },
          {
            name: "Odpowiedzialność za wynik pracy",
            value: EvaluationCategory.RESPONSIBILITY_FOR_THE_WORK_RESULT,
          },
          {
            name: "Pozytywny feedback",
            value: EvaluationCategory.POSITIVE_FEEDBACK,
          },
          {
            name: "Uczenie innych",
            value: EvaluationCategory.TEACHING_OTHERS,
          },
          {
            name: "Przekroczenie celu",
            value: EvaluationCategory.EXCEEDING_THE_TARGET,
          },
          {
            name: "Zgłoszenie zagrożenia",
            value: EvaluationCategory.REPORTING_DANGER,
          },
          { name: "Inne", value: EvaluationCategory.OTHER },
        ])
      : setAssessmentCategories([
          {
            name: "Wykroczenie BHP",
            value: EvaluationCategory.HEALTH_AND_SAFETY_VIOLATION,
          },
          {
            name: "Niedostarczenie zadania",
            value: EvaluationCategory.TASK_NOT_DELIVERED,
          },
          { name: "Komunikacja", value: EvaluationCategory.COMMUNICATION },
          {
            name: "Błędy w zadaniu",
            value: EvaluationCategory.ERRORS_IN_THE_TASK,
          },
          { name: "Nieterminowość", value: EvaluationCategory.LATENESS },
          {
            name: "Łamanie wartości",
            value: EvaluationCategory.BREAKING_VALUES,
          },
          {
            name: "Niewykonanie polecenia",
            value: EvaluationCategory.FAILURE_TO_EXECUTE_THE_COMMAND,
          },
          {
            name: "Brak odpowiedzialności za wyniki pracy",
            value: EvaluationCategory.NO_RESPONSIBILITY_FOR_THE_RESULT_WORK,
          },
          {
            name: "Negatywny feedback",
            value: EvaluationCategory.NEGATIVE_FEEDABCK,
          },

          {
            name: "Nieobecność na stanowisku",
            value: EvaluationCategory.ABSENCE,
          },
          {
            name: "Spóźnienie",
            value: EvaluationCategory.LATE,
          },
          {
            name: "Błędy w pracy",
            value: EvaluationCategory.MISTAKES_AT_WORK,
          },
          {
            name: "Brak realizacji celu",
            value: EvaluationCategory.FAILURE_TO_REACH_THE_GOAL,
          },
          { name: "Inne", value: EvaluationCategory.OTHER },
        ]);
  }, []);

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedCategory = event.target
      .value as evaluationModels.EvaluationCategory;
    setFormValues((prevValues) => ({
      ...prevValues,
      category: selectedCategory,
    }));
  };

  const handleCustomCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const customCategory = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      custom_category: customCategory,
    }));
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const content = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      content: content,
    }));
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      ...formValues,
      evaluation_for_date: new Date(),
      type: evaluationType,
      user_id: user_id as number,
      evaluated_user_id,
    };

    const object = {
      evaluationFiles: evaluationFiles,
      data: JSON.stringify(data),
    };

    const formData = serialize(object);

    dispatch(evaluationThunk.createEvaluation(formData));
  };

  const propsForEvaluation = {
    onRemove: (file: any) => {
      const index = evaluationFiles.indexOf(file);
      const newFileList = evaluationFiles.slice();
      newFileList.splice(index, 1);
      setEvaluationFileSize(newFileList);

      const fileSizeSum = evaluationFileSize - evaluationFiles.size;

      setEvaluationFileSize(fileSizeSum);
    },
    beforeUpload: (file: any) => {
      setEvaluationFiles([...evaluationFiles, file]);

      const fileSizeSum = evaluationFileSize + evaluationFiles.size;

      setEvaluationFileSize(fileSizeSum);

      return false;
    },
    evaluationFiles,
  };

  const validateFileSize = () =>
    evaluationFileSize > FILE_SIZE_MAX ? (
      <p className={styles["attachment-error"]}>{FILE_SIZE_TOO_BIG}</p>
    ) : (
      <></>
    );

  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  };

  const displayCorrectAssesmentHeader = () => {
    if (
      themeName &&
      ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
    ) {
      return evaluationType === "POSITIVE" ? "Do wyróżnienia" : "Do poprawy";
    } else {
      return evaluationType === "POSITIVE"
        ? "Pozytywna informacja zwrotna"
        : "Negatywna informacja zwrotna";
    }
  };
  return (
    <div className={styles["create-assessment"]}>
      <header className={styles["create-assessment__header"]}>
        <h2>{displayCorrectAssesmentHeader()}</h2>
        <Icon
          type={IconTypes.ASSESSMENT_CROSS}
          className={styles["assessment-status__header--close"]}
          onClick={handleOnClose}
        />
      </header>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <div className={styles["create-assessment__container"]}>
          <select
            name="category"
            value={formValues.category}
            onChange={handleCategoryChange}
            className={styles["create-assessment__select-category"]}
          >
            {assessmentCategories?.map((category) => (
              <option key={category?.value} value={category?.value}>
                {category?.name}
              </option>
            ))}
          </select>
          {formValues.category === "OTHER" && (
            <input
              name="custom_category"
              value={formValues.custom_category}
              onChange={handleCustomCategoryChange}
              placeholder="Dodaj tytuł"
              className={styles["create-assessment__container--input"]}
            />
          )}
          <textarea
            className={styles["create-assessment__container--add-description"]}
            name="content"
            value={formValues.content}
            onChange={handleContentChange}
            placeholder="Dodaj opis"
          ></textarea>

          <Upload className={styles["upload--open"]} {...propsForEvaluation}>
            <AntButton icon={<Icon type={IconTypes.UPLOAD} />}>
              Wybierz plik
            </AntButton>
          </Upload>

          {validateFileSize()}

          <footer className={styles["create-assessment__container--footer"]}>
            {!formValues.category ||
            !formValues.content ||
            (formValues.category === "OTHER" &&
              formValues.custom_category === "") ? (
              <div
                className={
                  styles["create-assessment__container--footer__errors"]
                }
              >
                <p>
                  Aby ocenić pracownika należy uzupełnić wszystkie pola
                  formularza
                </p>
                <button type="submit" disabled={true}>
                  Dodaj
                </button>
              </div>
            ) : (
              <button type="submit">Dodaj</button>
            )}
          </footer>
        </div>
      </form>
    </div>
  );
};

export default CreateAssessmentForm;
