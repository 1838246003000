import { useState, ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { InactiveAccount, NavBar } from "../../components/ui";

import styles from "./BonusManagerPage.module.scss";

import { 
  BonusMenu, 
  BonusTitle, 
  BonusContent  
} from "../components";

import { bonusData } from "../../../data/constants";

import { MenuProps } from "antd";
import { adminThunk, bonusThunk, departmentThunk } from "../../../__store/thunks";

const menuItems: MenuProps['items'] = [
  { label: 'Generowanie premii', key: bonusData.BonusSections.MANAGER_GENERATION },
  { label: 'Raport ogólny', key: bonusData.BonusSections.MANAGER_REPORT},
  // { label: 'Zasady wypłacania', key: bonusData.BonusSections.INSTRUCTIONS },
  // { label: 'Automatyzacja premii', key: bonusData.BonusSections.MANAGER_AUTOMATION },
];

const BonusManagerPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const [selectedSection, setSelectedSection] = useState(bonusData.BonusSections.MANAGER_GENERATION);

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);
  const user = useAppSelector((state) => state.users.authUser);
  const user_id = useAppSelector((state) => state.auth.user_id);


  useEffect(() => {
    if (user == null && user_id) {
      dispatch(adminThunk.fetchUser(user_id));
    }
  }, [user, user_id, dispatch]);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
    dispatch(bonusThunk.fetchAllBonuses());
    dispatch(adminThunk.fetchUsers());
  }, []);

  return (
    <div className={styles["bonus-page"]}>
      <NavBar /> 
      {isActive ? (
        <div className={styles["dashboard"]}>
          <div className={styles["header"]}>
            <BonusTitle title="Płatności" />
            <BonusMenu 
              menuData={menuItems}
              selectedSection={selectedSection} 
              setSelectedSection={setSelectedSection} 
            />
          </div>
          <div className={styles["container"]}>
            <BonusContent selectedSection={selectedSection} />
          </div>
        </div>) : <InactiveAccount />}
    </div>
  );
}

export default BonusManagerPage;
