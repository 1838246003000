import React, { ReactElement, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button, Icon, SubmitButton } from "../../components/ui";

import styles from "./CreateJobPositionForm.module.scss";
import { IconTypes, UserRoles, userRoles } from "../../../data/config";
import { InputTypes, structureData } from "../../../data/constants";

const {
  DEPARTMENT_NAME_MIN_LENGTH,
  DEPARTMENT_NAME_MAX_LENGTH,
  createJobPositionButtons,
  createJobPositionLabels,
  departmentMessages,
  jobPositionInputNames,
} = structureData;

const { UserRolesTranslated } = userRoles;

interface CreateJobPositionFormValues {
  name: string;
  department_id: number;
  role: string;
}

function CreateJobPositionForm(): ReactElement {
  const dispatch = useAppDispatch();

  const departmentsList = useAppSelector(
    (state) => state.departments.departments
  );

  const errorMessage = useAppSelector(
    (state) => state.departments.formError
  );

  const [formValues, setFormValues] = useState<CreateJobPositionFormValues>({
    name: "",
    department_id: 0,
    role: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function onSubmitHandler(event: React.FormEvent) {
    event.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (formValues.name.length < DEPARTMENT_NAME_MIN_LENGTH) {
      newErrors[jobPositionInputNames.NAME] =
        departmentMessages.NAME_TOO_SHORT;
    } else if (formValues.name.length > DEPARTMENT_NAME_MAX_LENGTH) {
      newErrors[jobPositionInputNames.NAME] = departmentMessages.NAME_TOO_LONG;
    } else if (formValues.name.trim() === "") {
      newErrors[jobPositionInputNames.NAME] = departmentMessages.NAME_REQUIRED;
    }

    if (formValues.department_id === 0) {
      newErrors[jobPositionInputNames.DEPARTMENT_ID] =
        departmentMessages.PARENT_REQUIRED;
    }

    if (formValues.role === "") {
      newErrors[jobPositionInputNames.ROLE] = departmentMessages.ROLE_REQUIRED;
    }

    if (Object.keys(newErrors).length === 0) {
      const { name, department_id, role } = formValues;
      dispatch(
        departmentThunk.createJobPosition({
          name: name.trim(),
          department_id,
          role,
        })
      );
    } else {
      setErrors(newErrors);
    }
  }

  function onCancelHandler() {
    dispatch(modalActions.closeModal());
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  return (
    <div className={styles["form"]}>
      <div className={styles["form__header"]}>
        <div className={styles["form__header__text"]}>
          <span>{createJobPositionLabels.TITLE}</span>
        </div>
        <div className={styles["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={onCancelHandler} />
        </div>
      </div>
      <form className={styles["form__body"]} onSubmit={onSubmitHandler}>
        <div className={styles["form-group"]}>
          <input
            type={InputTypes.TEXT}
            name={jobPositionInputNames.NAME}
            placeholder={createJobPositionLabels.NAME}
            className={styles["form-group__input"]}
            value={formValues.name}
            onChange={handleInputChange}
          />
          {errors[jobPositionInputNames.NAME] && (
            <div className={styles["form-group__error"]}>
              {errors[jobPositionInputNames.NAME]}
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <select
            name={jobPositionInputNames.DEPARTMENT_ID}
            className={styles["form-group__select"]}
            value={formValues.department_id}
            onChange={handleInputChange}
          >
            <option value="">{createJobPositionLabels.DEPARTMENT}</option>
            {departmentsList.map((department) => (
              <option
                key={department.department_id}
                value={+department.department_id}
              >
                {department.name}
              </option>
            ))}
          </select>
          {errors[jobPositionInputNames.DEPARTMENT_ID] && (
            <div className={styles["form-group__error"]}>
              {errors[jobPositionInputNames.DEPARTMENT_ID]}
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <select
            name={jobPositionInputNames.ROLE}
            className={styles["form-group__select"]}
            value={formValues.role}
            onChange={handleInputChange}
          >
            <option value="">{createJobPositionLabels.ROLE}</option>
            <option value={UserRoles.WORKER}>
              {UserRolesTranslated.get(UserRoles.WORKER)}
            </option>
            <option value={UserRoles.MANAGER}>
              {UserRolesTranslated.get(UserRoles.MANAGER)}
            </option>
          </select>
          {errors[jobPositionInputNames.ROLE] && (
            <div className={styles["form-group__error"]}>
              {errors[jobPositionInputNames.ROLE]}
            </div>
          )}
        </div>

        <div className={styles["form-error"]}>
          {errorMessage && (
            <span className={styles["form-error__text"]}>{errorMessage}</span>
          )}
        </div>

        <div className={styles["form-buttons"]}>
          <Button
            textValue={createJobPositionButtons.CANCEL_BUTTON}
            buttonClassName="cancel"
            buttonFunction={onCancelHandler}
            hiddenStyles=""
            type="button"
          />
          <SubmitButton
            buttonClassName="submit-button"
            value={createJobPositionButtons.SEND_BUTTON}
          />
        </div>
      </form>
    </div>
  );
}

export default CreateJobPositionForm;
