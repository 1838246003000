import { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { Icon } from "../../components/ui";

import styles from "./UserInfo.module.scss";

import { modalActions } from "../../../__store/slices";

import { adminData, usersData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";

const { ContractTypesTranslated } = adminData;
const { userInfoLabels } = usersData;

function UserInfo():ReactElement {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.users.userByIndex);
  
  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <div className={styles["user-info"]}>
      <div className={styles["user-info__close"]}></div>
      <div className={styles["user-info__header"]}>
        <h2 className={styles["context"]}>{userInfoLabels.PERSONAL_DATA}</h2>
        <div className={styles["user-info__header__icon"]}>
          <Icon type={IconTypes.CROSS} onClick={handleOnClose} />
        </div>
      </div>
      <div className={styles["user-info__container"]}>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.LOGIN}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.user.login}</p>
        </div>
        {user?.user.identifier && (
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.IDENTIFIER}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.user.identifier}</p>
        </div>
        )}
        {user?.email && (
          <div className={styles["user-info__container__details"]}>
            <p className={styles["user-info__container__details__name"]}>{userInfoLabels.EMAIL}</p>
            <p className={styles["user-info__container__details__value"]}>{user?.email}</p>
          </div>
        )}
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.NAME}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.name}</p>
        </div>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.SURNAME}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.surname}</p>
        </div>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.JOB_POSITION}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.job_position.name}
          </p>
        </div>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.DEPARTMENT}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.department.name || userInfoLabels.NONE_DEPARTMENT}</p>
        </div>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.AREA}</p>
          <p className={styles["user-info__container__details__value"]}>{user?.area.area_name}</p>
        </div>
        <div className={styles["user-info__container__details"]}>
          <p className={styles["user-info__container__details__name"]}>{userInfoLabels.CONTRACT}</p>
          <p className={styles["user-info__container__details__value"]}>
            {user?.contract ? ContractTypesTranslated.get(user.contract as adminData.ContractTypes) : userInfoLabels.UNKNOWN}
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
