import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { Button, Dropdown, Input, Menu, MenuProps, Space, Table, Tag, Typography } from 'antd';

import type { ColumnsType, TableProps } from 'antd/es/table';

import styles from "../views/AdminPanelPage.module.scss";

import { adminThunk, departmentThunk } from "../../../__store/thunks";

import { IconTypes, ModalClassnames } from "../../../data/config";
import { Icon } from "../../components/ui";
import { adminModels } from "../../../__store/models";
import { ACTIVATION_MESSAGES } from "../../../data/constants/activation.data";
import { dashboardAdminButtons, iconStyles } from "../../../data/constants/admin.data";
import { modalActions, usersActions } from "../../../__store/slices";
import { SpinnerModal } from "../../components/Modal";

const { Title } = Typography;
const { Search } = Input;

const { 
  ADD_USER_FORM,
  UPDATE_USER_FORM,
  ADMIN_CHANGE_CREDENTIAL,
  REMOVE_USER,
  USER_ACTIVITY,
  USER_INFO,
} = ModalClassnames;

interface IAdminUsersData {
  key: React.Key;
  number: number;
  worker: {
    id: number;
    name: string;
    surname: string;
  }
  department: string;
  status: UserStatuses;
  actions: {
    userID: number;
  }
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export enum UserStatuses {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FIRED = "FIRED"
};

const AdminUsersTable = (): ReactElement => {
  const dispatch = useAppDispatch();
  
  const [selectedTable, setSelectedTable] = useState<UserStatuses>(UserStatuses.ACTIVE);
  const [areButtonsActive, setAreButtonsActive] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const [selectedDepartment, setSelectedDepatment] = useState(0);
  const [selectedJob, setSelectedJob] = useState("all");
  const [filteredUsers, setFilteredUsers] = useState<adminModels.Users>([]);
  const [isMenuOpen, setIsMenuOpen] = useState({
    status: false,
    id: -1,
  });
  const [isLoading, setIsLoading] = useState(true);

  const managerId = useAppSelector((state) => state.auth.user_id);
  const usersList = useAppSelector((state) => state.users.users);

  const usersIds = usersList.map(user => user.user_id);

  const positionsList = useAppSelector((state) => state.departments.positionList);
  const departmentList = useAppSelector((state) => state.departments.departments);

  useEffect(() => {
    dispatch(adminThunk.fetchUsers());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  useEffect(() => {
    setFilteredUsers(usersList);
  }, [usersList]);

  useEffect(() => {
    if (departmentList.length > 0) {
      dispatch(departmentThunk.getDepartmentPosition({ id: departmentList[0].department_id }));
      setSelectedDepatment(departmentList[0].department_id);
    }
  }, [departmentList]);

  useEffect(() => {
    if (positionsList.length > 0) {
      setSelectedJob("all");
    }
  }, [positionsList]);

  useEffect(() => {
    if (usersList.length > 0) {
      setIsLoading(false);
    } else {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [usersList]);

  const closeMenu = () => {
    setIsMenuOpen({ status: false, id: -1 });
  };

  const toggleMenu = (e: React.MouseEvent, index: any, userId: number) => {
    e.stopPropagation();
    dispatch(usersActions.setUserId(userId));
    dispatch(adminThunk.fetchUser(userId));
    setIsMenuOpen((prevState) => ({
      status: !prevState.status,
      id: index,
    }));
  };

  const handleActivationBoxOpening = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(USER_ACTIVITY));
  }

  const handleOnEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(UPDATE_USER_FORM));
  };

  const handleOnEditCredential = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(ADMIN_CHANGE_CREDENTIAL));
  };

  const handleOnDelete = (e: React.MouseEvent<SVGElement>, user_id: number) => {
    e.stopPropagation();
    closeMenu();
    dispatch(usersActions.setUserId(user_id));
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(REMOVE_USER));
  };

  const handleOnResetFilter = () => {
    setFilteredUsers(usersList);
    // setCurrentPage(1);

    setSelectedDepatment(departmentList[0].department_id);
    dispatch(departmentThunk.getDepartmentPosition({ id: departmentList[0].department_id }));
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleOnAddUser = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(ADD_USER_FORM));
  };

  const subTablesData = {
    [UserStatuses.ACTIVE]: {
      label: "Aktywni",
      header: "Lista aktywnych pracowników",
      emptyContent: isLoading ? "" : "Brak aktywnych pracowników.",
      emptyAction: isLoading ? <SpinnerModal /> : (
        <Button
          type="primary"
          icon={<Icon type={IconTypes.SUGGESTION_BOX} />} 
          onClick={handleOnAddUser}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Dodaj pracownika
        </Button>
      ),
    },
    [UserStatuses.INACTIVE]: {
      label: "Nieaktywni",
      header: "Lista nieaktywnych pracowników",
      emptyContent: isLoading ? <SpinnerModal /> : "W tym miejscu pojawią się pracownicy, których konta zostaną zdezaktywowane.",
      emptyAction: null
    },
    [UserStatuses.FIRED]: {
      label: "Zwolnieni",
      header: "Lista zwolnionych pracowników",
      emptyContent: isLoading ? <SpinnerModal /> : "W tym miejscu pojawią się pracownicy, którzy zostaną zwolnieni.",
      emptyAction: null
    }
  };
  
  const items: MenuProps['items'] = [
    getItem(
      subTablesData[UserStatuses.ACTIVE].label, 
      UserStatuses.ACTIVE
      ), { type: 'divider' },
    getItem(
      subTablesData[UserStatuses.INACTIVE].label, 
      UserStatuses.INACTIVE
      ), { type: 'divider' },
    getItem(
      subTablesData[UserStatuses.FIRED].label, 
      UserStatuses.FIRED
    ),
  ];

  const usersData: IAdminUsersData[] = usersList
    .filter((user) => {
      return selectedTable === UserStatuses.ACTIVE
        ? user.user.isActive
        : selectedTable === UserStatuses.FIRED
          ? user.employmentStatus === UserStatuses.FIRED
          : (!user.user.isActive && !(user.employmentStatus === UserStatuses.FIRED))
    })
    .reduce((acc: any, user: any, index: number) => {
      const userID = user.user_id;

      if (!acc[userID]) {
        acc[userID] = {
          key: user.user_id,
          number: 0,
          worker: {
            id: user.user_id,
            name: user.name,
            surname: user.surname
          },
          department: user.department.name,
          status: user.user.isActive
            ? UserStatuses.ACTIVE
            : user.employmentStatus === UserStatuses.FIRED
              ? UserStatuses.FIRED
              : UserStatuses.INACTIVE,
          actions: {
            userID: user.user_id,
          }
        };
      }

      acc[userID].number++;

      return acc;
  }, {});

  const summarizedUsersData = Object.values(usersData);

  const filteredData = summarizedUsersData
    .filter((record) => {
      const fullName = `${record.worker.name} ${record.worker.surname}`;
      return fullName.toLowerCase().includes(searchText.toLowerCase());
  });

  const bonusColumns: ColumnsType<IAdminUsersData> = [
    {
      title: 'Nr',
      dataIndex: "number",
      render: (number: number, record: IAdminUsersData, index: number) => `${index + 1}.`,
      responsive: ['lg'],
    },
    {
      title: 'Pracownik',
      dataIndex: 'worker',
      render: (worker: { name: string, surname: string }) => {
        return <span>{worker.name} {worker.surname}</span>;
      },
      responsive: ['sm']
    },
    {
      title: 'Dział',
      dataIndex: 'department',
      render: (department: string) => {
        return <span>{department}</span>;
      },
      responsive: ['sm']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: UserStatuses) => {
        return (
          status === UserStatuses.ACTIVE
            ? <Tag color="green">{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Tag>
            : status === UserStatuses.FIRED
              ? <Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Tag>
              : <Tag color="orange">{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Tag> 
        );
      },
      responsive: ['sm']
    },
    {
      title: 'Akcje',
      dataIndex: 'actions',
      render: (actions: any) => {
        const userActionsExpandedMenu = [
          { key: '1', label: (
            <span onClick={(e) => handleOnEditCredential(e)}>
              {dashboardAdminButtons.EDIT_USER_CREDENTIAL_BUTTON}
            </span>
          )},
          { key: '2', label: (
            <span onClick={(e) => handleActivationBoxOpening(e)}>
              {dashboardAdminButtons.SWITCH_ACTIVITY_BUTTON}
            </span>
          )},
        ];

        return (
            <Space size="middle">
              <Icon 
                type={IconTypes.EDIT}
                width={30}
                height={30}
                onClick={(e) => handleOnEdit(e)}
                style={iconStyles}
              />
              <Icon
                type={IconTypes.TRASH}
                width={30}
                height={30}
                onClick={(e:any) => handleOnDelete(e, actions.userID)}
                style={iconStyles}
              />
              <Dropdown menu={{ items: userActionsExpandedMenu }}>
                <span>
                  Więcej <Icon type={IconTypes.ARROW_DOWN} />
                </span>
              </Dropdown>
            </Space>
        );
      },
      responsive: ['sm']
    },
  ];

  const userOnChange: TableProps<IAdminUsersData>['onChange'] = 
    (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onUserActionsClean = () => {
    setAreButtonsActive(false);
  }

  const tableMenuOnClick: MenuProps['onClick'] = (e) => {
    setSelectedTable(e.key as UserStatuses);
  };

  const onUserRowClick = (record: IAdminUsersData) => {
    closeMenu();
    dispatch(usersActions.setUserIndex(record.actions.userID));
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(USER_INFO));
  };

  return (
    <div className={styles["content-section"]}>
      <div className={styles["extended-table"]}>
        <Menu
          className={styles["table-navigation"]}
          onClick={tableMenuOnClick}
          defaultSelectedKeys={[selectedTable]}
          defaultOpenKeys={[selectedTable]}
          selectedKeys={[selectedTable]}
          mode="inline"
          items={items}
          style={{
            borderRadius: 4,
            margin: 0,
            padding: 0,
          }}
        />
        <Table 
          columns={bonusColumns} 
          dataSource={filteredData}
          onChange={userOnChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onUserRowClick(record),
            };
          }}          
          style={{
            flexGrow: 1,
            cursor: "pointer"
          }}
          pagination={{ 
            showSizeChanger: false,
            pageSize: 50, 
            position: ["bottomCenter"], 
            prevIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <Icon type={IconTypes.CHEVRON_LEFT} />
                <span>Poprzednia</span>
              </Button>), 
            nextIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <span>Następna</span>
                <Icon type={IconTypes.CHEVRON_RIGHT} />
              </Button>)
              }}
          showSorterTooltip={false}
          locale={{ 
            emptyText() {
              return (
                <>
                  <p className={styles["table-empty"]}>
                    {subTablesData[selectedTable].emptyContent}
                  </p>
                  {subTablesData[selectedTable].emptyAction}
                </>
              )
            },
          }}
          title={() => (
            <div className={styles["table-header"]}>
              <Title 
                level={3} 
                style={{ 
                  marginBottom: 0, 
                  fontSize: 24, 
                  fontWeight: 700
                }}>
                  {subTablesData[selectedTable].header}
              </Title>
              <div className={styles["table-search"]}>
                <Search 
                  placeholder="Wyszukaj..." 
                  enterButton
                  allowClear
                  onSearch={handleSearch}
                />
              </div>
              <div className={styles["table-actions"]}>
                {/* Miejsce na filtry */}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default AdminUsersTable;
