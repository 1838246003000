import React, { useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { suggestionActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button } from "../../components/ui";

import styles from "./SuggestionsFilters.module.scss";
import { ISuggestionsFiltersProps } from "../types/SuggestionsProps.types";

import { suggestionsData } from "../../../data/constants";

import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { topBarElementsDescriptions } = suggestionsData;

function SuggestionsFilters({
  suggestions,
  setSuggestions,
  resetFilters,
}: ISuggestionsFiltersProps) {
  const dispatch = useAppDispatch();
  const filtersRef = useRef<HTMLDivElement>(null);

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [selectedDepartmentId, setSelectedDepatmentId] = useState<number>(0);

  const departmentList = useAppSelector(
    (state) => state.departments.departments
  );

  const onChangeDateHandler = (
    dayjsArray: any,
    datesArray: [string, string]
  ) => {
    setSelectedStartDate(new Date(datesArray[0]));
    setSelectedEndDate(new Date(datesArray[1]));

    setIsDateSelected(true);
  };

  const handleOnFilter = () => {
    let suggestionToFilter = [...suggestions];
    if (selectedDepartmentId !== 0) {
      suggestionToFilter = suggestionToFilter.filter(
        (suggestion) =>
          suggestion.department.department_id === selectedDepartmentId
      );
    }

    if (isDateSelected && selectedStartDate && selectedEndDate) {
      suggestionToFilter = suggestionToFilter.filter((suggestion) => {
        const suggestionDate = Date.parse(suggestion.date.toString());
        return (
          suggestionDate >= Date.parse(selectedStartDate.toString()) &&
          suggestionDate < Date.parse(selectedEndDate.toString()) + 86400000
        );
      });
    }

    if (
      selectedDepartmentId !== 0 &&
      isDateSelected &&
      selectedStartDate &&
      selectedEndDate
    ) {
      suggestionToFilter = suggestionToFilter
        .filter(
          (suggestion) =>
            suggestion.department.department_id === selectedDepartmentId
        )
        .filter((suggestion) => {
          const suggestionDate = Date.parse(suggestion.date.toString());
          return (
            suggestionDate >= Date.parse(selectedStartDate.toString()) &&
            suggestionDate < Date.parse(selectedEndDate.toString()) + 86400000
          );
        });
    }

    setSuggestions(suggestionToFilter);
  };

  const handleOnReset = () => {
    resetFilters();

    setSelectedStartDate(null);
    setSelectedEndDate(null);

    setIsDateSelected(false);
    setSelectedDepatmentId(0);
    setSuggestions(suggestions);

    dispatch(suggestionActions.setFilterQuery(""));
    dispatch(suggestionActions.setSearchValue(""));
    dispatch(
      suggestionActions.setSearchPlaceholder(topBarElementsDescriptions.search)
    );
  };

  const onDepartmentChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setSelectedDepatmentId(+value);
    dispatch(departmentThunk.getDepartmentPosition({ id: +value }));
  };

  return (
    <div className={`${styles["filters"]} ${styles["show"]}`} ref={filtersRef}>
      <div className={styles["filters__container"]}>
        <RangePicker
          allowClear={false}
          defaultValue={undefined}
          value={(selectedStartDate && selectedEndDate) ? [dayjs(selectedStartDate), dayjs(selectedEndDate)] : null}
          className={styles["range-picker"]}
          size="middle"
          onChange={(date, dateStr) => onChangeDateHandler(date, dateStr)}
        />

        <select
          name="department"
          value={selectedDepartmentId}
          onChange={onDepartmentChange}
          className={styles["select"]}
        >
          <option value={0}>Wszystkie działy</option>
          {departmentList &&
            departmentList.map((department, i) => {
              return (
                department.supervisors.find(
                  (supervisor) => supervisor.isActive
                ) && (
                  <option key={i} value={department.department_id}>
                    {department.name}
                  </option>
                )
              );
            })}
        </select>

        <div className={styles["buttons"]}>
          <Button
            hiddenStyles=""
            textValue="Resetuj"
            buttonClassName="filter-reset-button"
            buttonFunction={handleOnReset}
          />
          <Button
            hiddenStyles=""
            textValue="Filtruj"
            buttonClassName="filter-search-button"
            buttonFunction={handleOnFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default SuggestionsFilters;
