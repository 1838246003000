import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../data/config";
import { vacationModels } from "../models";

const initialState: vacationModels.VacationState = {
  userVacations: [],
};

const vacationSlice = createSlice({
  name: SliceNames.VACATIONS,
  initialState,
  reducers: {
    setUserVacations: (state, { payload }) => {
      state.userVacations = payload;
    },
  },
});

export const { setUserVacations } = vacationSlice.actions;
export default vacationSlice.reducer;
