import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { applicationSettingsModels } from "../models";
import { applicationSettingsService } from "../thunks/services";
import { ThunkNames, SliceNames } from "../../data/config";

const initialState: applicationSettingsModels.ApplicationSettingsState = {
  themeName: "",
  shouldShowEvaluationPages: false,
  useEstimatedCostAsInput: false,
  useConsultationField: false,
  loading: false,
};

const { APPLICATION_SETTINGS } = ThunkNames;

export const getApplicationSettings = createAsyncThunk(
  APPLICATION_SETTINGS.GET_APPLICATION_SETTINGS_THUNK,
  (_, thunkAPI) => {
    applicationSettingsService.fetchShowEvaluationPageInfo().then((data) => {
      const { shouldShowEvaluationPages, useEstimatedCostAsInput, useConsultationField, themeName } = data.data;
      thunkAPI.dispatch(setShowEvaluationPages(shouldShowEvaluationPages));
      thunkAPI.dispatch(setUseEstimationCostType(useEstimatedCostAsInput));
      thunkAPI.dispatch(setUseConsultationField(useConsultationField));
      thunkAPI.dispatch(setThemeName(themeName))
    });
  }
);

const applicationSettingsSlice = createSlice({
  name: SliceNames.APPLICATION_SETTINGS,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getApplicationSettings.pending, (state) => {
      state.loading = true;
    });
  },
  reducers: {
    setShowEvaluationPages: (state, { payload }) => {
      state.shouldShowEvaluationPages = payload;
      state.loading = false;
    },
    setUseEstimationCostType: (state, { payload }) => {
      state.useEstimatedCostAsInput = payload;
      state.loading = false;
    },
    setUseConsultationField: (state, { payload }) => {
      state.useConsultationField = payload;
      state.loading = false;
    },
    setThemeName: (state, { payload }) => {
      state.themeName = payload;
      state.loading = false;
    }
  },
});

export const { 
  setShowEvaluationPages, 
  setUseEstimationCostType,
  setUseConsultationField,
  setThemeName
} = applicationSettingsSlice.actions;

export default applicationSettingsSlice.reducer;
