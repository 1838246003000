import styles from "./SuccessfullAfterUpdate.module.scss";
import { SuccessfullyIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "../../../__store/tools/hooks";
import { RoutesPaths, UserRoles } from "../../../data/config";

const SuccessfullAfterUpdate = () => {
  const navigate = useNavigate();
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const role = useAppSelector((state) => state.auth.role);
  const hasRulesAccepted = useAppSelector((state) => state.auth.hasRulesAccepted);

  const { WORKER, ADMIN } = UserRoles;
  const { AUTHORIZATION, PROFILE } = RoutesPaths;

  const { WORKER_PROFILE_PATH, YOUR_PROFILE_PATH } = PROFILE;
  const { RULES } = AUTHORIZATION;

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (isAuth) {
        if (!hasRulesAccepted) {
          navigate(RULES);
        } else {
          role === WORKER || role === ADMIN
            ? navigate(YOUR_PROFILE_PATH)
            : navigate(WORKER_PROFILE_PATH);
        }
      }
    }, 2000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [navigate, isAuth, hasRulesAccepted]);

  return (
    <div className={styles["info"]}>
      <SuccessfullyIcon data-testid="success-icon" />
      <h2 data-testid="success-header" className={styles["info__header"]}>
        Twoje hasło zostało zmienione
      </h2>
    </div>
  );
};

export default SuccessfullAfterUpdate;
