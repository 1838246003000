import { createSlice } from "@reduxjs/toolkit";

import { formsModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: formsModels.FormsState = {
  formInfoData: undefined,
  acceptSuggestion: "",
  evaluatingUser: undefined,

  solutionPath: [],
  resultPath: [],
  suggestionPath: [],
  verificationPath: [],
  correctionPath: [],
  endResultPath: [],

  soultionFilesNames: [],
  suggestionFilesName: [],
  resultFilesName: [],
  verificationFilesNames: [],
  correctionFilesNames: [],
  endResultFilesNames: [],

  showRankingFilters: false,
  showAdminPanelFilters: false,
  suggestionComment: [],
  pickedSuggestionId: 0,
  approvedSuggestion: "",
  endResult: null,
  evaluateSuggestion: {
    comment: "",
    suggestion_id: -1,
    status: "",
    substatus: "",
    suggestion_type: "",
    userRole: "",
    is_consulted: false,
    department_id: -1,
    evaluating_user: -1,
  },
  internalVerificationComment: "",
};

const formsSlice = createSlice({
  name: SliceNames.FORMS,
  initialState,
  reducers: {
    setEvaluatingUser: (state, { payload }) => {
      state.evaluatingUser = payload;
    },
    setSuggestionPath: (state, { payload }) => {
      state.suggestionPath = payload;
    },
    setSolutionFilesNames: (state, { payload }) => {
      state.soultionFilesNames = payload;
    },
    setResultFilesNames: (state, { payload }) => {
      state.resultFilesName = payload;
    },
    setSuggestionFilesNames: (state, { payload }) => {
      state.suggestionFilesName = payload;
    },
    setVerificationFilesNames: (state, { payload }) => {
      state.verificationFilesNames = payload;
    },
    setCorrectionFilesNames: (state, { payload }) => {
      state.correctionFilesNames = payload;
    },
    setEndResultFilesNames: (state, { payload}) => {
      state.endResultFilesNames = payload;
    },
    setResultPath: (state, { payload }) => {
      state.resultPath = payload;
    },
    setSolutionPath: (state, { payload }) => {
      state.solutionPath = payload;
    },
    setVerificationPath: (state, { payload }) => {
      state.verificationPath = payload;
    },
    setCorrectionPath: (state, { payload }) => {
      state.correctionPath = payload;
    },
    setEndResultPath: (state, { payload}) => {
      state.endResultPath = payload;
    },
    setPickedSuggestionId: (state, { payload }) => {
      state.pickedSuggestionId = payload;
    },
    setFormInfoData: (state, { payload }) => {
      state.formInfoData = payload;
    },
    acceptSuggestion: (state, { payload }) => {
      state.acceptSuggestion = payload;
    },
    showRankingFilters: (state) => {
      state.showRankingFilters = !state.showRankingFilters;
    },
    showAdminPanelFilters: (state) => {
      state.showAdminPanelFilters = !state.showAdminPanelFilters;
    },
    setSuggestionComment: (state, { payload }) => {
      state.suggestionComment = payload;
    },
    approvedSuggestion: (state, { payload }) => {
      state.approvedSuggestion = payload;
    },
    setEvaluateSuggestion: (state, { payload }) => {
      state.evaluateSuggestion = payload;
    },
    setInternalVerificationComment: (state, { payload }) => {
      state.internalVerificationComment = payload;
    },
    clearEvaluateSuggestion: (state) => {
      state.evaluateSuggestion = initialState.evaluateSuggestion;
    },

    setFormInitialState: () => initialState,
  },
});

export const {
  setSolutionPath,
  setResultPath,
  setSuggestionPath,
  setVerificationPath,
  setCorrectionPath,
  setEndResultPath,
  setFormInfoData,
  acceptSuggestion,
  showRankingFilters,
  showAdminPanelFilters,
  setSuggestionComment,
  setPickedSuggestionId,
  approvedSuggestion,
  setFormInitialState,
  setEvaluateSuggestion,
  clearEvaluateSuggestion,
  setSolutionFilesNames,
  setResultFilesNames,
  setSuggestionFilesNames,
  setVerificationFilesNames,
  setEndResultFilesNames,
  setEvaluatingUser,
  setCorrectionFilesNames,
  setInternalVerificationComment,
} = formsSlice.actions;

export default formsSlice.reducer;
