import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./DashboardRanking.module.scss";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";

import { rankingModels } from "../../../__store/models";
import { rankingThunk, departmentThunk } from "../../../__store/thunks";

import { Button } from "../../components/ui";

import { ranksData } from "../../../data/constants";

import { RankingFilters } from "../types/RanksComponents.types";

import { Avatar, DatePicker } from "antd";
import dayjs from "dayjs";

const { 
  avatarStyles, 
  dashboardRankingLabels, 
  dashboardRankingButtons, 
  RANKING_BREAK_POINT 
} = ranksData;

const { RangePicker } = DatePicker;

const DashboardRanking:FunctionComponent = ():ReactElement => {
  const dispatch = useAppDispatch();

  const [filtersState, setFiltersState] = useState<RankingFilters>({
    department: "",
    underDepartment: "",
  });

  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date|null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date|null>(null);

  const onChangeDateHandler = (dajsArray:any, datesArray:[string, string]) => {
    setSelectedStartDate(new Date(datesArray[0]));
    setSelectedEndDate(new Date(datesArray[1]));
    setIsDateSelected(true);
  };

  const [filteredRanking, setFilteredRanking] = useState<rankingModels.DepartmentRanking>([]);
  const ranking: rankingModels.DepartmentRanking =
    useAppSelector((state) => state.ranking.departmentRanking) || [];

  const departmentsInfo = useAppSelector((state) => state.departments.departments);

  useEffect(() => {
    dispatch(rankingThunk.fetchRanking());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  useEffect(() => {
    handleOnFilter();
  }, [ranking]);

  const handleOnSelectChange = (e: any) => {
    setFiltersState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnResetFilter = () => {
    setFiltersState({
      department: "",
      underDepartment: "",
    });

    setSelectedStartDate(null);
    setSelectedEndDate(null);

    setIsDateSelected(false);

    setFilteredRanking(ranking);
  };

  const handleOnFilter = () => {
    let rankingToFilter = [...ranking];
    if (filtersState.department !== "") {
      rankingToFilter = ranking.filter((worker) => {
        if (worker.user.profile) {
        const departmentName =
          worker.user.profile.department.name;
        return departmentName === filtersState.department;
        }
      });
    }
    if (isDateSelected && selectedStartDate && selectedEndDate) {
      rankingToFilter = rankingToFilter.filter(user => user.user.profile).map((user) => {
        const result = user.user.profile.points.filter((point) => {
          const pointDate = Date.parse(point.date.toString());
      
          return pointDate >= Date.parse(selectedStartDate.toString()) 
              && pointDate < (Date.parse(selectedEndDate.toString()) + 86400000);
      });
      
        const newUser = {
          ...user,
          user: {
            ...user.user,
            profile: {
              ...user.user.profile,
              points: result,
            },
          },
        };
        
        return newUser;

      });
    }
    setFilteredRanking(rankingToFilter);
  };

  const renderDepartmentsOptions = (
    departments: { name: string }[],
    index: number
  ) => {
    const splitDepartments = departments.map(
      (department) => department.name
    );
    const removeDuplicates = splitDepartments.filter(function (item, pos) {
      return splitDepartments.indexOf(item) === pos;
    });
    const removeEmptyString = removeDuplicates.filter((item) => item !== "");
    return removeEmptyString.map((department, index) => (
      <option key={index} value={department}>
        {department}
      </option>
    ));
  };

  const renderWorkers = (workers: rankingModels.DepartmentRanking) => {
    const filtredWorkers = workers.filter(worker => worker.user.profile).filter((worker, index) => index > 2);
    return filtredWorkers.map((worker, index) => {
      if (worker.user.profile) {
        const points = worker.user.profile.points.reduce(
          (acculumator, point) => (acculumator += point.value),
          0
        );

      return (
        <div key={index} className={styles["worker"]}>
          <div className={styles["worker__position"]}>
            <span>#{index + 4}</span>
          </div>
          <div className={styles["worker__user"]}>
            <div className={styles["worker__user__avatar"]}>
              <Avatar
                style={avatarStyles}
                size="large"
              >{`${worker.user.profile.name.charAt(
                0
              )} ${worker.user.profile.surname.charAt(0)}`}</Avatar>
            </div>
            <div className={styles["worker__user__name"]}>
              <span>
                {worker.user.profile.name} {worker.user.profile.surname}
              </span>
            </div>
          </div>
          <div className={styles["worker__department"]}>
            <span>{worker.user.profile.department.name}</span>
          </div>
          <div className={styles["worker__points"]}>
            <span>{points} pkt.</span>
          </div>
        </div>
      );
      }
    });
  };

  const renderTopWorkers = (workers: rankingModels.DepartmentRanking) => {
    const filtredWorkers = workers.filter(worker => worker.user.profile).filter((worker, index) => index <= 2);
    return filtredWorkers.map((worker, index) => {
      if (worker.user.profile) {
      const points = worker.user.profile.points.reduce(
        (acculumator, point) => (acculumator += point.value),
        0
      );
  
      return (
        <div key={index} className={`${styles["top-worker"]}`}>
          <div className={styles["top-worker__position"]}>
            <span>#{index + 1}</span>
          </div>
          <div className={styles["top-worker__info"]}>
            <div className={styles["top-worker__info__avatar"]}>
              <Avatar
                style={avatarStyles}
                size="large"
              >{`${worker.user.profile.name.charAt(
                0
              )} ${worker.user.profile.surname.charAt(0)}`}</Avatar>
            </div>
            <div className={styles["top-worker__info__name"]}>
              <span>
                {worker.user.profile.name} {worker.user.profile.surname}
              </span>
            </div>
            <div className={styles["top-worker__info__points"]}>
              <span>{points} pkt.</span>
            </div>
          </div>
        </div>
      );
    }
    });
  };

  return (
    <div className={styles["ranking"]}>
      <div
        className={`${styles["ranking__filters"]}  ${styles["show"]}`}
      >
        <div className={styles["ranking__filters__selectors"]}>
          <div
            className={styles["ranking__filters__selectors__select-container"]}
          >
          <RangePicker 
            allowClear={false}
            defaultValue={undefined}
            value={selectedStartDate && selectedEndDate ? [dayjs(selectedStartDate), dayjs(selectedEndDate)] : null}
            className={styles["range-picker"]}
            size="middle" 
            onChange={(date, dateStr) => onChangeDateHandler(date, dateStr)}
          />
          </div>
          <div
            className={styles["ranking__filters__selectors__select-container"]}
          >
            <select
              name="department"
              value={filtersState.department}
              onChange={(e) => handleOnSelectChange(e)}
              className={styles["select"]}
            >
              <option value="">{dashboardRankingLabels.DEPARTMENT}</option>
              {renderDepartmentsOptions(departmentsInfo, 0)}
            </select>
          </div>
          <div
            className={styles["ranking__filters__buttons__button-container"]}
          >
            <Button
              hiddenStyles=""
              textValue={dashboardRankingButtons.FILTER_RESET_BUTTON}
              buttonClassName="reset-button"
              buttonFunction={handleOnResetFilter}
            />
            <Button
              hiddenStyles=""
              textValue={dashboardRankingButtons.FILTER_SEARCH_BUTTON}
              buttonClassName="search-button"
              buttonFunction={handleOnFilter}
            />
          </div>
        </div>
      </div>
      <div className={styles["ranking__top"]}>
        {renderTopWorkers(filteredRanking)}
      </div>
      {filteredRanking.length > RANKING_BREAK_POINT && (
        <div
          className={`${styles["ranking__list"]} ${styles["resize"]}`}
        >
          <div className={styles["ranking__header"]}>
            <div className={styles["ranking__header__position"]}>
              <span>{dashboardRankingLabels.POSITION_COLUMN}</span>
            </div>
            <div className={styles["ranking__header__worker"]}>
              <span>{dashboardRankingLabels.WORKER_COLUMN}</span>
            </div>
            <div className={styles["ranking__header__department"]}>
              <span>{dashboardRankingLabels.DEPARTMENT_COLUMN}</span>
            </div>
            <div className={styles["ranking__header__points"]}>
              <span>{dashboardRankingLabels.POINTS_COLUMN}</span>
            </div>
          </div>
          <div className={styles["ranking__elements"]}>
            {renderWorkers(filteredRanking)}
          </div>
        </div>
      )
      }
    </div>
  );
};

export default DashboardRanking;
