import React, { ReactElement, Ref, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { useAppDispatch } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";

import styles from "./AttachmentView.module.scss";

import { Icon } from ".";
import { SpinnerModal } from "../Modal";
import { suggestionsData } from "../../../data/constants";

import { IconTypes } from "../../../data/config";

import { Button } from "antd";

export interface IAttachmentViewProps {
  path: string;
  description? :string;
};

const { ATTACHMENT_ALT } = suggestionsData;

const AttachmentView = React.forwardRef(({path, description}:IAttachmentViewProps, ref:Ref<HTMLDivElement>):ReactElement => {
  const dispatch = useAppDispatch();
  const [isImageLoading, setIsImageLoading] = useState(true);

  const attachmentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const image = new Image();
    image.src = path;
    image.onload = () => setIsImageLoading(false);
    image.onerror = () => setIsImageLoading(false);
  }, [path]);


  const handlePrintAttachment = useReactToPrint({
    content: () => attachmentRef.current,
    documentTitle: description
  });

  return (
    <div className={styles["attachment"]}>
      <Icon 
        type={IconTypes.CROSS}
        className={styles["attachment__icon"]}
        onClick={() => dispatch(modalActions.closeAttachmentModal())}
      />

      {isImageLoading ? (
        <div className={styles["attachment__image"]}><SpinnerModal /></div>
      ) : description ? (
        <div className={styles["attachment__image"]} ref={attachmentRef}>
          <img className={styles["attachment__image"]} src={path} alt={ATTACHMENT_ALT} />
          <h4 className={styles["attachment__description"]}>{description}</h4> 
          <Button 
            type="primary" 
            className={styles["primary-button"]} 
            onClick={handlePrintAttachment}
            icon={<Icon type={IconTypes.UPLOAD} />}
          >
            Eksportuj
          </Button>
        </div>
      ) : (
        <img className={styles["attachment__image"]} src={path} alt={ATTACHMENT_ALT} />
      )}
    </div>
  );
});

export default AttachmentView;
