import { ReactElement, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useAppSelector } from "../../../__store/tools/hooks";
import { suggestionModels } from "../../../__store/models";

import { 
  SuggestionsFilters, 
  DashboardHeader, 
  DashboardStatusButtons, 
  DashboardSuggestionsList 
} from ".";

import styles from "./SuggestionsDashboard.module.scss";
import { dashboardColumns, statusButtons } from "../types/SuggestionsDashboard.types";
import { ISuggestionsDashboardProps } from "../types/SuggestionsProps.types";
import MainStatuses from "../../../data/config/suggestionStatuses.data";
import { UserRoles } from "../../../data/config";

function SuggestionsDashboard(
  { suggestionsMap, suggestionsCount, userRole, readOnly } : ISuggestionsDashboardProps
  ) : ReactElement {
    
  const [statusButton, setStatusButton] = useState<MainStatuses>(MainStatuses.REPORT);
  const [suggestions, setSuggestions] = useState<suggestionModels.Suggestions[]>([]);
  const [suggestionsToFilter, setSuggestionsToFilter] = useState<suggestionModels.Suggestions[]>([]);

  const location = useLocation();
  const suggestionState = useAppSelector((state) => state.suggestions);
  const filterSuggestionQuery = useAppSelector((state) => state.suggestions.filterSuggestionQuery);

  const filteredSuggestions = suggestions
  .filter(
    (suggestion) => {
      const { title } = suggestion;
      const { name, surname } = suggestion.reporting_user.profile;
      const author = `${name} ${surname}`;

      const filteringData = `${title} ${author}`;
      const filteringReverseData = `${author} ${title}`;

      return filteringData
        .toLowerCase()
        .includes(filterSuggestionQuery.toLowerCase()) ||
        filteringReverseData
        .toLowerCase()
        .includes(filterSuggestionQuery.toLowerCase());
    }
  );

  function handleChangeStatus(status: MainStatuses) {
    setStatusButton(status);

    suggestionsMap.forEach((suggestionsCategory, suggestionsStatus) => {
      if (suggestionsStatus === status) {
        setSuggestions(suggestionsCategory);
        setSuggestionsToFilter(suggestionsCategory);
      }
    });
  }

  function resetFilters() {
    handleChangeStatus(statusButton);
  }

  function getStatusByValue(value: string): MainStatuses | undefined {
    const keys = Object.keys(MainStatuses) as Array<keyof typeof MainStatuses>;  
    for (const key of keys) {
      if (MainStatuses[key] === value) {
        return MainStatuses[key];
      }
    }
    return undefined;
  }

  useEffect(() => {
    handleChangeStatus(statusButton);
  }, [suggestionState]);

  useEffect(() => {
    if (checkIfBoss(userRole)) {
      const bossImplementedSuggestions = suggestionsMap.get(MainStatuses.REALIZATION);
      if (bossImplementedSuggestions) {
        setStatusButton(MainStatuses.REALIZATION);
        setSuggestions(bossImplementedSuggestions);
        setSuggestions(bossImplementedSuggestions);
        setSuggestionsToFilter(bossImplementedSuggestions);
      }
    }
  }, [userRole]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const statusQueryParam = searchParams.get("status");
    if (statusQueryParam){
      const status = getStatusByValue(statusQueryParam);
      if (status && status !== statusButton) {
        handleChangeStatus(status);
      }
    }
  }, [location.search])

  const checkIfBoss = (role?:string):boolean => role === UserRoles.BOSS;

  return (
    <div className={styles["suggestions-dashboard"]}>
      <SuggestionsFilters
        suggestions={suggestionsToFilter}
        setSuggestions={setSuggestions}
        resetFilters={resetFilters}
      />
      <DashboardStatusButtons
        statusButton={statusButton}
        buttonsList={statusButtons[checkIfBoss(userRole) ? "boss" : "worker"]}
        suggestionsCount={suggestionsCount}
        buttonsMainClassName="suggestions-dashboard__statusBtn"
        buttonsAdditionalClassName={checkIfBoss(userRole) ? "boss" : ""}
        onClickFunction={handleChangeStatus}
      />
      {filteredSuggestions.length > 0 && (
        <>
          <DashboardHeader 
            columnsList={dashboardColumns} 
            headerMainClassName="suggestions-dashboard__listHeader" 
          />
          <DashboardSuggestionsList
            suggestionsList={filteredSuggestions}
            suggestionsListClassName="suggestions-dashboard__list"
            filtersFlag={true}
            filtersClassName="filters"
            readOnly={readOnly}
          />
        </>
      )}
    </div>
  );
}

export default SuggestionsDashboard;
