import { useState, ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { Icon, InactiveAccount, NavBar } from "../../components/ui";

import styles from "./AdminPanelPage.module.scss";

import { 
  // AdminMenu, 
  AdminTitle, 
  AdminUsersTable  
} from "../components";

import { Button, MenuProps } from "antd";
import { adminThunk, bonusThunk, departmentThunk } from "../../../__store/thunks";
import { modalActions } from "../../../__store/slices";
import { IconTypes, ModalClassnames } from "../../../data/config";

const menuItems: MenuProps['items'] = [
  // { label: 'Generowanie premii', key: bonusData.BonusSections.MANAGER_GENERATION },
  // { label: 'Raport ogólny', key: bonusData.BonusSections.MANAGER_REPORT},
  // { label: 'Zasady wypłacania', key: bonusData.BonusSections.INSTRUCTIONS },
  // { label: 'Automatyzacja premii', key: bonusData.BonusSections.MANAGER_AUTOMATION },
];

const { ADD_USER_FORM } = ModalClassnames;

const AdminPanelPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  // const [selectedSection, setSelectedSection] = useState(bonusData.BonusSections.MANAGER_GENERATION);

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);
  const user = useAppSelector((state) => state.users.authUser);
  const user_id = useAppSelector((state) => state.auth.user_id);

  useEffect(() => {
    if (user == null && user_id) {
      dispatch(adminThunk.fetchUser(user_id));
    }
  }, [user, user_id, dispatch]);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
    dispatch(bonusThunk.fetchAllBonuses());
    dispatch(adminThunk.fetchUsers());
  }, []);

  const handleOnAddUser = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(ADD_USER_FORM));
  };

  return (
    <div className={styles["admin-page"]}>
      <NavBar /> 
      {isActive ? (
        <div className={styles["dashboard"]}>
          <div className={styles["header"]}>
            <AdminTitle title="Administrator" />
            <Button
              type="primary"
              icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
              onClick={handleOnAddUser}
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Dodaj pracownika
            </Button>
            {/* <AdminMenu 
              menuData={menuItems}
              selectedSection={selectedSection} 
              setSelectedSection={setSelectedSection} 
            /> */}
          </div>
          <div className={styles["container"]}>
            <AdminUsersTable />
          </div>
        </div>) : <InactiveAccount />}
    </div>
  );
}

export default AdminPanelPage;
