import { createAsyncThunk } from "@reduxjs/toolkit";
import { downloadAttachmentFromApi } from "./services/attachment.service";

import { attachmentModels } from "../models";

import { ThunkNames, GlobalVariables } from "../../data/config";

const { DOWNLOAD_ATTACHMENT_THUNK } = ThunkNames;
const { ATTACHMENTS_API_URL } = GlobalVariables;


export const downloadAttachment = createAsyncThunk(
  DOWNLOAD_ATTACHMENT_THUNK,
  ({ key, name }:attachmentModels.Attachment) => {
    const downloadLink = document.createElement("a");

    return downloadAttachmentFromApi(
      key.replace(ATTACHMENTS_API_URL,"")
    ).then((response) => {
      const base64File = Buffer.from(response.data, "binary").toString("base64");

      document.body.appendChild(downloadLink);
      const linkSource = `data:${key.split(".").pop()};base64,${base64File}`;
      downloadLink.href = linkSource;
      downloadLink.target = "_self";
      downloadLink.download = `${name}.${key.split(".").pop()}`;
      downloadLink.click();
      downloadLink.remove();
    });
  }
);
