import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";

import dayjs from "dayjs";
import { Avatar, Checkbox, Button, Switch, DatePicker, Tag } from "antd";
import { format } from "date-fns";

import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";

import { Icon } from "../../components/ui";

import styles from "./DashboardUserSettings.module.scss";

import { IDashboardUserSettingsProps } from "../types/AdminProps.types";

import { applicationSettingsThunk } from "../../../__store/thunks";
import { modalActions } from "../../../__store/slices";
import { adminThunk, authThunk, vacationThunk } from "../../../__store/thunks";

import {
  activationData,
  adminData,
  appHeaders,
  labelValues,
} from "../../../data/constants";
import {
  UserRoles,
  ModalClassnames,
  IconTypes,
  GlobalVariables,
} from "../../../data/config";
import { userInfoLabels } from "../../../data/constants/users.data";
import { UserRolesTranslated } from "../../../data/config/userRoles.data";

const { MAIN_DATE_FORMAT } = GlobalVariables;

const { 
  CHANGE_LOGIN, 
  CHANGE_PASSWORD, 
  UPDATED_MAIL_NOTIFICATIONS 
} = ModalClassnames;

const { ACTIVATION_MESSAGES } = activationData;

const { SETTINGS } = appHeaders;
const { SETTINGS_LABELS } = labelValues;

const {
  ContractTypesTranslated,
  userProfileAvatarStyles,
} = adminData;

const DashboardUserSettings: FunctionComponent<
  IDashboardUserSettingsProps
> = (): ReactElement => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.authUser);

  const user_id = useAppSelector((state) => state.auth.user_id);
  const rangepickerRef = useRef<any>(null);
  const userVacations = useAppSelector((state) => state.vacation.userVacations);
  const role = useAppSelector((state) => state.auth.role);
  const [isEditing, setIsEditing] = useState(false);
  const [userHoliday_id, setUserHolidayId] = useState<number>(-1);

  const showEvaluationPages = useAppSelector(
    (state) => state.applicationSettings.shouldShowEvaluationPages
  );
  const useEstimationCostInput = useAppSelector(
    (state) => state.applicationSettings.useEstimatedCostAsInput
  );
  const useConsultationField = useAppSelector(
    (state) => state.applicationSettings.useConsultationField
  );
  const sendMailNotifications = useAppSelector(
    (state) => state.auth.sendMailNotifications
  );

  const extendedMailNotifications = false; // to be changed after notifications full implementation

  const today = new Date();
  const tomorrow = new Date(today);

  const [dates, setDates] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([dayjs(today), dayjs(tomorrow.setDate(tomorrow.getDate() + 1))]);

  const { RangePicker } = DatePicker;
  const label = { inputprops: { "aria-label": "Switch e-mail notifications" } };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const yesterday = dayjs().subtract(1, "day").endOf("day");
    return current && current <= yesterday;
  };

  const handleOnEditLogin = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(CHANGE_LOGIN));
  };

  const handleOnEditPassword = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(CHANGE_PASSWORD));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (dates[0] && dates[1]) {
      if (!isEditing) {
        dispatch(
          vacationThunk.createVacation({
            user_id: user_id as number,
            endHoliday_at: dates[1],
            startHoliday_at: dates[0],
          })
        );

        setDates([null, null]);
      } else {
        dispatch(
          vacationThunk.updateUserVacation({
            user_id: user_id as number,
            userHoliday_id,
            endHoliday_at: dates[1],
            startHoliday_at: dates[0],
          })
        );
        setIsEditing(false);
        setDates([null, null]);
      }
    }
  };

  const onChangeRangePicker = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    setDates([
      dayjs(new Date(dateString[0])),
      dayjs(new Date(dateString[1])),
    ]);
  };

  useEffect(() => {
    dispatch(vacationThunk.getUserHolidays(user_id as number));
  }, []);

  useEffect(() => {
    dispatch(adminThunk.fetchAuthUser(user_id as number));
  }, [sendMailNotifications]);

  const validateEndOfVacationDate = () =>
    Boolean(
      dates[0] &&
        dates[1] &&
        new Date(dates[1].toString()) < new Date(dates[0].toString())
    );

  const userData = {
    activity: user?.user.isActive,
    status: user?.user.isActive 
      ? (<Tag color="green">{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Tag>) 
      : user?.employmentStatus === "FIRED" 
        ? (<Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Tag>) 
        : (<Tag color="orange">{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Tag>),
    initials: `${user?.name.charAt(0)}${" "}${user?.surname.charAt(0)}`,
    nameWithSurname: `${user?.name}${" "}${user?.surname}`,
    login: user?.user.login,
    hiddenPassword: "************",
    email: user?.email,
    department: user?.department.name,
    parentDepartment: user?.department.parent_department?.name,
    departmentWithParent: `${user?.department.parent_department?.name} > ${user?.department.name}`,
    jobPosition: user?.job_position.name,
    area: user?.area.area_name,
    contract: user?.contract ? ContractTypesTranslated.get(user.contract as adminData.ContractTypes) : userInfoLabels.UNKNOWN,
    role: user?.role && UserRolesTranslated.get(user.role as UserRoles),
  }

  return (
    <div className={styles["user-settings"]}>
      <div className={styles["user-settings__avatar-container"]}>
        <Avatar
          className={styles["avatar"]}
          style={userProfileAvatarStyles(userData.activity)}
        >
          {userData.initials}
        </Avatar>
        <h2 className={styles["avatar__header"]}>{userData.nameWithSurname}</h2>
      </div>
      
      <div className={styles["user-settings__row-content"]}>
        <div className={styles["row"]}>
          <div className={styles["row__header"]}>
            <h2>{SETTINGS.ACCESS}</h2>
            <Button
              size="large"
              type="primary"
              htmlType="button"
              className={styles["row__button"]}
              onClick={handleOnEditLogin}
            >
              Edytuj
              <Icon
                className={styles["row__button__icon"]}
                type={IconTypes.PENCIL_ICON}
              />
            </Button>
          </div>
          <hr />

          <div className={styles["row__content"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.INPUT_MAN_ICON} />
                {SETTINGS_LABELS.LOGIN}
              </label>
              {userData.login}
            </div>
            {userData.email && (
              <div className={styles["content__column"]}>
                <label className={styles["content__label"]}>
                  <Icon type={IconTypes.MONKEY_ICON} />
                  {SETTINGS_LABELS.EMAIL}
                </label>
                {userData.email}
              </div>
            )}
          </div>
        </div>
        <div className={styles["row"]}>
          <div className={styles["row__header"]}>
            <h2>{SETTINGS.PASSWORD}</h2>
            <Button
              size="large"
              type="primary"
              htmlType="button"
              className={styles["row__button"]}
              onClick={handleOnEditPassword}
            >
              Edytuj
              <Icon
                className={styles["row__button__icon"]}
                type={IconTypes.PENCIL_ICON}
              />
            </Button>
          </div>
          <hr />
          <div className={styles["row__content"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.KEY_INPUT_ICON} />
                {SETTINGS_LABELS.PASSWORD}
              </label>
              {userData.hiddenPassword}
            </div>
          </div>
        </div>
        <div className={styles["row"]}>
          <div className={styles["row__header"]}>
            <h2>{SETTINGS.INFO}</h2>
          </div>
          <hr />
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.STRUCTURE} />
                {SETTINGS_LABELS.DEPARTMENT}
              </label>
              {userData.parentDepartment ? userData.departmentWithParent : userData.department}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.SUIT_CASE_ICON} />
                {SETTINGS_LABELS.POSITION}
              </label>
              {userData.jobPosition}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.GRAPH_ICON} />
                {SETTINGS_LABELS.AREA}
              </label>
              {userData.area}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.PAPER_ICON} />
                {SETTINGS_LABELS.CONTRACT_TYPE}
              </label>
              {userData.contract}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.PEOPLE_GROUP_ICON} />
                {SETTINGS_LABELS.ROLE}
              </label>
              {userData.role}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.INPUT_MAN_ICON} />
                {SETTINGS_LABELS.STATUS}
              </label>
              {userData.status}
            </div>
          </div>
        </div>
        <div className={styles["row"]}>
          <div className={styles["row__header"]}>
            <h2>Urlop</h2>
            <Button
              size="large"
              type="primary"
              disabled={
                (userVacations.length >= 2 && !isEditing) ||
                validateEndOfVacationDate()
              }
              htmlType="submit"
              className={styles["row__button"]}
              form="holiday-form"
            >
              {isEditing ? "Edytuj" : "Dodaj"}
              <Icon
                className={styles["row__button__icon"]}
                type={IconTypes.PENCIL_ICON}
              />
            </Button>
          </div>
          <hr />

          <div className={styles["row__content--col"]}>
            <form className={styles["holiday-container"]} onSubmit={handleSubmit} id="holiday-form">
              {validateEndOfVacationDate() && (
                <p className={styles["holiday-container__paragraph--error"]}>
                  Należy wprowadzić prawidłową datę zakończenia urlopu
                </p>
              )}
              <div className={styles["holiday-container__range-select"]}>
                <RangePicker
                  allowClear={false}
                  placeholder={["Data początkowa", "Data końcowa"]}
                  ref={rangepickerRef}
                  defaultValue={undefined}
                  value={dates[0] && dates[1] ? dates : null}
                  disabledDate={disabledDate}
                  onChange={onChangeRangePicker}
                  className={styles["range-picker"]}
                  size="middle"
                />
              </div>

              <ul className={styles["holiday-container__dates-list"]}>
                {userVacations.map((vacation) => (
                  <li
                    className={styles["vacation"]}
                    key={vacation.userHoliday_id}
                  >
                    Od{" "}
                    {format(new Date(vacation.startHoliday_at), MAIN_DATE_FORMAT)}{" "}
                    do{" "}
                    {format(new Date(vacation.endHoliday_at), MAIN_DATE_FORMAT)}
                    <div className={styles["vacation__icons"]}>
                      <Icon
                        type={IconTypes.EDIT_VACATION}
                        className={styles["icons__icon"]}
                        onClick={() => {
                          rangepickerRef.current.focus();
                          setIsEditing(true);
                          setDates([
                            dayjs(new Date(vacation.startHoliday_at)),
                            dayjs(new Date(vacation.endHoliday_at)),
                          ]);
                          setUserHolidayId(vacation.userHoliday_id);
                        }}
                      />{" "}
                      <Icon
                        type={IconTypes.DELETE_VACATION}
                        className={styles["icons__icon"]}
                        onClick={() => {
                          dispatch(
                            vacationThunk.removeUserVacation({
                              user_id: user_id as number,
                              userHoliday_id: vacation.userHoliday_id,
                            })
                          );
                        }}
                      />{" "}
                    </div>
                  </li>
                ))}
              </ul>
            </form>
          </div>
        </div>

        <div className={styles["row"]}>
          <div className={styles["row__header"]}>
            <h2>{SETTINGS.NOTIFICATIONS}</h2>
          </div>
          <hr />
          <div className={styles["row__content--col"]}>
            {extendedMailNotifications ? (
              <>
                <p>{SETTINGS_LABELS.EMAIL_NOTIFICATIONS_SWITCH}</p>

                <Checkbox className={styles["checkbox"]} name="suggestions-notifications">
                  {SETTINGS_LABELS.SUGGESTION_NOTIFICATIONS_CHECKBOX}
                </Checkbox>
                <Checkbox className={styles["checkbox"]} name="bonuses-notifications">
                  {SETTINGS_LABELS.BONUSES_NOTIFICATIONS_CHECKBOX}
                </Checkbox>
                <Checkbox className={styles["checkbox"]} name="points-notifications">
                  {SETTINGS_LABELS.POINTS_NOTIFICATIONS_CHECKBOX}
                </Checkbox>
                <Checkbox className={styles["checkbox"]} name="ranking-notifications">
                  {SETTINGS_LABELS.RANKING_NOTIFICATIONS_CHECKBOX}
                </Checkbox>
              </>) : (
              <div className={styles["content__column"]}>
                <Switch
                  checked={sendMailNotifications}
                  onChange={() => {
                    dispatch(modalActions.openModal());
                    dispatch(modalActions.setModalClassName(UPDATED_MAIL_NOTIFICATIONS));
                    dispatch(
                      authThunk.patchSendMailNotifications({
                        user_id,
                        sendMailNotifications: !sendMailNotifications,
                      })
                    );
                  }}
                  {...label}
                />
                &nbsp;
                <span>{SETTINGS_LABELS.EMAIL_NOTIFICATIONS_SWITCH}</span>
              </div>
            )}
          </div>
        </div>

        {role === UserRoles.ADMIN && (
          <div className={styles["row"]}>
            <div className={styles["row__header"]}>
              <h2>Ustawienia aplikacji</h2>
            </div>
            <hr />
            <div className={styles["row__content--col"]}>
              <div className={styles["content__column"]}>
                <Switch
                  checked={showEvaluationPages}
                  onChange={() => {
                    dispatch(
                      applicationSettingsThunk.updateApplicationSettings({
                        shouldShowEvaluationPages: !showEvaluationPages,
                        useEstimatedCostAsInput: useEstimationCostInput,
                        useConsultationField: useConsultationField
                      })
                    );
                  }}
                  {...label}
                />
                &nbsp;
                <span>{SETTINGS_LABELS.WORKER_EVALUATION_SWITCH}</span>
              </div>
              <div className={styles["content__column"]}>
                <Switch
                  checked={useEstimationCostInput}
                  onChange={() => {
                    dispatch(
                      applicationSettingsThunk.updateApplicationSettings({
                        shouldShowEvaluationPages: showEvaluationPages,
                        useEstimatedCostAsInput: !useEstimationCostInput,
                        useConsultationField: useConsultationField,
                      })
                    );
                  }}
                  {...label}
                />
                &nbsp;
                <span>{SETTINGS_LABELS.ESTIMATED_COST_SWITCH(useEstimationCostInput)}</span>
              </div>
              <div className={styles["content__column"]}>
                <Switch
                  checked={useConsultationField}
                  onChange={() => {
                    dispatch(
                      applicationSettingsThunk.updateApplicationSettings({
                        shouldShowEvaluationPages: showEvaluationPages,
                        useEstimatedCostAsInput: useEstimationCostInput,
                        useConsultationField: !useConsultationField,
                      })
                    );
                  }}
                  {...label}
                />
                &nbsp;
                <span>{SETTINGS_LABELS.CONSULTATION_FIELD_SWITCH}</span>
              </div>
            </div>
          </div>
          )}
      </div>
    </div>
  );
};

export default DashboardUserSettings;
