import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";

import styles from "./SuggestionInfoTopBar.module.scss";

import { Icon } from "../../components/ui";

import { suggestionsData } from "../../../data/constants";
import { adminThunk, vacationThunk } from "../../../__store/thunks";
import { format } from "date-fns";
import { useVacation } from "../../../__store/tools/useVacation";
import { IconTypes } from "../../../data/config";

const { suggestionInfoTopBarHeaders } = suggestionsData;

export interface ISuggestionInfoTopBarProps {
  isReadOnly?: boolean;
}

const SuggestionInfoTopBar = ({
  isReadOnly,
}: ISuggestionInfoTopBarProps): ReactElement => {
  const dispatch = useAppDispatch();
  const formInfo = useAppSelector((state) => state.forms.formInfoData);
  const userId = useAppSelector((state) => state.auth.user_id);
  const { userVacations } = useVacation();

  useEffect(() => {
    userId === formInfo?.evaluating_user_id
      ? dispatch(
          vacationThunk.getUserHolidays(
            parseInt(formInfo?.reporting_user_id)
          )
        )
      : dispatch(
          vacationThunk.getUserHolidays(
            formInfo?.evaluating_user_id as number
          )
        );
  }, []);

  return (
    <>
      <div className={styles["top-bar"]}>
        <h1 className={styles["top-bar__header"]}>
          {isReadOnly
            ? suggestionInfoTopBarHeaders.SUGGESTION
            : suggestionInfoTopBarHeaders.SUGGESTION_FORM}
        </h1>
        <Icon
          type={IconTypes.CROSS}
          className={styles["top-bar__icon"]}
          onClick={() => dispatch(modalActions.closeModal())}
        />
      </div>
      {userVacations && (
        <div className={styles["holiday-info"]}>
          <Icon
            type={IconTypes.ALERT_VACATION}
            className={styles["holiday-icon"]}
          />{" "}
          &nbsp;
          <strong>
            {userId === formInfo?.evaluating_user?.user_id
              ? formInfo.reporting_user.profile.name +
                " " +
                formInfo?.reporting_user.profile.surname
              : formInfo?.evaluating_user?.profile.name +
                " " +
                formInfo?.evaluating_user?.profile.surname}{" "}
          </strong>{" "}
          &nbsp;
          {} przebywa na urlopie do dnia {} &nbsp;
          <strong>
            {format(new Date(userVacations.endHoliday_at), "dd-MM-yyyy")}
          </strong>
        </div>
      )}
    </>
  );
};

export default SuggestionInfoTopBar;
