import styles from "../views/SuggestionPreview.module.scss";

import { MainStatuses } from '../../../data/config';

import { Typography } from "antd";
import { SuggestionStatusTag } from ".";
const { Title } = Typography;

const SuggestionTitle = ({ title, status, substatus }: {
    title: string, status: MainStatuses, substatus: any
}) => {
  return (
    <div className={styles["title-section"]}>
    <Title level={3} style={{
        marginBottom: 0, 
        marginRight: 16,
        fontSize: 28, 
        fontWeight: 700
      }}
    >
      {title}
    </Title>
    <SuggestionStatusTag 
      status={status} 
      substatus={substatus} 
    />
  </div>
  )
};

export default SuggestionTitle;
