import { ReactElement, useEffect } from "react";
import { useDrag } from "react-dnd";

import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";

import { departmentActions } from "../../../../__store/slices";

import { Icon } from "../../../components/ui";

import styles from "./ChildDepartment.module.scss";
import { IChildDepartmentProps } from "../../types/AdminProps.types";
import { IconTypes } from "../../../../data/config";

function ChildDepartment({ department, changeDepartment }: IChildDepartmentProps):ReactElement {
  const dispatch = useAppDispatch();
  const selectedDepartmentId = useAppSelector((state) => state.departments.selectedDepartment);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "box",
    item: {
      type: "department",
      id: department.department_id,
      selectedId: selectedDepartmentId,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (isDragging) {
      dispatch(departmentActions.setDraggedDepartment(department.department_id));
    }
  }, [isDragging]);

  return (
    <li
      ref={drag}
      key={department.department_id}
      className={`${styles["department"]} `}
      onClick={() => changeDepartment(department.department_id, department)}
    >
      <div className={styles["department__icon"]}>
        <Icon type={IconTypes.BUSINESS} />
      </div>
      <span className={styles["department__name"]}>{department.name}</span>
    </li>
  );
}
export default ChildDepartment;
