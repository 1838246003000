import { adminModels } from "../../__store/models";

import { usersData } from "../constants";

const { updateUserFormMessages, NAME_MIN_LENGTH, SURNAME_MIN_LENGTH } = usersData;

class AddUserValidationForm {
  isEmailValid(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  isAllFieldFilled(state: adminModels.UpdateUser): boolean {
    let isValid = true;
    for (const values of Object.values(state)) {
      // @ts-ignore
      if (!values.length) {
        isValid = false;
      }
    }
    return isValid;
  }

  isMatchedPassword(password: string, repeatedPassword: string): boolean {
    return password === repeatedPassword;
  }

  updateFormValidation(localState: adminModels.UpdateUserInfo, user: adminModels.UpdateUserInfo) {
    let isValid = true;
    let message = "";

    if (
      localState.name === user.name &&
      localState.surname === user.surname &&
      localState.job_position_id === user.job_position_id &&
      localState.area_id === user.area_id &&
      localState.email === user.email &&
      localState.identifier === user.identifier &&
      localState.hr_permission === user.hr_permission &&
      localState.contract === user.contract &&
      localState.finance_permission === user.finance_permission &&
      localState.payment_permission === user.payment_permission &&
      localState.structure_permission === user.structure_permission &&
      localState.all_suggestions_preview_permission === user.all_suggestions_preview_permission &&
      localState.external_comments_permission === user.external_comments_permission &&
      localState.isActive === user.isActive &&
      localState.employmentStatus === user.employmentStatus
      ) {
      isValid = false;
      message = updateUserFormMessages.NEW_DATA_MESSAGE;
    }

    return { isValid, message };
  }

  validateNameAndSurname(name: string, surname: string) {
    let isValid = true;
    let message = "";

    if (name.length < NAME_MIN_LENGTH) {
      isValid = false;
      message = updateUserFormMessages.NAME_TOO_SHORT;
    } else if (surname.length < SURNAME_MIN_LENGTH) {
      isValid = false;
      message = updateUserFormMessages.SURNAME_TOO_SHORT;
    }

    return { isValid, message };
  }
}

export default AddUserValidationForm;
