import { useAppSelector } from "../__store/tools/hooks";

export const useClientLogo = () => {
  const CLIENT_NAME = process.env.REACT_APP_VERSION || "creaction";
  const Logo = require(`../assets/images/${CLIENT_NAME}/logo-1.png`);

  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );
  const logoClassName =
    themeName === "silesia"
      ? "logo--silesia"
      : themeName === "pgf"
      ? "logo--pgf"
      : themeName === "gobarto"
      ? "logo--gobarto"
      : "logo";
  return { CLIENT_NAME, Logo, themeName, logoClassName };
};
