import styles from "./NavTopBar.module.scss";
import { useClientLogo } from "../../../hooks/useClientLogo";
import { authData } from "../../../data/constants";

export interface NavTopBarProps {
  topBarHeader: string;
}

const NavTopBar = ({ topBarHeader }: NavTopBarProps) => {
  const { Logo, logoClassName } = useClientLogo();

  return (
    <header className={styles["navtopbar"]}>
      <div className={styles["navtopbar__section"]}>
        <h4 className={styles["navtopbar__header"]}>{topBarHeader}</h4>
        <img
          src={Logo}
          alt={authData.LOGO_ALT_DESC}
          className={styles[logoClassName]}
          data-testid="main-auth-page-logo"
        />
      </div>
    </header>
  );
};

export default NavTopBar;
