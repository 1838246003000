import { ReactElement } from "react";
import { useAppSelector } from "../../../__store/tools/hooks";

import styles from "./DashboardAllStatsInfo.module.scss";
import { IDashboardAllStatusInfoProps } from "../types/ProfileProps.types";

import { profileData } from "../../../data/constants";
import { UserRoles } from "../../../data/config";
const { dashboardAllStatsInfoLabels } = profileData;

const DashboardAllStatsInfo = ({
  isSupperVisor,
}: IDashboardAllStatusInfoProps):ReactElement => {
  const superVisorSuggestions = useAppSelector((state) => state.suggestions.superVisorSuggestions);

  const allSuggestions = useAppSelector((state) => state.suggestions.all);

  const userRole = useAppSelector((state) => state.auth.role);

  const reportCount = useAppSelector((state) => state.suggestions.report.countedStatisticSuggestion);
  const realizationCount = useAppSelector((state) => state.suggestions.realization.countedStatisticSuggestion);
  const verificationCount = useAppSelector((state) => state.suggestions.verification.countedStatisticSuggestion);
  const creationCount = useAppSelector((state) => state.suggestions.creation.countedStatisticSuggestion);
  const rejectionCount = useAppSelector((state) => state.suggestions.rejection.countedStatisticSuggestion);
  const appealedCount = useAppSelector((state) => state.suggestions.appealed.countedStatisticSuggestion);

  const suggestionsCounterForBoss = 
    reportCount 
    + rejectionCount 
    + realizationCount 
    + verificationCount 
    + creationCount 
    + appealedCount

  return (
    <div className={`${styles["all-suggestion__container"]} `}>
      <h3 className={styles["all-suggestion__header"]}>{dashboardAllStatsInfoLabels.ALL_SUGGESTIONS}</h3>
      <div className={`${styles["all-suggestion"]} `}>
        <p className={styles["all-suggestion__amount"]}>
          {userRole === UserRoles.BOSS 
            ? suggestionsCounterForBoss
            : isSupperVisor
              ? superVisorSuggestions.countedStatisticSuggestion
              : allSuggestions.countedSuggestion
          }
        </p>
      </div>
    </div>
  );
};

export default DashboardAllStatsInfo;
