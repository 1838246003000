import { createSlice } from "@reduxjs/toolkit";
import { evaluationModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: evaluationModels.EvalutaionState = {
  evaluationType: evaluationModels.EvaluationType.UNSPECIFIED,
  reviewingEvaluationId: -1,
  personalRates: {
    positiveRates: 0,
    negativeRates: 0,
  },
  evalautingUserId: -1,
};

const evaluationSlice = createSlice({
  name: SliceNames.EVALUATION,

  initialState,

  reducers: {
    setEvaluationType: (state, { payload }) => {
      state.evaluationType = payload;
    },
    setEvaluatingUserId: (state, { payload }) => {
      state.evalautingUserId = payload;
    },
    setUsersToEvaluate: (state, { payload }) => {
      state.usersToEvaluate = payload;
    },
    setUsersAppraisalStatuses: (state, { payload }) => {
      state.apperialUserStatuses = payload;
    },
    setUserRates: (state, { payload }) => {
      state.userRates = payload;

      state.personalRates.negativeRates = payload.filter(
        (rate: any) => rate.type === "NEGATIVE"
      ).length;

      state.personalRates.positiveRates = payload.filter(
        (rate: any) => rate.type === "POSITIVE"
      ).length;
    },

    setReviewingEvaluationId: (state, { payload }) => {
      state.reviewingEvaluationId = payload;
    },
    setSearchPlaceholder: (state, { payload }) => {
      state.searchPlaceholder = payload;
    },
  },
});

export const {
  setEvaluationType,
  setEvaluatingUserId,
  setUsersToEvaluate,
  setReviewingEvaluationId,
  setUsersAppraisalStatuses,
  setUserRates,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;
