import { createSlice } from "@reduxjs/toolkit";

import { dashboardModels } from "../models";

import { SliceNames } from "../../data/config";
import { profileData } from "../../data/constants";

const { DashboardOptions } = profileData;

const initialState: dashboardModels.DashboardState = {
  dashboardOptions: DashboardOptions.YOUR_DASHBOARD,
};

const dashboardSlice = createSlice({
  name: SliceNames.DASHBOARD,
  initialState,
  reducers: {
    setYourDashboard: (state) => {
      state.dashboardOptions = DashboardOptions.YOUR_DASHBOARD;
    },
    setWorkerDashboard: (state) => {
      state.dashboardOptions = DashboardOptions.WORKER_DASHBOARD;
    },
  },
});

export const { 
  setYourDashboard, 
  setWorkerDashboard 
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
