import { ReactElement } from "react";

import styles from "./SuggestionsDashboard.module.scss";
import { IDashboardHeaderProps } from "../types/SuggestionsProps.types";

const DashboardHeader = ({ columnsList, headerMainClassName } : IDashboardHeaderProps) : ReactElement => {
  return (
    <div className={styles[headerMainClassName]}>
      {columnsList.map((column) => {
        const { header, className } = column;
        return (<span key={header} className={styles[className]}>{header}</span>)
      })}
    </div>
  )
}

export default DashboardHeader;
