import { jwtDecode }  from "jwt-decode";
import { AxiosResponse } from "axios";
import axiosInstance from "../../tools/axios";

import { authModels } from "../../models";

import { ServerPaths } from "../../../data/config";

const { 
  LOGIN, 
  LOGOUT, 
  PASSWORD_CHECK, 
  PASSWORD_RESET, 
  PASSWORD_RECOVERY, 
  PASSWORD_VERIFY, 
  PASSWORD_CHANGE, 
  PASSWORD_NEW, 
  SETTINGS,
  RULES_ACCEPTANCE
} = ServerPaths;

export const postResetPasswordId = (
  passwordResetId: string
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${PASSWORD_CHECK}`, {
    passwordResetId,
  });
};

export const postResetPassword = (
  payload: authModels.ResetPasswordPayload
): Promise<AxiosResponse<any, any>> => {
  const { password, passwordResetId } = payload;
  return axiosInstance.post(`${PASSWORD_RESET}`, {
    password,
    passwordResetId,
  });
};

export const postUserLoginData = (
  credentials: authModels.Credentials
): Promise<AxiosResponse<any, any>> => {
  const { username, password } = credentials;
  return axiosInstance.post(`${LOGIN}`, {
    username,
    password,
  });
};

export const patchMailNotifications = (
  payload: authModels.PatchSendMailNotificationsPayload
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.patch(`${SETTINGS}`, { payload });
};

export const postUserLogoutData = (
  user_id: number
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${LOGOUT}/`, {
    user_id,
  });
};

export const postVerifyPassword = (
  payload: authModels.VerifyPassword
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${PASSWORD_VERIFY}/`, payload);
};

export const generateUserRoleFromJWT = () => {
  const token = localStorage.getItem("jwt");
  if (token) {
    const tokenValue: authModels.jwtTokenValue = jwtDecode(token);

    return tokenValue;
  }
  return;
};

export const postRecoveryPassword = (email: string) => {
  return axiosInstance.post(`${PASSWORD_RECOVERY}`, {
    email,
  });
};

export const postNewPassword = (payload: authModels.NewPasswordModel) => {
  return axiosInstance.post(`${PASSWORD_NEW}`, {
    ...payload,
  });
};

export const changePostPassword = (payload: authModels.NewPasswordModel) => {
  return axiosInstance.post(`${PASSWORD_CHANGE}`, {
    ...payload,
  });
};

export const updateRulesAcceptance = (payload: authModels.AcceptRulesPayload) => {
  return axiosInstance.post(`${RULES_ACCEPTANCE}`, {
    ...payload,
  });
};
