import { useEffect, useState, FunctionComponent, ReactElement } from "react";

import { useAppDispatch } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";

import { Icon } from "../../components/ui";

import styled from "./Popup.module.scss";

import { IconTypes } from "../../../data/config";
import { ModalColor } from "../../components/Modal/FormModalContainer";

export interface IPopupProps {
  text: string;
  color: string;
};

const Popup: FunctionComponent<IPopupProps> = ({ text, color }):ReactElement => {
  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState(3000);
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [timerId, setTimerId] = useState<number>(0);

  function clickHandler() {
    dispatch(modalActions.closeModal());
  }

  function mouseEnterHandler() {
    clearTimeout(timerId);
    const newTime = new Date().getTime() - startTime;
    setTimeLeft((prev) => prev - newTime);
  }

  function mouseLeaveHandler() {
    setStartTime(new Date().getTime());
    if (timeLeft >= 0) {
      const newTimer = timeOut(timeLeft);
      setTimerId(+newTimer);
    } else {
      clearTimeout(timerId);
    }
  }

  function timeOut(timeLeft: number) {
    return setTimeout(() => dispatch(modalActions.closeModal()), timeLeft);
  }

  useEffect(() => {
    const timer = timeOut(timeLeft);
    setTimerId(+timer);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={styled["popup"]}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      
      <Icon type={color === ModalColor.BLUE 
        ? IconTypes.SUCCESS_ICON 
        : IconTypes.FAIL_ICON} 
      />
      <span className={styled["popup__message"]}>{text}</span>

      <button onClick={clickHandler} className={styled["popup__button"]}>
        <Icon type={IconTypes.DENIED} />
      </button>
    </div>
  );
};

export default Popup;
