import { ReactElement } from "react";

import styles from "./SuggestionsDashboard.module.scss";
import { IDashboardStatusButtonsProps } from "../types/SuggestionsProps.types";

const DashboardStatusButtons = (
  { statusButton, buttonsList, suggestionsCount, buttonsMainClassName, buttonsAdditionalClassName, onClickFunction } 
  : IDashboardStatusButtonsProps) : ReactElement => {
    return (
      <div className={`${styles[buttonsMainClassName]} 
        ${buttonsAdditionalClassName && styles[buttonsAdditionalClassName]}`}>
        {buttonsList.map((button) => {
          const { name, status } = button;
          return (
            <button
              className={`${styles["status-btn"]} ${
              statusButton === status && styles["active"]}
              ${buttonsAdditionalClassName && styles[buttonsAdditionalClassName]}`}
              key={status}
              onClick={() => onClickFunction(status)}
             >
              {name} ({suggestionsCount[status]})
            </button>)
          })
        }
      </div>
    )
  }

export default DashboardStatusButtons;
