import { pl } from "date-fns/locale";

import { CLIENT_NAME } from "../../data/config/globalContentVariables.data";

export enum SuggestionTypes {
  MICROIMPROVEMENT = "MICROIMPROVEMENT",
  INVESTMENT = "INVESTMENT",
}

export const SuggestionTypesTranslated = new Map([
  [SuggestionTypes.MICROIMPROVEMENT, "Mikrousprawnienie"],
  [
    SuggestionTypes.INVESTMENT,
    CLIENT_NAME === "silesia" ? "Makrousprawnienie" : "Inwestycyjny",
  ],
]);

// Suggestions section data
export const topBarSuggestionsHeaders = {
  MySuggestionsHeader: "Twoje zgłoszenia",
  WorkerSuggestionsHeader: "Zgłoszenia pracowników twojego działu",
  WorkerSuggestionsHeaderForBoss: "Zgłoszenia twoich pracowników",
  DepartmentSuggestionsHeader: "Zgłoszenia pracowników innych działów",
  OtherDepartmentsSuggestionsHeader:
    "Zgłoszenia pracowników twojego działu do innych działów",
  AllSuggestionsPreviewHeader: "Podgląd wszystkich zgłoszeń",
  NewSuggestionHeader: "Nowa sugestia",
};

export const createSuggestionData = {
  headers: {
    data: "Dane",
    suggestionDescription: "Opis sugestii",
    additionalInformation: "Informacje dodatkowe",
  },
  labels: {
    suggestion: 
      CLIENT_NAME === "silesia"
        ? "Problem:"
        : CLIENT_NAME === "dimograf"
          ? "Opis problemu"
          : "Sugestia",
    solution: CLIENT_NAME === "silesia" ? "Przyczyny problemu:" : "Rozwiązanie",
    result:  
      CLIENT_NAME === "silesia"
        ? "Proponowane rozwiązanie i wymagane działania:"
        : "Rezultat",
    cost: "Koszt",
    title: "Tytuł",
    department: "Dział",
    area: "Obszar",
    sendTo: "Zgłoś do",
    consultWithOtherWorker: "Skonsultowano z innym pracownikiem",
    needsExtraHelp: "Możliwe do samodzielnego wdrożenia",
  },
  placeholders: {
    enterTitle: "Podaj tytuł",
    passSugestion:
      CLIENT_NAME === "silesia"
        ? "Opisz problem..."  
        : "Wpisz sugestię...",
    suggestSolution:
      CLIENT_NAME === "silesia"
        ? "Podaj przyczyny problemu..."
        : "Zaproponuj rozwiązanie...",
    expectedResult:
      CLIENT_NAME === "silesia"  
        ? "Opisz proponowane rozwiązanie i wymagane działania..."
        : "Opisz oczekiwany rezultat...",
    enterSurname: "Wpisz nazwisko",
    selectDepartment: "Wybierz dział",
    selectSupervisor: "Wybierz przełożonego",
    selectArea: "Wybierz obszar",
    selectRange: "Wybierz zakres",
    extraHelp: "Określ rodzaj potrzebnej pomocy"
  },
};

export const topBarElementsDescriptions = {
  search: "Wyszukaj tytuł lub autora",
  addSuggestion: "+ Dodaj zgłoszenie",
};

// General/common form variables
export const TITLE_VISIBLE_CHARS_MAX = 50;
export const DEPARTMENT_VISIBLE_CHARS_MAX = 35;

export const DATE_FORMAT = "d MMMM yyyy, HH:mm";
export const DATE_OPTIONS = { locale: pl };

export const COMMENT_MIN_LENGTH = 5;
export const COMMENT_MAX_LENGTH = CLIENT_NAME === "dimograf" ? 1500 : 500;

export const TEXT_AREA_MAX_LENGTH = CLIENT_NAME === "dimograf" ? 1500 : 500;
export const INPUT_MAX_LENGTH = 150;
export const FILE_SIZE_MAX = 100000000;

export const ESTIMATION_BREAK_POINT = 
  CLIENT_NAME === "filters" ? 500
  : CLIENT_NAME === ("silesia" || "pgf") ? 700 
  : CLIENT_NAME == "dimograf" ? 1000
  : 2000;

export const ACTIVE_BUTTON = "active-button";
export const ATTACHMENT_ALT = "Załącznik";

export const FILE_SIZE_TOO_BIG =
  "Wielkość załączników rozwiązania jest większa niż 100mb, proszę sprawdź wielkość ostatnio załączonego pliku";

export const createSuggestionValidationMessages = {
  title: "Podaj tytuł sugestii",
  evaluating_user: "Podaj osobę oceniającą z wybranego działu",
  department_id: "Podaj dział właściwy do rozpatrzenia sugestii",
  area_id: "Podaj obszar, którego dotyczy sugestia",
  estimated_cost: "Podaj poprawną dodatnią wartość szacowanego kosztu",
  problemDescription:
    CLIENT_NAME === "silesia"
      ? "Przedstaw opis problemu"
      : "Przedstaw treść rozwiązania",
  solutionDescription: 
    CLIENT_NAME === "silesia"
      ? "Przedstaw przyczyny problemu"
      : "Przedstaw treść rozwiązania",
  resultDescription: 
    CLIENT_NAME === "silesia"
      ? "Przedstaw opis proponowanego rozwiązania i wymaganych działań"
      : "Przedstaw treść rezultatu"
  };

export const EstimatedCostOptionsMap = new Map<string, number>([
  ["0-100", 100],
  ["100-500", 500],
  ["500-1000", 1000],
  ["1000-1500", 1500],
  ["1500-2000", 2000],
  ["powyżej 2000", 2001],
]);

export const approvedSuggestionFormLabels = {
  MOVE_SUGGESTION_TO_IMPLEMENTED:
    'Czy chcesz przenieść zgłoszenie do zakładki "Zrealizowane"?',
  BACK_SUGGESTION_TO_APPROVED: 'Cofnij do "W trakcie wdrożenia"',
  DATE: "Data: ",
  AUTHOR: "Autor: ",
  ADD_COMMENT: "Dodaj komentarz",
};

export const approvedSuggestionFormButtons = {
  SEND_BUTTON: "Potwierdź",
  CANCEL_BUTTON: "Anuluj",
  FILE_UPLOAD_BUTTON: "Wybierz plik",
};

export const correctSuggestionFormLabels = {
  WAS_SUGGESTION_CONSULTED:
    "Czy pomysł został skonsultowany z innym pracownikiem?",
  CONFIRM_CONSULTATION: "Tak",
  ADD_COMMENT: "Dodaj komentarz",
};

export const correctSuggestionFormButtons = {
  SEND_BUTTON: "Potwierdź",
  CANCEL_BUTTON: "Anuluj",
  FILE_UPLOAD_BUTTON: "Wybierz plik",
};

export const endResultLabels = {
  TITLE: "Rezultat końcowy",
  ESTIMATION: "Koszt pierwotnie oszacowano jako:",
  REAL_COST: "Realny koszt: ",
  DATE: "Data: ",
  AUTHOR: "Autor: ",
  ADD_RESULT: "Dodaj informację o rezultacie",
  ADD_REAL_COST: "Realny koszt w zł: ",
};

export const endResultButtons = {
  FILE_UPLOAD_BUTTON: "Wybierz plik",
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Dodaj",
  EXIT_BUTTON: "Wyjdź",
};

export const redirectionMessages = {
  REDIRECTION_TO_AUTHOR:
    "Nie możesz przekierować do działu, z którego pochodzi kierownik zgłaszający ocenianą sugestię!",
  REDIRECTED_AUTOMATICALLY:
    "Nie możesz dokonać przekierowania do własnego działu, ponieważ sugestia została do niego automatycznie przekierowana do kierownika wyższego szczebla.",
  MANAGER_NOT_DEFINED:
    "Wybrany dział nie posiada zdefiniowanego kierownika, dlatego przekierowanie nie jest możliwe.",
};

export const evaluateFormSubtitles = {
  ESTIMATED_COST_SUBTITLE: "Szacowany koszt w zł:",
  SUGGESTION_TYPE_SUBTITLE: "Rodzaj pomysłu:",
  CONSULTATION_WARNING: "Pomysł nie został skonsultowany z innym pracownikiem.",
  NOT_CORRECTED_WARNING:
    "Pomysł został uprzednio cofnięty do pracownika. Pomimo tego ocena sugestii jest możliwa.",
  EVALUATE_SECTION_SUBTITLE: "Przyjęcie pomysłu",
  REDIRECT_SECTION_SUBTITLE: "Przekierowanie do innego działu",
  COMMENTARY_SECTION_SUBTITLE: "Dodaj komentarz",
};

export const evaluateFormButtons = {
  ACCEPT_BUTTON: "Zaakceptuj",
  REJECT_BUTTON: "Odrzuć",
  REDIRECT_BUTTON: "Przekieruj",
  BACK_TO_WORKER_BUTTON: "Cofnij do pracownika",
  STOP_BUTTON: "Anuluj",
  SEND_BUTTON: "Potwierdź",
};

export const evaluateModalOptions = {
  APPROVE: "Tak",
  REJECT: "Nie",
};

export const loadingAddSuggestionInfo = "Trwa przesyłanie formularza";

export const noteFormLabels = {
  TITLE: "Notatka",
  DATE: "Data: ",
  AUTHOR: "Autor: ",
};

export const noteFormButtons = {
  EXPORT: "Eksportuj",
  ADD: "Dodaj",
  EDIT: "Edytuj",
  CANCEL: "Anuluj",
};

export const popupDoneLabels = {
  VERIFY: "Czy na pewno chcesz oddać zadanie do weryfikacji?",
  VERIFY_AGAIN: "Czy chcesz ponownie oddać zgłoszenie do weryfikacji?",
  MOVE_TO_IMPLEMENTED_BOSS:
    'Czy chcesz przenieść zgłoszenie do zakładki "Zrealizowane"?',
  DATE: "Data: ",
  AUTHOR: "Autor: ",
  ADD_COMMENT: "Dodaj komentarz",
};

export const popupDoneButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Potwierdź",
  APPROVE_BUTTON: "Tak",
  REJECT_BUTTON: "Nie",
};

export const popupYesNoButtons = {
  APPROVE_BUTTON: "Tak",
  REJECT_BUTTON: "Nie",
};

export const suggestionInfoFormLabels = {
  ADD_COMMENT: "Dodaj komentarz",
};

// RepealBox data

export const repealBoxLabels = {
  SENT_TO_VERIFICATION: "Wysłano do weryfikacji",
  VERIFICATION_DECISION: "Czy na pewno chcesz oddać zadanie do weryfikacji?",
};

// AppealedBox data

export const appealedBoxLabels = {
  DATE: "Data: ",
  REJECTING_USER: "Odrzucone przez: ",
  REJECTION_REASON: "Wyjaśnienie: ",
  EVALUATION: "Ocena: ",
  REJECTION: "Odrzucony ",
  MAKE_APPEAL: "Złóż sprzeciw",
  APPEAL_REASON: "Wyjaśnij powód swojego sprzeciwu",
};

export const appealedBoxButtons = {
  SEND_BUTTON: "Potwierdź",
};

// SuggestionStatusInfo data

export const ALLOWED_IMAGE_EXTENSIONS = [
  ".jpeg",
  ".png",
  ".jpg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".pnp",
  ".svg",
  ".webp",
];

export const suggestionStatusInfoLabels = {
  ATTACHMENTS: "Załączniki",
  ATTACHMENT_SUGGESTION_DESCRIPTION: "Załącznik do sugestii",
  ATTACHMENT_SOLUTION_DESCRIPTION: "Załącznik do rozwiązania",
  ATTACHMENT_RESULT_DESCRIPTION: "Załącznik do rezultatu",
  ATTACHMENT_VERIFICATION_DESCRIPTION: "Załącznik po weryfikacji sugestii",
  DATE: "Data: ",
  AUTHOR: "Autor: ",
  DEPARTMENT: "Dział: ",
  MANAGER: "Manager",
  REPORTING_USER: "Zgłaszający: ",
  SUPERVISOR: "Przełożony: ",
  IS_CONSULTED: "Czy pomysł został skonsultowany z innym pracownikiem?",
  IS_TODO_YOURSELF: "Czy jesteś sam w stanie wdrożyć usprawnienia?",
  TITLE: "Tytuł",
  SUGGESTION: CLIENT_NAME === "silesia" ? "Problem:" : "Sugestia",
  SOLUTION: CLIENT_NAME === "silesia" ? "Przyczyny problemu:" : "Rozwiązanie",
  RESULT:
    CLIENT_NAME === "silesia"
      ? "Proponowane rozwiązanie i wymagane działania:"
      : "Rezultat",
  END_RESULT: "Rezultat końcowy",
  ESTIMATED_COST:
    CLIENT_NAME === "silesia" ? "Szacowany koszt w zł: " : "Szacowany koszt",
  NEEDED_HELP: "Jakiej potrzebujesz pomocy?",
  ANSWER_TRUE: "Tak",
  ANSWER_FALSE: "Nie",
  COMMENTS: "Komentarze",
  NOTE: "Notatka",
  ADD_COMMENT: "Dodaj komentarz"
};

export const suggestionStatusInfoButtons = {
  ATTACHMENT_DOWNLOAD_BUTTON: "Pobierz",
  ATTACHMENT_VIEW_BUTTON: "Podgląd",
  EXPORT_PDF_BUTTON: "Eksportuj",
  SEND_BUTTON: "Wyślij",
  CANCEL_BUTTON: "Anuluj",
};

// SuggestionInfoTopBar data

export const suggestionInfoTopBarHeaders = {
  SUGGESTION: "Zgłoszenie",
  SUGGESTION_FORM: "Formularz zgłoszeniowy",
};
