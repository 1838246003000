enum iconTypes {
  ACCEPTANCE = "acceptance",
  ADMIN = "admin",
  ALERT_VACATION = "alert-vacation",
  ARROW = "arrow",
  ARROW_DOWN = "arrow-down",
  ATTACH = "attach",
  ASSESSMENT = "assessment",
  ASSESSMENT_CROSS = "assessment-cross",
  AVATAR = "avatar",
  AVATAR_BLACK = "avatar-black",
  BIN = "bin",
  BLUE_BELL = "blue-bell",
  BUSINESS = "business",
  CALENDAR = "calendar",
  CANCEL = "cancel",
  CHEVRON_LEFT = "chevron-left",
  CHEVRON_RIGHT = "chevron-right",
  CIRCLE = "circle",
  CROSS = "cross",
  CHECKMARK = "checkmark",
  CHECK_WHITE = "check-white",
  CHECK_VIOLET = "check-violet",
  DASHBOARD = "dashboard",
  DELETE_VACATION = "delete-vacation",
  DENIED = "denied",
  DOWNLOAD = "download",
  DOWNLOAD_WHITE = "download-white",
  DOWNLOAD_VIOLET = "download-violet",
  EDIT = "edit",
  EDIT_VACATION = "edit-vacation",
  EXPAND_LESS = "expand-less",
  EXPAND_MORE = "expand-more",
  EXCLAMATION = "exclamation",
  EYE = "eye",
  EYE_SLASH = "eye-slash",
  INFO = "info",
  INFO_ICON = "info-icon",
  KEY = "key",
  LOADING = "loading",
  MENU = "menu",
  MESSAGE = "message",
  MESSAGE_ICON = "messageIcon",
  MONEY_BOX = "money-box",
  OPEN_ASSESSMENT = "open-assessment",
  RANKING = "ranking",
  REPORT = "report",
  REPORT_WHITE = "report-white",
  RED_BELL = "red-bell",
  SEARCH = "search",
  STRUCTURE = "structure",
  SUGGESTION = "suggestion",
  THUMB = "thumb",
  TRASH = "trash",
  UPLOAD = "upload",
  USER = "user",
  VACATION = "vacation",
  WAIT = "wait",
  WORKER = "worker",
  YELLOW_BELL = "yellow-bell",
  HELP = "help",
  GEAR = "gear",
  LOGOUT = "logout",
  GRAPH_ICON = "graphIcon",
  DOLAR_ICON = "dolarIcon",
  USER_ICON = "userIcon",
  SUGGESTION_BOX = "suggestionBox",
  STAR = "starIcon",
  GO_BACK = "goBackIcon",
  BURGER_ICON = "burgerIcon",
  CHECK_CIRCLE_ICON = "checkCircleIcon",
  CANCEL_CIRCLE_ICON = "cancelCircleIcon",
  BIG_CHEVRON_LEFT = "big-chevron-left",
  PENCIL_ICON = "pencilIcon",
  MONKEY_ICON = "monkeyIcon",
  PAPER_ICON = "paperIcon",
  SUIT_CASE_ICON = "suitCaseIcon",
  PEOPLE_GROUP_ICON = "peopleGroupIcon",
  INPUT_MAN_ICON = "input-man-icon",
  KEY_INPUT_ICON = "key-icon",
  SUCCESS_ICON = "successs-icon",
  FAIL_ICON = "fail-icon",
  AREA_ICON = "area-icon",
  INFO_STRUCTURE_ICON = "info-structure-icon",
  CLOCK_ICON = "clock-icon",
  EMPTY_DOT_ICON = "empty-dot-icon",
  PREVIEW_EYE_ICON = "preview-eye-icon"
}

export default iconTypes;
