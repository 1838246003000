import { useEffect, ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { departmentThunk, suggestionThunk } from "../../../__store/thunks";

import { MainStatuses } from "../../../data/config";
import { ISuggestionsCount, TCategoriedSuggestions } from "../types/SuggestionsDashboard.types";
import { SuggestionsPageTemplate } from "../components";

const DepartmentSuggestionsPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const suggestionState = useAppSelector((state) => state.suggestions);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
    dispatch(suggestionThunk.getOtherDepartmentsSuggestions());
  }, []);

  const otherDepartmentSuggestionsCount: ISuggestionsCount =
  suggestionState.otherDepartmentSuggestionsCount;

  const DepartmentSuggestionsMap: TCategoriedSuggestions = new Map([
  [ MainStatuses.REPORT, suggestionState.departmentReportedSuggestions ],
  [ MainStatuses.REALIZATION, suggestionState.departmentImplementedSuggestions ],
  [ MainStatuses.VERIFICATION, suggestionState.departmentDoneSuggestions ],
  [ MainStatuses.CREATION, suggestionState.departmentApprovedSuggestions ],
  [ MainStatuses.APPEALED, suggestionState.departmentAppealedSuggestions ],
  [ MainStatuses.REJECTION, suggestionState.departmentRejectedSuggestions ],
]);

  return (
    <SuggestionsPageTemplate
      suggestionsCount={otherDepartmentSuggestionsCount}
      suggestionsMap={DepartmentSuggestionsMap}
    />
  );
}

export default DepartmentSuggestionsPage;
