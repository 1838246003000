enum UserRoles {
  WORKER = "WORKER",
  MANAGER = "MANAGER",
  BOSS = "BOSS",
  ADMIN = "ADMIN"
};

export const UserRolesTranslated = new Map([
  [UserRoles.WORKER, "Pracownik"],
  [UserRoles.MANAGER, "Manager"],
  [UserRoles.BOSS, "Prezes"],
  [UserRoles.ADMIN, "Administrator"]
]);

export enum ManagerRoles {
  MANAGER = "MANAGER",
  BOSS = "BOSS",
};

export const fullPermissions:UserRoles[] = [
  UserRoles.WORKER, 
  UserRoles.MANAGER, 
  UserRoles.BOSS, 
  UserRoles.ADMIN,
];

export const managersPermissions:UserRoles[] = [
  UserRoles.MANAGER,
  UserRoles.BOSS,
];

export const adminOnlyPermissions:UserRoles[] = [
  UserRoles.ADMIN
];

export default UserRoles;
