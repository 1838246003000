import { createAsyncThunk } from "@reduxjs/toolkit";

import { adminService } from "./services";
import { adminModels } from "../models";
import { formsActions, modalActions, usersActions } from "../slices";

import { ServerStatuses, ThunkNames, ModalClassnames } from "../../data/config";

const {
  ADDED_USER,
  ADD_USER_ERROR,
  REMOVED_USER,
  REMOVE_EVALUATING_USER_ERROR,
  REMOVED_USER_ERROR,
  UPDATED_USER,
  UPDATE_USER_ERROR,
} = ModalClassnames;

const { USERS } = ThunkNames;

export const addUser = createAsyncThunk(
  USERS.ADD_USER_THUNK,
  (payload: adminModels.NewUser, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(usersActions.clearErrorMessage());
    adminService
      .postUser(payload)
      .then((data) => {
        if (data.status === ServerStatuses.CREATED) {
          dispatch(modalActions.setModalClassName(ADDED_USER));
        }
        dispatch(fetchUsers());
      })
      .catch((error) => {
        if (error.response.status === ServerStatuses.INTERNAL_SERVER_ERROR) {
          dispatch(modalActions.setModalClassName(ADD_USER_ERROR));
        } else {
          dispatch(
            usersActions.setErrorMessage(error.response.data.errorMessage)
          );
        }
      });
  }
);

export const removeUser = createAsyncThunk(
  USERS.DELETE_USER_THUNK,
  (payload: adminModels.DeleteUser, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return adminService
      .deleteUser(payload)
      .then((data) => {
        if (data.status === ServerStatuses.OK) {
          dispatch(modalActions.setModalClassName(REMOVED_USER));
          dispatch(usersActions.setUserId(null));
        }
        dispatch(fetchUsers());
      })
      .catch((err) => {
        if (err.response.status === ServerStatuses.BAD_REQUEST) {
          dispatch(
            modalActions.setModalClassName(REMOVE_EVALUATING_USER_ERROR)
          );
        } else {
          dispatch(modalActions.setModalClassName(REMOVED_USER_ERROR));
        }
        dispatch(usersActions.setUserId(null));
      });
  }
);

export const updateUser = createAsyncThunk(
  USERS.UPDATE_USER_THUNK,
  (payload: adminModels.UpdateUser, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(usersActions.setErrorMessage(""));
    return adminService
      .patchUser(payload)
      .then((data) => {
        if (data.status === ServerStatuses.OK) {
          dispatch(modalActions.setModalClassName(UPDATED_USER));
          dispatch(usersActions.setUserId(null));
        }
        dispatch(fetchUsers());
      })
      .catch((error) => {
        if (error.response.status === ServerStatuses.INTERNAL_SERVER_ERROR) {
          dispatch(modalActions.setModalClassName(UPDATE_USER_ERROR));
          dispatch(usersActions.setUserId(null));
        } else {
          dispatch(
            usersActions.setErrorMessage(error.response.data.errorMessage)
          );
        }
      });
  }
);

export const UpdateYourInfo = createAsyncThunk(
  USERS.UPDATE_YOUR_INFO_THUNK,
  (payload: adminModels.UpdateUser, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(usersActions.setErrorMessage(""));
    return adminService
      .patchUser(payload)
      .then((data) => {
        if (data.status === ServerStatuses.OK) {
          dispatch(modalActions.setModalClassName(UPDATED_USER));
          dispatch(usersActions.setUserId(null));
          dispatch(fetchAuthUser(payload.user_id as number));
        }
      })
      .catch((error) => {
        if (error.response.status === ServerStatuses.INTERNAL_SERVER_ERROR) {
          dispatch(modalActions.setModalClassName(UPDATE_USER_ERROR));
          dispatch(usersActions.setUserId(null));
        } else {
          dispatch(
            usersActions.setErrorMessage(error.response.data.errorMessage)
          );
        }
      });
  }
);

export const fetchUsers = createAsyncThunk(
  USERS.FETCH_USERS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return adminService.getUsers().then((data) => {
      dispatch(usersActions.setUsers(data.data));
    });
  }
);

export const fetchUser = createAsyncThunk(
  USERS.FETCH_USER_THUNK,
  (payload: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return adminService
      .getUser(payload)
      .then((data) => {
        dispatch(formsActions.setEvaluatingUser(data.data));
        dispatch(usersActions.setUser(data.data));
      })
      .catch((err) => {
        return err;
      });
  }
);

export const fetchAuthUser = createAsyncThunk(
  USERS.FETCH_AUTH_USER_THUNK,
  (payload: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return adminService
      .getUser(payload)
      .then((data) => {
        dispatch(usersActions.setAuthUser(data.data));
      })
      .catch((err) => {
        return err;
      });
  }
);
