import { useEffect } from "react";
import { format } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { getUsersToEvaluate } from "../../../__store/thunks/evaluation.thunk";

import { InactiveAccount, NavBar } from "../../components/ui";
import { AssessmentDashboard } from "../components/AssessmentDashboard";
import { TopBar } from "../components";

import styles from "./CreateAssessment.module.scss";

const CreateAssessment = () => {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);

  useEffect(() => {
    dispatch(getUsersToEvaluate());
  }, []);

  const assessmentList = useAppSelector(
    (state) => state.evaluation.usersToEvaluate
  );
  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["page__dashboard"]}>
        <TopBar />
        <h2 className={styles["page__date--header"]}>
          Data: {format(new Date(), "MM-dd-yyyy")}
        </h2>
        <AssessmentDashboard assessmentList={assessmentList} />
      </div>) : <InactiveAccount />}
    </div>
  );
};

export default CreateAssessment;
