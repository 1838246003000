import { Link, useLocation } from "react-router-dom";
import styles from "./Table.module.scss";
import { useTable } from "./hooks/useTable";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Input } from "antd";
import { IconTypes, RoutesPaths } from "../../../data/config";
import { Icon } from "../../components/ui";
import { UserRates } from "../../../__store/models/evaluation.models";
import { format } from "date-fns";
import { MAIN_DATE_FORMAT } from "../../../data/config/globalContentVariables.data";
import { useAssessmentDisplayData } from "../../Assessment/components/hooks/useAssessmentDisplayData";
import { ITEMS_PER_PAGE } from "./data";
import ReactPaginate from "react-paginate";
import "./Table.css";
import { useEffect, useState } from "react";
import { evaluationModels } from "../../../__store/models";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { evaluationThunk } from "../../../__store/thunks";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { evaluationActions } from "../../../__store/slices";

interface ItemsListProps<T extends UserRates[], K> {
  items: T;
  withFilters?: boolean;

  //   withNavigation?: boolean;
  //   NavigationItems: T[];
  rowClass: string;
  headerValue: K[];
  checkboxLabels?: [string, string, string];
  placeholderFilterValue?: string;
  renderHeaderItem: (item: K) => React.ReactNode;
}

export function Table<T extends UserRates[], K>({
  items,
  withFilters,
  headerValue,
  renderHeaderItem,
  checkboxLabels,
  placeholderFilterValue,
  rowClass,
}: ItemsListProps<T, K>) {
  const { tableHeader, pagesVisted, paginationValues, setPaginationValues } =
    useTable();
  const location = useLocation();
  const { evaluationTitleTranslationMap, evaluationTranslationMap } =
    useAssessmentDisplayData();

  const [query, setType] = useState<evaluationModels.FilterEvaluationState>({
    search: "",
  });

  const dispatch = useAppDispatch();
  const user_id = useAppSelector((state) => state.auth.user_id as number);

  const onChange = (
    e: CheckboxChangeEvent | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.target.name === "POSITIVE" || e.target.name === "NEGATIVE"
      ? setType((prevstate) => ({
          search: prevstate.search,
          type: e.target.value,
        }))
      : setType((prevstate) => ({
          type: prevstate?.type,
          search: e.target.value,
        }));
  };

  const debouncedOnChange = debounce(onChange, 50);
  const filteringData = () => {
    if (location.pathname === "/my-assessment") {
      dispatch(evaluationThunk.filterEvaluations({ ...query, user_id }));
    }
  };

  useEffect(() => {
    filteringData();
  }, [query]);

  const pageCount = Math.ceil((items?.length as number) / ITEMS_PER_PAGE);
  const { ASSESSMENT } = RoutesPaths;

  const renderRow = <T extends UserRates>(
    index: number,
    el: T
  ): JSX.Element => {
    if ("evaluation_id" in el) {
      return (
        <tr className={rowClass}>
          <td>{index + 1}</td>
          <td>{format(new Date(el.evaluation_for_date), MAIN_DATE_FORMAT)}</td>

          <td>
            <span
              className={
                el.type === "POSITIVE" ? "positive__td" : "negative__td"
              }
            >
              {evaluationTranslationMap[el.type]}
            </span>
          </td>
          <td>{evaluationTitleTranslationMap[el.category as string]}</td>
          <td>{el.content}</td>
          <td>
            <Link to={`${ASSESSMENT.MY_ASSESSMENT_PATH}/${el.evaluation_id}`}>
              <Icon type={IconTypes.CHEVRON_RIGHT} className={styles["icon"]} />
            </Link>
          </td>
        </tr>
      );
    }
    return <></>;
  };

  const renderMobileRow = <T extends UserRates>(
    index: number,
    el: T
  ): JSX.Element => {
    if ("evaluation_id" in el) {
      return (
        <li className={styles["assessment"]} key={index}>
          <span className={styles["assessment__header"]}>
            <p
              className={
                el.type === "POSITIVE"
                  ? styles["assessment__type--poisitive"]
                  : styles["assessment__type--negative"]
              }
            >
              {evaluationTranslationMap[el.type]}
            </p>
            <p>{format(new Date(el.evaluation_for_date), MAIN_DATE_FORMAT)}</p>
          </span>
          <section className={styles["assessment__section"]}>
            <div className={styles["section"]}>
              <h4 className={styles["section__header"]}>
                {evaluationTitleTranslationMap[el.category as string]}
              </h4>
              <p className={styles["section__content"]}>
                {el.content.slice(0, 20)}...
              </p>
            </div>
            <Link to={`${ASSESSMENT.MY_ASSESSMENT_PATH}/${el.evaluation_id}`}>
              <Icon type={IconTypes.CHEVRON_RIGHT} className={styles["icon"]} />
            </Link>
          </section>
          <span className={styles["assessment__break"]} />
        </li>
      );
    }
    return <></>;
  };

  return (
    <div className={styles["table"]}>
      <div className={styles["table__header"]}>
        <h3>{tableHeader[location.pathname]}</h3>
        {withFilters && checkboxLabels && placeholderFilterValue && (
          <div className={styles["table__header--filter-section"]}>
            <div className={styles["checkbox__section"]}>
              <span>{checkboxLabels[0]}</span>
              <Checkbox
                className={styles["checkbox"]}
                name="POSITIVE"
                onChange={debouncedOnChange}
                value={
                  !query.type || query.type === "NEGATIVE"
                    ? "POSITIVE"
                    : undefined
                }
                checked={query.type === "POSITIVE"}
              >
                {checkboxLabels[1]}
              </Checkbox>
              <Checkbox
                className={styles["checkbox"]}
                name="NEGATIVE"
                onChange={debouncedOnChange}
                value={
                  !query.type || query.type === "POSITIVE"
                    ? "NEGATIVE"
                    : undefined
                }
                checked={query.type === "NEGATIVE"}
              >
                {checkboxLabels[2]}
              </Checkbox>
            </div>
            <Input
              prefix={
                <Icon type={IconTypes.SEARCH} className={styles["icon"]} />
              }
              placeholder={placeholderFilterValue}
              onChange={debouncedOnChange}
              className={styles["input"]}
              size="small"
            />
          </div>
        )}
        <span className={styles["hr"]} />
        <ul className={styles["header__assessments-list"]}>
          {items
            ?.slice(pagesVisted, pagesVisted + ITEMS_PER_PAGE)
            .map((row, index) => {
              return renderMobileRow<UserRates>(index, row);
            })}
        </ul>
        <ReactPaginate
          containerClassName={styles["header__assessments__pagination"]}
          pageCount={pageCount}
          onPageChange={({ selected }) =>
            setPaginationValues({
              ...paginationValues,
              selectedPage: selected,
            })
          }
          pageRangeDisplayed={paginationValues.selectedPage === 1 ? 2 : 3}
          marginPagesDisplayed={pageCount === 7 ? 2 : 3}
          pageLinkClassName={styles["pagination-link"]}
          activeLinkClassName={styles["pagination-link--active"]}
          forcePage={paginationValues.selectedPage}
          nextLabel={
            items?.length > 0 && (
              <button className={styles["next-btn"]}>
                <Icon
                  type={IconTypes.CHEVRON_RIGHT}
                  className={styles["icon"]}
                />
              </button>
            )
          }
          previousLabel={
            items?.length > 0 && (
              <button className={styles["previous-btn"]}>
                <Icon
                  type={IconTypes.CHEVRON_LEFT}
                  className={styles["icon"]}
                />
              </button>
            )
          }
          breakLabel="..."
        />
      </div>

      <table className={styles["table__body"]}>
        <thead className={styles["table__body__header"]}>
          <tr>{headerValue.map((item) => renderHeaderItem(item))}</tr>
        </thead>

        <tbody className={styles["table__body__rows"]}>
          {items ? (
            items
              ?.slice(pagesVisted, pagesVisted + ITEMS_PER_PAGE)
              .map((row, index) => {
                return renderRow<UserRates>(index, row);
              })
          ) : (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
            />
          )}
        </tbody>
      </table>

      <ReactPaginate
        containerClassName={styles["table__pagination"]}
        pageCount={pageCount}
        onPageChange={({ selected }) =>
          setPaginationValues({
            ...paginationValues,
            selectedPage: selected,
          })
        }
        pageRangeDisplayed={paginationValues.selectedPage === 1 ? 2 : 3}
        marginPagesDisplayed={pageCount === 7 ? 2 : 3}
        pageLinkClassName={styles["pagination-link"]}
        activeLinkClassName={styles["pagination-link--active"]}
        forcePage={paginationValues.selectedPage}
        nextLabel={
          items?.length > 0 && (
            <button className={styles["next-btn"]}>
              {" "}
              Następna
              <Icon type={IconTypes.CHEVRON_RIGHT} className={styles["icon"]} />
            </button>
          )
        }
        previousLabel={
          items?.length > 0 && (
            <button className={styles["previous-btn"]}>
              <Icon type={IconTypes.CHEVRON_LEFT} className={styles["icon"]} />
              Poprzednia
            </button>
          )
        }
        breakLabel="..."
      />
    </div>
  );
}
