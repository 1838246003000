enum SliceNames {
  USERS = "users",
  AUTH = "auth",
  ATTACHMENT = "attachment",
  BONUS = "bonus",
  DASHBOARD = "dashboard",
  DEPARTMENTS = "departments",
  FORMS = "forms",
  HELPERS = "helpers",
  MIGRATION = "migration",
  MODAL = "modal",
  RANKING = "ranking",
  REPORT = "report",
  SUGGESTIONS = "suggestions",
  EVALUATION = "evaluation",
  APPLICATION_SETTINGS = "application-settings",
  VACATIONS = "vacations",
  UI = "ui",
}

export default SliceNames;
