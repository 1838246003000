import React, { ReactElement, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions, usersActions } from "../../../__store/slices";
import { adminThunk, departmentThunk, migrationThunk } from "../../../__store/thunks";

import { Button, Icon } from "../../components/ui";
import AddUserValidationForm from "../../../data/validation/updateUserValidation";

import styled from "./UpdateUserForm.module.scss";

import { InputTypes, activationData, adminData, usersData } from "../../../data/constants";
import { IconTypes, UserRoles } from "../../../data/config";

import { ActivationDecisionResult } from "./activation";
import { RadioChangeEvent } from "antd/lib/radio";

const { ActivationDecisions } = activationData;
const { 
  ContractTypes,
  ContractTypesTranslated, 
  EmploymentStatuses, 
  EmploymentStatusesTranslated 
} = adminData;

const { 
  updateUserFormLabels, 
  userFormInputNames, 
  userFormRoles, 
  updateUserFormButtons, 
  updateUserFormMessages, 
  PermissionTypes 
} = usersData;

type LocalState = {
  name: string;
  surname: string;
  email: string;
  identifier: string;
  contract: adminData.ContractTypes;
  hr_permission: boolean;
  finance_permission: boolean;
  structure_permission: boolean;
  payment_permission: boolean;
  all_suggestions_preview_permission: boolean;
  external_comments_permission: boolean;
  job_position_id: number;
  area_id: number;
  isActive: boolean;
  employmentStatus: string;
};

const validation = new AddUserValidationForm();

const UpdateUserForm = ({title, type} : {title: string, type?: string}):ReactElement => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const user_id = useAppSelector((state) => state.users.user_id);
  const userRole = useAppSelector((state) => state.users.user?.role);
  const positionsList = useAppSelector((state) => state.departments.positionList);
  const departmentList = useAppSelector((state) => state.departments.newDepartmentsList);
  const areasList = useAppSelector((state) => state.departments.areas);

  const errorMessage = useAppSelector((state) => state.users.errorMessage);

  const departmentsWithSupervisors = useAppSelector((state) => state.departments.departmentsWithSupervisors);

  const actualDepartment = user && departmentsWithSupervisors ? departmentsWithSupervisors[user?.department_id] : null;

  const parentSupervisors = useAppSelector((state) => state.migrations.parentSupervisors);
  const departmentWorkers = useAppSelector((state) => state.migrations.departmentWorkers);

  const [department, setDepartment] = useState(1);
  const [selectedSupervisor, setSelectedSupervisor] = useState(-1);
  const [activationBoxUsing, setActivationBoxUsing] = useState(false);
  const [selectedDecision, setSelectedDecision] = useState(ActivationDecisions.NONE);
  const [selectedWorkerToTempManager, setSelectedWorkerToTempManager] = useState(-1);
  const [revertTempManager, setRevertingTempManager] = useState(false);

  const [localState, setLocalState] = useState<LocalState>({
    name: "",
    surname: "",
    job_position_id: 0,
    area_id: 0,
    email: "",
    identifier: "",
    contract: ContractTypes.CONTRACT,
    hr_permission: false,
    finance_permission: false,
    structure_permission: false,
    payment_permission: false,
    all_suggestions_preview_permission: false,
    external_comments_permission: false,
    isActive: true,
    employmentStatus: "WORKING"
  });

  const [formError, setFormError] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    setFormError({ status: true, message: errorMessage });
  }, [errorMessage]);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentsWithSuperVisors());
    if (user_id && userRole === UserRoles.MANAGER) {
      dispatch(migrationThunk.getParentSupervisorsToDeactivateEvaluator(user_id));
      dispatch(migrationThunk.getDepartmentWorkersForTemporaryEvaluator(user_id));
    }
  }, []);

  useEffect(() => {
    if (user) {
      setLocalState({
        name: user.name || "",
        surname: user.surname || "",
        job_position_id: user.job_position_id,
        area_id: user.area_id,
        email: user.email || "",
        identifier: user.user.identifier || "",
        contract: user.contract || ContractTypes.UNSPECIFIED,
        hr_permission: user.user.hr_permission,
        finance_permission: user.user.finance_permission,
        structure_permission: user.user.structure_permission,
        payment_permission: user.user.payment_permission,
        all_suggestions_preview_permission: user.user.all_suggestions_preview_permission,
        external_comments_permission: user.user.external_comments_permission,
        isActive: user.user.isActive,
        employmentStatus: user.employmentStatus
      });

      setDepartment(user.department.department_id);
      dispatch(departmentThunk.getDepartmentPosition({ id: user.department.department_id }));
    }
  }, [user]);

  useEffect(() => {
    if (positionsList.length > 0) {
      const position = positionsList.filter(
        (position) => position.job_position_id === user?.job_position_id
      );

      if (position.length > 0) {
        setLocalState((prev) => ({
          ...prev,
          job_position_id: position[0].job_position_id,
        }));
      } else {
        setLocalState((prev) => ({
          ...prev,
          job_position_id: positionsList[0].job_position_id,
        }));
      }
    }

  }, [positionsList]);

  const handleOnSubmit = () => {
    if (user) {
      const formValidation = validation.updateFormValidation(localState, {
        name: user.name.trim(),
        surname: user.surname.trim(),
        job_position_id: user.job_position_id,
        area_id: user.area_id,
        email: user.email ? user.email.trim() : user.email,
        identifier: user.user.identifier ? user.user.identifier.trim() : user.user.identifier,
        contract: user.contract,
        hr_permission: user.user.hr_permission,
        finance_permission: user.user.finance_permission,
        structure_permission: user.user.structure_permission,
        payment_permission: user.user.payment_permission,
        all_suggestions_preview_permission: user.user.all_suggestions_preview_permission,
        external_comments_permission: user.user.external_comments_permission,
        isActive: user.user.isActive,
        employmentStatus: user.employmentStatus
      });

      if (localState.isActive !== user?.user.isActive) {
        if (user_id && userRole === UserRoles.MANAGER && !localState.isActive) {
          if (selectedDecision === ActivationDecisions.REDIRECT && selectedSupervisor != -1) {
            dispatch(migrationThunk.migrateDeactivatingEvaluatorSuggestions(
              { userId: user_id, selectedManagerId: selectedSupervisor }
            ));
          } else if (selectedDecision === ActivationDecisions.REPLACEMENT && selectedWorkerToTempManager != -1) {
            dispatch(migrationThunk.migrateDeactivatingEvaluatorSuggestionsToTemporaryManager(
              { userId: user_id, newManagerId: selectedWorkerToTempManager}
            ));
          } else if (selectedSupervisor != -1 && selectedSupervisor != user_id) {
            dispatch(migrationThunk.migrateDeactivatingEvaluatorSuggestions(
              { userId: user_id, selectedManagerId: selectedSupervisor  }
            ));
          } else {
            dispatch(migrationThunk.migrateDeactivatingEvaluatorSuggestions({ userId: user_id }));
          }
        };
  
        if (user_id && userRole === UserRoles.MANAGER && localState.isActive) {
          if (actualDepartment && actualDepartment?.supervisors.length === 1) {
            if (revertTempManager) {
              dispatch(migrationThunk.revertSuggestionsFromTemporaryEvaluator({ 
                userId: user_id, 
                tempManagerId: actualDepartment.supervisors[0].user_id
              }));
            }
          } else {
            dispatch(migrationThunk.revertReactivatingEvaluatorSuggestions({ userId: user_id }));
          }
        };
      }

      if (!formValidation.isValid) {
        setFormError({
          status: true,
          message: formValidation.message,
        });
        return;
      }
    } else {
      return;
    }

    const nameValidation = validation.validateNameAndSurname(
      localState.name.trim(),
      localState.surname.trim()
    );
    if (!nameValidation.isValid) {
      setFormError({
        status: true,
        message: nameValidation.message,
      });
      return;
    }

    if (localState.email) {
      const isEmailValid = validation.isEmailValid(localState.email.trim());
      if (!isEmailValid) {
        setFormError({
          status: true,
          message: updateUserFormMessages.INCORRECT_EMAIL_ERROR,
        });
        return;
      }
    }

    setFormError({
      status: false,
      message: "",
    });

    if (localState.email === user.email) {
      dispatch(
        adminThunk.updateUser({
          name: localState.name ? localState.name.trim() : localState.name,
          surname: localState.surname ? localState.surname.trim() : localState.surname,
          identifier: localState.identifier ? localState.identifier.trim() : localState.identifier,
          job_position_id: localState.job_position_id,
          area_id: localState.area_id,
          user_id,
          contract: localState.contract,
          hr_permission: localState.hr_permission,
          finance_permission: localState.finance_permission,
          structure_permission: localState.structure_permission,
          payment_permission: localState.payment_permission,
          all_suggestions_preview_permission: localState.all_suggestions_preview_permission,
          external_comments_permission: localState.external_comments_permission,
          isActive: localState.isActive,
          employmentStatus: localState.employmentStatus
        })
      );
    } else {
      dispatch(
        adminThunk.updateUser({
          ...localState,
          user_id,
          identifier: localState.identifier,
          contract: localState.contract,
          hr_permission: localState.hr_permission,
          finance_permission: localState.finance_permission,
          structure_permission: localState.structure_permission,
          payment_permission: localState.payment_permission,
          all_suggestions_preview_permission: localState.all_suggestions_preview_permission,
          external_comments_permission: localState.external_comments_permission,
          isActive: localState.isActive,
          employmentStatus: localState.employmentStatus
        })
      );
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;

    if (name === PermissionTypes.HR_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        hr_permission: checked,
      }));
    } else if (name === PermissionTypes.FINANCE_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        finance_permission: checked,
      }));
    } else if (name === PermissionTypes.STRUCTURE_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        structure_permission: checked,
      }));
    } else if (name === PermissionTypes.PAYMENT_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        payment_permission: checked,
      }));
    } else if (name === PermissionTypes.ALL_SUGGESTIONS_PREVIEW_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        all_suggestions_preview_permission: checked,
      }));
    } else if (name === PermissionTypes.EXTERNAL_COMMENTS_PERMISSION) {
      setLocalState((prevState) => ({
        ...prevState,
        external_comments_permission: checked
      }))
    } else {
      setLocalState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOnClose = () => {
    dispatch(usersActions.setErrorMessage(""));
    setFormError({ status: false, message: "" });
    dispatch(modalActions.closeModal());
  };

  const handleOnChangeDepartment = (e: React.FormEvent<HTMLSelectElement>) => {
    setDepartment(+e.currentTarget.value);
    dispatch(departmentThunk.getDepartmentPosition({ id: +e.currentTarget.value }));
  };

  const handleSelectSupervisor = (e: React.FormEvent<HTMLSelectElement>) => {
    setSelectedSupervisor(Number(e.currentTarget.value));
    setSelectedWorkerToTempManager(-1);
  }

  const handleSelectWorkerToTempManager = (e: React.FormEvent<HTMLSelectElement>) => {
    setSelectedWorkerToTempManager(Number(e.currentTarget.value));
    setSelectedSupervisor(-1);
  }

  const handleSelectActivationDecision = (e: RadioChangeEvent) => {
    setSelectedDecision(e.target.value);
  }
  const handleRevertingTempManager = (revert: boolean) => {
    setRevertingTempManager(revert);
  }

  const handleChangePosition = (e: React.FormEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.value;
    setLocalState((prevState) => ({
      ...prevState,
      job_position_id: +id,
    }));
  };

  const handleChangeArea = (e: React.FormEvent<HTMLSelectElement>) => {
    const id = e.currentTarget.value;
    setLocalState((prevState) => ({
      ...prevState,
      area_id: +id,
    }));
  };

  const handleChangeContract = (e: any) => {    
    const value:adminData.ContractTypes = e.target.value;
    
    setLocalState((prevState) => ({
      ...prevState,
      contract: value,
    }));
  };

  const handleChangeEmploymentStatus = (employmentStatus: string) => {
    if (userRole !== UserRoles.ADMIN) {
      setLocalState((prevState) => ({
        ...prevState,
        employmentStatus: employmentStatus
      }))
    }
  }

  const handleChangeAccountActivity = (isActive: boolean) => {
    if (userRole !== UserRoles.ADMIN) {
      setLocalState((prevState) => ({
        ...prevState,
        isActive: isActive,
      }));
    }

    setActivationBoxUsing(!activationBoxUsing);

    if (actualDepartment?.supervisors && actualDepartment?.supervisors.length >= 2) {
      setSelectedSupervisor(actualDepartment?.supervisors.filter(supervisor => supervisor.user_id != user_id)[0].user_id || -1);
    } else {
      departmentWorkers.length > 0 && setSelectedWorkerToTempManager(departmentWorkers[0].user_id);
      parentSupervisors.length > 0 && setSelectedSupervisor(parentSupervisors[0].user_id); 

      setSelectedDecision(ActivationDecisions.REDIRECT);
    }
  };

  const positionRole = positionsList.filter(
    (item: { job_position_id: number; }) => item.job_position_id === localState.job_position_id
  );

  return (
    <div className={styled["form"]}>
      <div className={styled["form__header"]}>
        <div className={styled["form__header__text"]}>
          <span>{title}</span>
        </div>
        <div className={styled["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={handleOnClose} />
        </div>
      </div>
      <div className={styled["form__context"]}>
      {type === "activation" ? (
        <div className={styled["form__context__activation-box"]}>
          <div className={styled["activation-box__body"]}>
            <div className={styled["activation-box__section"]}>
            </div>
          </div>
            <ActivationDecisionResult 
              userID={user_id}
              userRole={userRole}
              printedUserRole={userFormRoles[positionRole[0]?.role]}
              isActuallyActive={user?.user.isActive}
              willBeActive={localState.isActive}
              handleChangeAccountActivity={handleChangeAccountActivity}
              actualEmploymentStatus={user?.employmentStatus || null}
              targetEmploymentStatus={localState.employmentStatus}
              handleChangeEmploymentStatus={handleChangeEmploymentStatus}
              actualDepartment={actualDepartment}
              parentSupervisors={parentSupervisors}
              departmentWorkers={departmentWorkers}
              selectedSupervisor={selectedSupervisor}
              selectedWorkerToTempManager={selectedWorkerToTempManager}
              selectSupervisorHandler={handleSelectSupervisor}
              selectWorkerToTempHandler={handleSelectWorkerToTempManager}
              selectedDecision={selectedDecision}
              selectDecisionHandler={handleSelectActivationDecision}
              revertTempManager={revertTempManager}
              handleRevertingTempManager={handleRevertingTempManager}
            />
          </div>) : (
        <>
        <div className={styled["form__context__input-container"]}>
          <input
            value={localState.name}
            name={userFormInputNames.INPUT_NAME}
            type={InputTypes.TEXT}
            className={styled["input"]}
            placeholder={updateUserFormLabels.INPUT_NAME}
            onChange={handleOnChange}
          />
        </div>
        <div className={styled["form__context__input-container"]}>
          <input
            value={localState.surname}
            name={userFormInputNames.INPUT_SURNAME}
            type={InputTypes.TEXT}
            className={styled["input"]}
            placeholder={updateUserFormLabels.INPUT_SURNAME}
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <div className={styled["form__context__input-container"]}>
          <input
            value={localState.identifier}
            name={userFormInputNames.INPUT_IDENTIFIER}
            type={InputTypes.TEXT}
            className={styled["input"]}
            placeholder={updateUserFormLabels.INPUT_IDENTIFIER}
            onChange={handleOnChange}
          />
        </div>
        <div className={styled["form__context__input-container"]}>
          <input
            value={localState.email}
            name={userFormInputNames.INPUT_EMAIL}
            type={InputTypes.TEXT}
            className={styled["input"]}
            placeholder={updateUserFormLabels.INPUT_EMAIL}
            onChange={handleOnChange}
          />
        </div>
        <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.INPUT_CONTRACT_TYPE}
              className={styled["select"]}
              onChange={(e) => handleChangeContract(e)}
              value={localState.contract}
            >
              {Array.from(ContractTypesTranslated, ([type, name]:[string, string]) => 
                (<option
                  key={type}
                  value={type}>
                  {name}
                </option>)
              )}
            </select>
          </div>

        <div className={styled["form__context__checkbox-container"]}>
          <input
            type={InputTypes.CHECKBOX}
            name={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
            id={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
            checked={localState.structure_permission}
            onChange={handleOnChange}
          />
          <label 
            className={styled["formLabel"]} 
            htmlFor={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
          >
            {updateUserFormLabels.INPUT_STRUCTURE_PERMISSION}
          </label>
        </div>

        <div className={styled["form__context__checkbox-container"]}>
          <input
            type={InputTypes.CHECKBOX}
            name={userFormInputNames.INPUT_PAYMENT_PERMISSION}
            id={userFormInputNames.INPUT_PAYMENT_PERMISSION}
            checked={localState.payment_permission}
            onChange={handleOnChange}
          />
          <label 
            className={styled["formLabel"]} 
            htmlFor={userFormInputNames.INPUT_PAYMENT_PERMISSION}
          >
            {updateUserFormLabels.INPUT_PAYMENT_PERMISSION}
          </label>
        </div>

        <div className={styled["form__context__checkbox-container"]}>
          <input
            type={InputTypes.CHECKBOX}
            name={userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
            id={userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
            checked={localState.all_suggestions_preview_permission}
            onChange={handleOnChange}
          />
          <label 
            className={styled["formLabel"]} 
            htmlFor={userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
          >
            {updateUserFormLabels.INPUT_ALL_SUGGESTION_PREVIEW_PERMISSION}
          </label>
        </div>

        <div className={styled["form__context__checkbox-container"]}>
          <input
            type={InputTypes.CHECKBOX}
            name={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
            id={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
            checked={localState.external_comments_permission}
            onChange={handleOnChange}
          />
          <label 
            className={styled["formLabel"]} 
            htmlFor={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
          >
            {updateUserFormLabels.INPUT_EXTERNAL_COMMENTS_PERMISSION}
          </label>
        </div>

        <div className={styled["form__context__select-container"]}>
          <select
            value={department}
            name={userFormInputNames.SELECT_DEPARTMENT}
            className={styled["select"]}
            onChange={(e) => handleOnChangeDepartment(e)}
          >
            {departmentList.map((department, i) => (
              <option key={i} value={department.department_id}>
                {department.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styled["form__context__select-container"]}>
          <select
            value={localState.job_position_id}
            name={userFormInputNames.SELECT_JOB_POSITION}
            className={styled["select"]}
            onChange={(e) => handleChangePosition(e)}
          >
            {positionsList.map((position) => (
              <option key={position.name} value={position.job_position_id}>
                {position.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styled["form__context__select-container"]}>
          <select
            value={localState.area_id}
            name={userFormInputNames.SELECT_AREA}
            className={styled["select"]}
            onChange={(e) => handleChangeArea(e)}
          >
            {areasList.map((area) => (
              <option key={area.area_id} value={area.area_id}>
                {area.area_name}
              </option>
            ))}
          </select>
        </div>
        </>)}
      </div>

      {type !== "activation" && (
      <div className={styled["form__section-role"]}>
        <span>{userFormRoles[positionRole[0]?.role]}</span>
      </div>)}

      <div className={styled["form__error"]}>
        <span className={styled["form__error__message"]}>
          {formError.status && formError.message}
        </span>
      </div>
      <div className={styled["form__footer"]}>
        <div className={styled["form__footer__button-container"]}>
          <Button
            hiddenStyles=""
            textValue={updateUserFormButtons.CANCEL_BUTTON}
            buttonClassName="reset-button"
            buttonFunction={handleOnClose}
          />
        </div>
        <div className={styled["form__footer__button-container"]}>
          <Button
            hiddenStyles=""
            textValue={updateUserFormButtons.SEND_BUTTON}
            buttonClassName="search-button"
            buttonFunction={handleOnSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateUserForm;
