import { useEffect, ReactElement } from 'react';

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { departmentThunk, suggestionThunk } from "../../../__store/thunks";
import { MainStatuses } from "../../../data/config";

import { ISuggestionsCount, TCategoriedSuggestions } from "../types/SuggestionsDashboard.types";
import { SuggestionsPageTemplate } from '../components';

const AllSuggestionsPreviewPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const role = useAppSelector((state) => state.auth.role);
  const suggestionState = useAppSelector((state) => state.suggestions);

  useEffect(() => {
    dispatch(suggestionThunk.getAllSuggestionsPreview());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  const AllSuggestionsPreviewCount: ISuggestionsCount = suggestionState.allSuggestionsPreviewCount;

  const AllSuggestionsPreviewMap: TCategoriedSuggestions = new Map([
    [ MainStatuses.REPORT, suggestionState.allSuggestionsPreviewReported ],
    [ MainStatuses.REALIZATION, suggestionState.allSuggestionsPreviewImplemented ],
    [ MainStatuses.VERIFICATION, suggestionState.allSuggestionsPreviewDone ],
    [ MainStatuses.CREATION, suggestionState.allSuggestionsPreviewApproved ],
    [ MainStatuses.APPEALED, suggestionState.allSuggestionsPreviewAppealed ],
    [ MainStatuses.REJECTION, suggestionState.allSuggestionsPreviewRejected ],
  ]);

  return (
    <SuggestionsPageTemplate
      suggestionsCount={AllSuggestionsPreviewCount}
      suggestionsMap={AllSuggestionsPreviewMap}
      userRole={role}
      readOnly={true}
    />
  );
}

export default AllSuggestionsPreviewPage;
