import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { departmentThunk, suggestionThunk } from "../../../__store/thunks";

import { MainStatuses } from "../../../data/config";

import { ISuggestionsCount, TCategoriedSuggestions } from "../types/SuggestionsDashboard.types";
import { SuggestionsPageTemplate } from "../components";

const OtherDepartmentsSuggestionsPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const suggestionState = useAppSelector((state) => state.suggestions);

  useEffect(() => {
    dispatch(suggestionThunk.getWorkersSuggestionsToOtherDepartments());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  const workerToOtherSuggestionsCount : ISuggestionsCount = suggestionState.workersToOtherSuggestionsCount;

  const WorkerToOtherSuggestionsMap: TCategoriedSuggestions = new Map([
    [ MainStatuses.REPORT, suggestionState.workersToOtherReportedSuggestions ],
    [ MainStatuses.REALIZATION, suggestionState.workersToOtherImplementedSuggestions ],
    [ MainStatuses.VERIFICATION, suggestionState.workersToOtherDoneSuggestions ],
    [ MainStatuses.CREATION, suggestionState.workersToOtherApprovedSuggestions ],
    [ MainStatuses.APPEALED, suggestionState.workersToOtherAppealedSuggestions ],
    [ MainStatuses.REJECTION, suggestionState.workersToOtherRejectedSuggestions ],
  ]);

  return (
    <SuggestionsPageTemplate
      suggestionsCount={workerToOtherSuggestionsCount}
      suggestionsMap={WorkerToOtherSuggestionsMap}
    />
  );
}

export default OtherDepartmentsSuggestionsPage;
