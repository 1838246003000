const appHeaders = {
  MAIN_HEADER: "Aplikacja sugestii",
  GUIDE_HEADER: "Jak to działa",
  PROGRAM_HEADER: "Regulamin",
  SETTINGS_HEADER: "Ustawienia",
  ADMIN: {
    ADMIN_HEADER: "Administrator",
    ADMIN_ACTIVE_HEADER: "Aktywni",
    ADMIN_INACTIVE_HEADER: "Nieaktywni",
    ADMIN_FIRED_HEADER: "Zwolnieni",
    STRUCTURE_HEADER: "Struktura",
    SETTINGS_HEADER: "Profil",
  },
  ASSESSMENT: {
    ASSESSMENT_HEADER: "Informacja Zwrotna",
    MY_ASSESSMENT_HEADER: "Mojej pracy",
    CREATE_ASSESSMENT_HEADER: "Wystawianie",
    REVIEW_ASSESSMENT_HEADER: "Pracowników",
  },
  AUTHORIZATION: {
    //   FORGOT_HEADER: "",
    //   NEW_PASSWORD_HEADER: "",
    //   CHANGE_PASSWORD_HEADER: "",
    LOGOUT_HEADER: "Wyloguj",
  },
  PROFILE: {
    DASHBOARD_HEADER: "Pulpit",
    YOUR_PROFILE_HEADER: "Twój Dashboard",
    WORKER_PROFILE_HEADER: "Dashboard pracowników",
  },
  RANKS: {
    RANKING_HEADER: "Ranking",
    POINTS_HEADER: "Premie",
    BONUS_WORKER_HEADER: "Twoje premie",
    BONUS_MANAGER_HEADER: "Zarządzaj premiami"
  },
  SETTINGS: {
    ACCESS: "Dostęp",
    INFO: "Informacje",
    PASSWORD: "Hasło",
    NOTIFICATIONS: "Powiadomienia",
  },
  SUGGESTIONS: {
    CREATE_SUGGESTION: "Zgłoś sugestię",
    SUGGESTIONS_HEADER: "Lista Sugestii",
    MY_SUGGESTIONS_HEADER: "Twoje zgłoszenia",
    WORKER_SUGGESTIONS_HEADER: "Pracowników twojego działu",
    WORKER_SUGGESTIONS_HEADER_FOR_BOSS: "Zgłoszenia twoich pracowników",
    DEPARTMENT_SUGGESTIONS_HEADER: "Pracowników z innych działów",
    OTHER_DEPARTMENTS_SUGGESTIONS_HEADER:
      "Pracowników z twojego działu do innych działów",
    ALL_SUGGESTIONS_PREVIEW_HEADER: "Podgląd wszystkich zgłoszeń",
  },
};

export default appHeaders;
