import ReactDOM from "react-dom";
import { useAppSelector } from "../../../__store/tools/hooks";

import styles from "./AttachmentModal.module.scss";

export interface IAttachmentModalProps {
  children?: React.ReactNode;
}

const AttachmentModal = (props: IAttachmentModalProps) => {

  const modalState = useAppSelector((state) => state.modal);

  return modalState.isAttachmentModalOpen
    ? ReactDOM.createPortal(
        <div className={styles["modal"]}>
          <div
            className={`${styles["modal__backdrop"]} ${
              styles[modalState.backdropClassname]
            }`}
          >
            <div
              className={`${styles["modal__box"]} ${
                styles[modalState.modalClassName]
              }`}
            >
              {props.children}
            </div>
          </div>
        </div>,
        document.getElementById("attachment-modal-root") as HTMLElement
      )
    : null;
};

export default AttachmentModal;
