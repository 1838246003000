import UserInfo from "../../Admin/components/UserInfo";
import {
  AddUserForm,
  AdminUpdateCredentialForm,
  CreateAreaForm,
  CreateDepartmentForm,
  CreateJobPositionForm,
  EditAreaForm,
  EditDepartmentForm,
  EditJobPositionForm,
  PasswordLoginChangeForm,
  UpdateUserForm,
} from "../../Admin/forms";

import {
  CreateAssessmentForm,
  DisplayAssessmentStatus,
} from "../../Assessment/forms";

import LoginForm from "../../Authorization/components/LoginForm";

import {
  AddNoteForm,
  AcceptSuggestionForm,
  RejectSuggestionForm,
  RedirectSuggestionForm,
  WithdrawSuggestionForm,
  CorrectSuggestionForm,
  InternalCommentSuggestionForm,
  SendToVerificationForm,
  ConfirmCreationForm,
  RejectCreationForm,
  AddEndResultForm,
  AppealSuggestionForm,
} from "../../Suggestions/forms";

import {
  Popup,
  PopupDone,
  PopupYesNo,
} from "../../Suggestions/modals"

import { AreaDetails } from "../../Admin/components/structure";

import { SpinnerModal } from ".";

import {
  MainStatuses,
  ModalClassnames,
} from "../../../data/config";
import { modalMessages, usersData } from "../../../data/constants";
import { AllStatuses, RealizationSubstatuses, RejectionSubstatuses, ReportSubstatuses } from "../../../data/config/suggestionStatuses.data";
import ExternalCommentSuggestionForm from "../../Suggestions/forms/ExternalCommentSuggestionForm";

const { UserDataChangeType } = usersData;

export enum ModalColor {
  BLUE = "blue",
  RED = "red",
}

export interface ModalMap {
  [key: string]: JSX.Element;
}

export const FormModalContainer: ModalMap = {
  [ModalClassnames.ACCEPT_PAYMENTS_EMPTY]: (
    <Popup text={modalMessages.ACCEPT_PAYMENTS_EMPTY} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.ACCEPT_PAYMENTS_FAIL]: (
    <Popup text={modalMessages.ACCEPT_PAYMENTS_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.ACCEPT_PAYMENTS_SUCCESS]: (
    <Popup
      text={modalMessages.ACCEPT_PAYMENTS_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.ACCEPT_PAYMENT_EMPTY]: (
    <Popup text={modalMessages.ACCEPT_PAYMENT_EMPTY} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.ACCEPT_PAYMENT_SUCCESS]: (
    <Popup
      text={modalMessages.ACCEPT_PAYMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.ADDED_USER]: (
    <Popup text={modalMessages.ADDED_USER} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.ADD_SUGGESTION_ERROR]: (
    <Popup text={modalMessages.ADD_SUGGESTION_ERROR} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.ADD_SUGGESTION_SUCCESS]: (
    <Popup
      text={modalMessages.ADD_SUGGESTION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.ADD_USER_ERROR]: (
    <Popup text={modalMessages.ADD_USER_ERROR} color={ModalColor.RED} />
  ),
  [ModalClassnames.ADD_USER_FORM]: <AddUserForm />,
  [ModalClassnames.ADMIN_CHANGE_CREDENTIAL]: (
    <AdminUpdateCredentialForm text={modalMessages.ADMIN_CHANGE_CREDENTIAL} />
  ),
  [ModalClassnames.ASSESSMENT_INFO]: <DisplayAssessmentStatus />,
  [ModalClassnames.APPEALED_SUGGESTION]: (
    <Popup text={modalMessages.APPEALED_SUGGESTION} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.APPROVED_SUCCESS]: (
    <Popup text={modalMessages.APPROVED_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.AREA_INFO]: <AreaDetails />,
  [ModalClassnames.CANNOT_REMOVE_AREA]: (
    <Popup
      text={modalMessages.CANNOT_REMOVE_AREA}
      color={ModalColor.RED}
    />
  ),
  [ModalClassnames.CANNOT_REMOVE_DEPARTMENT]: (
    <Popup
      text={modalMessages.CANNOT_REMOVE_DEPARTMENT}
      color={ModalColor.RED}
    />
  ),
  [ModalClassnames.CHANGE_LOGIN]: (
    <PasswordLoginChangeForm
      type={UserDataChangeType.CHANGE_LOGIN}
      text={modalMessages.CHANGE_LOGIN}
    />
  ),
  [ModalClassnames.CHANGE_PASSWORD]: (
    <PasswordLoginChangeForm
      type={UserDataChangeType.CHANGE_PASSWORD}
      text={modalMessages.CHANGE_PASSWORD}
    />
  ),
  [ModalClassnames.CLOSED_SUCCESS]: (
    <Popup text={modalMessages.CLOSED_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.CORRECTED_SUGGESTION]: (
    <Popup
      text={modalMessages.CORRECTED_SUGGESTION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.COMMENTED_SUGGESTION]: (
    <Popup
      text={modalMessages.COMMENTED_SUGGESTION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.WITHDRAWN]: (
    <Popup text={modalMessages.WITHDRAWN} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.CREATE_ASSESSMENT]: (
    <Popup text={modalMessages.CREATE_ASSESSMENT} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.CREATE_ASSESSMENT_ERROR]: (
    <Popup
      text={modalMessages.CREATE_ASSESSMENT_ERROR}
      color={ModalColor.RED}
    />
  ),
  [ModalClassnames.CREATE_COMMENT_TO_EVALUATION]: (
    <Popup
      text={modalMessages.CREATE_COMMENT_TO_EVALUATION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.CREATE_COMMENT_TO_EVALUATION_ERROR]: (
    <Popup
      text={modalMessages.CREATE_COMMENT_TO_EVALUATION_ERROR}
      color={ModalColor.RED}
    />
  ),
  [ModalClassnames.CREATE_AREA]: <CreateAreaForm />,
  [ModalClassnames.CREATE_AREA_FAIL]: (
    <Popup 
      text={modalMessages.CREATE_AREA_FAIL} 
      color={ModalColor.RED} 
    />
  ),
  [ModalClassnames.CREATE_AREA_SUCCESS]: (
    <Popup 
      text={modalMessages.CREATE_AREA_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.CREATE_DEPARTMENT]: <CreateDepartmentForm />,
  [ModalClassnames.CREATE_DEPARTMENT_FAIL]: (
    <Popup text={modalMessages.CREATE_DEPARTMENT_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.CREATE_DEPARTMENT_SUCCESS]: (
    <Popup
      text={modalMessages.CREATE_DEPARTMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.CREATE_JOB_POSITION]: <CreateJobPositionForm />,
  [ModalClassnames.CREATE_JOB_POSITION_FAIL]: (
    <Popup
      text={modalMessages.CREATE_JOB_POSITION_FAIL}
      color={ModalColor.RED}
    />
  ),
  [ModalClassnames.CREATE_JOB_POSITION_SUCCESS]: (
    <Popup
      text={modalMessages.CREATE_JOB_POSITION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.DONE_PAYMENTS_EMPTY]: (
    <Popup text={modalMessages.DONE_PAYMENTS_EMPTY} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.DONE_PAYMENTS_SUCCESS]: (
    <Popup text={modalMessages.DONE_PAYMENTS_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.DONE_PAYMENT_SUCCESS]: (
    <Popup text={modalMessages.DONE_PAYMENT_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.DONE_SUGGESTION]: (
    <Popup text={modalMessages.DONE_SUGGESTION} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.EDIT_AREA]: <EditAreaForm />,
  [ModalClassnames.EDIT_AREA_FAIL]: (
    <Popup 
      text={modalMessages.EDIT_AREA_FAIL} 
      color={ModalColor.RED} 
    />
  ),
  [ModalClassnames.EDIT_AREA_SUCCESS]: (
    <Popup
      text={modalMessages.EDIT_AREA_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.EDIT_DEPARTMENT]: <EditDepartmentForm />,
  [ModalClassnames.EDIT_DEPARTMENT_FAIL]: (
    <Popup text={modalMessages.EDIT_DEPARTMENT_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.EDIT_DEPARTMENT_SUCCESS]: (
    <Popup
      text={modalMessages.EDIT_DEPARTMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.EDIT_JOB_POSITION]: <EditJobPositionForm />,
  [ModalClassnames.EDIT_JOB_POSITION_FAIL]: (
    <Popup text={modalMessages.EDIT_JOB_POSITION_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.EDIT_JOB_POSITION_SUCCESS]: (
    <Popup
      text={modalMessages.EDIT_JOB_POSITION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.REMOVE_VACATION_SUCCESS]: (
    <Popup
      text={modalMessages.REMOVE_VACATION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.REMOVE_VACATION_ERROR]: (
    <Popup text={modalMessages.REMOVE_VACATION_ERROR} color={ModalColor.RED} />
  ),
  [ModalClassnames.UPDATE_VACATION_SUCCESS]: (
    <Popup
      text={modalMessages.UPDATE_VACATION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.UPDATE_VACATION_ERROR]: (
    <Popup text={modalMessages.UPDATE_VACATION_ERROR} color={ModalColor.RED} />
  ),
  [ModalClassnames.CREATE_VACATION_SUCCESS]: (
    <Popup
      text={modalMessages.CREATE_VACATION_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.CREATE_VACATION_ERROR]: (
    <Popup text={modalMessages.CREATE_VACATION_ERROR} color={ModalColor.RED} />
  ),

  [ModalClassnames.END_RESULT_SUCCESS]: (
    <Popup text={modalMessages.END_RESULT_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.ACCEPT_SUGGESTION_FORM]: (
    <AcceptSuggestionForm
      text="Zaakceptuj sugestię"
      mainStatus={MainStatuses.REALIZATION}
      secondStatus={AllStatuses.DURING_REALIZATION}
    />
  ),
  [ModalClassnames.REJECT_SUGGESTION_FORM]: (
    <RejectSuggestionForm
      text="Odrzuć sugestię"
      mainStatus={MainStatuses.REJECTION}
      secondStatus={AllStatuses.REVOCABLE}
    />
  ),
  [ModalClassnames.REDIRECT_SUGGESTION_FORM]: (
    <RedirectSuggestionForm
      text="Przekieruj sugestię"
      mainStatus={MainStatuses.REPORT}
      secondStatus={AllStatuses.REDIRECTED}
    />
  ),
  [ModalClassnames.WITHDRAW_SUGGESTION_FORM]: (
    <WithdrawSuggestionForm
      text="Cofnij do pracownika"
      mainStatus={MainStatuses.REPORT}
      secondStatus={AllStatuses.WITHDRAWN}
    />
  ),
  [ModalClassnames.CORRECT_SUGGESTION_FORM]: (
    <CorrectSuggestionForm 
      text="Popraw sugestię" 
      mainStatus={MainStatuses.REPORT} 
      secondStatus={AllStatuses.CORRECTED} 
    />
  ),
  [ModalClassnames.INTERNAL_COMMENT_REALIZATION_FORM]: (
    <InternalCommentSuggestionForm
      text="Dodaj komentarz"
      mainStatus={MainStatuses.REALIZATION}
      secondStatus={AllStatuses.DURING_REALIZATION}
    />
  ),
  [ModalClassnames.SEND_TO_VERIFICATION_FORM]: (
    <SendToVerificationForm
      text="Oddaj do sprawdzenia"
      mainStatus={MainStatuses.VERIFICATION}
      secondStatus={AllStatuses.SUBMITTED_FOR_VERIFICATION}
    />
  ),
  [ModalClassnames.INTERNAL_COMMENT_VERIFICATION_FORM]: (
    <InternalCommentSuggestionForm
      text="Dodaj komentarz"
      mainStatus={MainStatuses.VERIFICATION}
      secondStatus={AllStatuses.SUBMITTED_FOR_VERIFICATION}
    />
  ),
  [ModalClassnames.CONFIRM_CREATION_FORM]: (
    <ConfirmCreationForm
      text="Zatwierdź wdrożenioe"
      mainStatus={MainStatuses.CREATION}
      secondStatus={AllStatuses.COMPLETED}
    />
  ),
  [ModalClassnames.REJECT_CREATION_FORM]: (
    <RejectCreationForm
      text="Do poprawy"
      mainStatus={MainStatuses.REALIZATION}
      secondStatus={AllStatuses.RETURNED_FROM_VERIFICATION}
    />
  ),
  [ModalClassnames.ADD_END_RESULT_FORM]: (
    <AddEndResultForm
      text="Dodaj rezultat"
      mainStatus={MainStatuses.CREATION}
      secondStatus={AllStatuses.RESULT}
    />
  ),
  [ModalClassnames.APPEAL_SUGGESTION_FORM]: (
    <AppealSuggestionForm
      text="Odwołaj się"
      mainStatus={MainStatuses.APPEALED}
      secondStatus={AllStatuses.APPEALED}
    />
  ),
  [ModalClassnames.EXTERNAL_COMMENT_FORM]: (
    <ExternalCommentSuggestionForm
      text="Dodaj komentarz"
    />
  ),
  [ModalClassnames.EXTERNAL_COMMENT_SUCCESS]: (
    <Popup
      text={modalMessages.EXTERNAL_COMMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.GENERATE_PAYMENTS_FAIL]: (
    <Popup text={modalMessages.GENERATE_PAYMENTS_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.GENERATE_PAYMENT_EMPTY]: (
    <Popup
      text={modalMessages.GENERATE_PAYMENT_EMPTY}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.GENERATE_PAYMENT_FAIL]: (
    <Popup text={modalMessages.GENERATE_PAYMENT_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.GENERATE_PAYMENT_SUCCESS]: (
    <Popup
      text={modalMessages.GENERATE_PAYMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.IMPLEMENTED_SUCCESS]: (
    <Popup 
      text={modalMessages.IMPLEMENTED_SUCCESS} 
      color={ModalColor.BLUE} 
    />
  ),
  [ModalClassnames.IMPLEMENTED_SUGGESTION]: (
    <Popup
      text={modalMessages.IMPLEMENTED_SUGGESTION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.CORRECT_SUGGESTION_REALIZATION]: (
    <Popup
      text={modalMessages.CORRECT_SUGGESTION_REALIZATION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.IMPLEMENT_SUGGESTION]: (
    <PopupDone type="done" text={modalMessages.IMPLEMENT_SUGGESTION} />
  ),
  [ModalClassnames.LOGIN_PAGE]: <LoginForm />,
  [ModalClassnames.MAKE_ASSESSMENT]: <CreateAssessmentForm />,
  [ModalClassnames.NOTE_FORM]: <AddNoteForm text="Dodaj notatkę" />,
  [ModalClassnames.PAYMENT_FAIL]: (
    <Popup text={modalMessages.PAYMENT_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.REJECTED_SUGGESTION]: (
    <Popup text={modalMessages.REJECTED_SUGGESTION} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REJECT_PAYMENT_EMPTY]: (
    <Popup text={modalMessages.REJECT_PAYMENT_EMPTY} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REJECT_PAYMENT_SUCCESS]: (
    <Popup
      text={modalMessages.REJECT_PAYMENT_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.REMOVED_USER]: (
    <Popup text={modalMessages.REMOVED_USER} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REMOVED_USER_ERROR]: (
    <Popup text={modalMessages.REMOVED_USER_ERROR} color={ModalColor.RED} />
  ),
  [ModalClassnames.REMOVE_AREA]: (
    <Popup
      text={modalMessages.REMOVE_AREA}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.REMOVE_DEPARTMENT_POSITION]: (
    <Popup
      text={modalMessages.REMOVE_DEPARTMENT_POSITION}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.REMOVE_EVALUATING_USER_ERROR]: (
    <Popup
      text={modalMessages.REMOVE_EVALUATING_USER_ERROR}
      color={ModalColor.RED}
    />
  ),

  [ModalClassnames.REMOVE_JOB_POSITION]: (
    <Popup text={modalMessages.REMOVE_JOB_POSITION} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REMOVE_USER]: (
    <PopupYesNo text={modalMessages.REMOVE_USER} type="remove-user" />
  ),
  [ModalClassnames.SPINNER_MODAL]: <SpinnerModal />,
  [ModalClassnames.SUGGESTION_REDIRECTED]: (
    <Popup text={modalMessages.SUGGESTION_REDIRECTED} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.SUPERVISOR_NOTE_SUCCESS]: (
    <Popup
      text={modalMessages.SUPERVISOR_NOTE_SUCCESS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.UPDATED_MAIL_NOTIFICATIONS]: (
    <Popup
      text={modalMessages.UPDATED_MAIL_NOTIFICATIONS}
      color={ModalColor.BLUE}
    />
  ),
  [ModalClassnames.UPDATED_USER]: (
    <Popup text={modalMessages.UPDATED_USER} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.UPDATE_USER_ERROR]: (
    <Popup text={modalMessages.UPDATE_USER_ERROR} color={ModalColor.RED} />
  ),
  [ModalClassnames.UPDATE_USER_FORM]: (
    <UpdateUserForm title={modalMessages.UPDATE_USER_FORM} />
  ),
  [ModalClassnames.USER_ACTIVITY]: (
    <UpdateUserForm title={modalMessages.USER_ACTIVITY} type="activation" />
  ),
  [ModalClassnames.USER_INFO]: <UserInfo />,
  [ModalClassnames.WITHDRAW_POINTS]: (
    <Popup text={modalMessages.WITHDRAW_POINTS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REPORT_GENERATE_SUCCESS]: (
    <Popup text={modalMessages.REPORT_GENERATE_SUCCESS} color={ModalColor.BLUE} />
  ),
  [ModalClassnames.REPORT_GENERATE_FAIL]: (
    <Popup text={modalMessages.REPORT_GENERATE_FAIL} color={ModalColor.RED} />
  ),
  [ModalClassnames.REPORT_GENERATE_EMPTY]: (
    <Popup text={modalMessages.REPORT_GENERATE_EMPTY} color={ModalColor.RED} />
  ),
};

export default FormModalContainer;
