import { createSlice } from "@reduxjs/toolkit";

import { rankingModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: rankingModels.RankingState = {
  smallRanking: [],
  departmentRanking: [],
};

const rankingSlice = createSlice({
  name: SliceNames.RANKING,
  initialState,
  reducers: {
    setSmallRanking: (state, { payload }) => {
      state.smallRanking = payload;
    },
    setDepartmentRanking: (state, { payload }) => {
      state.departmentRanking = payload;
    },
    setRankingInitialState: () => initialState,
  },
});

export default rankingSlice.reducer;

export const { 
  setSmallRanking, 
  setDepartmentRanking, 
  setRankingInitialState 
} = rankingSlice.actions;
