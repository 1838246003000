import { Icon } from "../../components/ui";
import dayjs from "dayjs";
import { ReactElement, useEffect, useState } from "react";
import { reportThunk, departmentThunk } from "../../../__store/thunks";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { Button, DatePicker, Divider, Select, Typography } from "antd";

import styles from "../views/BonusManagerPage.module.scss";
import { modalActions } from "../../../__store/slices";
import { BonusStatuses, IconTypes, ModalClassnames } from "../../../data/config";
import { BonusStatusesTranslated } from "../../../data/constants/bonus.data";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const BonusGeneralReport = ():ReactElement => {
  const dispatch = useAppDispatch();

  const allBonuses = useAppSelector((state) => state.bonus.allBonuses);

  const [selectedStartDate, setSelectedStartDate] = useState<Date|null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date|null>(null);
  const [selectedDepartmentId, setSelectedDepatmentId] = useState<number>(0);
  const [selectedAreaId, setSelectedAreaId] = useState<number>(0);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedBonusStatus, setSelectedBonusStatus] = useState<BonusStatuses | "ALL" | null>(null);

  const departmentList = useAppSelector((state) => state.departments.departments);
  const areas = useAppSelector((state) => state.departments.areas);
  const users = useAppSelector((state) => state.users.users) || []; 

  const [areFiltersActive, setAreFilterActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedStartDate 
      || selectedEndDate 
      || selectedAreaId 
      || selectedDepartmentId 
      || selectedUserId 
      || selectedBonusStatus
      ) {
      setAreFilterActive(true);
    } else {
      setAreFilterActive(false);
    }
  }, [
    selectedStartDate, 
    selectedEndDate, 
    selectedAreaId, 
    selectedDepartmentId, 
    selectedBonusStatus,
    selectedUserId
  ]);

  const onChangeDateHandler = (dayjsArray:any, datesArray:[string, string]) => {
    setSelectedStartDate(new Date(datesArray[0]));
    setSelectedEndDate(new Date(datesArray[1]));
  };

  const handleOnFilter = () => {
    if (allBonuses.length === 0) {
      dispatch(modalActions.openModal());
      dispatch(modalActions.setModalClassName(ModalClassnames.REPORT_GENERATE_EMPTY));
    } else {
      const filterOptions = {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        departmentId: selectedDepartmentId,
        areaId: selectedAreaId,
        userId: selectedUserId,
        bonusStatus: selectedBonusStatus !== "ALL" ? selectedBonusStatus : null,
      };
    
      dispatch(reportThunk.postReportsData({
        filterOptions
      }))
    }
  };

  const handleOnReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedDepatmentId(0);
    setSelectedAreaId(0);
    setSelectedUserId(0);
    setSelectedBonusStatus("ALL");
  };

  const onDepartmentChange = (value: string) => {
    setSelectedDepatmentId(+value);
    dispatch(departmentThunk.getDepartmentPosition({ id: +value }));
  };

  const onAreaChange = (value: string) => {
    setSelectedAreaId(+value);
  };

  const onUserChange = (value: string) => {
    setSelectedUserId(+value);
  };

  const onBonusStatusChange = (value: BonusStatuses) => {
    setSelectedBonusStatus(value);
  };

  return (
    <div className={styles["content-section"]}>
      <div className={styles["report"]}>
        <Title level={3} style={{ marginBottom: 0, fontSize: 24, fontWeight: 700}}>Raport ogólny</Title>
        <Divider style={{ borderBlockStart: "2px solid #9CCEFC" }} />
        <div className={styles["report__container"]}>
          <RangePicker 
            allowClear={false}
            className={styles["range-picker"]}
            suffixIcon={<Icon type={IconTypes.CALENDAR} />}
            defaultValue={undefined}
            placeholder={["Data początkowa", "Data końcowa"]}
            value={
              (selectedStartDate && selectedEndDate) 
              ? [dayjs(selectedStartDate), dayjs(selectedEndDate)] 
              : null
            }
            size="middle" 
            onChange={
              (date, dateStr) => onChangeDateHandler(date, dateStr)
            }
          />
          <Select
            allowClear
            className={styles["select"]}
            size="middle"
            id="area_id"
            options={
              [{
                value: 0, 
                label: (
                  <span className={styles["select__placeholder"]}>
                    Wszystkie obszary
                  </span>
                 )
              }].concat(
                areas?.map((area) => ({
                  value: area.area_id,
                  label: (
                    <span className={styles["select__placeholder"]}>
                      {area.area_name}
                    </span>
                  ),
                }))
              )
            }
            onChange={onAreaChange}
            placeholder={
              <span className={styles["select__placeholder"]}>
                <Icon type={IconTypes.GRAPH_ICON} />
                Obszar
              </span>
            }
          />
          <Select
            className={styles["select"]}
            size="middle"
            allowClear
            id="department_id"
            options={
              [{
                value: 0, 
                label: (
                  <span className={styles["select__placeholder"]}>
                    Wszystkie działy
                  </span>
                 )
              }].concat(
                departmentList?.map((department) => ({
                value: department.department_id,
                label: (
                  <span className={styles["select__placeholder"]}>
                    {department.name}
                  </span>
                ),
               }))
              )
            }
            onChange={onDepartmentChange}
            placeholder={
              <span className={styles["select__placeholder"]}>
                <Icon type={IconTypes.GRAPH_ICON} />
                Dział
              </span>
            }
            />
            </div>

            <div className={styles["report__container"]}>
            <Select
              id="user_id"
              className={styles["select"]}
              size="middle"
              onChange={onUserChange}
              placeholder={
                <span className={styles["select__placeholder"]}>
                  <Icon type={IconTypes.USER_ICON} />
                  Pracownik
                </span>
              }
              options={
                [{
                  value: 0, 
                  label: (
                    <span className={styles["select__placeholder"]}>
                      Wszyscy pracownicy
                    </span>
                   )
                }].concat(
                  users?.filter((user) => {
                    if (!selectedAreaId && !selectedDepartmentId) return true;
                    if (selectedAreaId && selectedDepartmentId) {
                      return user.area_id === selectedAreaId && user.department_id === selectedDepartmentId;
                    }
                    if (selectedAreaId) return user.area_id === selectedAreaId;
                    if (selectedDepartmentId) return user.department_id === selectedDepartmentId;
                  }).map((user) => ({
                    value: user.user_id,
                    label: (
                      <span className={styles["select__placeholder"]}>
                        {user.name} {user.surname}
                      </span>
                    ),
                }))
              )
            }
            />

            <Select
              id="bonus_status"
              className={styles["select"]}
              size="middle"
              onChange={onBonusStatusChange}
              placeholder={
                <span className={styles["select__placeholder"]}>
                  <Icon type={IconTypes.INFO_ICON} />
                  Status premii
                </span>
              }
              options={
                [{
                  value: "ALL", 
                  label: (
                    <span className={styles["select__placeholder"]}>
                      Wszystkie statusy premii
                    </span>
                   )
                }].concat(
                Array.from(BonusStatusesTranslated).map(
                  ([status, label]) => {
                    return {
                      value: status,  
                      label: (
                        <span className={styles["select__placeholder"]}>
                          {label}
                        </span>)
                      }
                    }
                )
                )
              }
            />
          <div className={styles["report-buttons"]}>
            {/* CLEAR button functionality need to be corrected */}
            {/* <Button
              type="default"
              onClick={handleOnReset}
              style={{
                visibility: areFiltersActive ? "visible" : "hidden",
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1.5px solid #8391AD",
                fontSize: "1rem",
                maxWidth: "50%",
                height: 40,
                marginRight: "0.5rem"
              }}
            >
              <Icon type={IconTypes.BIN} />
              {" "}
              <span>Wyczyść</span>
            </Button> */}
            {" "}
            <Button
              type={areFiltersActive ? "primary" : "default"}
              onClick={handleOnFilter}
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: areFiltersActive ? "#FFF" : "#391580",
                border: areFiltersActive ? "none" : "1.5px solid #391580",
                fontWeight: 500,
                fontSize: "1rem",
                maxWidth: "50%",
                height: 40
              }}
            >
              <span>Generuj</span>
              <Icon type={areFiltersActive 
                ? IconTypes.REPORT_WHITE 
                : IconTypes.REPORT} 
              />
            </Button>
          </div>
        </div>
        </div>
      </div>
  );
};

export default BonusGeneralReport;
