import { useDrop } from "react-dnd";
import { useEffect, useState, useRef, ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";

// not sure if that's a good practise to use fetch services directly in components //
import { departmentService } from "../../../../__store/thunks/services";
import { departmentModels } from "../../../../__store/models";
import { departmentThunk } from "../../../../__store/thunks";

import { INestedDepartmentProps } from "../../types/AdminProps.types";
import { structureData } from "../../../../data/constants";

const { DragType } = structureData;

import styles from "./NestedDepartment.module.scss";

function NestedDepartment({ department, onMouseLeave }: INestedDepartmentProps):ReactElement {
  const dispatch = useAppDispatch();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [paragraphWidth, setParagraphWidth] = useState<number>(0);
  const [childList, setChildList] = useState<departmentModels.StructureDepartment[]>([]);
  const draggedDepartmentId = useAppSelector(
    (state) => state.departments.draggedDepartment
  );

  const paragraphRef = useRef<HTMLParagraphElement>(null);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "box",
      drop: (
        item: { type: structureData.DragType; id: number; selectedId: number },
        monitor
      ) => {
        const didDrop = monitor.didDrop();

        if (!didDrop) {
          if (item.type === DragType.job) {
            dispatch(
              departmentThunk.moveJobPosiiton({
                job_position_id: item.id,
                department_id: item.selectedId,
                new_department_id: department.department_id,
              })
            );
          } else if (item.type === DragType.department) {
            dispatch(
              departmentThunk.changeParentDepartment({
                department_id: item.id,
                new_parent_id: department.department_id,
              })
            );
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),

    ["ANY"]
  );

  useEffect(() => {
    if (canDrop && isOver) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [canDrop, isOver]);

  useEffect(() => {
    let controller = new AbortController();

    (async () => {
      try {
        const res = await departmentService.fetchDepartmentsByParentId(department.department_id);
        setChildList(res.data);
      } catch (error) {
        setChildList([]);
      }
    })();

    return () => controller?.abort();
  }, []);

  useEffect(() => {
    if (paragraphRef.current?.clientWidth) {
      setParagraphWidth(paragraphRef.current.clientWidth);
    }
  });

  return (
    <div
      ref={drop}
      className={`${styles["nested-department"]}  ${
        isHover && styles["active"]
      }`}
      onMouseLeave={onMouseLeave}
    >
      <p ref={paragraphRef} className={styles["nested-department__name"]}>
        {department.name}
      </p>

      {childList.length > 0 && department.department_id !== 1 && (
        <div
          className={`${styles["child-container"]} ${
            isHover && styles["active"]
          }`}
          style={{ left: `${paragraphWidth + 80 + "px"}` }}
        >
          <ul className={`${styles["child-container__list"]} `}>
            {childList
              .filter(
                (department) => department.department_id !== draggedDepartmentId
              )
              .map((childDepartment) => (
                <NestedDepartment
                  department={childDepartment}
                  key={childDepartment.department_id}
                  onMouseLeave={() => {}}
                />
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NestedDepartment;
