import { useState } from "react";

import style from "./AssessmentsList.module.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../__store/tools/hooks";

import { AssessmentArray } from "../../types/AssessmentList.types";
import { useAssessmentDisplayData } from "../hooks/useAssessmentDisplayData";
import { Link } from "react-router-dom";

import { Icon } from "../../../components/ui";

import { departmentModels, evaluationModels } from "../../../../__store/models";
import { evaluationActions, modalActions } from "../../../../__store/slices";

import { format } from "date-fns";

import {
  IconTypes,
  ModalClassnames,
  RoutesPaths,
} from "../../../../data/config";

const AssessmentList = ({ assessmentList }: AssessmentArray) => {
  const dispatch = useAppDispatch();

  const { ASSESSMENT_INFO, MAKE_ASSESSMENT } = ModalClassnames;

  const PAGE_SIZE = 20;

  const { ASSESSMENT } = RoutesPaths;
  const [currentPage, setCurrentPage] = useState(1);

  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );

  const {
    assessmentTopBarValue,
    evaluationTranslationMap,
    evaluationTitleTranslationMap,
  } = useAssessmentDisplayData();

  const totalPages = assessmentList
    ? Math.ceil(assessmentList?.length / PAGE_SIZE)
    : 1;
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const onClickAssesmentHandler = (
    el:
      | evaluationModels.UserRates
      | departmentModels.FormUser
      | evaluationModels.EvaluationUserStatuses
  ) => {
    if ("content" in el) {
      dispatch(modalActions.setModalClassName(ASSESSMENT_INFO));
      dispatch(evaluationActions.setReviewingEvaluationId(el.evaluation_id));
      dispatch(modalActions.openModal());
    }
  };

  const onClickAssesmnetRateButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    userId: number
  ) => {
    dispatch(evaluationActions.setEvaluationType(e.currentTarget.name));
    dispatch(evaluationActions.setEvaluatingUserId(userId));
    dispatch(modalActions.setModalClassName(MAKE_ASSESSMENT));
    dispatch(modalActions.openModal());
  };

  const returnAssessmentItmes = (
    el:
      | evaluationModels.UserRates
      | departmentModels.FormUser
      | evaluationModels.EvaluationUserStatuses,
    index: number
  ) => {
    if ("count" in el) {
      return (
        <Link
          to={`${ASSESSMENT.REVIEW_ASSESSMENT_PATH}/${el.user_id}`}
          className={style["review-link"]}
        >
          <span>
            {index + 1}. {el.name} {el.surname}
          </span>

          <span className={style["rates__container"]}>
            <span>{el.count.POSITIVE}</span>
            <span>{el.count.NEGATIVE}</span>
          </span>
          <span>
            <Icon type={IconTypes.OPEN_ASSESSMENT} />
          </span>
        </Link>
      );
    } else if ("profile" in el) {
      return (
        <>
          <span>
            {el.profile.name} {el.profile.surname}
          </span>
          <span className={style["assessments-list__item--buttons-container"]}>
            <button
              onClick={(e) => onClickAssesmnetRateButton(e, el.user_id)}
              name="POSITIVE"
            >
              {themeName &&
              ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
                ? "Do wyróżnienia"
                : "Pozytywna"}
            </button>
            <button
              onClick={(e) => onClickAssesmnetRateButton(e, el.user_id)}
              name="NEGATIVE"
            >
              {themeName &&
              ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
                ? "Do poprawy"
                : "Negatywna"}
            </button>
          </span>
        </>
      );
    } else if ("content" in el) {
      return (
        <>
          <span>{format(new Date(el.evaluation_for_date), "MM-dd-yyyy")}</span>
          <span>{evaluationTranslationMap[el.type]}</span>
          <span>
            {el.custom_category
              ? el.custom_category
              : evaluationTitleTranslationMap[
                  el.category as evaluationModels.EvaluationCategory
                ]}
          </span>
          <span>
            {" "}
            <Icon type={IconTypes.OPEN_ASSESSMENT} />
          </span>
        </>
      );
    }
  };

  return (
    <div className={style["assessments-container"]}>
      <ul className={style["assessments-list"]}>
        {assessmentList
          ?.slice(startIndex, endIndex)
          .map(
            (
              el:
                | evaluationModels.UserRates
                | departmentModels.FormUser
                | evaluationModels.EvaluationUserStatuses,
              index: number
            ) => (
              <li
                key={index}
                className={
                  assessmentTopBarValue === "WORKERS_WORK"
                    ? style["assessments-list__item--rates"]
                    : style["assessments-list__item"]
                }
                onClick={() => onClickAssesmentHandler(el)}
              >
                {returnAssessmentItmes(el, index)}
              </li>
            )
          )}
      </ul>
      <div className={style["assessments-container__pagination"]}>
        <button disabled={currentPage === 1} onClick={handlePreviousPage}>
          Poprzednia
        </button>
        <span>
          Strona {currentPage} z {totalPages}
        </span>
        <button disabled={currentPage === totalPages} onClick={handleNextPage}>
          Następna
        </button>
      </div>
    </div>
  );
};

export default AssessmentList;
