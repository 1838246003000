import { BonusStatuses } from "../config";

export const BonusStatusesTranslated = new Map([
  [BonusStatuses.GENERATED, "Wygenerowana"],
  [BonusStatuses.AWARDED, "Przyznana"],
  [BonusStatuses.PAID, "Wypłacona"],
  [BonusStatuses.SUSPENDED, "Wstrzymana"]
]);

export enum BonusSections {
  WORKER_BONUSES = "bonuses",
  WORKER_PAYMENTS = "payments",
  INSTRUCTIONS = "instructions",
  MANAGER_GENERATION = "generaion",
  MANAGER_AUTOMATION = "automation",
  MANAGER_REPORT = "report"
};

export const bonusLabels = {
  [BonusStatuses.GENERATED]: "Wygenerowane",
  [BonusStatuses.AWARDED]: "Przyznane",
  [BonusStatuses.PAID]: "Wypłacone",
  [BonusStatuses.SUSPENDED]: "Wstrzymane"
};

export const dashboardPaymentsLabels = {
  WORKER_COLUMN: "Pracownik",
  CHANGE_COLUMN: "Data wymiany",
  POINTS_COLUMN: "Ilość punktów",
  AMOUNT_COLUMN: "Suma pieniędzy",
  STATUS_COLUMN: "Status",
};

export enum PaymentDashboards {
  PAYMENT = "PAYMENT",
  USER = "USER",
};
