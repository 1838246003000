import { useNavigate } from "react-router-dom";
import { useEffect, ReactElement, FormEvent } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { KeyInputIcon, InputManIcon } from "../../../assets/icons/";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { authModels } from "../../../__store/models";
import { authActions } from "../../../__store/slices";
import { authThunk } from "../../../__store/thunks";

import styles from "./LoginForm.module.scss";

import { IconTypes, RoutesPaths, UserRoles } from "../../../data/config";
import { authData } from "../../../data/constants";
import { Form, Input, Button } from "antd";
import { useFormInput } from "../hooks/useFormInput";

const { AUTHORIZATION } = RoutesPaths;
const { NEW_PASSWORD_PATH, CHANGE_PASSWORD_PATH } = AUTHORIZATION;

const { loginTexts, loginUsernameInputData, loginPasswordInputData } = authData;

const LoginForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const credentials: authModels.Credentials = { username: "", password: "" };

  const username = useFormInput(credentials.username);
  const password = useFormInput(credentials.password);

  const firstLogin = useAppSelector((state) => state.auth.firstLogin);
  const changePassword = useAppSelector((state) => state.auth.changePassword);
  const isCredentialsFalse = useAppSelector((state) => state.auth.error);
  const loginError = isCredentialsFalse ? loginTexts.LOGIN_ERROR_MESSAGE : "";
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const role = useAppSelector((state) => state.auth.role);
  const { WORKER, ADMIN } = UserRoles;
  const { PROFILE } = RoutesPaths;
  const { YOUR_PROFILE_PATH, WORKER_PROFILE_PATH } = PROFILE;

  useEffect(() => {
    if (isAuth) {
      role === WORKER || role === ADMIN
        ? navigate(YOUR_PROFILE_PATH)
        : navigate(WORKER_PROFILE_PATH);
    }
  }, [isAuth, navigate]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(
      authThunk.login({ username: username.value, password: password.value })
    );
  };

  useEffect(() => {
    if (firstLogin) {
      navigate(NEW_PASSWORD_PATH);
    }
  }, [firstLogin, navigate]);

  useEffect(() => {
    if (changePassword) {
      navigate(CHANGE_PASSWORD_PATH);
    }
  }, [changePassword, navigate]);

  useEffect(() => {
    dispatch(authActions.clearRecoveryStatus());
  }, [dispatch]);

  return (
    <Form
      form={form}
      className={styles["login"]}
      onSubmitCapture={handleSubmit}
      layout="vertical"
    >
      <h2 className={styles["login__header"]}>{loginTexts.LOGIN_HEADER}</h2>

      <Form.Item
        label={<label className={styles["login__label"]}>Login</label>}
      >
        <Input
          {...username}
          size="large"
          data-testid="login__login-input"
          className={styles["login__input"]}
          placeholder="podaj swój login"
          prefix={<InputManIcon />}
        />
      </Form.Item>
      <Form.Item
        label={<label className={styles["login__label"]}>Hasło</label>}
      >
        <Input.Password
          {...password}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          size="large"
          data-testid="login__password-input"
          className={styles["login__input"]}
          placeholder="podaj swoje hasło"
          prefix={<KeyInputIcon />}
        />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          data-testid="login__submit-button"
          className={styles["login__submit-button"]}
        >
          Zaloguj
        </Button>
      </Form.Item>

      <p className={styles["login__error-pargraph"]}>{loginError}</p>
    </Form>
  );
};

export default LoginForm;
