import { format } from "date-fns";
import { useState, FunctionComponent, ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { adminThunk, suggestionThunk } from "../../../__store/thunks";

import { displayErrorMessage, validateInputLength } from "../../../data/validation/suggestionValidation";

import { Button } from "../../components/ui";

import styled from "./PopupDone.module.scss";

import { UserRoles, MainStatuses } from "../../../data/config";
import { suggestionsData } from "../../../data/constants";

import { VerificationSubstatuses } from "../../../data/config/suggestionStatuses.data";

const { 
  DATE_FORMAT, 
  COMMENT_MAX_LENGTH, 
  COMMENT_MIN_LENGTH, 
  popupDoneLabels, 
  INPUT_MAX_LENGTH, 
  popupDoneButtons 
} = suggestionsData;

export interface IPopupYesNoProps {
  text: string;
  type: string;
};
  
export interface IPopupYesNoMapType {
  [key: string]: any;
};

export const PopupYesNoMap: IPopupYesNoMapType = {
  "remove-user": adminThunk.removeUser,
};

const PopupYesNo: FunctionComponent<IPopupYesNoProps> = ({text, type }):ReactElement => {
  const formInfo = useAppSelector((state) => state.forms.formInfoData);
  const dispatch = useAppDispatch();
  const pickedSuggestionId = useAppSelector((state) => state.forms.pickedSuggestionId);
  const userRole = useAppSelector((state) => state.auth.role);

  const allCommentaries = useAppSelector((state) => state.suggestions.allCommentaries);
  const selectedSuggestionCommentaries = allCommentaries?.filter(
    commentary => commentary.suggestion_id === formInfo?.suggestion_id
  ).filter(commentary => commentary.content !== "");

  const [comment, setComment] = useState<string>("");

  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  };

  const onSubmitHandler = () => {
    dispatch(
      suggestionThunk.updateSuggestionStatus({
        comment: comment,
        status: MainStatuses.VERIFICATION,
        substatus: VerificationSubstatuses.SUBMITTED_FOR_VERIFICATION,
        suggestion_id: pickedSuggestionId,
        userRole: userRole,
      })
    );
  };

  const validateComment = validateInputLength(comment, COMMENT_MAX_LENGTH);

  const disabledClass = !validateComment && comment.length < COMMENT_MIN_LENGTH;

  return (
    <div className={`${styled["popup"]}`}>
      {formInfo?.is_verification_comment_required ? (
        <>
          <div className={`${styled["popup__header"]}`}>
            <span>{popupDoneLabels.VERIFY_AGAIN}</span>
          </div>
          <div className={styled["popup__comments"]}>
            {selectedSuggestionCommentaries.length > 0 && selectedSuggestionCommentaries
              .filter(comment => comment.status === MainStatuses.VERIFICATION)
              .map((comment, index) => (
                <div key={index} className={styled["popup__comments__comment"]}>
                  <p className={styled["popup__comments__comment__date"]}>
                    <span className={styled["bold"]}>{popupDoneLabels.DATE}</span>
                    {format(new Date(comment.created_date), DATE_FORMAT)}
                  </p>
                  <p className={styled["popup__comments__comment__author"]}>
                    <span className={styled["bold"]}>{popupDoneLabels.AUTHOR}</span>
                    {comment.author.name} {comment.author.surname}
                  </p>
                  <div className={styled["popup__comments__comment__content"]}>
                    {comment.content}
                  </div>
                </div>
            ))}
          </div>
          <div className={styled["popup__comment"]}>
            <div>
              <h2 className={styled["popup__comment__header"]}>
                {popupDoneLabels.ADD_COMMENT}
              </h2>
              <div className={styled["popup__comment__text-areas"]}>
                <textarea
                  className={styled["text-areas--big"]}
                  name={"helpDescription"}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
                {validateComment && (
                  <span className={styled["text-areas__error"]}>
                    {displayErrorMessage(INPUT_MAX_LENGTH, comment.length)}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={styled["popup__send"]}>
            <div className={styled["cancel"]}>
              <Button
                textValue={popupDoneButtons.CANCEL_BUTTON}
                buttonClassName="cancel"
                buttonFunction={handleOnClose}
                hiddenStyles=""
              />
            </div>
            <div>
              <Button
                textValue={popupDoneButtons.SEND_BUTTON}
                buttonClassName="submit-button"
                buttonFunction={onSubmitHandler}
                hiddenStyles=""
                disabled={disabledClass}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`${styled["popup__header"]}`}>
            <span>
              {userRole === UserRoles.BOSS
                ? popupDoneLabels.MOVE_TO_IMPLEMENTED_BOSS
                : popupDoneLabels.VERIFY}
            </span>
          </div>
          <div className={`${styled["popup__buttons"]}`}>
            <div className={`${styled["popup__buttons__button"]}`}>
              <button onClick={handleOnClose} className={`${styled["button"]}`}>
                {popupDoneButtons.REJECT_BUTTON}
              </button>
            </div>
            <div className={`${styled["popup__buttons__button"]}`}>
              <button
                onClick={onSubmitHandler}
                className={`${styled["button"]}`}
              >
                {popupDoneButtons.APPROVE_BUTTON}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PopupYesNo;
