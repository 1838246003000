import ReactDOM from "react-dom/client";
import "./assets/styles/global.module.scss";
import Root from "./routes/Root";
import reportWebVitals from "./reportWebVitals";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import * as dotenv from "dotenv";
import store from "./__store/store";
import { ConfigProvider } from "antd";

// import ErrorBoundry from "./pages/components/ui/ErrorBoundry";

dotenv.config();

// Sentry.init({
//   dsn: "https://a087441fd3dd486dabb0e4faff491d3c@o4504213375287296.ingest.sentry.io/4504213385183232",
//   integrations: [new BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

// function FallbackComponent() {
//   return <div>An error has occurred</div>;
// }

// const myFallback = <FallbackComponent />;

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const App = () => {
  return (
    // <Sentry.ErrorBoundary fallback={myFallback} showDialog>
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#391580",
            colorPrimaryText: "#FFF",
            colorBorder: "#391580",
            colorText: "#391580",
            borderColorDisabled: "#8391ad",
            colorTextDisabled: "#8391ad",
          },
          Input: {
            colorText: "#000",
            colorBorder: "#8391ad",
            colorTextPlaceholder: "#8391ad",
            borderRadius: 6,
          },
          Select: {
            colorText: "#000",
            colorBorder: "#8391ad",
            colorTextPlaceholder: "#8391ad",
            borderRadius: 6,
            colorIcon: "#8391ad",
          },
          DatePicker: {
            colorText: "#000",
            colorBorder: "#8391AD",
            colorTextPlaceholder: "#8391AD",
          },
          Table: {
            headerBg: "#9CCEFC",
            headerColor: "#2D273A",
            headerSplitColor: "transparent",
            borderColor: "transparent",
            headerSortActiveBg: "#DEEFFE",
            headerSortHoverBg: "#DEEFFE",
            rowHoverBg: "#DEEFFE",
            rowSelectedBg: "#DEEFFE",
            rowSelectedHoverBg: "#DEEFFE",
          },
          Menu: {
            activeBarHeight: 0,
            horizontalItemSelectedColor: "#000",
            itemSelectedBg: "#9CCEFC",
            itemSelectedColor: "#000",
            colorSplit: "transparent",
            itemMarginBlock: 0,
            itemMarginInline: 0
          },
          Pagination: {
            itemActiveBg: "#9CCEFC",
            colorLinkActive: "#000",
            colorPrimary: "#000",
            lineType: "none",
          }
        },
      }}
    >
      <Provider store={store}>
        <Root />
      </Provider>
    </ConfigProvider>

    // </Sentry.ErrorBoundary>,
  );
};

root.render(<App />);

reportWebVitals();
