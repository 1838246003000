import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";

const { APPLICATION_SETTINGS } = ServerPaths;

export const fetchShowEvaluationPageInfo = async () => {
  return await axiosInstance.get(APPLICATION_SETTINGS);
};

export const patchShowEvaluationPageInfo = async (
  data: {
    shouldShowEvaluationPage?: boolean,
    useEstimatedCostAsInput?: boolean,
    themeName?: string
  }) => {
  return await axiosInstance.patch(APPLICATION_SETTINGS, {
    data: data,
  });
};
