export enum DragType {
  job = "job",
  department = "department",
};

// Common structure variables
export const DEPARTMENT_NAME_MIN_LENGTH = 2;
export const DEPARTMENT_NAME_MAX_LENGTH = 100;

export const departmentMessages = {
  NAME_TOO_SHORT: "Nazwa jest za krótka",
  NAME_TOO_LONG: "Nazwa jest za długa",
  AREA_NAME_REQUIRED: "Nazwa obszaru jest wymagana",
  NAME_REQUIRED: "Nazwa działu jest wymagana",
  AREA_REQUIRED: "Obszar jest wymagany",
  PARENT_REQUIRED: "Dział jest wymagany",
  ROLE_REQUIRED: "Rola jest wymagana",
};

// CREATE AREA

export const areaInputNames = {
  NAME: "area_name",
};

export const createAreaLabels = {
  TITLE: "Dodaj obszar",
  NAME: "Nazwa obszaru",
};

export const createAreaButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Dodaj"
};

// CREATE DEPARTMENT

export const departmentInputNames = {
  NAME: "name",
  PARENT_ID: "parent_id",
  AREA_ID: "area_id"
};

export const createDepartmentLabels = {
  TITLE: "Dodaj dział",
  NAME: "Nazwa działu",
  PARENT: "Dział nadrzędny",
  AREA: "Obszar"
};

export const createDepartmentButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Dodaj"
};

// EDIT AREA

export const editAreaLabels = {
  TITLE: "Edytuj obszar",
  NAME: "Nazwa obszaru",
};

export const editAreaButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Zmień",
};

// EDIT DEPARTMENT

export const editDepartmentLabels = {
  TITLE: "Edytuj dział",
  NAME: "Nazwa działu",
  AREA: "Obszar",
};

export const editDepartmentButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Zmień"
};

// CREATE JOB POSITION

export const jobPositionInputNames = {
  NAME: "name",
  DEPARTMENT_ID: "department_id",
  ROLE: "role"
};

export const createJobPositionLabels = {
  TITLE: "Dodaj stanowisko",
  NAME: "Nazwa stanowiska",
  DEPARTMENT: "Wybierz dział",
  ROLE: "Wybierz stanowisko"
};

export const createJobPositionButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Dodaj"
};

// EDIT JOB POSITION

export const editJobPositionLabels = {
  TITLE: "Edytuj stanowisko",
  NAME: "Nazwa stanowiska",
};

export const editJobPositionButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Zmień"
};
