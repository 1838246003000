export const CLIENT_NAME = process.env.REACT_APP_VERSION;

import { ReactElement } from 'react';

import { Divider, Typography } from 'antd';

import styles from "../views/BonusWorkerPage.module.scss";

const { Title } = Typography;

const BonusInstructions = (): ReactElement => {
  return (
    <div className={styles["content-section"]}>
      {CLIENT_NAME === "silesia" && (<div className={styles["bonus-instructions"]}>
        <Title level={3} style={{ marginBottom: 0, fontSize: 24, fontWeight: 700}}>Wartość punktowa i pieniężna</Title>
        <Divider style={{ borderBlockStart: "2px solid #9CCEFC" }} />
        <p className={styles["instructions-description"]}>
          Premia specjalna wypłacana jest w kolejnym miesiącu przypadającym po ocenie usprawnień i ogłoszeniu wyników wyboru przez zespół Water&Mind.
          <ul>
            <li>
            <strong>Do 30 czerwca 2023</strong> <br />
            Zgłoszenie     1 pkt - 5 PLN netto <br />
            Wdrożenie      10 pkt - 50 PLN netto
            </li>

            <li>
              <strong>Do 30 września 2023</strong> <br />
              Zgłoszenie     5 pkt - 5 PLN netto <br />
              Wdrożenie      40 pkt - 40 PLN netto
            </li>

            <li>
              <strong>Od 1 października 2023</strong> <br />
              Zgłoszenie     40 pkt - 40 PLN netto <br />
              Wdrożenie      250 pkt - 250 PLN netto
            </li>
          </ul>
        </p>
      </div>)}

      {CLIENT_NAME === "cisowianka" && (<div className={styles["bonus-instructions"]}>
        <Title level={3} style={{ marginBottom: 0, fontSize: 24, fontWeight: 700}}>Kryteria oceny najlepszych usprawnień</Title>
        <Divider style={{ borderBlockStart: "2px solid #9CCEFC" }} />
        <p className={styles["instructions-description"]}>
          Spośród wszystkich usprawnień raz na kwartał wybierane są przez Zespół Water&Mind najlepsze spośród wdrożonych usprawnień pod kątem ich wpływ na działalność firmy. Trzy najlepsze usprawnienia są dodatkowo nagradzane premią specjalną w wysokości:
          <ul>
            <li>1 miejsce     3600 PLN netto</li>
            <li>2 miejsce     2400 PLN netto</li>
            <li>3 miejsce     1200 PLN netto</li>
          </ul>
          Premia specjalna wypłacana jest w kolejnym miesiącu przypadającym po ocenie usprawnień i ogłoszeniu wyników wyboru przez zespół Water&Mind.
        </p>
      </div>)}
    </div>
  )
};

export default BonusInstructions;
