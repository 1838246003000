import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkNames } from "../../data/config";

import { applicationSettingsActions } from "../slices";
import { applicationSettingsService } from "./services";
import { applicationSettingsModels } from "../models";

const { APPLICATION_SETTINGS } = ThunkNames;

export const updateApplicationSettings = createAsyncThunk(
  APPLICATION_SETTINGS.UPDATE_APPLICATION_SETTINGS_THUNK,
    (data: applicationSettingsModels.ApplicationSettingsState, thunkAPI) => {
    const { dispatch } = thunkAPI;

    applicationSettingsService.patchShowEvaluationPageInfo(data).then((data) => {
      const { 
        shouldShowEvaluationPages, 
        useEstimatedCostAsInput, 
        useConsultationField, 
        themeName 
      } = data.data;
      
      dispatch(applicationSettingsActions.setShowEvaluationPages(shouldShowEvaluationPages));
      dispatch(applicationSettingsActions.setUseEstimationCostType(useEstimatedCostAsInput));
      dispatch(applicationSettingsActions.setUseConsultationField(useConsultationField))
      dispatch(applicationSettingsActions.setThemeName(themeName));
    });
  }
);
