import { format } from "date-fns";
import { evaluationModels } from "../../../__store/models";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { IconTypes } from "../../../data/config";
import { Icon, InactiveAccount, NavBar } from "../../components/ui";
import { useAssessmentDisplayData } from "../components/hooks/useAssessmentDisplayData";
import styles from "./MyAssessmentDetails.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { MAIN_DATE_FORMAT } from "../../../data/config/globalContentVariables.data";
import { useEffect, useState } from "react";
import { evaluationThunk } from "../../../__store/thunks";
import { Input } from "antd";
import { useFormik } from "formik";
import { Button } from "antd";
import * as Yup from "yup";
import NavTopBar from "../../components/ui/NavTopBar";
import appHeaders from "../../../data/constants/appHeaders.data";

const MyAssessmentDetails = () => {
  const navigate = useNavigate();
  const isActive = useAppSelector(
    (state) => state.users.authUser?.user.isActive
  );
  const [showResponseDialog, setShowResponseDialog] = useState(false);

  const userId = useAppSelector((state) => state.auth.user_id);
  const dispatch = useAppDispatch();

  const params = useParams();

  const { evaluationTranslationMap, evaluationTitleTranslationMap } =
    useAssessmentDisplayData();

  const { negativeRates, positiveRates } = useAppSelector(
    (state) => state.evaluation.personalRates
  );

  const { TextArea } = Input;

  const createAssessmentComent = Yup.object().shape({
    evaluationComment: Yup.string().required("Podaj treść odpowiedzi"),
  });

  const { ASSESSMENT } = appHeaders;

  const { values, handleBlur, handleChange, errors, touched, handleSubmit } =
    useFormik({
      initialValues: {
        evaluationComment: "",
      },
      validationSchema: createAssessmentComent,
      onSubmit: () => {
        dispatch(
          evaluationThunk.createEvaluationComment({
            user_id: userId as number,
            content: reviewingEvaluation.content,
            evaluation_id: reviewingEvaluation.evaluation_id,
          })
        );
      },
    });

  useEffect(() => {
    dispatch(evaluationThunk.getUserEvaluations(userId as number));
  }, []);
  const [reviewingEvaluationId] = useState(Number(params.id));

  const reviewingEvaluation = useAppSelector((state) =>
    state.evaluation.userRates?.find(
      (el) => el.evaluation_id === reviewingEvaluationId
    )
  ) as evaluationModels.UserRates;

  const validateFields = errors.evaluationComment && touched.evaluationComment;

  const displayAddCommentButton = () => {
    const returnButton = () => (
      <div className={styles["card__footer"]}>
        <Button
          size="large"
          type="primary"
          htmlType="button"
          onClick={() => {
            setShowResponseDialog((prevState) => !prevState);
          }}
          className={styles["card__button"]}
        >
          Odpowiedź
        </Button>
      </div>
    );

    if (
      reviewingEvaluation &&
      !reviewingEvaluation.evaluation_comments?.length &&
      !showResponseDialog &&
      userId === reviewingEvaluation.evaluated_user_id
    ) {
      return returnButton();
    } else if (
      reviewingEvaluation &&
      reviewingEvaluation.evaluation_comments?.length === 1 &&
      !showResponseDialog &&
      userId === reviewingEvaluation.evaluating_user_id
    ) {
      return returnButton();
    }
  };

  const displayMobileCommentButton = () => {
    const returnButton = () => (
      <div className={styles["card__footer--mobile"]}>
        <Button
          size="large"
          type="primary"
          htmlType="button"
          onClick={() => {
            setShowResponseDialog((prevState) => !prevState);
          }}
          className={styles["card__button"]}
        >
          Odpowiedź
        </Button>
      </div>
    );

    if (
      reviewingEvaluation &&
      !reviewingEvaluation.evaluation_comments?.length &&
      !showResponseDialog &&
      userId === reviewingEvaluation.evaluated_user_id
    ) {
      return returnButton();
    } else if (
      reviewingEvaluation &&
      reviewingEvaluation.evaluation_comments?.length === 1 &&
      !showResponseDialog &&
      userId === reviewingEvaluation.evaluating_user_id
    ) {
      return returnButton();
    }
  };

  const displayMobileSubmitButton = () => {
    if (
      showResponseDialog &&
      reviewingEvaluation &&
      reviewingEvaluation.evaluation_comments?.length === 0
    ) {
      return (
        <div className={styles["card__footer--mobile"]}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            onClick={() => handleSubmit()}
            disabled={!!validateFields}
            className={styles["card__button"]}
          >
            Zapisz
          </Button>
        </div>
      );
    }
  };

  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
        <div className={styles["page__dashboard"]}>
          <NavTopBar topBarHeader={ASSESSMENT.ASSESSMENT_HEADER} />
          <div className={styles["container"]}>
            <div className={styles["container__rates-section"]}>
              <div className={styles["rate"]}>
                <h4 className={styles["rate__header"]}>Wyróżnienia</h4>
                <h2 className={styles["rate__header--bg"]}>{positiveRates}</h2>
                <Icon
                  type={IconTypes.CHECK_CIRCLE_ICON}
                  className={styles["rate__icon"]}
                />
              </div>

              <div className={styles["rate"]}>
                <h4 className={styles["rate__header"]}>Do poprawy</h4>
                <h2 className={styles["rate__header--bg"]}>{negativeRates}</h2>
                <Icon
                  type={IconTypes.CANCEL_CIRCLE_ICON}
                  className={styles["rate__icon"]}
                />
              </div>
            </div>

            <div className={styles["container__cards-section"]}>
              <div className={styles["card"]}>
                <header className={styles["card__header"]}>
                  <span className={styles["card__header__info"]}>
                    <Icon
                      className={styles["card__icon"]}
                      onClick={() => navigate(-1)}
                      type={IconTypes.BIG_CHEVRON_LEFT}
                    />
                    <h2>
                      {reviewingEvaluation.category &&
                        evaluationTitleTranslationMap[
                          reviewingEvaluation.category
                        ]
                      }
                    </h2>
                    <span
                      className={
                        reviewingEvaluation?.type === "POSITIVE"
                          ? styles["positive__info"]
                          : styles["negative__info"]
                      }
                    >
                      {" "}
                      {evaluationTranslationMap[reviewingEvaluation?.type]}{" "}
                    </span>
                  </span>
                  <span className={styles["card__header__date"]}>
                    {reviewingEvaluation &&
                      format(
                        new Date(reviewingEvaluation?.evaluation_for_date),
                        MAIN_DATE_FORMAT
                      )}
                  </span>
                </header>
                <hr />
                <section className={styles["card__section"]}>
                  {reviewingEvaluation && reviewingEvaluation.content}
                </section>
                {reviewingEvaluation &&
                  reviewingEvaluation.evaluationFilePath && (
                    <ul className={styles["card__links"]}>
                      {reviewingEvaluation.evaluationFilePath.map(
                        (el, index) => (
                          <li key={index} className={styles["link"]}>
                            {" "}
                            <span>
                              {" "}
                              <Icon
                                type={IconTypes.ATTACH}
                                className={styles["link__icon"]}
                              />
                              Załacznik_
                              {index + 1}
                            </span>
                            <a href={el}>
                              <Icon
                                className={styles["link__icon"]}
                                type={IconTypes.DOWNLOAD}
                              />
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  )}

                {displayAddCommentButton()}
              </div>
              {showResponseDialog &&
                reviewingEvaluation &&
                reviewingEvaluation.category &&
                reviewingEvaluation.evaluation_comments?.length === 0 && (
                  <div className={styles["card"]}>
                    <header className={styles["card__header"]}>
                      <h2>
                        {
                          evaluationTitleTranslationMap[
                            reviewingEvaluation.category
                          ]
                        }
                        &nbsp; (odpowiedź)
                      </h2>
                      <span className={styles["card__header__date"]}>
                        {format(new Date(), MAIN_DATE_FORMAT)}
                      </span>
                    </header>
                    <hr />

                    <form
                      onSubmit={handleSubmit}
                      className={styles["card__section--edit"]}
                    >
                      <TextArea
                        onBlur={handleBlur}
                        name="evaluationComment"
                        value={values.evaluationComment}
                        onChange={handleChange}
                        id="evaluationComment"
                        className={styles["card__textarea"]}
                        autoSize={{ minRows: 5, maxRows: 7 }}
                      />
                      <div className={styles["card__footer"]}>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="submit"
                          disabled={!!validateFields}
                          className={styles["card__button"]}
                        >
                          Zapisz
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              {reviewingEvaluation 
              && reviewingEvaluation.evaluation_comments 
              && reviewingEvaluation.category 
              && reviewingEvaluation.evaluation_comments.length > 0 
              && userId === reviewingEvaluation.evaluated_user_id 
              && (
                  <div className={styles["card"]}>
                    <header className={styles["card__header"]}>
                      <h2>
                        {
                          evaluationTitleTranslationMap[
                            reviewingEvaluation.category
                          ]
                        }
                        &nbsp; (odpowiedź)
                      </h2>
                      <span className={styles["card__header__date"]}>
                        {reviewingEvaluation &&
                        reviewingEvaluation?.evaluation_comments
                          ? format(
                              new Date(
                                reviewingEvaluation?.evaluation_comments[0].created_at
                              ),
                              MAIN_DATE_FORMAT
                            )
                          : format(new Date(), MAIN_DATE_FORMAT)}
                      </span>
                    </header>
                    <hr />
                    {reviewingEvaluation?.evaluation_comments && (
                      <section className={styles["card__section"]}>
                        {reviewingEvaluation?.evaluation_comments[0].content}
                      </section>
                    )}
                  </div>
                )}
              {reviewingEvaluation?.category 
                && reviewingEvaluation?.evaluation_comments?.length === 2 && (
                <div className={styles["card"]}>
                  <header className={styles["card__header"]}>
                    <h2>
                      {
                        evaluationTitleTranslationMap[
                          reviewingEvaluation.category
                        ]
                      }
                      &nbsp; (odpowiedź przełożonego)
                    </h2>
                    <span className={styles["card__header__date"]}>
                      {reviewingEvaluation &&
                      reviewingEvaluation?.evaluation_comments
                        ? format(
                            new Date(
                              reviewingEvaluation?.evaluation_comments[1].created_at
                            ),
                            MAIN_DATE_FORMAT
                          )
                        : format(new Date(), MAIN_DATE_FORMAT)}
                    </span>
                  </header>
                  <hr />
                  <section className={styles["card__section"]}>
                    {reviewingEvaluation &&
                      reviewingEvaluation?.evaluation_comments[1].content}
                  </section>
                </div>
              )}
            </div>
          </div>
          {displayMobileCommentButton()}
          {displayMobileSubmitButton()}
        </div>
      ) : (
        <InactiveAccount />
      )}{" "}
    </div>
  );
};

export default MyAssessmentDetails;
