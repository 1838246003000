import { createSlice } from "@reduxjs/toolkit";

import { adminModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: adminModels.UserState = {
  user_id: null,
  users: [],
  user: null,
  userByIndex: null,
  errorMessage: "",
  authUser: null,
};

const usersSlice = createSlice({
  name: SliceNames.USERS,
  initialState,
  reducers: {
    setUsers: (state, { payload }) => {
      state.users = payload.sort((a: adminModels.Profile, b: adminModels.Profile) =>
        a.surname.localeCompare(b.surname)
      );
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setUserId: (state, { payload }) => {
      state.user_id = payload;
    },
    setUserIndex: (state, { payload }) => {
      state.userByIndex = state.users.filter(
        (user) => user.user_id === payload
      )[0];
    },
    setUserInitialState: () => initialState,
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    clearErrorMessage: (state) => {
      state.errorMessage = "";
    },
    setAuthUser: (state, { payload }) => {
      state.authUser = payload;
    },
  },
});

export const {
  setUsers,
  setUserId,
  setUser,
  setUserInitialState,
  setUserIndex,
  setErrorMessage,
  clearErrorMessage,
  setAuthUser,
} = usersSlice.actions;

export default usersSlice.reducer;
