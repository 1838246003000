import styles from "./Button.module.scss";
export interface ButtonProps {
  buttonClassName: string;
  disabledClass?: boolean;
  value: string;
}

const SubmitButton = ({
  buttonClassName,
  value,
  disabledClass,
}: ButtonProps) => {
  return (
    <div>
      <button
        disabled={disabledClass}
        type="submit"
        className={`${styles[buttonClassName]}`}
      >
        {value}
      </button>
    </div>
  );
};

export default SubmitButton;
