import React, { useEffect, useRef } from "react";
import { notification } from "antd";
import { useAppSelector } from "../../../__store/tools/hooks";

const UtilNotification: React.FC = () => {
  const sendingSuggestion = useAppSelector(
    (state) => state.suggestions.sendingSuggestion
  );
  const notificationDisplayed = useRef(false);

  const openNotification = () => {
    const args = {
      message: "Tworzenie sugestii",
      description: "Trwa wysyłanie danych sugestii",
      duration: 0,
      closeIcon: undefined,
    };
    notification.open(args);
  };

  useEffect(() => {
    if (sendingSuggestion && !notificationDisplayed.current) {
      openNotification();
      notificationDisplayed.current = true;
    } else if (!sendingSuggestion && notificationDisplayed.current) {
      notification.destroy();
      notificationDisplayed.current = false;
    }
  }, [sendingSuggestion]);

  return null;
};

export default UtilNotification;
