import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom'

import { 
  SuggestionActionButtons, 
  SuggestionStatusInfo, 
  SuggestionTitle 
} from '../components';

import { NavBar } from '../../components/ui';

import styles from "./SuggestionPreview.module.scss";
import { useAppDispatch, useAppSelector } from '../../../__store/tools/hooks';
import { suggestionThunk } from '../../../__store/thunks';
import { formsActions } from '../../../__store/slices';

import { MainStatuses } from '../../../data/config';

const SuggestionPreview = () => {

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const readOnlyParam = queryParams.get('readOnly');
  const readOnly = readOnlyParam === 'true';

  const selectedSuggestionData = useAppSelector((state) => state.suggestions.selectedSuggestion);

  useEffect(() => {
    dispatch(suggestionThunk.getSelectedSuggestion(id));
  }, []);

  useEffect(() => {
    if (selectedSuggestionData) {
      dispatch(formsActions.setFormInfoData(selectedSuggestionData));
    }
  }, [selectedSuggestionData]);

  return (
    <div className={styles["page"]}>
      <NavBar /> 
      {id && selectedSuggestionData ?
        <div className={styles["dashboard"]}>
          <div className={styles["header"]}>
            <SuggestionTitle
              title={selectedSuggestionData.title}
              status={selectedSuggestionData.status as MainStatuses}
              substatus={selectedSuggestionData.substatus}
            />
            <SuggestionActionButtons
              status={selectedSuggestionData.status as MainStatuses}
              substatus={selectedSuggestionData.substatus}
              suggestionData={selectedSuggestionData}
              suggestionID={+id}
              readOnly={readOnly}
            />
          </div>
          <div className={styles["container"]}>
            <SuggestionStatusInfo
              suggestionId={id}
              suggestionData={selectedSuggestionData}
            /> 
          </div>
        </div> : null}
    </div>
  );
};

export default SuggestionPreview;
