import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";
import { evaluationThunk } from "../../../__store/thunks";

import { InactiveAccount, NavBar } from "../../components/ui";
import { AssessmentDashboard } from "../components/AssessmentDashboard";
import { TopBar } from "../components";

import styles from "./CreateAssessment.module.scss";

const ReviewAssessment = () => {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);

  useEffect(() => {
    dispatch(evaluationThunk.getUsersAppraisalStatuses());
  }, []);

  const assessmentList = useAppSelector(
    (state) => state.evaluation.apperialUserStatuses
  );

  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["page__dashboard"]}>
        <TopBar />

        <AssessmentDashboard assessmentList={assessmentList} />
      </div>) : <InactiveAccount />}
    </div>
  );
};

export default ReviewAssessment;
