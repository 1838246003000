import { createSlice, createSelector  } from "@reduxjs/toolkit";
import { selectSelft } from "../tools/hooks";

import { authModels } from "../models";
import { authData } from "../../data/constants";

import { 
  SliceNames, 
  LocalStorageKeys 
} from "../../data/config";

import { authThunk } from "../thunks";
const { changePasswordMethod, login, newPassword, recovery } = authThunk;

const { PASSWORD_NOT_UNIQUE_ERROR} = authData;
const { ACCESS_TOKEN,  } = LocalStorageKeys;

const initialState: authModels.AuthState = {
  isAuth: Boolean(localStorage.getItem(ACCESS_TOKEN)),
  error: "",
  loading: false,
  role: "",
  changePassword: false,
  newPasswordErrorMessage: "",
  changePasswordErrorMessage: "",
  login: "",
  sendMailNotifications: false,
  user_id: null,
  passwordVerification: {
    verified: false,
    message: "",
  },
  recoveryStatus: "",
  permissions: {
    hr_permission: false,
    admin_permission: false,
    finance_permission: false,
    structure_permission: false,
    payment_permission: false,
    all_suggestions_preview_permission: false,
    external_comments_permission: false,
  },
  isActive: false,
  firstLogin: false,
  hasRulesAccepted: false,
  firsLoginCreddentials: {
    login: "",
    password: "",
  },
};

const authSlice = createSlice({
  name: SliceNames.AUTH,
  initialState,
  reducers: {
    setUserLogin: (state, { payload }) => {
      state.login = payload;
    },
    setUserRole: (state, { payload }) => {
      state.role = payload.role;
      state.login = payload.login;
      state.user_id = payload.user_id;
      state.permissions = payload.permissions;
      state.sendMailNotifications = payload.sendMailNotifications;
      state.hasRulesAccepted = payload.hasRulesAccepted;
      state.isActive = payload.isActive;
      state.hasRulesAccepted = payload.hasRulesAccepted;
    },
    setChangePassword: (state, { payload }) => {
      state.changePassword = payload;
    },
    clearAuthStage: (state) => {
      localStorage.clear();
      state.isAuth = false;
      state.isActive = false;
      state.login = "";
      state.role = "";
      state.user_id = null;
      state.changePassword = false;
      state.error = "";
      state.permissions = initialState.permissions;
      state.firstLogin = false;
      state.hasRulesAccepted = false;
      state.firsLoginCreddentials = {
        login: "",
        password: "",
      };
    },

    setSendMailNotifications: (state, { payload }) => {
      state.sendMailNotifications = payload;
    },

    setPasswordVerification: (state, { payload }) => {
      state.passwordVerification = payload;
    },
    setRecoveryStatus: (state, { payload }) => {
      state.recoveryStatus = payload;
    },
    clearRecoveryStatus: (state) => {
      state.recoveryStatus = "";
    },
    setFirstLogin: (state, { payload }) => {
      state.firstLogin = payload;
    },
    setFirstLoginCredentials: (state, { payload }) => {
      state.firsLoginCreddentials = payload;
    },
    setRulesAcceptance: (state, { payload }) => {
      state.hasRulesAccepted = payload;
    },
    setLoginSucces: (state) => {
      state.loading = false;
      state.isAuth = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(newPassword.rejected, (state) => {
      state.newPasswordErrorMessage = PASSWORD_NOT_UNIQUE_ERROR;
    });

    builder.addCase(changePasswordMethod.rejected, (state) => {
      state.changePasswordErrorMessage = PASSWORD_NOT_UNIQUE_ERROR;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(recovery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recovery.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(recovery.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const yourLogin = createSelector(
  selectSelft,
  (state) => state.auth.login
);

export const {
  clearAuthStage,
  setUserRole,
  setUserLogin,
  setPasswordVerification,
  setRecoveryStatus,
  setSendMailNotifications,
  clearRecoveryStatus,
  setFirstLogin,
  setLoginSucces,
  setChangePassword,
  setFirstLoginCredentials,
  setRulesAcceptance
} = authSlice.actions;

export default authSlice.reducer;
