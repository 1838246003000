import { ReactElement } from 'react';

import { Typography } from 'antd';
const { Title } = Typography;

interface IBonusTitle {
  title: string;
}

const BonusTitle = ({ title }: IBonusTitle): ReactElement => {
  return (
    <Title level={3} style={{
      marginBottom: 0, 
      fontSize: 28, 
      fontWeight: 700
      }}
    >
      {title}
    </Title>
  );
};

export default BonusTitle;
