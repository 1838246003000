import { ReactElement } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import styles from "./DashboardStatusCard.module.scss";
import { useAppSelector } from "../../../__store/tools/hooks";
import { IDashboardStatusCardProps } from "../types/ProfileProps.types";
import { IDashboardStatusCardValues, IDashboardStatusCardTranslationMapping } from "../types/ProfileComponents.types";
import SuggestionStatuses from "../../../data/config/suggestionStatuses.data";
import RoutesPaths from "../../../data/config/routesPaths.data";

const { SUGGESTIONS } = RoutesPaths;
const { MY_SUGGESTIONS_PATH, WORKER_SUGGESTIONS_PATH } = SUGGESTIONS;

const DashboardStatusCard = ({
  value,
  icon,
  isWorkerSuggestion,
  isBossSuggestion,
}: IDashboardStatusCardProps):ReactElement => {
  const reportCount = useAppSelector((state) => state.suggestions.report);
  const realizationCount = useAppSelector((state) => state.suggestions.realization);
  const verificationCount = useAppSelector((state) => state.suggestions.verification);
  const creationCount = useAppSelector((state) => state.suggestions.creation);
  const rejectionCount = useAppSelector((state) => state.suggestions.rejection);
  const appealedCount = useAppSelector((state) => state.suggestions.appealed);

  const mySuggestionsCount = useAppSelector((state) => state.suggestions.mySuggestionsCount);

  const navigate = useNavigate();

  const navigateToSuggestionDashboard = (isWorkerSuggestion: boolean, status: SuggestionStatuses) => {
    if (status && isWorkerSuggestion) {
      navigate({
        pathname: WORKER_SUGGESTIONS_PATH,
        search: createSearchParams({
          status: status
        }).toString()
      });
    } else if (status && !isWorkerSuggestion){
      navigate({
        pathname: MY_SUGGESTIONS_PATH,
        search: createSearchParams({
          status: status
        }).toString()
      });
    }
  }

  const Count: IDashboardStatusCardValues = {
    Nowe: isWorkerSuggestion
      ? reportCount.countedStatisticSuggestion
      : mySuggestionsCount.report,
    "W trakcie wdrożenia": isWorkerSuggestion
      ? realizationCount.countedStatisticSuggestion
      : mySuggestionsCount.realization,
    Weryfikacja: isWorkerSuggestion
      ? verificationCount.countedStatisticSuggestion
      : mySuggestionsCount.verification,
    Zrealizowane: isWorkerSuggestion
      ? creationCount.countedStatisticSuggestion
      : mySuggestionsCount.creation,
    Odrzucone: isWorkerSuggestion
      ? rejectionCount.countedStatisticSuggestion
      : mySuggestionsCount.rejection,
    Odwołane: isWorkerSuggestion
      ? appealedCount.countedStatisticSuggestion
      : mySuggestionsCount.appealed,
  };

  const statusTranslationMapping: IDashboardStatusCardTranslationMapping = {
    "Nowe": SuggestionStatuses.REPORT,
    "W trakcie wdrożenia": SuggestionStatuses.REALIZATION,
    "Weryfikacja": SuggestionStatuses.VERIFICATION,
    "Zrealizowane": SuggestionStatuses.CREATION,
    "Odrzucone": SuggestionStatuses.REJECTION,
    "Odwołane": SuggestionStatuses.APPEALED,
  };

  const suggestionStatus = statusTranslationMapping[value];
  return (
    <div
      className={styles[`status-card${isBossSuggestion ? "--boss" : ""}`]}
      onClick={() => {navigateToSuggestionDashboard(Boolean(isWorkerSuggestion), suggestionStatus)}}
    >
      <p className={styles[`status-card${isBossSuggestion ? "--boss" : ""}__header`]}>{value}</p>
      <span className={styles[`status-card${isBossSuggestion ? "--boss" : ""}__amount`]}>{Count[value]}</span>
      <div className={styles[`status-card${isBossSuggestion ? "--boss" : ""}__icon`]}>{icon}</div>
    </div>
  );
};

export default DashboardStatusCard;
