// @ts-nocheck
// check token type
import axios from "axios";
import { checkToken } from "./axios";

import LocalStorageKeys from "../../data/config/localStorage.data";
import ServerPaths from "../../data/config/serverPaths.data";

const { ACCESS_TOKEN } = LocalStorageKeys;
const { BASE } = ServerPaths;

const jwt = localStorage.getItem(ACCESS_TOKEN);
const token = jwt ? JSON.parse(jwt) : "";

const axiosFilesInstance = axios.create({
  baseURL: BASE,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: token ? `Bearer ${token}` : null,
  },
});

axiosFilesInstance.interceptors.request.use(checkToken);

export default axiosFilesInstance;
