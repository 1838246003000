// INFO: Opisy nowego systemu statusów znajdują się w komentarzach do pliku statuses.ts
// UWAGA: W przypadku zmian w statusach nalezy pamiętać, aby je aktualizować zarówno na backendzie, jak i na frontendzie.
export interface Status {
  [key: string]: string;
}

export const StatusAcceptanceInfo: Status = { // do lekkiej przebudowy - statusy wymieszane z podstatusami
  realization: "Czy na pewno chcesz zaakceptować sugestię?",
  rejection: "Czy na pewno chcesz odrzucić sugestię?",
  withdrawn: "Czy na pewno chcesz cofnąć sugestię do pracownika?",
  redirected: "Czy na pewno chcesz przekierować sugestię?",
  creation: "Czy na pewno chcesz zatwierdzić wdrożenie?",
  appealed: "Czy na pewno chcesz wysłać sugestię do weryfikacji?",
  closed: "Czy na pewno sugestia ma być zamknięta?",

};

export const StatusCommentHeader: Status = { // do lekkiej przebudowy - statusy wymieszane z podstatusami
  realization: "Wyjaśnij, dlaczego sugestia została zaakceptowana",
  rejection: "Wyjaśnij, dlaczego sugestia została odrzucona",
  withdrawn: "Wyjaśnij przyczyny cofnięcia sugestii",
  redirected: "Opisz przyczyny przekierowania",
  creation: "Skomentuj zatwierdzenie wdrożenia sugestii",
  appealed: "Czy na pewno chcesz wysłać sugestię do weryfikacji?",
  closed: "Wyjaśnij, dlaczego sugestia została zamknięta",
};

export enum MainStatuses {
  REPORT = "report", 
  REALIZATION = "realization", 
  VERIFICATION = "verification",
  CREATION = "creation",
  REJECTION = "rejection",
  APPEALED = "appealed", 
};

export enum AllStatuses {
  REPORT = "report", 
  UNMARKED = "unmarked",
  WITHDRAWN = "withdrawn", 
  REDIRECTED = "redirected", 
  CORRECTED = "corrected",


  REALIZATION = "realization",
  DURING_REALIZATION = "during_realization", 
  RETURNED_FROM_VERIFICATION = "returned_from_verification", 
  EXT_COMMENT_REALIZATION = "ext_comment_realization", 

  VERIFICATION = "verification", 
  SUBMITTED_FOR_VERIFICATION = "submited_for_verification", 
  EXT_COMMENT_VERIFICATION = "ext_comment_verification", 

  CREATION = "creation", 
  COMPLETED = "completed", 
  RESULT = "result", 
 
  REJECTION = "rejection", 
  REVOCABLE = "revocable", 
  CLOSED = "closed", 

  APPEALED = "appealed", 

  NOTE = "note",
  EXTERNAL_COMMENT = "external_comment"
};

export enum ReportSubstatuses {
  UNMARKED = "unmarked",
  WITHDRAWN = "withdrawn", 
  REDIRECTED = "redirected", 
  CORRECTED = "corrected", 
};

export enum RealizationSubstatuses {
  DURING_REALIZATION = "during_realization",
  RETURNED_FROM_VERIFICATION = "returned_from_verification",
  EXTERNAL_COMMENT = "external_comment"
};

export enum VerificationSubstatuses {
  SUBMITTED_FOR_VERIFICATION = "submited_for_verification",
  EXTERNAL_COMMENT = "external_comment"
};

export enum CreationSubstatuses {
  COMPLETED = "completed",
  RESULT = "result"
};

export enum RejectionSubstatuses {
  REVOCABLE = "revocable",
  CLOSED = "closed"
}


export default MainStatuses;
  