import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { Button, Divider, Input, Menu, MenuProps, Table, Typography } from 'antd';

import type { ColumnsType, TableProps } from 'antd/es/table';

import styles from "../views/BonusManagerPage.module.scss";

import { BonusStatuses } from "../../../data/config";
import { bonusThunk, reportThunk } from "../../../__store/thunks";

import { IconTypes } from "../../../data/config";
import { Icon } from "../../components/ui";

const { Title } = Typography;
const { Search } = Input;

interface IBonusManagerData {
  key: React.Key;
  number: number;
  worker: {
    id: number;
    name: string;
    surname: string;
  }
  points: number;
  amount: number;
  amountGros: number;
  amountNet: number;
  updateDate: Date;
  details: any[];
  workerMobile: {
    id: number;
    name: string;
    surname: string;
    amountGros: number;
    amountNet: number;
  };
  pointsAndBonusMobile: {
    points: number;
    updateDate: Date;
  };
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const BonusManagerTable = (): ReactElement => {
  const dispatch = useAppDispatch();
  
  const [selectedTable, setSelectedTable] = useState<BonusStatuses>(BonusStatuses.GENERATED);
  const [areButtonsActive, setAreButtonsActive] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const managerId = useAppSelector((state) => state.auth.user_id);
  const usersList = useAppSelector((state) => state.users.users);

  const bonusesData = {
    [BonusStatuses.GENERATED]: useAppSelector((state) => state.bonus.generatedBonuses),
    [BonusStatuses.AWARDED]: useAppSelector((state) => state.bonus.awardedBonuses),
    [BonusStatuses.PAID]: useAppSelector((state) => state.bonus.paidBonuses),
    [BonusStatuses.SUSPENDED]: useAppSelector((state) => state.bonus.suspendedBonuses),
  };

  useEffect(() => {
    dispatch(bonusThunk.fetchBonusesByStatus(BonusStatuses.GENERATED));
    dispatch(bonusThunk.fetchBonusesByStatus(BonusStatuses.AWARDED));
    dispatch(bonusThunk.fetchBonusesByStatus(BonusStatuses.PAID));
    dispatch(bonusThunk.fetchBonusesByStatus(BonusStatuses.SUSPENDED));
  }, []);

  const usersIds = usersList.map(user => user.user_id);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const buttonsActions = {
    generateBonuses: () => {
      if (usersIds.length && managerId) {
        dispatch(bonusThunk.generateBonusesForPoints({
          beneficiaryUserIds: usersIds, 
          generatingUserId: managerId
        }));
      } else {
        console.error("Nie mozna wygenerować płatności");
      }
    },

    awardBonuses: () => {
      dispatch(bonusThunk.updateMultipleStatuses({ 
        updatingUserId: managerId as number, 
        newStatus: BonusStatuses.AWARDED,
        beneficiaryUserIds: selectedRowKeys as number[]
      }));

      setSelectedRowKeys([]);
    },
    payBonuses: () => {
      dispatch(bonusThunk.updateMultipleStatuses({ 
        updatingUserId: managerId as number, 
        newStatus: BonusStatuses.PAID,
        beneficiaryUserIds: selectedRowKeys as number[]
      }));

      setSelectedRowKeys([]);
    },
    downloadGeneralReport: () => {
      const filterOptions = {
        bonusStatus: selectedTable,
      };

      dispatch(reportThunk.postReportsData({ filterOptions }));

      setSelectedRowKeys([]);
    }
  };

  const handleButtonsActivity = () => {
    setAreButtonsActive(true);
  }

  const subTablesData = {
    [BonusStatuses.GENERATED]: {
      label: "Oczekujące",
      header: "Oczekujące premie",
      headerAction: buttonsActions.awardBonuses,
      emptyContent: "Kiedy pracownicy zgłoszą nowe sugestie, premie pojawią się tutaj.",
      emptyAction: (
        <Button
        type="primary"
        icon={<Icon type={IconTypes.SUGGESTION_BOX} />} 
        onClick={buttonsActions.generateBonuses}
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Generuj premie
      </Button>
      ),
    },
    [BonusStatuses.AWARDED]: {
      label: "Przyznane",
      header: "Przyznane premie",
      headerAction: buttonsActions.payBonuses,
      emptyContent: "Nie ma jeszcze żadnych przyznanych premii. Przyznaj oczekujące premie.",
      emptyAction: (
        <Button
          type="primary"
          icon={<Icon type={IconTypes.SUGGESTION_BOX} />} 
          onClick={() => setSelectedTable(BonusStatuses.GENERATED)}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Przyznaj premie
        </Button>
      ),
    },
    [BonusStatuses.PAID]: {
      label: "Wypłacone",
      header: "Wypłacone premie",
      headerAction: () => console.log("pay"),
      emptyContent: "Nie ma jeszcze żadnych wypłaconych premii. Wypłać przyznane premie.",
      emptyAction: (
        <Button
          type="primary"
          icon={<Icon type={IconTypes.SUGGESTION_BOX} />} 
          onClick={() => setSelectedTable(BonusStatuses.AWARDED)}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Wypłać premie
        </Button>
      ),
    },
    [BonusStatuses.SUSPENDED]: {
      label: "Oczekujące",
      header: "Oczekujące premie",
      headerAction: buttonsActions.awardBonuses,
      emptyContent: "Kiedy pracownicy zgłoszą nowe sugestie, premie pojawią się tutaj.",
      emptyAction: null,
    }
  };
  
  const items: MenuProps['items'] = [
    getItem(
      subTablesData[BonusStatuses.GENERATED].label, 
      BonusStatuses.GENERATED
      ), { type: 'divider' },
    getItem(
      subTablesData[BonusStatuses.AWARDED].label, 
      BonusStatuses.AWARDED
      ), { type: 'divider' },
    getItem(
      subTablesData[BonusStatuses.PAID].label, 
      BonusStatuses.PAID
    ),
  ];

  const bonusData: IBonusManagerData[] = bonusesData[selectedTable].reduce((acc: any, bonus: any, index: number) => {
    const theLastHistoryRecord = bonus.bonus_history[bonus.bonus_history.length - 1];
    const beneficiaryId = bonus.beneficiary_user_id;

    if (!acc[beneficiaryId]) {
        acc[beneficiaryId] = {
            key: bonus.beneficiary_user_id,
            number: 0,
            updateDate: new Date(theLastHistoryRecord.date),
            points: 0,
            amountGros: 0,
            amountNet: 0,
            worker: {
                id: bonus.beneficiary_user_id,
                name: bonus.beneficiary_user.name,
                surname: bonus.beneficiary_user.surname
            },
            details: [],
            workerMobile: {
              id: bonus.beneficiary_user_id,
              name: bonus.beneficiary_user.name,
              surname: bonus.beneficiary_user.surname,
              amountGros: 0,
              amountNet: 0
            },
            pointsAndDateMobile: {
              points: 0,
              updateDate: new Date(theLastHistoryRecord.date),
            }
        };
    }

    const amount = bonus.bonus_amount;
    const amountType = bonus.point.amount_type;

    if (amountType === 'gross') {
        acc[beneficiaryId].amountGros += amount;
        acc[beneficiaryId].workerMobile.amountGros += amount;
    } else if (amountType === 'net') {
        acc[beneficiaryId].amountNet += amount;
        acc[beneficiaryId].workerMobile.amountNet += amount;
    }

    acc[beneficiaryId].details.push({
        key: bonus.bonus_id,
        number: index,
        updateDate: new Date(theLastHistoryRecord.date),
        points: bonus.point.value,
        amount: amount,
        worker: {
          id: bonus.beneficiary_user_id,
          name: bonus.beneficiary_user.name,
          surname: bonus.beneficiary_user.surname,
        },
        amountType: amountType,
    });

    acc[beneficiaryId].points += bonus.point.value;
    acc[beneficiaryId].pointsAndDateMobile.points += bonus.point.value;

    acc[beneficiaryId].number++;

    return acc;
  }, {});

  const summarizedBonusData = Object.values(bonusData);

  const filteredData = summarizedBonusData.filter((record) => {
    const fullName = `${record.worker.name} ${record.worker.surname}`;
    return fullName.toLowerCase().includes(searchText.toLowerCase());
  });

  const bonusColumns: ColumnsType<IBonusManagerData> = [
    {
      title: 'Nr',
      dataIndex: "number",
      render: (number: number, record: IBonusManagerData, index: number) => `${index + 1}.`,
      responsive: ['lg'],
    },
    {
      title: 'Pracownik',
      dataIndex: 'worker',
      render: (worker: { name: string, surname: string }) => {
        return <span>{worker.name} {worker.surname}</span>;
      },
      responsive: ['sm']
    },
    {
      title: 'Pacownik',
      dataIndex: 'workerMobile',
      render: (workerMobile: { name: string, surname: string, amountGros: number, amountNet: number }) => {
        return (
          <div className={styles["worker-mobile"]}>
            <span className={styles["worker-mobile__credentials"]}>{workerMobile.name} {workerMobile.surname}</span>
            {workerMobile.amountGros !== 0 && (<span className={styles["worker-mobile__amount"]}>{`${workerMobile.amountGros} zł brutto`}</span>)}
            {workerMobile.amountNet !== 0 && (<span className={styles["worker-mobile__amount"]}>{`${workerMobile.amountNet} zł netto`}</span>)}
          </div>
        );
      },
      responsive: ['xs']
    },
    {
      title: 'Punkty',
      dataIndex: 'points',
      render: (points: number) => `${points} pkt.`,
      responsive: ['sm']
    },
    summarizedBonusData.find(bonusRecord => bonusRecord.amountGros > 0) ? {
      title: 'Kwota brutto',
      dataIndex: 'amountGros',
      render: (amountGros: number) => `${amountGros} zł`,
      responsive: ['sm']
    } : {},
    summarizedBonusData.find(bonusRecord => bonusRecord.amountNet > 0) ? {
      title: 'Kwota netto',
      dataIndex: 'amountNet',
      render: (amountNet: number) => `${amountNet} zł`,
      responsive: ['sm']
    } : {},
    {
      title: 'Data aktualizacji',
      dataIndex: 'updateDate',
      render: (updateDate: Date) => {
        const bonusDate = new Date(updateDate);
  
        const dateOptions: Intl.DateTimeFormatOptions = { 
          day: 'numeric', month: 'long', year: 'numeric' 
        };
  
        return `${bonusDate.toLocaleDateString('pl-PL', dateOptions)}`;
      },
      responsive: ['sm']
    },
    {
      title: 'Data',
      dataIndex: 'pointsAndDateMobile',
      render: (pointsAndDateMobile: { updateDate: Date, points: number }) => {
        const bonusDate = new Date(pointsAndDateMobile.updateDate);
  
        const dateOptions: Intl.DateTimeFormatOptions = { 
          day: 'numeric', month: 'long', year: 'numeric' 
        };
  
        return (
          <div className={styles["points-mobile"]}>
            <span className={styles["points-mobile__date"]}>{bonusDate.toLocaleDateString('pl-PL', dateOptions)}</span>
            <span className={styles["points-mobile__points"]}>{pointsAndDateMobile.points} pkt.</span>
          </div>
        );
      },
      responsive: ['xs']
    },
    selectedTable === BonusStatuses.PAID 
      ? Table.EXPAND_COLUMN
      : {},
    areButtonsActive && selectedTable !== BonusStatuses.PAID 
      ? Table.SELECTION_COLUMN 
      : {},
  ];

  const bonusOnChange: TableProps<IBonusManagerData>['onChange'] = 
    (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onBonusActionsClean = () => {
    setAreButtonsActive(false);
  }

  const tableMenuOnClick: MenuProps['onClick'] = (e) => {
    setSelectedTable(e.key as BonusStatuses);
  };

  const disableDownloadButton: boolean = 
    (Boolean(!bonusesData[selectedTable].length) 
    && (!areButtonsActive && selectedTable !== BonusStatuses.PAID));

  return (
    <div className={styles["content-section"]}>
      <div className={styles["extended-table"]}>
        <Menu
          className={styles["table-navigation"]}
          onClick={tableMenuOnClick}
          defaultSelectedKeys={[selectedTable]}
          defaultOpenKeys={[selectedTable]}
          selectedKeys={[selectedTable]}
          mode="inline"
          items={items}
          style={{
            borderRadius: 4,
            margin: 0,
            padding: 0,
          }}
        />
        <Table 
          columns={bonusColumns} 
          dataSource={filteredData}
          onChange={bonusOnChange} 
          rowSelection={(areButtonsActive && selectedTable !== BonusStatuses.PAID) ? { 
            selectedRowKeys, 
            onChange: onSelectChange,
          } : undefined}
          expandable={selectedTable === BonusStatuses.PAID ? {
            expandedRowRender: (record) => record.details.map(
              bonus => {
                const bonusDate = new Date(bonus.updateDate);
  
                const dateOptions: Intl.DateTimeFormatOptions = { 
                  day: 'numeric', month: 'long', year: 'numeric' 
                };

                return (
                  <div className={styles["bonus-details"]}>
                    <div className={styles["worker-mobile"]}>
                      <span className={styles["worker-mobile__credentials"]}>{bonus.worker.name} {bonus.worker.surname}</span>
                      <span className={styles["worker-mobile__amount"]}>{bonus.amount} zł {bonus.amountType}</span>
                    </div>
                    <div className={styles["points-mobile"]}>
                      <span className={styles["points-mobile__date"]}>{bonusDate.toLocaleDateString('pl-PL', dateOptions)}</span>
                      <span className={styles["points-mobile__points"]}>{bonus.points} pkt.</span>
                    </div>

                    <div className={styles["bonus-details__desktop"]}>
                      <span className={styles["details-item"]}>{bonus.worker.name} {bonus.worker.surname}</span>
                      <span className={styles["details-item"]}>{bonus.points} pkt.</span>
                      <span className={styles["details-item"]}>{bonus.amount} zł {bonus.amountType === "gross" ? "brutto" : "netto"}</span>
                      
                      <span className={styles["details-item"]}>{bonusDate.toLocaleDateString('pl-PL', dateOptions)}</span>
                    </div>
                  </div>
                );
              }
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              <div className={styles["expand-icon"]} onClick={e => onExpand(record, e)}>
                <Icon type={expanded 
                  ? IconTypes.CHEVRON_LEFT 
                  : IconTypes.CHEVRON_RIGHT} 
                />
              </div>
          } : undefined}       
          style={{
            flexGrow: 1
          }}
          pagination={{ 
            showSizeChanger: false,
            pageSize: 5, 
            position: ["bottomCenter"], 
            prevIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <Icon type={IconTypes.CHEVRON_LEFT} />
                <span>Poprzednia</span>
              </Button>), 
            nextIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <span>Następna</span>
                <Icon type={IconTypes.CHEVRON_RIGHT} />
              </Button>)
              }}
          showSorterTooltip={false}
          locale={{ 
            emptyText() {
              return (
                <>
                  <p className={styles["table-empty"]}>
                    {subTablesData[selectedTable].emptyContent}
                  </p>
                  {subTablesData[selectedTable].emptyAction}
                </>
              )
            },
          }}
          title={() => (
            <div className={styles["table-header"]}>
              <Title 
                level={3} 
                style={{ 
                  marginBottom: 0, 
                  fontSize: 24, 
                  fontWeight: 700
                }}>
                  {subTablesData[selectedTable].header}
              </Title>
              {/* <Divider style={{ borderBlockStart: "2px solid #9CCEFC", margin: "4px 0 0 0" }} /> */}
              <div className={styles["table-search"]}>
                <Search 
                  placeholder="Wyszukaj..." 
                  enterButton
                  allowClear
                  onSearch={handleSearch}
                />
              </div>
              <div className={styles["table-actions"]}>
                <Button
                  type={areButtonsActive ? "primary" : "default"}
                  disabled={disableDownloadButton}
                  className={styles["action-button"]}
                  onClick={buttonsActions.downloadGeneralReport}
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                 >
                  <span>Pobierz</span>
                  <Icon type={
                    disableDownloadButton
                    ? IconTypes.DOWNLOAD 
                    : areButtonsActive 
                      ? IconTypes.DOWNLOAD_WHITE
                      : IconTypes.DOWNLOAD_VIOLET
                  }/>
                </Button>
                {selectedTable !== BonusStatuses.PAID && <Button
                  onClick={areButtonsActive ? subTablesData[selectedTable].headerAction : handleButtonsActivity}          
                  type={areButtonsActive ? "primary" : "default"}
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={styles["action-button"]}
                >
                  <Icon type={
                    areButtonsActive 
                    ? IconTypes.CHECK_WHITE 
                    : IconTypes.CHECK_VIOLET
                  }/>
                  <span>Zatwierdź</span>
                </Button>}
                {areButtonsActive && 
                  <Button 
                    className={styles["actions-cleaner"]} 
                    onClick={onBonusActionsClean}
                    style={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1.5px solid #8391AD",
                      fontSize: "1rem",
                    }}
                  >
                    <Icon type={IconTypes.BIN} />
                    {" "}
                    <span>Wyczyść</span>
                  </Button>}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default BonusManagerTable;
