import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";

const { DOWNLOAD, GUIDE, PROGRAM } = ServerPaths;

export const getInstructionFile = () =>
  axiosInstance.get(`${DOWNLOAD}${GUIDE}`);

export const getProgramFile = () => axiosInstance.get(`${DOWNLOAD}${PROGRAM}`);
