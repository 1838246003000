import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import styles from "./MainAuthPage.module.scss";
import {
  CreactionAuthLogo,
  LightBallLogo,
  // MobileCreActionIcon,
} from "../../../assets/icons";

import { applicationSettingsActions } from "../../../__store/slices";
import { authData } from "../../../data/constants";
import { useClientLogo } from "../../../hooks/useClientLogo";

// import { CLIENT_NAME } from "../../../data/config/globalContentVariables.data";

interface MainAuthPageProps<T> {
  form: T;
  renderItem: (form: T) => React.ReactNode;
}

// const Logo =
//   require(`../../../assets/images/${CLIENT_NAME}/logo-1.png`).default;

export default function MainAuthPage<T>(props: MainAuthPageProps<T>) {
  const { form, renderItem } = props;

  const dispatch = useAppDispatch();

  const { Logo, logoClassName } = useClientLogo();

  useEffect(() => {
    dispatch(applicationSettingsActions.getApplicationSettings());
  }, []);

  return (
    <div className={styles["page"]}>
      <div className={styles["page__logos-container"]}>
        <CreactionAuthLogo data-testid="creaction-auth-logo" />
        <LightBallLogo data-testid="light-ball-logo" />
      </div>
      <div className={styles["page__forms-container"]}>
        {/* <MobileCreActionIcon
          className={styles["page__forms-container__mobile-icon"]}
          data-testid="mobile-creaction-icon"
        /> */}
        <img
          src={Logo}
          alt={authData.LOGO_ALT_DESC}
          className={styles[logoClassName]}
          data-testid="main-auth-page-logo"
        />
        {renderItem(form)}
      </div>
    </div>
  );
}
