import React, { ReactElement, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button, Icon, SubmitButton } from "../../components/ui";

import styles from "./EditDepartmentForm.module.scss";

import { structureData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";

const {
  DEPARTMENT_NAME_MAX_LENGTH,
  DEPARTMENT_NAME_MIN_LENGTH,
  departmentInputNames,
  departmentMessages,
  editDepartmentButtons,
  editDepartmentLabels,
} = structureData;

interface EditDepartmentFormValues {
  name: string;
  area_id: number;
}

function EditDepartmentForm(): ReactElement {
  const dispatch = useAppDispatch();
  
  const selectedDepartment = useAppSelector(
    (state) => state.departments.selectedDepartment
  );

  const areasList = useAppSelector(
    (state) => state.departments.areas
  );

  const departmentsList = useAppSelector(
    (state) => state.departments.departments
  );

  const actualDepartment = departmentsList.find(department => department.department_id === selectedDepartment);
  const actualArea = actualDepartment?.area_id;

  const [formValues, setFormValues] = useState<EditDepartmentFormValues>({
    name: actualDepartment?.name || "",
    area_id: -1
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function onSubmitHandler(event: React.FormEvent) {
    event.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (formValues.name.length < DEPARTMENT_NAME_MIN_LENGTH) {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_TOO_SHORT;
    } else if (formValues.name.length > DEPARTMENT_NAME_MAX_LENGTH) {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_TOO_LONG;
    } else if (formValues.name.trim() === "") {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_REQUIRED;
    }

    if (Object.keys(newErrors).length === 0) {
      dispatch(
        departmentThunk.editDepartment({
          name: formValues.name.trim(),
          department_id: selectedDepartment,
          area_id: formValues.area_id !== -1 ? +formValues.area_id : actualArea
        })
      );
    } else {
      setErrors(newErrors);
    }
  }

  function onCancelHandler() {
    dispatch(modalActions.closeModal());
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  return (
    <div className={styles["form"]}>
      <div className={styles["form__header"]}>
        <div className={styles["form__header__text"]}>
          <span>{editDepartmentLabels.TITLE}</span>
        </div>
        <div className={styles["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={onCancelHandler} />
        </div>
      </div>
      <form className={styles["form__body"]} onSubmit={onSubmitHandler}>
        <div className={styles["form-group"]}>
          <input
            type="text"
            name={departmentInputNames.NAME}
            placeholder={editDepartmentLabels.NAME}
            className={styles["form-group__input"]}
            value={formValues.name}
            onChange={handleInputChange}
          />
          {errors[departmentInputNames.NAME] && (
            <div className={styles["form-group__error"]}>
              {errors[departmentInputNames.NAME]}
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <select
            name={departmentInputNames.AREA_ID}
            className={styles["form-group__select"]}
            value={formValues.area_id}
            onChange={handleInputChange}
          >
            <option value="">{editDepartmentLabels.AREA}</option>
            {areasList.map((area) => (
              <option
                key={area.area_id}
                value={+area.area_id}
              >
                {area.area_name}
              </option>
            ))}
          </select>
          {errors[departmentInputNames.AREA_ID] && (
            <div className={styles["form-group__error"]}>
              {errors[departmentInputNames.AREA_ID]}
            </div>
          )}
        </div>

        <div className={styles["form-buttons"]}>
          <Button
            textValue={editDepartmentButtons.CANCEL_BUTTON}
            buttonClassName="cancel"
            buttonFunction={onCancelHandler}
            hiddenStyles=""
            type="button"
          />
          <SubmitButton
            buttonClassName="submit-button"
            value={editDepartmentButtons.SEND_BUTTON}
          />
        </div>
      </form>
    </div>
  );
}

export default EditDepartmentForm;
