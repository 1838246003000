import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router";

import { useAppDispatch } from "../../../__store/tools/hooks";

import { 
  authActions, 
  departmentActions, 
  formsActions, 
  helpersActions,
  suggestionActions, 
  rankingActions, 
  usersActions, 
} from "../../../__store/slices";

import { RoutesPaths } from "../../../data/config";

import styles from "./LogoutPage.module.scss";

const { ROOT_PATH } = RoutesPaths;

const LogoutPage = ():ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      dispatch(authActions.clearAuthStage());
      dispatch(suggestionActions.setSuggestionInitalState());
      dispatch(departmentActions.setDepartmentInitialState());
      dispatch(rankingActions.setRankingInitialState());
      dispatch(formsActions.setFormInitialState());
      dispatch(usersActions.setUserInitialState());
      dispatch(helpersActions.setInitialState());
      navigate(ROOT_PATH);
  }, [navigate, dispatch]);

  return (
    <div className={styles["logout"]} >
      <div className={styles["loader"]}></div>
    </div>
  );
};

export default LogoutPage;