import { ReactElement, useState } from 'react'

import styled from "../UpdateUserForm.module.scss";
import { Radio, Space, Switch, Tag } from "antd";

import { activationData } from '../../../../data/constants';
import { IActivationBoxTags } from './activation.types';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UserRoles } from '../../../../data/config';

const { 
  ACTIVATION_MESSAGES, 
  ActivationTags, 
  EmploymentStatus 
} = activationData;

export const ActivationBoxTags = ({
  userRole,
  printedUserRole,
  isActuallyActive,
  willBeActive,
  handleChangeAccountActivity,
  actualEmploymentStatus,
  targetEmploymentStatus,
  handleChangeEmploymentStatus,
}:IActivationBoxTags):ReactElement => {

  const startChoice = isActuallyActive 
    ? ActivationTags.ACTIVE
    : actualEmploymentStatus === EmploymentStatus.FIRED 
      ? ActivationTags.FIRED 
      : ActivationTags.INACTIVE;

  const [activationChoice, setActivationChoice] = useState(startChoice);

  const handleActivationChoice = (e: RadioChangeEvent) => {
    setActivationChoice(e.target.value);
    
    if (e.target.value === ActivationTags.ACTIVE) {
      handleChangeAccountActivity(true);
      handleChangeEmploymentStatus(EmploymentStatus.WORKING);
    } else if (e.target.value === ActivationTags.FIRED) {
      handleChangeAccountActivity(false);
      handleChangeEmploymentStatus(EmploymentStatus.FIRED);
    } else {
      handleChangeAccountActivity(false);
      handleChangeEmploymentStatus(EmploymentStatus.SICKNESS);
    }
  };

  return (
    <div className={styled["activation-box__tags"]}>

      <Tag color="blue">{printedUserRole}</Tag>

      <Radio.Group 
        onChange={handleActivationChoice} 
        value={activationChoice}
        disabled={userRole === UserRoles.ADMIN}
      >
        <Space direction="vertical">
          <Radio value={ActivationTags.ACTIVE}>{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Radio>
          <Radio value={ActivationTags.INACTIVE}>{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Radio>
          <Radio value={ActivationTags.FIRED}>{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Radio>
        </Space>

      </Radio.Group>

      {willBeActive
        ? (<Tag color="green">{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Tag>) 
        : targetEmploymentStatus === EmploymentStatus.FIRED
          ? (<Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Tag>)
          : (<Tag color="orange">{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Tag>)
        }
    </div>
  )
};


export default ActivationBoxTags;
