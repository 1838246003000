import { ReactElement, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";

import { suggestionThunk, departmentThunk, rankingThunk } from "../../../__store/thunks";
import { DashboardStatusCard, DashboardAllStatsInfo, DashboardSmallRanking } from ".";

import "antd/dist/reset.css";
import { Collapse } from "antd";

import { Notification, Icon } from "../../components/ui";

import styles from "./YourProfileDashboard.module.scss";

import { UserRoles, MainStatuses, IconTypes } from "../../../data/config";

import { profileData } from "../../../data/constants";
const { 
  NOTIFICATIONS_HEADER, 
  NOTIFICATIONS_REST_MESSAGE, 
  yourProfileDashboardBossLabels
} = profileData;

const YourProfileDashboard = ():ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(suggestionThunk.getMySuggestions());
    dispatch(departmentThunk.getDepartmentData());
    dispatch(suggestionThunk.getSuggestionsInfo());
    dispatch(rankingThunk.fetchRanking());
  }, []);

  const [restArrays, setRestArray] = useState<{
    restNotifications: any;
  }>({
    restNotifications: [],
  });

  const userRole = useAppSelector((state) => state.auth.role);
  const userLogin = useAppSelector((state) => state.auth.login);
  const smallRanking = useAppSelector((state) => state.ranking.smallRanking);
  const mySuggestions = useAppSelector((state) => state.suggestions.mySuggestions);

  const mySuggestionsCount = useAppSelector((state) => state.suggestions.mySuggestionsCount);
  const stretch =
    userRole === UserRoles.WORKER || userRole === UserRoles.ADMIN ? "stretch" : "";

  const unreadSuggestions = mySuggestions.filter(
    (suggestion) =>
      !suggestion.is_read &&
      suggestion.status !== MainStatuses.REPORT &&
      suggestion.status !== MainStatuses.APPEALED &&
      suggestion.status !== MainStatuses.CREATION
  );

  const renderNotifications = () => {
    return unreadSuggestions.map((suggestion, index) =>
      index < 6 ? (
        <Notification
          key={index}
          hide={index >= 3}
          suggestion_id={suggestion.suggestion_id}
          status={suggestion.status}
          hidePosition={index}
        />
      ) : null
    );
  };

  const renderRestNotifications = () => {
    return restArrays.restNotifications.map((suggestion: any, index: any) => (
      <Notification
        key={index}
        hide={index >= 3}
        suggestion_id={suggestion.suggestion_id}
        status={suggestion.status}
        hidePosition={index}
      />
    ));
  };

  useEffect(() => {
    setRestArray({
      restNotifications: unreadSuggestions.slice(6),
    });

    dispatch(suggestionThunk.getMySuggestions());
    dispatch(suggestionThunk.getSuggestionsInfo());
  }, []);

  const statusCards: JSX.Element[] = [
    { name: "Nowe", icon: <Icon type={IconTypes.ARROW} /> },
    { name: "Weryfikacja", icon: <Icon type={IconTypes.MESSAGE} /> },
    { name: "Odwołane", icon: <Icon type={IconTypes.THUMB} /> },
    { name: "W trakcie wdrożenia", icon: <Icon type={IconTypes.WAIT} /> },
    { name: "Zrealizowane", icon: <Icon type={IconTypes.CIRCLE} /> },
    { name: "Odrzucone", icon: <Icon type={IconTypes.CANCEL} /> },
  ].map((status) => (
    <DashboardStatusCard
      key={status.name}
      value={status.name}
      icon={status.icon}
    />
  ));

  const { Panel } = Collapse;

  return (
    <div className={styles["your-proile"]}>
      <div
        className={`${styles["your-profile__your-stats-container"]} ${styles[stretch]}`}
      >
        <div
          className={`${styles["your-profile__your-stats__notifications"]} ${
            unreadSuggestions.length > 2 && styles["between"]
          } ${unreadSuggestions.length === 0 && styles["hide"]}`}
        >
          {renderNotifications().length > 0 ? (
            <Collapse
              className={`${styles["your-profile__notification-container"]}`}
            >
              <Panel header={NOTIFICATIONS_HEADER} key="1" className={`${styles["panel"]}`}>
                {renderNotifications()}

                {renderRestNotifications().length > 0 ? (
                  <Collapse>
                    <Panel
                      header={NOTIFICATIONS_REST_MESSAGE}
                      key="2"
                      className={`${styles["panel"]}`}
                    >
                      {renderRestNotifications()}
                    </Panel>
                  </Collapse>
                ) : (
                  <></>
                )}
              </Panel>
            </Collapse>
          ) : (
            <></>
          )}
        </div>
        <DashboardSmallRanking users={smallRanking} login={userLogin} />
        <div className={styles["your-profile__your-stats"]}>
          <h3 className={styles["your-profile__your-stats__header"]}>
            Statystyki
          </h3>
          <div className={styles["your-profile__global-stats"]}>
            {userRole === UserRoles.BOSS ? (
              <div className={styles["boss-profile"]}>
                <DashboardStatusCard
                  value={yourProfileDashboardBossLabels.REALIZATION}
                  icon={<Icon type="wait" />}
                  isBossSuggestion={true}  
                />
                <DashboardStatusCard
                  value={yourProfileDashboardBossLabels.CREATION}
                  icon={<Icon type="circle" />}
                  isBossSuggestion={true}  
                />
                <div className={styles["all-suggestion"]}>
                  <p className={styles["all-suggestion__header"]}>
                    {yourProfileDashboardBossLabels.ALL}
                  </p>
                  <p className={styles["all-suggestion__amount"]}>
                    {mySuggestionsCount.all}
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className={styles["your-profile__list"]}>
                  {statusCards}
                </div>
                <div className={styles["all"]}>
                  <DashboardAllStatsInfo isSupperVisor={false} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourProfileDashboard;
