import styles from "./AccessDenied.module.scss";
import { useAppSelector } from "../../../__store/tools/hooks";

import { authData } from "../../../data/constants";
const { ACCESS_DENIED_HEADER } = authData;

const AccessDenied = () => {
  const { loading } = useAppSelector((state) => state.applicationSettings);

  return (
    <div className={styles["access-denied"]}>
      {loading ? (
        <div className={styles["loader"]}></div>
      ) : (
        <h1>{ACCESS_DENIED_HEADER}</h1>
      )}
    </div>
  );
};

export default AccessDenied;
