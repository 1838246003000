import { ReactElement, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions, suggestionActions } from '../../../__store/slices';

import { Button, InputField } from "../../components/ui/";

import styles from "./TopBar.module.scss";

import { 
  ModalClassnames, 
  RoutesPaths, 
  UserRoles, 
  userRoles 
} from '../../../data/config';

import { InputTypes, suggestionsData } from '../../../data/constants';

const { SUGGESTION_FORM } = ModalClassnames;
const { ManagerRoles } = userRoles;
const { topBarSuggestionsHeaders, topBarElementsDescriptions} = suggestionsData;

const { PROFILE, SUGGESTIONS } = RoutesPaths;

function TopBar() : ReactElement {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const sendingSuggestion = useAppSelector((state) => state.suggestions.sendingSuggestion);
  const userRole = useAppSelector((state) => state.auth.role);
  const searchPlaceholder = useAppSelector((state) => state.suggestions.searchPlaceholder);
  const searchValue = useAppSelector((state) => state.suggestions.searchValue);

  useEffect(() => {
    dispatch(suggestionActions.setSearchPlaceholder(topBarElementsDescriptions.search));
  }, []);

  const onFilterChange = (e: { currentTarget: { value: any; }; }) => {
    dispatch(suggestionActions.setSearchPlaceholder(""));
    dispatch(suggestionActions.setSearchValue(e.currentTarget.value));
    dispatch(suggestionActions.setFilterQuery(e.currentTarget.value));
    if (e.currentTarget.value === "") dispatch(suggestionActions.setSearchPlaceholder(topBarElementsDescriptions.search));
  };

  const TopBarHeader = ({pathname} : {pathname: string}) : ReactElement => {
    let headerDescription;

    switch(pathname) {
      case SUGGESTIONS.MY_SUGGESTIONS_PATH: 
        headerDescription = topBarSuggestionsHeaders.MySuggestionsHeader; 
        break;
      case SUGGESTIONS.WORKER_SUGGESTIONS_PATH: 
        headerDescription = userRole === UserRoles.BOSS 
          ? topBarSuggestionsHeaders.WorkerSuggestionsHeaderForBoss 
          : topBarSuggestionsHeaders.WorkerSuggestionsHeader; 
        break;
      case SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH: 
        headerDescription = topBarSuggestionsHeaders.DepartmentSuggestionsHeader; 
        break;
      case SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH:
        headerDescription = topBarSuggestionsHeaders.OtherDepartmentsSuggestionsHeader;
        break;
      case SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH: 
        headerDescription = topBarSuggestionsHeaders.AllSuggestionsPreviewHeader; 
        break;
      default: 
      topBarSuggestionsHeaders.MySuggestionsHeader; 
        break;
    }

    return (
      <div className={styles["top-bar__top__left"]}>
        <p className={`${styles["header"]}`}>
          {headerDescription}
        </p>
      </div>

    )
  }

  return (
    <div className={`${styles["top-bar"]}`}>
      <div className={styles["top-bar__top"]}>
        <TopBarHeader pathname={location.pathname} />
        <InputField
          search
          name="search"
          placeholder={searchPlaceholder}
          inputClassName="search"
          value={searchValue}
          type={InputTypes.TEXT}
          hiddenStyles=""
          onChangeHandler={onFilterChange}
        />

        <div className={`${styles["top-bar__top__right"]} `}>
          <Link to={SUGGESTIONS.CREATE_SUGGESTION_PATH}>
            <Button
              buttonClassName={
                (Object.values(ManagerRoles).includes(userRole as userRoles.ManagerRoles))
                &&
                (Object.values(PROFILE).includes(location.pathname))
                  ? "send-suggestion"
                  : "button"
              }
              hiddenStyles=""
              disabled={sendingSuggestion}
              textValue={topBarElementsDescriptions.addSuggestion}
              buttonFunction={() => console.log("click")}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
