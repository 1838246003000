import { ReactElement } from 'react';
import { useAppDispatch } from '../../../__store/tools/hooks';
import { modalActions } from '../../../__store/slices';

import styles from "./AttachmentComponent.module.scss";

import Icon from './Icon';

import { IconTypes } from '../../../data/config';
import { ALLOWED_IMAGE_EXTENSIONS } from '../../../data/constants/suggestions.data';

export interface IAttachmentViewComponent {
  attachmentsData: any[];
  description?: string;
  header?: string;
};

export interface IAttachmentPreviewHandler {
  attachmentPath: string;
  attachmentDescription: string;
  attachmentName: string;
};

import { CLIENT_NAME } from "../../../data/config/globalContentVariables.data";

const AttachmentComponent = ({ 
  attachmentsData,
  description 
}:
  IAttachmentViewComponent):ReactElement => {  
  const dispatch = useAppDispatch();
  
  const validateFilePath = (path: string) => ALLOWED_IMAGE_EXTENSIONS.some(extension => path.includes(extension));

  const handleAttachmentPreview = ({ attachmentPath, attachmentDescription, attachmentName } : IAttachmentPreviewHandler) => {
    const path = CLIENT_NAME === "dimograf" ? `/api/${attachmentPath}` : attachmentPath;
    dispatch(modalActions.setAttachmentPath(path));
    description && dispatch(modalActions.setAttachmentDescription(`${attachmentDescription} - ${attachmentName}`));
    dispatch(modalActions.openAttachmentModal());
  }

  return (
    <div className={styles["attachment-component__box"]}>
      {attachmentsData.map((attachment, index) => {
        const attachmentData = { 
          attachmentPath: attachment.attachment_path, 
          attachmentDescription: description || "", 
          attachmentName: attachment.attachment_name
        };

        return (
          <div className={styles["files"]} key={attachment}>
            {validateFilePath(attachment.attachment_path) ?
              (<Icon 
                type={IconTypes.ATTACH} 
                className={styles["icon"]} 
                onClick={() => handleAttachmentPreview(attachmentData)} 
              />
              ) : (
                <a href={attachment} download={attachment.attachment_path} target='__blank'>
                  <Icon type={IconTypes.ATTACH} className={styles["icon"]} />
                </a>
              )}
            <span className={styles["files__filename"]}>
              {attachment.attachment_name}
            </span>
            {validateFilePath(attachment.attachment_path) ?
              (<Icon 
                  type={IconTypes.PREVIEW_EYE_ICON} 
                  className={styles["icon"]} 
                  onClick={() => handleAttachmentPreview(attachmentData)} 
                />
              ) : (
                <a href={CLIENT_NAME === "dimograf" ? `/api/${attachment}` : attachment} download={attachment.attachment_name} target='__blank'>
                  <Icon type={IconTypes.PREVIEW_EYE_ICON} className={styles["icon"]} />
                </a>
              )}
          </div>
        );
      })}
    </div>
  )
}

export default AttachmentComponent;
