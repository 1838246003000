import { ReactElement } from 'react';
import styled from "../UpdateUserForm.module.scss";

import { Switch, Tag } from 'antd';

import { activationData } from '../../../../data/constants';
import { IActivationCheckbox } from './activation.types';

const { ACTIVATION_MESSAGES } = activationData;

const ActivationSwitch = ({
  isDisabled,
  revertTempManager,
  handleRevertingTempManager,
}:IActivationCheckbox):ReactElement => {
  return (
    <span className={styled["activation-switch"]}>
      <strong className={styled["switch-label"]}>
        <Tag color="green">{ACTIVATION_MESSAGES.KEEP_TEMP_MANAGER}</Tag>
      </strong>
      <Switch 
        defaultChecked={revertTempManager} 
        onChange={handleRevertingTempManager} 
        disabled={isDisabled}
      />
      <strong className={styled["switch-label"]}>
        <Tag color="red">{ACTIVATION_MESSAGES.REVERT_TEMP_MANAGER}</Tag>
      </strong>
    </span>
  )
}

export default ActivationSwitch;
