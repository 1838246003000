enum ModalClassnames {
  ACCEPT_PAYMENTS_EMPTY = "accept-payments-empty",
  ACCEPT_PAYMENTS_FAIL = "accept-payments-fail",
  ACCEPT_PAYMENTS_SUCCESS = "accept-payments-success",
  ACCEPT_PAYMENT_EMPTY = "accept-payment-empty",
  ACCEPT_PAYMENT_SUCCESS = "accept-payment-success",
  ACCEPT_SUGGESTION_FORM = "accept-suggestion-form",
  ADDED_USER = "added-user",
  ADD_END_RESULT_FORM = "add-end-result",
  ADD_SUGGESTION_ERROR = "add-suggestion-error",
  ADD_SUGGESTION_SUCCESS = "add-suggestion-success",
  ADD_USER_ERROR = "add-user-error",
  ADD_USER_FORM = "add-user-form",
  APPEAL_SUGGESTION_FORM = "appeal-suggestion",
  AREA_INFO = "area-info",
  ASSESSMENT_INFO = "assessment-info",
  CREATE_ASSESSMENT = "create-assessment",
  CREATE_ASSESSMENT_ERROR = "create-assessment-error",
  CREATE_COMMENT_TO_EVALUATION = "create-comment-to-evaluation",
  CREATE_COMMENT_TO_EVALUATION_ERROR = "create-comment-to-evaluation--error",
  ADMIN_CHANGE_CREDENTIAL = "admin-change-credential",
  APPEALED_SUGGESTION = "appealed-suggestion",
  APPROVED_SUCCESS = "approved-success",
  APPROVED_SUGGESTION = "approved-suggestion",
  CANNOT_REMOVE_DEPARTMENT = "cannot-remove-department",
  CANNOT_REMOVE_AREA = "cannot-remove-area",
  CHANGE_LOGIN = "change-login",
  CHANGE_PASSWORD = "change-password",
  CLOSED_SUCCESS = "closed-success",
  CORRECTED_SUGGESTION = "corrected-suggestion",
  CORRECT_SUGGESTION_REALIZATION = "correct-suggestion-realization",
  COMMENTED_SUGGESTION = "commented-suggestion",
  CONFIRM_CREATION_FORM = "confirm-creation",
  WITHDRAWN = "withdrawn",
  CORRECT_SUGGESTION_FORM = "correct-suggestion-form",
  CREATE_AREA = "create-area",
  CREATE_AREA_FAIL = "create-area-fail",
  CREATE_AREA_SUCCESS = "create-area-success",
  CREATE_DEPARTMENT = "create-department",
  CREATE_DEPARTMENT_FAIL = "create-department-fail",
  CREATE_DEPARTMENT_SUCCESS = "create-department-success",
  CREATE_JOB_POSITION = "create-job-position",
  CREATE_JOB_POSITION_FAIL = "create-job-position-fail",
  CREATE_JOB_POSITION_SUCCESS = "create-job-position-success",
  DONE_PAYMENTS_EMPTY = "done-payments-empty",
  DONE_PAYMENTS_SUCCESS = "done-payments-success",
  DONE_PAYMENT_SUCCESS = "done-payment-success",
  DONE_SUGGESTION = "done-suggestion",
  EDIT_AREA = "edit-area",
  EDIT_AREA_FAIL = "edit-area-fail",
  EDIT_AREA_SUCCESS = "edit-area-success",
  EDIT_DEPARTMENT = "edit-department",
  EDIT_DEPARTMENT_FAIL = "edit-department-fail",
  EDIT_DEPARTMENT_SUCCESS = "edit-department-success",
  EDIT_JOB_POSITION = "edit-job-position",
  EDIT_JOB_POSITION_FAIL = "edit-job-position-fail",
  EDIT_JOB_POSITION_SUCCESS = "edit-job-position-success",
  END_RESULT = "end_result",
  END_RESULT_SUCCESS = "end-result-success",
  EVALUATE_APPEALED = "evaluate-appealed-appealed",
  EVALUATE_CLOSED = "evaluate-rejection-closed",
  EVALUATE_WITHDRAWN = "evaluate-report-withdrawn",
  EVALUATE_FORM = "evaluate-form",
  EVALUATE_IMPLEMENTED = "evaluate-realization-during_realization",
  EVALUATE_VERIFICATION = "evaluate-verification-submitted_for_verification",
  EVALUATE_REJECTED = "evaluate-rejection-revocable",
  EVALUATE_REDIRECTED = "evaluate-report-redirected",
  EXTERNAL_COMMENT_SUCCESS = "external-comment-success",
  EXTERNAL_COMMENT_FORM = "external-comment-form",
  GENERATE_PAYMENTS_FAIL = "generate-payments-fail",
  GENERATE_PAYMENT_EMPTY = "generate-payment-empty",
  GENERATE_PAYMENT_FAIL = "generate-payment-fail",
  GENERATE_PAYMENT_SUCCESS = "generate-payment-success",
  IMPLEMENTED_SUCCESS = "implemented-success",
  IMPLEMENTED_SUGGESTION = "implemented-suggestion",
  IMPLEMENT_SUGGESTION = "implement-suggestion",
  INTERNAL_COMMENT_REALIZATION_FORM = "internal-comment-realization",
  INTERNAL_COMMENT_VERIFICATION_FORM = "internal-comment-verification",
  LOADING = "loading",
  LOGIN_PAGE = "login-page",
  MAKE_ASSESSMENT = "make-assessment",
  NOTE_FORM = "note-form",
  PAYMENT_FAIL = "payment-fail",
  PAYMENT_INFO_MODAL = "payment-info-modal",
  REDIRECT_SUGGESTION_FORM = "redirect-suggestion-form",
  REJECTED_SUGGESTION = "rejected-suggestion",
  REJECT_PAYMENT_EMPTY = "reject-payment-empty",
  REJECT_PAYMENT_SUCCESS = "reject-payment-success",
  REJECT_SUGGESTION_FORM = "reject-suggestion-form",
  REJECT_CREATION_FORM = "reject-creation",
  REMOVED_USER = "removed-user",
  REMOVED_USER_ERROR = "remove-user-error",
  REMOVE_AREA = "remove-area",
  REMOVE_DEPARTMENT_POSITION = "remove-department-position",
  REMOVE_EVALUATING_USER_ERROR = "remove-evaluating-user-error",
  REMOVE_JOB_POSITION = "remove-job-position",
  REMOVE_USER = "remove-user",
  REPEAL_FORM = "repeal-form",
  SEND_TO_VERIFICATION_FORM = "send-to-verification",
  SPINNER_MODAL = "spinner-modal",
  SUGGESTION_FORM = "suggestion-form",
  SUGGESTION_INFO = "suggestion-info",
  SUGGESTION_INFO_RONLY = "suggestion-info-ronly",
  SUGGESTION_REDIRECTED = "suggestion_redirected",
  SUPERVISOR_NOTE_SUCCESS = "supervisor-note-success",
  UPDATED_MAIL_NOTIFICATIONS = "updated-mail-notifications",
  UPDATED_USER = "updated-user",
  UPDATE_USER_ERROR = "update-user-error",
  UPDATE_USER_FORM = "update-user-form",
  USER_ACTIVITY = "user-activity",
  USER_INFO = "user-info",
  WITHDRAW_POINTS = "withdraw-points",
  WITHDRAW_SUGGESTION_FORM = "withdraw-suggestion-form",
  REMOVE_VACATION_SUCCESS = "remove-vacation-success",
  REMOVE_VACATION_ERROR = "remove-vacation-error",
  CREATE_VACATION_SUCCESS = "create-vacation-success",
  CREATE_VACATION_ERROR = "create-vacation-error",
  UPDATE_VACATION_ERROR = "update-vacation-error",
  UPDATE_VACATION_SUCCESS = "update-vacation-success",
  REPORT_GENERATE_SUCCESS = "report-generate-success",
  REPORT_GENERATE_FAIL = "report-generate-fail",
  REPORT_GENERATE_EMPTY = "report-generate-empty"
}

export default ModalClassnames;
