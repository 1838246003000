const ThunkNames = {
  AUTH: {
    LOGIN_THUNK: "auth/login",
    CHECK_RESET_PASSWORD_THUNK: "auth/checkResetPasswordId",
    LOGOUT_THUNK: "auth/logout",
    MAIL_NOTIFICATION_THUNK: "auth/patchSendMailNotifications",
    RESET_PASSWORD_THUNK: "auth/reset-password",
    VERIFY_PASSWORD_THUNK: "auth/verify-password",
    RECOVERY_PASSWORD_THUNK: "auth/recovery",
    NEW_PASSWORD_THUNK: "auth/new-password",
    CHANGE_PASSWORD_THUNK: "auth/change-password",
    RULES_ACCEPTANCE_THUNK: "auth/rules-acceptance"
  },

  BONUS: {
    FETCH_ALL_BONUSES_THUNK: "bonus/fetch-all-bonuses",
    FETCH_BONUSES_USER_THUNK: "bonus/fetch-bonuses-of-user",
    FILTER_BONUSES_STATUS_THUNK: "bonus/fetch-bonuses-of-status",
    GENERATE_BONUSES_POINTS_THUNK: "bonus/generate-bonuses-for-points",
    UPDATE_BONUS_STATUS_THUNK: "bonus/update-bonus-status",
    UPDATE_MULTIPLE_STATUSES_THUNK: "bonus/update-multiple-statuses",
  },

  VACATION: {
    CREATE_VACATION: "vacation/createVacation",
    GET_USER_HOLIDAYS: "vacation/getUserHolidays",
    DELETE_USER_VACATION: "vacation/deleteUserHolidays",
    UPDATE_USER_VACATION: "vacation/updateUserHolidays",
  },

  APPLICATION_SETTINGS: {
    UPDATE_APPLICATION_SETTINGS_THUNK:
      "application-settings/updateApplicationSettings",
    GET_APPLICATION_SETTINGS_THUNK:
      "application-settings/getApplicationSettings",
  },

  REPORT_GENERATE_THUNK: "report/postReportData",

  DEPARTMENTS: {
    GET_ALL_AREAS_THUNK: "departments/getAllAreas",
    GET_SELECTED_AREA_THUNK: "departments/getSelectedArea",
    POST_AREA_THUNK: "departments/postArea",
    EDIT_AREA_THUNK: "departments/editArea",
    DELETE_AREA_THUNK: "departments/removeArea",

    GET_DEPARTMENT_THUNK: "departments/getDepartment",
    GET_DEPARTMENT_POSITION: "departments/getDepartmentPosition",
    GET_DEPARTMENT_POSITION_BY_NAME_THUNK:
      "departments/getDepartmentPositionByName",
    GET_DEPARTMENT_BY_ID_THUNK: "departments/getDepartmentById",
    GET_DEPARTMENT_BY_PARENT_THUNK: "departments/getDepartmentByParentId",
    GET_CHILDREN_DEPARTMENT_BY_PARENT_THUNK:
      "departments/getChildDepartmentByParentId",

    GET_DEPARTMENT_WITH_SUPERVISORS_THUNK: "department/withSuperVisors",

    POST_DEPARTMENT_THUNK: "departments/postDepartment",
    EDIT_DEPARTMENT_DETAILS_THUNK: "department/editDepartmentDetails",
    CHANGE_PARENT_DEPARTMENT_THUNK: "departments/changeParentDepartment",
    DELETE_DEPARTMENT_THUNK: "departments/deleteDepartment",

    EDIT_JOB_POSITION_THUNK: "department/editJobPosition",
    CREATE_JOB_POSITION_THUNK: "departments/createJobPosition",
    MOVE_JOB_POSITION_THUNK: "departments/moveJobPosition",
    DELETE_JOB_POSITION_THUNK: "department/deleteJobPosition",
  },

  DOWNLOAD_ATTACHMENT_THUNK: "attachment/downloadAttachment",

  EVALUATION: {
    FILTER_EVALUATION_THUNK: "evaluations/filrerEvaluation",
    FILTER_APPRAISAL_STATUSES_THUNK: "evaluations/filterAppraisalStatuses",
    GET_USERS_APPRAISAL_STATUSES_THUNK: "evaluations/getUsersAppraisalStatuses",
    GET_USER_EVALUATIONS_THUNK: "evaluations/getUserEvaluations",
    MAKE_EVALUATION_THUNK: "evaluations/makeEvaluation",
    FILTER_USERS_TO_EVALUATE: "evaluations/filterUsersToEvaluate",
    GET_USERS_TO_EVALUATE_THUNK: "evaluations/getUsersToEvaluate",
    CREATE_EVALUATION_COMMENT_THUNK: "evaluations/createEvaluation",
  },

  RANKING_FETCH_THUNK: "ranking/fetchRanking",

  SUGGESTION: {
    ADD_SUGGESTION_THUNK: "suggestions/addSuggestion",
    UPDATE_SUGGESTION_STATUS_THUNK: "suggestions/updateStatus",
    UPDATE_SUGGESTION_READ_THUNK: "suggestions/updateReadStatus",

    GET_SELECTED_SUGGESTION_THUNK: "suggestion/get-selected-suggestion",
    
    GET_ALL_COMMENTARIES_THUNK: "suggestion/commentaries",
    GET_SUGGESTIONS_INFO_THUNK: "suggestions/getSuggestionsInfo",
    GET_MY_SUGGESTIONS_THUNK: "suggestions/mySuggestions",
    GET_WORKERS_TO_OTHERS_SUGGESTIONS_THUNK:
      "suggestions/suggestions-to-other-departments",
    GET_WORKERS_SUGGESTIONS_THUNK: "suggestions/getWorkersSuggestions",
    GET_OTHER_DEPARTMENTS_SUGGESTIONS_THUNK:
      "suggestions/departmentSuggestions",
    GET_SUPERVISOR_SUGGESTIONS_THUNK: "suggestions/getSuperVisorSuggestions",
    GET_ALL_SUGGESTIONS_PREVIEW_THUNK: "suggestions/getAllSuggestionsPreview",

    COUNT_REPORTED_THUNK: "suggestions/getReported",
    COUNT_REJECTED_THUNK: "suggestions/getRejected",
    COUNT_CLOSED_THUNK: "suggestions/getClosed",
    COUNT_IMPLEMENTED_THUNK: "suggestions/getImplemented",
    COUNT_DONE_THUNK: "suggestions/getDone",
    COUNT_APPEALED_THUNK: "suggestions/getAppealed",
    COUNT_APPROVED_THUNK: "suggestions/getApproved",

    ATTACHMENTS_THUNK: "suggestion/attachmentsThunk",
    CORRECTION_ATTACHMENT_THUNK: "suggestion/correctionAttachment",
    VERIFICATION_ATTACHMENT_THUNK: "suggestion/verificationAttachment",
    END_RESULT_ATTACHMENT_THUNK: "suggestion/resultAttachment",
  },
  
  USERS: {
    ADD_USER_THUNK: "users/addUser",
    DELETE_USER_THUNK: "users/deleteUser",
    UPDATE_USER_THUNK: "users/updateUser",
    UPDATE_YOUR_INFO_THUNK: "users/updateYoutInfo",
    FETCH_USERS_THUNK: "users/fetchUsers",
    FETCH_USER_THUNK: "users/fetchUser",
    FETCH_AUTH_USER_THUNK: "users/fetchUser",
  },

  MIGRATION: {
    MIGRATE_SUGGESTIONS_THUNK: "migrations/migrateSuggestions",
    CREATE_TEMP_MANAGER_THUNK: "migrations/createTempManager",
    MIGRATE_SUGGESTIONS_TO_TEMP_THUNK: "migrations/migrateSuggestionsToTemp",
    REVERT_SUGGESTIONS_THUNK: "migrations/revertSuggestions",
    REVERT_SUGGESTIONS_FROM_TEMP_THUNK: "migrations/revertSuggestionsFromTemp",
    GET_WORKERS_FOR_TEMP_THUNK: "migrations/getWorkersForTemp",
    GET_PARENT_SUPERVISORS_THUNK: "migrations/getParentSupervisors"
  }
};

export default ThunkNames;
