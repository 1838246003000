import React, { useState, SyntheticEvent, ReactElement } from "react";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { authThunk } from "../../../__store/thunks";

import { InputField, SubmitButton } from "../../components/ui";

import styles from "./RecoveryPassword.module.scss";

import { authStatuses, RoutesPaths } from "../../../data/config";
import { authData } from "../../../data/constants";

const { ROOT_PATH } = RoutesPaths;

const { PASSWORD_VALIDATION_PATTERN, recoveryTexts, recoveryInputData } = authData;

const LoginForm = ():ReactElement => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const recoveryStatus = useAppSelector((state) => state.auth.recoveryStatus);

  function isEmailValid(email: string) {
    return String(email)
      .toLowerCase()
      .match(PASSWORD_VALIDATION_PATTERN);
  }

  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
    setEmail(e.currentTarget.value);
  };
  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();

    const isValid = isEmailValid(email);
    if (isValid === null) {
      setErrorMessage(recoveryTexts.EMAIL_ERROR_MESSAGE);
    } else {
      dispatch(authThunk.recovery(email));
      setErrorMessage("");
    }
  };

  return recoveryStatus === authStatuses.RecoveryStatus.SENT ? (
    <div className={styles["recovery-message"]}>
      <div className={styles["recovery-message__header"]}>
        <h2 className={styles["recovery-message__header__text"]}>
          {recoveryTexts.RESET_PASSWORD_HEADER}
        </h2>
      </div>
      <div className={styles["recovery-message__description"]}>
        <p className={styles["recovery-message__description__text"]}>
          {recoveryTexts.RESET_PASSWORD_DESCRIPTION}
        </p>
      </div>
      <Link to={ROOT_PATH} className={styles["recovery-message__link"]}>
        {recoveryTexts.BACK_LINK_LABEL}
      </Link>
    </div>
  ) : (
    <form onSubmit={(e) => handleSubmit(e)} className={styles["recovery"]}>
      <h2 className={styles["recovery__header"]}>{recoveryTexts.FORGET_PASSWORD_HEADER}</h2>
      <div className={styles["recovery__description"]}>
        <p>{recoveryTexts.FORGET_PASSWORD_DESCRIPTION}</p>
      </div>
      <div className={styles["recovery__input"]}>
        <InputField
          hiddenStyles=""
          name={recoveryInputData.name}
          placeholder={recoveryInputData.placeholder}
          inputClassName={recoveryInputData.class}
          onChangeHandler={onChangeHandler}
          type={recoveryInputData.type}
        />
      </div>
      {errorMessage && (<p className={styles["recovery__error-pargraph"]}>{errorMessage}</p>)}
      <div className={styles["recovery__buttons"]}>
        <SubmitButton buttonClassName="button" value={recoveryTexts.RESET_PASSWORD_BUTTON} />
      </div>
    </form>
  );
};

export default LoginForm;
