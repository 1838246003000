import { createAsyncThunk } from "@reduxjs/toolkit";
import { vacationModels } from "../models";
import { ModalClassnames, ServerStatuses, ThunkNames } from "../../data/config";
import {
  getHolidaysForUser,
  postHolidays,
  deleteHolidaysForUser,
  patchHolidaysForUser,
} from "./services/vacation.service";
import { modalActions, vacationActions } from "../slices";

const { VACATION } = ThunkNames;

export const createVacation = createAsyncThunk(
  VACATION.CREATE_VACATION,
  (payload: vacationModels.VacationPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    postHolidays(payload)
      .then((data) => {
        dispatch(getUserHolidays(payload.user_id));
        dispatch(modalActions.openModal());
        dispatch(
          modalActions.setModalClassName(
            ModalClassnames.CREATE_VACATION_SUCCESS
          )
        );
      })
      .catch(() => {
        dispatch(
          modalActions.setModalClassName(ModalClassnames.CREATE_VACATION_ERROR)
        );
      });
  }
);

export const getUserHolidays = createAsyncThunk(
  VACATION.GET_USER_HOLIDAYS,
  (user_id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    // getHolidaysForUser(user_id).then((data) => {
    //   dispatch(vacationActions.setUserVacations(data.data));
    // });
  }
);

export const removeUserVacation = createAsyncThunk(
  VACATION.DELETE_USER_VACATION,
  (payload: { user_id: number; userHoliday_id: number }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    deleteHolidaysForUser(payload)
      .then((data) => {
        dispatch(getUserHolidays(payload.user_id));
        dispatch(modalActions.openModal());
        dispatch(
          modalActions.setModalClassName(
            ModalClassnames.REMOVE_VACATION_SUCCESS
          )
        );
      })
      .catch(() => {
        dispatch(
          modalActions.setModalClassName(ModalClassnames.REMOVE_VACATION_ERROR)
        );
      });
  }
);

export const updateUserVacation = createAsyncThunk(
  VACATION.UPDATE_USER_VACATION,
  (
    payload: vacationModels.VacationPayload & { userHoliday_id: number },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;

    patchHolidaysForUser(payload)
      .then((data) => {
        dispatch(getUserHolidays(payload.user_id));
        dispatch(modalActions.openModal());
        dispatch(
          modalActions.setModalClassName(
            ModalClassnames.UPDATE_VACATION_SUCCESS
          )
        );
      })
      .catch(() => {
        dispatch(modalActions.openModal());
        dispatch(
          modalActions.setModalClassName(ModalClassnames.UPDATE_VACATION_ERROR)
        );
      });
  }
);
