
import { ReactElement } from "react";

import { adminModels } from "../../../__store/models";

import styles from "../views/BonusWorkerPage.module.scss";

import { BonusPointCard } from ".";

interface IPointCardData {
  title: string; 
  value: number; 
  valueDesc: string; 
  background: string;
};

const structurizePointsCardsData = (userData : adminModels.Profile) 
: IPointCardData[] => {
  const cardsData =  [
    {
      title: "Wszystkie punkty",
      value: userData.score,
      valueDesc: "p.",
      background: "#389CF8"
    },
    {
      title: "Wypłacone punkty",
      value: userData.blocked_points,
      valueDesc: "p.",
      background: "#9CCEFC",
    },
    {
      title: "Do wypłacenia",
      value: userData.remaining_points,
      valueDesc: "p.",
      background: "#391580",
    },
  ];

  return cardsData;
};

interface IBonusWorkerCards {
  userPointsData: any;
}

const BonusWorkerCards = (
  { userPointsData } : IBonusWorkerCards): ReactElement => {
  return (
    <div className={styles["cards-section"]}>
      {userPointsData && structurizePointsCardsData(userPointsData).map((item) => (
        <BonusPointCard
          key={item.title}
          title={item.title}
          value={item.value as number}
          valueDesc={item.valueDesc}
        />
      ))}
    </div>
  );
};

export default BonusWorkerCards;
