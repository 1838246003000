import { AxiosResponse } from "axios";
import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";

const { ATTACHMENT } = ServerPaths;

export const downloadAttachmentFromApi = async (
  key: string
): Promise<AxiosResponse<any, any>> => {
  const response = await axiosInstance.get(`${ATTACHMENT}/${key}`, {
    responseType: "arraybuffer",
  });

  return response as any;
};
