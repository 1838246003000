import { ReactElement } from "react";
import { useDrag } from "react-dnd";

import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";

import { departmentActions, modalActions } from "../../../../__store/slices";
import { departmentThunk } from "../../../../__store/thunks";

import { Icon } from "../../../components/ui";

import styles from "./JobPosition.module.scss";

import { IJobPositionProps } from "../../types/AdminProps.types";
import { IconTypes, ModalClassnames, UserRoles } from "../../../../data/config";
import { structureData } from "../../../../data/constants";

const { EDIT_JOB_POSITION } = ModalClassnames;
const { DragType } = structureData;

function JobPosition({ position }: IJobPositionProps):ReactElement {
  const dispatch = useAppDispatch();
  const selectedDepartmentId = useAppSelector(
    (state) => state.departments.selectedDepartment
  );

  function editJobPositionHandler() {
    dispatch(departmentActions.setJobPositionId(position.job_position_id));
    dispatch(modalActions.setModalClassName(EDIT_JOB_POSITION));
    dispatch(modalActions.openModal());
  }

  const deleteJobPositionHandler = () => {
    dispatch(
      departmentThunk.deleteJobPosition({
        id: position.job_position_id,
        departmentid: selectedDepartmentId,
      })
    );
  };

  const canDrag = position.role === UserRoles.WORKER;

  const [, drag] = useDrag(() => ({
    type: "box",

    item: {
      id: position.job_position_id,
      type: DragType.job,
      selectedId: selectedDepartmentId,
    },
    canDrag: canDrag,
  }));

  return (
    <li
      className={`${styles["position"]} ${position.role === UserRoles.MANAGER && styles["supervisor"]}`}
      ref={drag}
    >
      {position.name}

      <button
        className={styles["position__button"]}
        onClick={editJobPositionHandler}
      >
        <Icon type={IconTypes.EDIT} />
      </button>
      <button
        className={styles["position__button"]}
        onClick={deleteJobPositionHandler}
      >
        <Icon type={IconTypes.TRASH} />
      </button>
    </li>
  );
}

export default JobPosition;
