import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { adminThunk } from "../../../__store/thunks";

import { NavBar, TopBar, InactiveAccount } from "../../components/ui";
import { DashboardRanking } from "../components";

import styles from "./RankingPage.module.scss";

import { ranksData } from "../../../data/constants";
const { rankingListHeader } = ranksData;

const RankingPage = ():ReactElement => {
  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.users.authUser);
  const user_id = useAppSelector((state) => state.auth.user_id);

  useEffect(() => {
    if (user == null && user_id) {
      dispatch(adminThunk.fetchUser(user_id));
    }
  }, [user, user_id, dispatch]);

  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["page__dashboard"]}>
        <TopBar headerValue={rankingListHeader} />
        <DashboardRanking />
      </div>) : <InactiveAccount />}
    </div>
  );
};

export default RankingPage;
