import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../__store/tools/hooks";
import PrivateRoute from "./PrivateRoute";

import {
  StructurePage,
  UserPanelPage,
  AdminPanelPage
} from "../pages/Admin/views";

import {
  MyAssessment,
  CreateAssessment,
  ReviewAssessment,
  ReviewUserAssessment,
  MyAssessmentDetails
} from "../pages/Assessment/views";

import {
  MainAuthPage,
  LogoutPage,
  RecoveryPage,
} from "../pages/Authorization/views";

import {
  ConsentForm,
  LoginForm,
  NewPassword,
  SuccessfullAfterUpdate,
} from "../pages/Authorization/components";

import {
  WorkerSuggestionsPage,
  MySuggestionsPage,
  DepartmentSuggestionsPage,
  OtherDepartmentsSuggestionsPage,
  AllSuggestionsPreviewPage,
  CreateSuggestionPage,
  SuggestionPreview
} from "../pages/Suggestions/views";

import { 
  RankingPage, 
  BonusWorkerPage,
  BonusManagerPage,
} from "../pages/Ranks/views";

import { YourProfilePage, WorkerProfilePage } from "../pages/Profile/views";

import { Modal, FormModalContainer } from "../pages/components/Modal";

import { applicationSettingsActions, authActions } from "../__store/slices";
import { authService } from "../__store/thunks/services";
import { adminThunk } from "../__store/thunks";

import { RoutesPaths, authStatuses, userRoles } from "../data/config";

const { Permission } = authStatuses;
const { fullPermissions, managersPermissions, adminOnlyPermissions } =
  userRoles;

const {
  ROOT_PATH,
  ADMIN,
  ASSESSMENT,
  AUTHORIZATION,
  PROFILE,
  RANKS,
  SUGGESTIONS,
} = RoutesPaths;

import { CLIENT_NAME } from "../data/config/globalContentVariables.data";

const Root = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const userRole = useAppSelector((state) => state.auth.role);
  const userId = useAppSelector((state) => state.auth.user_id);
  const user = useAppSelector((state) => state.users.authUser);
  const permission = useAppSelector((state) => state.auth.permissions);
  const modalClassName = useAppSelector((state) => state.modal.modalClassName);

  const showEvaluationPages = CLIENT_NAME === "dimograf" ? false : useAppSelector(
    (state) => state.applicationSettings.shouldShowEvaluationPages
  );

  if (userId && !user) {
    dispatch(adminThunk.fetchAuthUser(userId));
  }

  if (isAuth && userRole === "") {
    dispatch(authActions.setUserRole(authService.generateUserRoleFromJWT()));
  }

  useEffect(() => {
    dispatch(applicationSettingsActions.getApplicationSettings());
  }, []);

  return (
    <>
      <Router>
        <Modal>{FormModalContainer[modalClassName]}</Modal>

        <Routes>
          {/* ADMIN section routes */}
          <Route
            path={ADMIN.ADMIN_PATH}
            element={
              <PrivateRoute
                roles={
                  permission.admin_permission
                    ? fullPermissions
                    : adminOnlyPermissions
                }
                // component={AdminPage}
                component={AdminPanelPage}
              />
            }
          />
          <Route
            path={AUTHORIZATION.SUCCESSFULL_CHANGING_PASSWORD_PATH}
            element={
              <MainAuthPage
                form={<SuccessfullAfterUpdate />}
                renderItem={() => <SuccessfullAfterUpdate />}
              />
            }
          />

          <Route
            path={SUGGESTIONS.CREATE_SUGGESTION_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={CreateSuggestionPage}
              ></PrivateRoute>
            }
          />
          <Route
            path={ADMIN.STRUCTURE_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={StructurePage}
                requiredPermission={Permission.structure_permission}
              />
            }
          />

          {/* ASSESSMENT section routes */}
          <Route
            path={ASSESSMENT.MY_ASSESSMENT_PATH}
            element={
              <PrivateRoute
                roles={showEvaluationPages ? fullPermissions : []}
                component={MyAssessment}
              />
            }
          />
          <Route
            path={ASSESSMENT.MY_ASSESSMENT_PATH_INFO}
            element={
              <PrivateRoute
                roles={showEvaluationPages ? fullPermissions : []}
                component={MyAssessmentDetails}
              />
            }
          />
          <Route
            path={ASSESSMENT.CREATE_ASSESSMENT_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={CreateAssessment}
              />
            }
          />
          <Route
            path={ASSESSMENT.REVIEW_ASSESSMENT_PATH}
            element={
              <PrivateRoute
                roles={managersPermissions}
                component={ReviewAssessment}
              />
            }
          />
          <Route
            path={ASSESSMENT.REVIEW_USER_ASSESSMENT_PATH}
            element={
              <PrivateRoute
                roles={managersPermissions}
                component={ReviewUserAssessment}
              />
            }
          />
          <Route
            path={ADMIN.SETTINGS_PATH}
            element={
              <PrivateRoute roles={fullPermissions} component={UserPanelPage} />
            }
          />

          {/* AUTHORIZATION section routes */}
          <Route
            path={ROOT_PATH}
            element={
              <MainAuthPage
                form={<LoginForm />}
                renderItem={() => <LoginForm />}
              />
            }
          />

          <Route
            path={AUTHORIZATION.RULES}
            element={
              <MainAuthPage
                form={<ConsentForm />}
                renderItem={() => <ConsentForm />}
              />
            }
          />

          <Route path={AUTHORIZATION.FORGOT_PATH} element={<RecoveryPage />} />
          <Route
            path={AUTHORIZATION.NEW_PASSWORD_PATH}
            element={
              <MainAuthPage
                form={<NewPassword />}
                renderItem={() => <NewPassword />}
              />
            }
          />
          <Route
            path={AUTHORIZATION.CHANGE_PASSWORD_PATH}
            element={
              <MainAuthPage
                form={<NewPassword />}
                renderItem={() => <NewPassword />}
              />
            }
          />
          <Route
            path={AUTHORIZATION.LOGOUT_PATH}
            element={
              <PrivateRoute roles={fullPermissions} component={LogoutPage} />
            }
          />

          {/* PROFILE section routes */}
          <Route
            path={PROFILE.YOUR_PROFILE_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={YourProfilePage}
              />
            }
          />
          <Route
            path={PROFILE.WORKER_PROFILE_PATH}
            element={
              <PrivateRoute
                roles={managersPermissions}
                component={WorkerProfilePage}
              />
            }
          />

          {/* SUGGESTIONS section routes */}
          <Route
            path={SUGGESTIONS.SUGGESTION_PREVIEW_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={SuggestionPreview}
              />
            }
          />
          
          <Route
            path={SUGGESTIONS.MY_SUGGESTIONS_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={MySuggestionsPage}
              />
            }
          />
          <Route
            path={SUGGESTIONS.ALL_SUGGESTIONS_PREVIEW_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={AllSuggestionsPreviewPage}
              />
            }
          />
          <Route
            path={SUGGESTIONS.WORKER_SUGGESTIONS_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={WorkerSuggestionsPage}
              />
            }
          />
          <Route
            path={SUGGESTIONS.DEPARTMENT_SUGGESTIONS_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={DepartmentSuggestionsPage}
              />
            }
          />
          <Route
            path={SUGGESTIONS.OTHER_DEPARTMENTS_SUGGESTIONS_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={OtherDepartmentsSuggestionsPage}
              />
            }
          />

          {/* RANKS section routes */}
          <Route
            path={RANKS.RANKING_PATH}
            element={
              <PrivateRoute
                roles={fullPermissions}
                component={RankingPage}
              />
            }
          />
          <Route
            path={RANKS.BONUS_WORKER_PATH}
            element = {
              <PrivateRoute roles={fullPermissions} component={BonusWorkerPage} />
            }
          />
          <Route 
            path={RANKS.BONUS_MANAGER_PATH}
            element = {
              <PrivateRoute roles={fullPermissions} component={BonusManagerPage} />
            }
          />
        </Routes>
      </Router>
    </>
  );
};
export default Root;
