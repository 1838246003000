import { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import {
  MainStatuses, 
  AllStatuses, 
  ReportSubstatuses, 
  RealizationSubstatuses, 
  VerificationSubstatuses, 
  CreationSubstatuses, 
  RejectionSubstatuses
} from "../../../data/config/suggestionStatuses.data";

import { IconTypes, ModalClassnames } from "../../../data/config";

import styles from "../views/SuggestionPreview.module.scss";

import { Button } from 'antd';
import { FetchSuggestion } from "../../../__store/models/suggestion.models";
import { formsActions, modalActions } from "../../../__store/slices";
import { IButtonActions } from "../types/SuggestionsDashboard.types";
import { Icon } from "../../components/ui";

const { 
  // SUGGESTION_INFO,
  // SUGGESTION_INFO_RONLY,
  // IMPLEMENT_SUGGESTION,
  // END_RESULT,
  // EVALUATE_FORM,
  NOTE_FORM,
  // EVALUATE_APPEALED,
  // APPROVED_SUGGESTION,
  ACCEPT_SUGGESTION_FORM,
  REJECT_SUGGESTION_FORM,
  REDIRECT_SUGGESTION_FORM,
  WITHDRAW_SUGGESTION_FORM,
  CORRECT_SUGGESTION_FORM,
  INTERNAL_COMMENT_REALIZATION_FORM,
  INTERNAL_COMMENT_VERIFICATION_FORM,
  SEND_TO_VERIFICATION_FORM,
  CONFIRM_CREATION_FORM,
  REJECT_CREATION_FORM,
  ADD_END_RESULT_FORM,
  APPEAL_SUGGESTION_FORM,
  EXTERNAL_COMMENT_FORM,
} = ModalClassnames;


type SuggestionFlowButtonsMatrix = {
  [key in MainStatuses]: {
    [key: string]: ReactElement
  }
};

interface ISuggestionActionButtons {
  status: MainStatuses;
  substatus: any;
  suggestionData: FetchSuggestion;
  suggestionID: number;
  readOnly?: boolean;
};

const SuggestionActionButtons = (
  { status, substatus, suggestionData, suggestionID, readOnly } 
  :  ISuggestionActionButtons) => {

  const dispatch = useAppDispatch();

  const userID = useAppSelector((state) => state.auth.user_id);
  const userPermissions = useAppSelector((state) => state.auth.permissions);
  
  const allCommentaries = useAppSelector((state) => state.suggestions.allCommentaries);
  const selectedSuggestionCommentaries = allCommentaries?.filter(
    commentary => commentary.suggestion_id === suggestionID
  ).filter(commentary => commentary.content !== "");

  const dispatchSuggetionDataAndOpenModal = () => {
    dispatch(formsActions.setFormInfoData(suggestionData));
    dispatch(formsActions.setSuggestionComment(selectedSuggestionCommentaries));
    dispatch(formsActions.setPickedSuggestionId(suggestionID));
    dispatch(modalActions.openModal());
  };

  const setPath = () => {
    dispatch(formsActions.setSuggestionPath(suggestionData.suggestionPath));
    dispatch(formsActions.setResultPath(suggestionData.resultPath));
    dispatch(formsActions.setSolutionPath(suggestionData.solutionPath));
    dispatch(formsActions.setVerificationPath(suggestionData.verificationPath));
    dispatch(formsActions.setCorrectionPath(suggestionData.correctionPath));
    dispatch(formsActions.setEndResultPath(suggestionData.endResultPath));

    dispatch(formsActions.setSuggestionFilesNames(suggestionData.suggestionFilesName));
    dispatch(formsActions.setSolutionFilesNames(suggestionData.soultionFilesName));
    dispatch(formsActions.setResultFilesNames(suggestionData.resultFilesName));
    dispatch(formsActions.setVerificationFilesNames(suggestionData.verificationFilesName));
    dispatch(formsActions.setCorrectionFilesNames(suggestionData.correctionFilesName));
    dispatch(formsActions.setEndResultFilesNames(suggestionData.endResultFilesName));
  };

  const buttonActions: IButtonActions = {
    evaluateAccept: () => {
      setPath();
      dispatch(modalActions.setModalClassName(ACCEPT_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    evaluateReject: () => {
      setPath();
      dispatch(modalActions.setModalClassName(REJECT_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    evaluateWithdraw: () => {
      setPath();
      dispatch(modalActions.setModalClassName(WITHDRAW_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    evaluateRedirect: () => {
      setPath();
      dispatch(modalActions.setModalClassName(REDIRECT_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    correct: () => {
      setPath();
      dispatch(modalActions.setModalClassName(CORRECT_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
      dispatch(formsActions.setInternalVerificationComment("comment"));
    },
    internalCommentRealization: () => {
      setPath();
      dispatch(modalActions.setModalClassName(INTERNAL_COMMENT_REALIZATION_FORM));
      dispatchSuggetionDataAndOpenModal();
      dispatch(formsActions.setInternalVerificationComment("comment"));
    },
    sendToVerification: () => {
      setPath();
      dispatch(modalActions.setModalClassName(SEND_TO_VERIFICATION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    internalCommentVerification: () => {
      setPath();
      dispatch(modalActions.setModalClassName(INTERNAL_COMMENT_VERIFICATION_FORM));
      dispatchSuggetionDataAndOpenModal();
      dispatch(formsActions.setInternalVerificationComment("comment"));
    },
    confirmCreation: () => {
      setPath();
      dispatch(modalActions.setModalClassName(CONFIRM_CREATION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    rejectCreation: () => {
      setPath();
      dispatch(modalActions.setModalClassName(REJECT_CREATION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    addEndResult: () => {
      setPath();
      dispatch(modalActions.setModalClassName(ADD_END_RESULT_FORM))
      dispatchSuggetionDataAndOpenModal();
    },
    appeal: () => {
      setPath();
      dispatch(modalActions.setModalClassName(APPEAL_SUGGESTION_FORM));
      dispatchSuggetionDataAndOpenModal();
    },
    // note: () => {
    //   setPath();
    //   dispatch(modalActions.setModalClassName(NOTE_FORM));
    //   dispatchSuggetionDataAndOpenModal();
    // }, /// ???
    externalComment: () => {
      setPath();
      dispatch(modalActions.setModalClassName(EXTERNAL_COMMENT_FORM));
      dispatchSuggetionDataAndOpenModal();
    }
  }
  
  // EVALUATING USER ACTIONS

  const PrimaryEvaluateButtons = () => (
    <>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateReject"]}
        className={styles["secondary-button"]}
        icon={<Icon type={IconTypes.CROSS} />}
      >
        Odrzuć
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateAccept"]}
        className={styles["primary-button"]}
        icon={<Icon type={IconTypes.CHECK_WHITE} />}
      >
        Zaakceptuj
      </Button>
    </>
  );

  const ExtendedEvaluteButtons = () => (
    <>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateWithdraw"]}
        className={styles["additional-button"]}
      >
        Cofnij
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateRedirect"]}
        className={styles["additional-button"]}
      >
        Przekieruj
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateReject"]}
        className={styles["secondary-button"]}
        icon={<Icon type={IconTypes.CROSS} />}
      >
        Odrzuć
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["evaluateAccept"]}
        className={styles["primary-button"]}
        icon={<Icon type={IconTypes.CHECK_WHITE} />}
      >
        Zaakceptuj
      </Button>
    </>
  );

  const InternalCommentaryButton = ({ status } : { status: MainStatuses }) => {
    const actionTypeByStatus = status === MainStatuses.REALIZATION 
      ? "internalCommentRealization" 
      : "internalCommentVerification";
    
    return (
      <Button
        size="large"
        type="primary"
        onClick={buttonActions[actionTypeByStatus]}
        className={styles["primary-button"]}
        icon={<Icon type={IconTypes.EMPTY_DOT_ICON} />}
      >
        Dodaj komentarz
      </Button>
    );
  };

  const VerificationButtons = () => (
    <>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["rejectCreation"]}
        className={styles["secondary-button"]}
      >
        Do poprawy
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["confirmCreation"]}
        className={styles["primary-button"]}
      >
        Zatwierdź
      </Button>
    </>
  );

  const AddResult = () => (
    <Button
      size="large"
      type="primary"
      onClick={buttonActions["addEndResult"]}
      className={styles["primary-button"]}
      icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
    >
      Dodaj Rezultat
    </Button>
  );

  // REPORTING USER ACTIONS

  const CorrectButtons = () => (
    <Button
      size="large"
      type="primary"
      onClick={buttonActions["correct"]}
      className={styles["primary-button"]}
    >
      Popraw
    </Button>
  );

  const RealizationButtons = () => (
    <>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["internalCommentRealization"]}
        className={styles["secondary-button"]}
        icon={<Icon type={IconTypes.EMPTY_DOT_ICON} />}
      >
        Zaktualizuj status
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={buttonActions["sendToVerification"]}
        className={styles["primary-button"]}
        icon={<Icon type={IconTypes.EMPTY_DOT_ICON} />}
      >
        Oddaj do sprawdzenia
      </Button>
    </>
  );

  const AppealButton = () => (
    <Button
      size="large"
      type="primary"
      onClick={buttonActions["appeal"]}
      className={styles["primary-button"]}
    >
      Odwołaj się
    </Button>
  );

  const ExternalCommentButton = () => (
    <Button
      size="large"
      type="primary"
      onClick={buttonActions["externalComment"]}
      className={styles["primary-button"]}
    >
      Dodaj komentarz
    </Button>
  );

  
  const suggestionFlowReporterButtonsMatrix: SuggestionFlowButtonsMatrix = {
    [MainStatuses.REPORT]: {
      [ReportSubstatuses.UNMARKED]: <></>,
      [ReportSubstatuses.WITHDRAWN]: <CorrectButtons />,
      [ReportSubstatuses.REDIRECTED]: <></>,
      [ReportSubstatuses.CORRECTED]: <></>,
    },
    [MainStatuses.REALIZATION]: {
      [RealizationSubstatuses.DURING_REALIZATION]: <RealizationButtons />,
      [RealizationSubstatuses.RETURNED_FROM_VERIFICATION]: <RealizationButtons />,
    },
    [MainStatuses.VERIFICATION]: {
      [VerificationSubstatuses.SUBMITTED_FOR_VERIFICATION]: <InternalCommentaryButton status={MainStatuses.VERIFICATION} />,
    },
    [MainStatuses.CREATION]: {
      [CreationSubstatuses.COMPLETED]: <AddResult />,
      [CreationSubstatuses.RESULT]: <AddResult />,
    },
    [MainStatuses.REJECTION]: {
      [RejectionSubstatuses.REVOCABLE]: <AppealButton />,
      [RejectionSubstatuses.CLOSED]: <></>,
    },
    [MainStatuses.APPEALED]: {
      [AllStatuses.APPEALED]: <></>,
    },
  };

  const suggestionFlowEvaluatorButtonsMatrix: SuggestionFlowButtonsMatrix = {
    [MainStatuses.REPORT]: {
      [ReportSubstatuses.UNMARKED]: <ExtendedEvaluteButtons />,
      [ReportSubstatuses.WITHDRAWN]: <PrimaryEvaluateButtons />,
      [ReportSubstatuses.REDIRECTED]: <ExtendedEvaluteButtons />,
      [ReportSubstatuses.CORRECTED]: <ExtendedEvaluteButtons />,
    },
    [MainStatuses.REALIZATION]: {
      [RealizationSubstatuses.DURING_REALIZATION]: <InternalCommentaryButton status={MainStatuses.REALIZATION} />,
      [RealizationSubstatuses.RETURNED_FROM_VERIFICATION]: <InternalCommentaryButton status={MainStatuses.REALIZATION} />,
    },
    [MainStatuses.VERIFICATION]: {
      [VerificationSubstatuses.SUBMITTED_FOR_VERIFICATION]: <VerificationButtons />,
    },
    [MainStatuses.CREATION]: {
      [CreationSubstatuses.COMPLETED]: <AddResult />,
      [CreationSubstatuses.RESULT]: <AddResult />,
    },
    [MainStatuses.REJECTION]: {
      [RejectionSubstatuses.REVOCABLE]: <></>,
      [RejectionSubstatuses.CLOSED]: <></>,
    },
    [MainStatuses.APPEALED]: {
      [AllStatuses.APPEALED]: <PrimaryEvaluateButtons />,
    },
  };
        
  const getActions = () => {
    if (userID === Number(suggestionData.reporting_user_id)) {
      return suggestionFlowReporterButtonsMatrix[status][substatus];
    } else if (userID === Number(suggestionData.evaluating_user_id)) {
      if (status === MainStatuses.REPORT && !substatus) {
        return suggestionFlowEvaluatorButtonsMatrix[status][ReportSubstatuses.UNMARKED];
      } else {
        return suggestionFlowEvaluatorButtonsMatrix[status][substatus];
      }
    } else if (readOnly && userPermissions.external_comments_permission) {
      return <ExternalCommentButton />;
    } else {
      console.log("Nieobsługiwany status/rola!");
      return <></>;
    }
  };
      
  return (          
    <div className={styles["buttons-section"]}>
      {getActions()}
    </div>
  );
};
      
export default SuggestionActionButtons;
