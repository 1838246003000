import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { modalActions } from "../../../__store/slices";

import { RecoveryPassword } from "../components";

import styles from "./RecoveryPage.module.scss";

import { RoutesPaths } from "../../../data/config";

const { PROFILE } = RoutesPaths;
const { YOUR_PROFILE_PATH } = PROFILE;

function RecoveryPage():ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuth = useAppSelector((state) => state.auth.isAuth);

  useEffect(() => {
    if (isAuth) {
      dispatch(modalActions.closeModal());
      navigate(YOUR_PROFILE_PATH);
    }
  }, [isAuth, dispatch, navigate]);

  return (
    <div className={styles["recoveryPage"]}>
        <RecoveryPassword />
    </div>
  );
}

export default RecoveryPage;
