import axios, { AxiosResponse } from "axios";

import { ServerPaths, LocalStorageKeys } from "../../../data/config";

const { BASE, REPORTS } = ServerPaths;
const { ACCESS_TOKEN } = LocalStorageKeys;

const jwt = localStorage.getItem(ACCESS_TOKEN);
let token = jwt ? JSON.parse(jwt) : "";

const axiosBlobInstance = axios.create({
  baseURL: `${BASE}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : null,
  },
  responseType: "blob",
});

export const generateReportsData = (
  payload: { payments?: any, filterOptions?: any }
): Promise<AxiosResponse<any, any>> =>
  axiosBlobInstance.post(`${REPORTS}`, {
    payload,
  });
