import { useEffect, ReactElement } from 'react';

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { suggestionThunk, departmentThunk } from '../../../__store/thunks';

import { MainStatuses } from "../../../data/config";

import { ISuggestionsCount, TCategoriedSuggestions } from "../types/SuggestionsDashboard.types";
import { SuggestionsPageTemplate } from '../components';

const MySuggestionsPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const role = useAppSelector((state) => state.auth.role);
  const suggestionState = useAppSelector((state) => state.suggestions);

  useEffect(() => {
    dispatch(suggestionThunk.getMySuggestions());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  const mySuggestionsCount: ISuggestionsCount = suggestionState.mySuggestionsCount;

  const MySuggestionsMap: TCategoriedSuggestions = new Map([
    [ MainStatuses.REPORT, suggestionState.myReportedSuggestions ],
    [ MainStatuses.REALIZATION, suggestionState.myImplementedSuggestions ],
    [ MainStatuses.VERIFICATION, suggestionState.myDoneSuggestions ],
    [ MainStatuses.CREATION, suggestionState.myApprovedSuggestions ],
    [ MainStatuses.APPEALED, suggestionState.myAppealedSuggestions ],
    [ MainStatuses.REJECTION, suggestionState.myRejectedSuggestions ],
  ]);

  return (
    <SuggestionsPageTemplate
      suggestionsCount={mySuggestionsCount}
      suggestionsMap={MySuggestionsMap}
      userRole={role}
    />
  );
}

export default MySuggestionsPage;
