import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { evaluationThunk } from "../../../__store/thunks";

import style from "./AddResponseToAssessment.module.scss";

export interface AddResponseToAssessment {
  content: string;
}
const AddResponseToAssessment = () => {
  const [responseToAssessment, setResponseToAssessment] =
    useState<AddResponseToAssessment>({ content: "" });
  const dispatch = useAppDispatch();
  const evaluation_id = useAppSelector(
    (state) => state.evaluation.reviewingEvaluationId
  );
  const evaluationUserId = useAppSelector(
    (state) => state.evaluation.evalautingUserId
  );

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name;

    setResponseToAssessment({
      ...responseToAssessment,
      [name]: e.currentTarget.value,
    });
  };

  const createComment = () => {
    dispatch(
      evaluationThunk.createEvaluationComment({
        user_id: evaluationUserId,
        content: responseToAssessment.content,
        evaluation_id,
      })
    );
  };

  return (
    <div className={style["add-response"]}>
      <textarea
        name="content"
        onChange={(e) => onChangeHandler(e)}
        className={style["add-response__text-area"]}
        placeholder="Dodaj odpowiedź"
      ></textarea>
      <div className={style["add-response__text-area--buttons"]}>
        <button onClick={createComment}>Zatwierdź</button>
      </div>
    </div>
  );
};

export default AddResponseToAssessment;
