import { useEffect, ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { departmentThunk, suggestionThunk } from "../../../__store/thunks";

import { MainStatuses } from "../../../data/config";
import {
  ISuggestionsCount,
  TCategoriedSuggestions,
} from "../types/SuggestionsDashboard.types";
import { SuggestionsPageTemplate } from "../components";

const WorkerSuggestionsPage = ():ReactElement => {
  const dispatch = useAppDispatch();

  const suggestionState = useAppSelector((state) => state.suggestions);

  useEffect(() => {
    dispatch(suggestionThunk.getWorkerSuggestions());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  const workerSuggestionsCount: ISuggestionsCount =
    suggestionState.workerSuggestionsCount;

  const WorkerSuggestionsMap: TCategoriedSuggestions = new Map([
    [MainStatuses.REPORT, suggestionState.workerReportedSuggestions],
    [MainStatuses.REALIZATION, suggestionState.workerImplementedSuggestions],
    [MainStatuses.VERIFICATION, suggestionState.workerDoneSuggestions],
    [MainStatuses.CREATION, suggestionState.workerApprovedSuggestions],
    [MainStatuses.APPEALED, suggestionState.workerAppealedSuggestions],
    [MainStatuses.REJECTION, suggestionState.workerRejectedSuggestions],
  ]);

  return (
    <SuggestionsPageTemplate
      suggestionsCount={workerSuggestionsCount}
      suggestionsMap={WorkerSuggestionsMap}
    />
  );
}

export default WorkerSuggestionsPage;
