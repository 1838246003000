import { ReactElement, useState } from "react";
import { Avatar } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";

import { JobPosition, ChildDepartment, Breadcrumb } from ".";

import { departmentModels } from "../../../../__store/models";
import { modalActions } from "../../../../__store/slices";
import { departmentThunk } from "../../../../__store/thunks";

import { Icon } from "../../../components/ui";

import styles from "./DepartmentDetails.module.scss";

import { IDepartmentDetailsProps } from "../../types/AdminProps.types";

import { IconTypes, ModalClassnames } from "../../../../data/config";
import { adminData } from "../../../../data/constants";

const { EDIT_DEPARTMENT, CANNOT_REMOVE_DEPARTMENT } = ModalClassnames;
const { avatarStyles, departmentDetailsLabels } = adminData;

function DepartmentDetails({ selectDepartment }: IDepartmentDetailsProps):ReactElement {
  const dispatch = useAppDispatch();
  const [showJobs, setShowJobs] = useState<boolean>(false);
  const department = useAppSelector((state) => state.departments.department);
  const breadcrumbs = useAppSelector((state) => state.departments.breadcrumbs);

  function toggleJobPositons() {
    setShowJobs((prev) => !prev);
  }

  function changeDepartment(
    departmentId: number,
    department: departmentModels.StructureDepartment
  ) {
    if (breadcrumbs[0].department_id === 1) {
      selectDepartment({
        departmentId: departmentId,
        breadcrumbs: [department],
      });
    } else {
      selectDepartment({
        departmentId: departmentId,
        breadcrumbs: [...breadcrumbs, department],
      });
    }
  }

  function editDepartmentHandler() {
    dispatch(modalActions.setModalClassName(EDIT_DEPARTMENT));
    dispatch(modalActions.openModal());
  }

  const deleteDepartmentHandler = () => {
    const profiles: any = department?.profile;
    if (!profiles.length && department?.department_id) {
      dispatch(departmentThunk.deleteDepartment(department?.department_id));
    } else {
      dispatch(modalActions.setModalClassName(CANNOT_REMOVE_DEPARTMENT));
      dispatch(modalActions.openModal());
    }
  };

  if (!department) {
    return <div>{departmentDetailsLabels.NONE_DEPARTMENTS}</div>;
  } else {
    return (
      <div className={styles["department-details"]}>
        <div className={styles["header"]}>
          <div className={styles["header__department"]}>
            <div className={styles["header__department__breadcrumbs"]}>
              {breadcrumbs.map((breadcrumb) => (
                <Breadcrumb
                  key={breadcrumb.department_id}
                  breadcrumb={breadcrumb}
                  breadcrumbs={breadcrumbs}
                  selectDepartment={selectDepartment}
                />
              ))}
            </div>
            <button
              onClick={editDepartmentHandler}
              className={styles["header__department__edit"]}
            >
              <Icon type={IconTypes.EDIT} />
            </button>
            <button
              onClick={deleteDepartmentHandler}
              className={styles["header__department__delete"]}
            >
              <Icon type={IconTypes.TRASH} />
            </button>
          </div>
          <div className={styles["header__supervisors"]}>
            {department.supervisors.length > 0 ? (
              <>
                {department.supervisors.map((supervisor) => (
                  <div
                    key={supervisor.user_id}
                    className={styles["supervisor"]}
                  >
                    <Avatar
                      style={avatarStyles(supervisor.isActive)}
                      size="large"
                    >
                      {`${supervisor.profile.name.charAt(0)} ${supervisor.profile.surname.charAt(0)}`}
                    </Avatar>
                    <div className={styles["supervisor__details"]}>
                      <p className={styles["supervisor__details__name"]}>
                        {supervisor.profile.name} {supervisor.profile.surname}
                      </p>
                      <p
                        className={styles["supervisor__details__job-position"]}
                      >
                        {supervisor.profile.job_position.name}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={styles["header__supervisors__empty"]}>
                {departmentDetailsLabels.NONE_SUPERVISOR}
              </div>
            )}
          </div>
        </div>
        <div className={styles["job-position"]}>
          <div className={styles["job-position__header"]}>
            <button
              className={styles["job-position__header__btn"]}
              onClick={toggleJobPositons}
            >
              {departmentDetailsLabels.POSITIONS_LIST} <Icon type={IconTypes.ARROW_DOWN} />
            </button>
          </div>
          {showJobs && (
            <ul className={styles["job-position__list"]}>
              {department.job_position.map((position) => (
                <JobPosition
                  position={position}
                  key={position.job_position_id}
                />
              ))}
            </ul>
          )}
        </div>
        <div className={styles["departments"]}>
          <ul className={styles["departments__list"]}>
            {department.child_departments.map((childDepartment) => (
              <ChildDepartment
                key={childDepartment.department_id}
                department={childDepartment}
                changeDepartment={changeDepartment}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default DepartmentDetails;
