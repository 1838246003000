import { Component, ReactNode } from "react";

interface IErrorBoundaryProps {
  children?: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Wystąpił błąd, prosimy skontaktować się z obsługą</h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
