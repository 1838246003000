import { ReactElement } from 'react';

import { Table, Typography } from 'antd';

import type { ColumnsType, TableProps } from 'antd/es/table';

import styles from "../views/BonusWorkerPage.module.scss";

const { Title } = Typography;

interface IPaymentData {
  key: React.Key;
  number: number;
  date: string;
  action: any;
};

const paymentColumns: ColumnsType<IPaymentData> = [
  {
    title: 'Nr',
    dataIndex: "number",
    sorter: {
      compare: (a, b) => a.number - b.number,
    },
    render: (number: number) => `${number}.`,
  },
  {
    title: 'Data',
    dataIndex: 'date',
  },
  {
    title: 'Akcja',
    dataIndex: "action",
    render: (status: string) => status === "in-progress" ? "W przygotowaniu" : "Pobierz"
  }
];

const BonusWorkerPaymentTable = (): ReactElement => {
  const paymentOnChange: TableProps<IPaymentData>['onChange'] = 
    (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };
  
  return (
    <div className={styles["content-section"]}>
      <Table
        columns={paymentColumns}
        // dataSource={paymentsData}  // toDo after creating Payslips storage
        onChange={paymentOnChange}
        pagination={{ pageSize: 5 }}
        showSorterTooltip={false}
        locale={{ 
          emptyText() {
            return (
              <p className={styles["table-empty"]}>
                Na początku przyszłego miesiąca będziesz mógł tu pobrać pasek wynagrodzenia.
              </p>
            )
          },
        }}
        title={() => <Title level={3} style={{marginBottom: 0, fontSize: 24, fontWeight: 700}}>Wypłaty</Title>}
      />
    </div>
  );
};

export default BonusWorkerPaymentTable;
