import { useEffect, useState } from "react";
import { AssessmentTopBarValue } from "../hooks/useAssessmentDisplayData";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../__store/tools/hooks";
import { getDepartmentData } from "../../../../__store/thunks/department.thunk";

export type AssessmentHeaderValue = {
  [key: string]: string;
};

export const useTopBar = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartmentData());
  }, []);

  const evaluatedUser = useAppSelector((state) =>
    state.departments.formUsers?.find(
      (el) => params.id && el.user_id === parseInt(params.id)
    )
  );

  const topBarHeaderValue: AssessmentHeaderValue = {
    YOUR_WORK: "Moja informacja zwrotna",
    CREATE_ASSESSMENT: "Wystawianie informacji zwrotnej",
    WORKERS_WORK: "Informacja zwrotna dla pracowników",
    REVIEW_USER: evaluatedUser
      ? `Ocena Pracownika ${evaluatedUser?.profile.name} ${evaluatedUser?.profile.surname}`
      : `Ocena pracownika`,
  };

  const setAssessmentHeaderTopBarValue = (
    assessmentTopBarValue: AssessmentTopBarValue
  ) => topBarHeaderValue[assessmentTopBarValue];

  const useInputField = (value: string) => {
    const [searchValue, setSearchValue] = useState(value);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) =>
      setSearchValue(e.currentTarget.value);

    const inputFieldProps = { value: searchValue, onChange: handleChange };

    return inputFieldProps;
  };
  return { setAssessmentHeaderTopBarValue, useInputField, topBarHeaderValue };
};
