const RoutesPaths = {
  ROOT_PATH: "/",
  ADMIN: {
    ADMIN_PATH: "/admin",
    ADMIN_INACTIVE_PATH: "/admin-deactivated",
    ADMIN_FIRED_PATH: "/admin-fired",
    STRUCTURE_PATH: "/structure",
    SETTINGS_PATH: "/settings",
  },
  ASSESSMENT: {
    MY_ASSESSMENT_PATH: "/my-assessment",
    MY_ASSESSMENT_PATH_INFO: "/my-assessment/:id",
    CREATE_ASSESSMENT_PATH: "/create-assessment",
    REVIEW_ASSESSMENT_PATH: "/review-assessment",
    REVIEW_USER_ASSESSMENT_PATH: "/review-assessment/:id",
  },
  AUTHORIZATION: {
    FORGOT_PATH: "/forgot",
    NEW_PASSWORD_PATH: "/new-password",
    SUCCESSFULL_CHANGING_PASSWORD_PATH: "/info",
    CHANGE_PASSWORD_PATH: "/change-password",
    LOGIN_PATH: "/login",
    LOGOUT_PATH: "/logout",
    RULES: "/rules",
  },
  PROFILE: {
    YOUR_PROFILE_PATH: "/profile",
    WORKER_PROFILE_PATH: "/worker-profile",
  },
  RANKS: {
    RANKING_PATH: "/ranking",
    POINTS_PATH: "/points",
    BONUS_WORKER_PATH: "/worker/bonus",
    BONUS_MANAGER_PATH: "/manager/bonus",
  },
  SUGGESTIONS: {
    CREATE_SUGGESTION_PATH: "/create-suggestion",
    SUGGESTION_PREVIEW_PATH: "/suggestion-preview/:id",
    MY_SUGGESTIONS_PATH: "/my-suggestions",
    WORKER_SUGGESTIONS_PATH: "/worker-suggestions",
    DEPARTMENT_SUGGESTIONS_PATH: "/department-suggestions",
    OTHER_DEPARTMENTS_SUGGESTIONS_PATH: "/other-departments-suggestions",
    ALL_SUGGESTIONS_PREVIEW_PATH: "/all-suggestions-preview",
  },
};

export default RoutesPaths;
