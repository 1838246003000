import { createAsyncThunk } from "@reduxjs/toolkit";

import { evaluationService } from "./services";
import { evaluationActions, modalActions } from "../slices";
import { evaluationModels } from "../models";

import { ModalClassnames, ServerStatuses, ThunkNames } from "../../data/config";

const { EVALUATION } = ThunkNames;

export const getUsersToEvaluate = createAsyncThunk(
  EVALUATION.GET_USERS_TO_EVALUATE_THUNK,
  (_, thunkAPI) => {
    evaluationService.getUsers().then((data) => thunkAPI.dispatch(evaluationActions.setUsersToEvaluate(data.data)));
  }
);

export const createEvaluation = createAsyncThunk(
  EVALUATION.MAKE_EVALUATION_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.postEvaluation(payload)
      .then((data) => {
        dispatch(getUsersToEvaluate());
        if (data.status === ServerStatuses.CREATED) {
          dispatch(modalActions.setModalClassName(ModalClassnames.CREATE_ASSESSMENT));
        }
      })
      .catch(() => {
        dispatch(modalActions.setModalClassName(ModalClassnames.CREATE_ASSESSMENT_ERROR));
    });
  }
);

export const createEvaluationComment = createAsyncThunk(
  EVALUATION.CREATE_EVALUATION_COMMENT_THUNK,
  (
    {
      content,
      evaluation_id,
      user_id,
    }: evaluationModels.CreateEvaluationCommentData & { user_id: number },
    thunkAPI
  ) => {
    const { dispatch } = thunkAPI;

    evaluationService.postEvaluationComment({ content, evaluation_id })
      .then((data) => {
        if (data.status === ServerStatuses.CREATED) {
          dispatch(
            modalActions.setModalClassName(ModalClassnames.CREATE_COMMENT_TO_EVALUATION)
          );
        }

        thunkAPI.dispatch(getUserEvaluations(user_id));
      })
      .catch(() => {
        dispatch(
          modalActions.setModalClassName(ModalClassnames.CREATE_COMMENT_TO_EVALUATION_ERROR)
        );
      });
  }
);

export const filterEvaluations = createAsyncThunk(
  EVALUATION.FILTER_EVALUATION_THUNK,
  (params: evaluationModels.FilterEvaluationParams, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.getFilteredEvaluations(params).then((data) => {
      dispatch(evaluationActions.setUserRates(data.data));
    });
  }
);

export const filterUsersToEvaluate = createAsyncThunk(
  EVALUATION.FILTER_USERS_TO_EVALUATE,
  (search: string, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.getFilterdUsersToEvaluate(search).then((data) =>
      dispatch(evaluationActions.setUsersToEvaluate(data.data))
    );
  }
);
export const filterAppraisalStatuses = createAsyncThunk(
  EVALUATION.FILTER_APPRAISAL_STATUSES_THUNK,
  (search: string, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.getFilteredUserStatuses(search).then((data) =>
      dispatch(evaluationActions.setUsersAppraisalStatuses(data.data))
    );
  }
);

export const getUsersAppraisalStatuses = createAsyncThunk(
  EVALUATION.GET_USERS_APPRAISAL_STATUSES_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.getUserStatuses().then((data) =>
      dispatch(evaluationActions.setUsersAppraisalStatuses(data.data))
    );
  }
);

export const getUserEvaluations = createAsyncThunk(
  EVALUATION.GET_USER_EVALUATIONS_THUNK,
  (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    evaluationService.getUserRates(id).then((data) => dispatch(evaluationActions.setUserRates(data.data)));
  }
);
