import { AxiosResponse } from "axios";
import axiosInstance from "../../tools/axios";

import { departmentModels } from "../../models";

import { ServerPaths } from "../../../data/config";

const {
  FORM,
  DEPARTMENT,
  DEPARTMENTS,
  DEPARTMENT_SUPERVISORS,
  JOB_POSITION,
  PARENT,
  MOVE,
  AREA_ALL,
  AREA_SELECTED,
  AREA_ADD,
  AREA_UPDATE,
  AREA_REMOVE
} = ServerPaths;

export const getFormInfo = () => axiosInstance.get(`${FORM}/`);

export const getAreas = () => {
  return axiosInstance.get(AREA_ALL);
};

export const getSelectedArea = (payload: any) => {
  return axiosInstance.get(AREA_SELECTED, {
    ...payload
  });
};

export const postArea = (payload: any): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(AREA_ADD, {
    ...payload,
  });
};

export const patchArea = (
  payload: any
) => {
  return axiosInstance.patch(AREA_UPDATE, {
    ...payload,
  });
};

export const removeArea = (payload: any) => {
  return axiosInstance.delete(AREA_REMOVE, {
    ...payload
  });
};

export const getDepartmentList = (payload: any) => {
  return axiosInstance.get(`${FORM}/${payload.id}`);
};

export const fetchDepartmentsWithSupervisors = () => {
  return axiosInstance.get(`${DEPARTMENT_SUPERVISORS}`);
};

export const getDepartmentListByName = (payload: any) => {
  return axiosInstance.get(`${FORM}/${payload.name}`);
};

export const fetchDepartmentsByParentId = (id: number) => {
  return axiosInstance.get(`${DEPARTMENT}${PARENT}/${id}`);
};

export const fetchDepartmentById = (id: number) => {
  return axiosInstance.get(`${DEPARTMENT}/${id}`);
};

export const removeDepartment = (id: number) => {
  return axiosInstance.delete(`${DEPARTMENT}/${id}`);
};

export const updateDepartmentParentId = (
  payload: departmentModels.UpdateParentDepartmentProps
) => {
  return axiosInstance.patch(`${DEPARTMENTS}${MOVE}`, {
    ...payload,
  });
};

export const patchJobPosition = (
  payload: departmentModels.MoveJobPositionPayload
) => {
  return axiosInstance.patch(`${JOB_POSITION}${MOVE}`, {
    job_position_id: payload.job_position_id,
    new_department_id: payload.new_department_id,
  });
};

export const patchDepartmentDetails = (
  payload: departmentModels.PatchDepartmentModel
) => {
  return axiosInstance.patch(`${DEPARTMENT}`, {
    ...payload,
  });
};

export const patchJobPositionDetails = (
  payload: departmentModels.PatchJobPosition
) => {
  return axiosInstance.patch(`${JOB_POSITION}`, {
    job_position_id: payload.job_position_id,
    name: payload.name,
  });
};

export const removeJobPosition = (id: number) =>
  axiosInstance.delete(`${JOB_POSITION}/${id}`);

export const postDepartment = (
  payload: departmentModels.PostDepartmentModel
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${DEPARTMENT}`, {
    ...payload,
  });
};

export const postJobPosition = (
  payload: departmentModels.PostJobPositionModel
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.post(`${JOB_POSITION}`, {
    ...payload,
  });
};
