export const LOGIN_MIN_LENGTH = 5;
export const NAME_MIN_LENGTH = 2;
export const SURNAME_MIN_LENGTH = 3;
export const MIN_PASSWORD_LENGTH = 8;

interface RoleMap {
  [key: string]: string;
};

export const userFormRoles:RoleMap= {
  WORKER: "Rola: Pracownik",
  MANAGER: "Rola: Kierownik",
  BOSS: "Rola: Prezes",
  ADMIN: "Rola: Administrator"
};

export enum PermissionTypes {
  HR_PERMISSION = "hr_permission",
  FINANCE_PERMISSION = "finance_permission",
  PAYMENT_PERMISSION = "payment_permission",
  STRUCTURE_PERMISSION = "structure_permission",
  ALL_SUGGESTIONS_PREVIEW_PERMISSION = "all_suggestions_preview_permission",
  EXTERNAL_COMMENTS_PERMISSION = "external_comments_permission"
};

// ADD USER 

export const passwordValidationMessages = {
  MIN_LENGTH_MESSAGE: "Hasło musi zawierać minimum 8 znaków",
  MIN_LOWER_LETTER_MESSAGE: "Hasło musi zawierać minimum jedną małą literę",
  MIN_UPPER_LETTER_MESSAGE: "Hasło musi zawierać minimum jedną dużą literę",
  MIN_DIGIT_MESSAGE: "Hasło musi zawierać minimum jedną cyfrę",
};

export const userFormInputNames = {
  INPUT_NAME: "name",
  INPUT_SURNAME: "surname",
  INPUT_LOGIN: "login",
  INPUT_PASSWORD: "password",
  INPUT_PASSWORD_REPEAT: "repeatedPassword",
  SELECT_DEPARTMENT: "department",
  SELECT_JOB_POSITION: "job_position_id",
  SELECT_AREA: "area_id",
  INPUT_ADMIN_PERMISSION: "admin_permission",
  INPUT_STRUCTURE_PERMISSION: "structure_permission",
  INPUT_PAYMENT_PERMISSION: "payment_permission",
  INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION: "all_suggestions_preview_permission",
  INPUT_EXTERNAL_COMMENTS_PERMISSION: "external_comments_permission",
  INPUT_IS_EMAIL: "isEmail",
  INPUT_IS_IDENTIFIER: "isIdentifier",
  INPUT_EMAIL: "email",
  INPUT_IDENTIFIER: "identifier",
  INPUT_CONTRACT_TYPE: "contract",
};

export const addUserFormLabels = {
  TITLE: "Formularz rejestracyjny",
  INPUT_NAME: "Imię",
  INPUT_SURNAME: "Nazwisko",
  INPUT_LOGIN: "Login",
  INPUT_PASSWORD: "Hasło",
  INPUT_PASSWORD_REPEAT: "Powtórz hasło",
  SELECT_DEPARTMENT: "Wybierz dział",
  SELECT_JOB_POSITION: "Wybierz stanowisko",
  SELECT_AREA: "Wybierz obszar",
  INPUT_ADMIN_PERMISSION: "Uprawnienia administratora",
  INPUT_STRUCTURE_PERMISSION: "Tworzenie struktury",
  INPUT_PAYMENT_PERMISSION: "Płatności",
  INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION: "Podgląd Wszystkich Zgłoszeń",
  INPUT_EXTERNAL_COMMENTS_PERMISSION: "Komentarze zewnętrzne",
  INPUT_IS_EMAIL: "E-mail",
  INPUT_IS_IDENTIFIER: "Identyfikator",
  INPUT_EMAIL: "Adres e-mail",
  INPUT_IDENTIFIER: "Identyfikator",
  INPUT_CONTRACT_TYPE: "Typ umowy",
};

export const addUserFormsButtons = {
  SEND_BUTTON: "Zarejestruj",
  CANCEL_BUTTON: "Anuluj"
};

export const addUserFormMessages = {
  EMAIL_VALIDATION_ERROR: "Nieprawidłowy adres email",
  LOGIN_TOO_SHORT_ERROR: "Login musi zawierać minimum 5 znaków",
};

// UPDATE CREDENTIALS

export const updateCredentialMessages = {
  PASSWORD_MATCH_ERROR: "Hasła nie są takie same",
  SAME_LOGIN_ERROR: "Podaj nowy Login",
  LOGIN_TOO_SHORT_ERROR: "Login musi zawierać minimum 5 znaków",
};

export const credentialFormInputNames = {
  INPUT_NEW_LOGIN: "login",
  INPUT_NEW_PASSWORD: "password",
  INPUT_NEW_PASSWORD_REPEAT: "repeatPassword",
};

export const updateCredentialFormLabels = {
  NEW_LOGIN: "Wpisz nowy login",
  NEW_PASSWORD: "Wpisz nowe hasło",
  NEW_PASSWORD_REPEAT: "Powtórz hasło",
  INPUT_NEW_LOGIN: "Login",
  INPUT_NEW_PASSWORD: "Nowe hasło",
  INPUT_NEW_PASSWORD_REPEAT: "Powtórz hasło",
};

export const updateCredentialFormButtons = {
  CHANGE_LOGIN_BUTTON: "Zmień login",
  CHANGE_PASSWORD_BUTTON: "Zmień hasło",
  CANCEL_BUTTON: "Anuluj"
};

// Password/Login Change FORM

export enum UserDataChangeType {
  CHANGE_LOGIN = "change-login",
  CHANGE_PASSWORD = "change-password",
}

export const passwordLoginChangeMessages = {
  PASSWORDS_NOT_EQUAL: "Podane hasła nie są identyczne",
  LOGIN_TOO_SHORT: "Login musi zawierać minimum 5 znaków",

};

export const passwordLoginChangeFormInputNames = {
  INPUT_NEW_LOGIN: "login",
  INPUT_ACTUAL_PASSWORD: "passwordToVerify",
  INPUT_NEW_PASSWORD: "password",
  INPUT_NEW_PASSWORD_REPEAT: "repeatPassword",
}

export const passwordLoginChangeFormLabels = {
  YOUR_LOGIN: "Twój login: ",
  NEW_LOGIN: "Wpisz nowy login",
  ACTUAL_PASSWORD: "Podaj aktualne hasło",
  NEW_PASSWORD: "Wpisz nowe hasło",
  REPEAT_PASSWORD: "Powtórz hasło",
  INPUT_NEW_PASSWORD: "Nowe hasło",
  INPUT_NEW_PASSWORD_REPEAT: "Powtórz hasło",
  INPUT_NEW_LOGIN: "Login",
  INPUT_ACTUAL_PASSWORD: "Hasło"
};

export const passwordLoginChangeFormButtons = {
  CANCEL_BUTTON: "Anuluj",
  SAVE_BUTTON: "Zapisz",
  VERIFY_BUTTON: "Zweryfikuj",
}

export const userInfoLabels = {
  PERSONAL_DATA: "Dane osobowe",
  LOGIN: "Login: ",
  EMAIL: "E-mail: ",
  IDENTIFIER: "Identyfikator: ",
  NAME: "Imię: ",
  SURNAME: "Nazwisko: ",
  JOB_POSITION: "Stanowisko: ",
  DEPARTMENT: "Dział: ",
  AREA: "Obszar: ",
  NONE_DEPARTMENT: "Brak",
  CONTRACT: "Typ umowy: ",
  UNKNOWN: "Nieznany"
}

export const updateUserFormMessages = {
  INCORRECT_EMAIL_ERROR: "Nieprawidłowy adres email",
  NEW_DATA_MESSAGE: "Podaj nowe dane",
  NAME_TOO_SHORT: "Imię musi zawierać minimum 2 znaki",
  SURNAME_TOO_SHORT: "Nazwisko musi zawierać minimum 3 znaki",
};

export const updateUserFormLabels = {
  TITLE: "Edytuj dane pracownika",
  INPUT_NAME: "Imię",
  INPUT_SURNAME: "Nazwisko",
  INPUT_EMAIL: "E-mail",
  INPUT_IDENTIFIER: "Identyfikator",
  INPUT_STRUCTURE_PERMISSION: "Tworzenie Struktury",
  INPUT_PAYMENT_PERMISSION: "Płatności",
  INPUT_ALL_SUGGESTION_PREVIEW_PERMISSION: "Podgląd Wszystkich Zgłoszeń",
  INPUT_EXTERNAL_COMMENTS_PERMISSION: "Komentarze zewnętrzne"
};

export const updateUserFormButtons = {
  CANCEL_BUTTON: "Anuluj",
  SEND_BUTTON: "Zapisz"
};
