import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { adminThunk, departmentThunk } from "../../../__store/thunks";

import AddUserValidationForm from "../../../data/validation/addUserValidation";
import { Button, SubmitButton, Icon } from "../../components/ui";

import styled from "./AddUserForm.module.scss";

import { IconTypes, UserRoles } from "../../../data/config";
import { InputTypes, adminData, usersData } from "../../../data/constants";
import { EmploymentStatuses } from "../../../data/constants/admin.data";

const { ContractTypes, ContractTypesTranslated } = adminData;

const {
  addUserFormMessages,
  addUserFormLabels,
  userFormInputNames,
  userFormRoles,
  addUserFormsButtons,
} = usersData;

interface IAddUserFormProps {}

type LocalState = {
  login: string;
  password: string;
  repeatedPassword: string;
  name: string;
  surname: string;
  contract: adminData.ContractTypes;
  employmentStatus: string;
  job_position_id: number;
  area_id: number;
};

interface Permissions {
  hr_permission: boolean;
  finance_permission: boolean;
  structure_permission: boolean;
  payment_permission: boolean;
  admin_permission: boolean;
  all_suggestions_preview_permission: boolean;
  external_comments_permission: boolean;
}

const validation = new AddUserValidationForm();

const AddUserForm: FunctionComponent<IAddUserFormProps> = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [isIdentifier, setIsIdentifier] = useState(false);
  const [workerRole, setWorkerRole] = useState("");
  const [error, setError] = useState({ status: false, message: "" });

  const [permissions, setPermisionss] = useState<Permissions>({
    hr_permission: false,
    finance_permission: false,
    structure_permission: false,
    payment_permission: false,
    admin_permission: false,
    all_suggestions_preview_permission: false,
    external_comments_permission: false,
  });

  const [localState, setLocalState] = useState<LocalState>({
    login: "",
    password: "",
    repeatedPassword: "",
    name: "",
    surname: "",
    contract: ContractTypes.UNSPECIFIED,
    employmentStatus: "WORKING",
    job_position_id: 0,
    area_id: 1,
  });

  const positionsList = useAppSelector(
    (state) => state.departments.positionList
  );
  const departmentList = useAppSelector(
    (state) => state.departments.departments
  );
  const areasList = useAppSelector(
    (state) => state.departments.areas
  );

  const newDepartmentList = departmentList
    .map((item) => {
      return {
        department_id: item.department_id,
        name: item.name,
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const userErrorMessage = useAppSelector((state) => state.users.errorMessage);
  const { isValid, message } = validation.isPasswordValid(localState.password);

  const prepareErrorMessages = () => {
    if (!isValid && localState.password) {
      return message;
    } else if (
      !validation.isMatchedPassword(
        localState.password,
        localState.repeatedPassword
      )
    ) {
      return "Podane hasła muszą się zgadzać";
    } else {
      return "";
    }
  };

  const disabledClass =
    !validation.isAllFieldFilled(localState) ||
    !isValid ||
    !validation.isMatchedPassword(
      localState.password,
      localState.repeatedPassword
    );

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  useEffect(() => {
    if (positionsList.length > 0) {
      setLocalState((prev) => ({
        ...prev,
        job_position_id: positionsList[0].job_position_id,
      }));
      setWorkerRole(positionsList[0].role);
    }
  }, [positionsList]);

  useEffect(() => {
    if (newDepartmentList.length > 0) {
      dispatch(
        departmentThunk.getDepartmentPosition({
          id: newDepartmentList[0].department_id,
        })
      );
    }
  }, [dispatch, departmentList]);

  useEffect(() => {
    setError({
      status: true,
      message: userErrorMessage,
    });
  }, [userErrorMessage]);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const { name, surname, login, repeatedPassword, password, ...data } =
      localState;

    if (!validation.isAllFieldFilled(localState)) return;
    if (!validation.isMatchedPassword(password, repeatedPassword)) return;

    const isEmailValid = validation.isEmailValid(email);

    if (isEmail && !isEmailValid) {
      setError({
        status: true,
        message: addUserFormMessages.EMAIL_VALIDATION_ERROR,
      });
      return;
    }

    const isLoginValid = validation.isLoginValid(login);
    if (!isLoginValid.isValid) {
      setError({
        status: true,
        message: isLoginValid.message,
      });
      return;
    }

    const isPasswordValid = validation.isPasswordValid(password);
    if (!isPasswordValid.isValid) {
      setError({
        status: true,
        message: isPasswordValid.message,
      });
      return;
    }

    setError({
      status: false,
      message: "",
    });

    const userData = {
      ...data,
      name: name.trim(),
      surname: surname.trim(),
      login: login.trim(),
      password: password.trim(),
      email: email ? email.trim() : "",
      identifier: identifier ? identifier.trim() : "",
      ...permissions,
    };

    dispatch(adminThunk.addUser(userData));
  };

  const handleOnChange = (e: any) => {
    setLocalState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  };

  const handleOnChangeIsEmail = () => {
    setIsEmail((prev) => !prev);
  };

  const handleOnChangeIsIdentifier = () => {
    setIsIdentifier((prev) => !prev);
  };

  const handleOnChangeDepartment = (e: any) => {
    dispatch(departmentThunk.getDepartmentPosition({ id: e.target.value }));
  };

  const handleOnChangeArea = (e: any) => {
    const selectedAreaId = e.target.value;
    setLocalState((prevState) => ({
      ...prevState,
      [e.target.name]: Number(selectedAreaId),
    }));  
  }

  const handleChangePosition = (e: any) => {
    const value = JSON.parse(e.target.value);
    setLocalState((prevState) => ({
      ...prevState,
      [e.target.name]: value.id,
    }));
    setWorkerRole(value.role);
  };

  const handleChangeContract = (e: any) => {
    const value: adminData.ContractTypes = e.target.value;
    setLocalState((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  };

  const handleOnChangePermissions = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget;

    setPermisionss((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <form className={styled["form"]} onSubmit={(e) => handleOnSubmit(e)}>
      <div className={styled["form__header"]}>
        <div className={styled["form__header__text"]}>
          <span>{addUserFormLabels.TITLE}</span>
        </div>
        <div className={styled["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={handleOnClose} />
        </div>
      </div>
      <div className={styled["form__context"]}>
        <div className={styled["form__context__section-1"]}>
          <div className={styled["form__context__section-1__top"]}>
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_NAME}
                type={InputTypes.TEXT}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_NAME}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_SURNAME}
                type={InputTypes.TEXT}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_SURNAME}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_LOGIN}
                type={InputTypes.TEXT}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_LOGIN}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_PASSWORD}
                type={InputTypes.PASSWORD}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_PASSWORD}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_PASSWORD_REPEAT}
                type={InputTypes.PASSWORD}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_PASSWORD_REPEAT}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styled["form__context__section-2"]}>
          <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.SELECT_DEPARTMENT}
              className={styled["select"]}
              onChange={(e) => handleOnChangeDepartment(e)}
              defaultValue={-1}
            >
              <option value={-1} disabled>
                {addUserFormLabels.SELECT_DEPARTMENT}
              </option>
              {newDepartmentList.map((department, i) => (
                <option key={i} value={department.department_id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.SELECT_JOB_POSITION}
              className={styled["select"]}
              onChange={(e) => handleChangePosition(e)}
              defaultValue={-1}
            >
              <option value={-1} disabled>
                {addUserFormLabels.SELECT_JOB_POSITION}
              </option>

              {positionsList.map((position, index) => (
                <option
                  key={index}
                  value={JSON.stringify({
                    role: position.role,
                    id: position.job_position_id,
                  })}
                >
                  {position.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.SELECT_AREA}
              className={styled["select"]}
              onChange={(e) => handleOnChangeArea(e)}
              defaultValue={-1}
            >
              <option value={-1} disabled>
                {addUserFormLabels.SELECT_AREA}
              </option>
              {areasList.map((area) => (
                <option key={area.area_id} value={area.area_id}>
                  {area.area_name}
                </option>
              ))}
            </select>
          </div>
          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_ADMIN_PERMISSION}
              id={userFormInputNames.INPUT_ADMIN_PERMISSION}
              checked={permissions.admin_permission}
              onChange={handleOnChangePermissions}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_ADMIN_PERMISSION}
            >
              {addUserFormLabels.INPUT_ADMIN_PERMISSION}
            </label>
          </div>
          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
              id={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
              checked={permissions.structure_permission}
              onChange={handleOnChangePermissions}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_STRUCTURE_PERMISSION}
            >
              {addUserFormLabels.INPUT_STRUCTURE_PERMISSION}
            </label>
          </div>

          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_PAYMENT_PERMISSION}
              id={userFormInputNames.INPUT_PAYMENT_PERMISSION}
              checked={permissions.payment_permission}
              onChange={handleOnChangePermissions}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_PAYMENT_PERMISSION}
            >
              {addUserFormLabels.INPUT_PAYMENT_PERMISSION}
            </label>
          </div>

          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
              id={userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
              checked={permissions.all_suggestions_preview_permission}
              onChange={handleOnChangePermissions}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={
                userFormInputNames.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION
              }
            >
              {addUserFormLabels.INPUT_ALL_SUGGESTIONS_PREVIEW_PERMISSION}
            </label>
          </div>

          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
              id={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
              checked={permissions.external_comments_permission}
              onChange={handleOnChangePermissions}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_EXTERNAL_COMMENTS_PERMISSION}
            >
              {addUserFormLabels.INPUT_EXTERNAL_COMMENTS_PERMISSION}
            </label>
          </div>

          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_IS_IDENTIFIER}
              id={userFormInputNames.INPUT_IS_IDENTIFIER}
              checked={isIdentifier}
              onChange={handleOnChangeIsIdentifier}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_IS_IDENTIFIER}
            >
              {addUserFormLabels.INPUT_IS_IDENTIFIER}
            </label>
          </div>
          {isIdentifier && (
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_IDENTIFIER}
                type={InputTypes.TEXT}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_IDENTIFIER}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </div>
          )}
          <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.INPUT_CONTRACT_TYPE}
              className={styled["select"]}
              onChange={(e) => handleChangeContract(e)}
              defaultValue={-1}
            >
              <option value={-1} disabled>
                {addUserFormLabels.INPUT_CONTRACT_TYPE}
              </option>
              {Array.from(
                ContractTypesTranslated,
                ([type, name]: [string, string]) => (
                  <option key={type} value={type}>
                    {name}
                  </option>
                )
              )}
            </select>
          </div>

          <div className={styled["form__context__checkbox-container"]}>
            <input
              type={InputTypes.CHECKBOX}
              name={userFormInputNames.INPUT_IS_EMAIL}
              id={userFormInputNames.INPUT_IS_EMAIL}
              checked={isEmail}
              onChange={handleOnChangeIsEmail}
            />
            <label
              className={styled["formLabel"]}
              htmlFor={userFormInputNames.INPUT_IS_EMAIL}
            >
              {addUserFormLabels.INPUT_IS_EMAIL}
            </label>
          </div>
          {isEmail && (
            <div className={styled["form__context__input-container"]}>
              <input
                name={userFormInputNames.INPUT_EMAIL}
                type={InputTypes.TEXT}
                className={styled["input"]}
                placeholder={addUserFormLabels.INPUT_EMAIL}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}
          <div className={styled["form__context__select-container"]}>
            <select
              name={userFormInputNames.INPUT_CONTRACT_TYPE}
              className={styled["select"]}
              onChange={(e) => handleChangeContract(e)}
              defaultValue={-1}
            >
              <option value={-1} disabled>
                {addUserFormLabels.INPUT_CONTRACT_TYPE}
              </option>
              {Array.from(
                ContractTypesTranslated,
                ([type, name]: [string, string]) => (
                  <option key={type} value={type}>
                    {name}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
        <div className={styled["form__context__section-role"]}>
          {workerRole === UserRoles.WORKER && (
            <span>{userFormRoles.WORKER}</span>
          )}
          {workerRole === UserRoles.MANAGER && (
            <span>{userFormRoles.MANAGER}</span>
          )}
          {workerRole === UserRoles.BOSS && <span>{userFormRoles.BOSS}</span>}
          {workerRole === UserRoles.ADMIN && <span>{userFormRoles.ADMIN}</span>}
        </div>
        <div className={styled["form__context__section-error"]}>
          {error.status && (
            <span className={styled["form__context__section-error__message"]}>
              {error.message}
            </span>
          )}
          {prepareErrorMessages() && (
            <span className={styled["form__context__section-error__message"]}>
              {prepareErrorMessages()}
            </span>
          )}
        </div>
      </div>
      <div className={styled["form__footer"]}>
        <div className={styled["form__footer__button-container"]}>
          <Button
            hiddenStyles=""
            textValue={addUserFormsButtons.CANCEL_BUTTON}
            buttonClassName="reset-button"
            buttonFunction={handleOnClose}
          />
        </div>
        <div className={styled["form__footer__button-container"]}>
          <SubmitButton
            buttonClassName="submit-button"
            disabledClass={disabledClass}
            value={addUserFormsButtons.SEND_BUTTON}
          />
        </div>
      </div>
    </form>
  );
};

export default AddUserForm;
