import { ReactElement } from "react";
import styles from "./SpinnerModal.module.scss";

interface SpinnerModalProps {
  className?: string;
}

function SpinnerModal({ className }: SpinnerModalProps): ReactElement {
  return (
    <div className={`${styles["spinner-modal"]} ${className}`}>
      <div className={styles["spinner-container"]}>
        <div className={styles["spinner"]}></div>
      </div>
    </div>
  );
}

export default SpinnerModal;

