import { AxiosResponse } from "axios";
import { migrationModels } from "../../models";
import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";

const {
  SUGGESTIONS,
  MIGRATE,
  PARENTS,
  REVERT,
  REVERT_FROM_TEMP,
  ASSIGN_MANAGER,
  TEMP_POSITION,
  WORKERS,
} = ServerPaths;


export const fetchDepartmentWorkersForMigrations = (
  userId: number
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.get(`${SUGGESTIONS}${WORKERS}?userId=${userId}`)
};
  
export const fetchParentSupervisorForMigrations = (
  userId: number
): Promise<AxiosResponse<any, any>> => {
  return axiosInstance.get(`${SUGGESTIONS}${PARENTS}?userId=${userId}`)
};
  
export const migrateSuggestions = (
  updatePayload: migrationModels.SuggestionMigrationPayload
): Promise<AxiosResponse<any, any>> => {
  const { userId, selectedManagerId } = updatePayload;
  return axiosInstance.patch(`${SUGGESTIONS}${MIGRATE}`, { userId, selectedManagerId })
};
  
export const revertSuggestions = (
  updatePayload: migrationModels.SuggestionMigrationPayload
): Promise<AxiosResponse<any, any>> => {
  const { userId } = updatePayload;
  return axiosInstance.patch(`${SUGGESTIONS}${REVERT}`, { userId })
};
  
export const createTemporaryManager = (
  updatePayload: migrationModels.SuggestionMigrationPayload
): Promise<AxiosResponse<any, any>> => {
  const { newManagerId } = updatePayload;
  return axiosInstance.patch(`${SUGGESTIONS}${TEMP_POSITION}`, { newManagerId })
};
  
export const migrateSuggestionToTempManager = (
  updatePayload: migrationModels.SuggestionMigrationPayload
): Promise<AxiosResponse<any, any>> => {
  const { userId, newManagerId } = updatePayload;
  return axiosInstance.patch(`${SUGGESTIONS}${ASSIGN_MANAGER}`, { userId, newManagerId })
};
  
export const revertSuggestionsFromTempManager = (
  updatePayload: migrationModels.SuggestionMigrationPayload
): Promise<AxiosResponse<any, any>> => {
  const { userId, tempManagerId } = updatePayload;
  return axiosInstance.patch(`${SUGGESTIONS}${REVERT_FROM_TEMP}`, { userId, tempManagerId })
};
