import { createSlice } from "@reduxjs/toolkit";

import { bonusModels } from "../models";

import { BonusStatuses, SliceNames } from "../../data/config";

const initialState: bonusModels.BonusState = {
  allBonuses: [],
  userBonuses: [],
  generatedBonuses: [],
  awardedBonuses: [],
  paidBonuses: [],
  suspendedBonuses: [],
  selectedBonusId: -1,
  selectedUserId: -1,
  selectedStartDate: null,
  selectedEndDate: null,
  status: BonusStatuses.GENERATED,
};

const bonusSlice = createSlice({
  name: SliceNames.BONUS,
  initialState,
  reducers: {
    setAllBonuses: (state, { payload }) => {
      state.allBonuses = payload;
    },
    setUserBonuses: (state, { payload }) => {
      state.userBonuses = payload;
    },
    setGeneratedBonuses: (state, { payload }) => {
      state.generatedBonuses = payload;
    },
    setAwardedBonuses: (state, { payload }) => {
      state.awardedBonuses = payload;
    },
    setPaidBonuses: (state, { payload }) => {
      state.paidBonuses = payload;
    },
    setSuspendedBonuses: (state, { payload }) => {
      state.suspendedBonuses = payload;
    },
    setSelectedBonusId: (state, { payload }) => {
      state.selectedBonusId = payload;
    },
    setSelectedUserId: (state, { payload }) => {
      state.selectedUserId = payload;
    },
    setSelectedStartDate: (state, { payload }) => {
      state.selectedStartDate = payload;
    },
    setSelectedEndDate: (state, { payload }) => {
      state.selectedEndDate = payload;
    },
    clearBonuses: (state) => {
      state.allBonuses = [];
      state.userBonuses = [];
      state.generatedBonuses = [];
      state.awardedBonuses = [];
      state.paidBonuses = [];
      state.suspendedBonuses = [];
    },
    setBonusesByStatus: (state, { payload }) => {
      state.status = payload.bonusStatus;

      switch (payload.bonusStatus) {
        case BonusStatuses.GENERATED:
          state.generatedBonuses = payload.bonuses;
          break;

        case BonusStatuses.AWARDED:
          state.awardedBonuses = payload.bonuses;
          break;

        case BonusStatuses.PAID:
          state.paidBonuses = payload.bonuses;
          break;

        case BonusStatuses.SUSPENDED:
          state.suspendedBonuses = payload.bonuses;
          break;
      
        default:
          console.error("Unknown status");
          break;
      }
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setInitialBonusState: () => initialState,
  },
});

export const {
  setAllBonuses,
  setUserBonuses,
  setGeneratedBonuses,
  setAwardedBonuses,
  setPaidBonuses,
  setSuspendedBonuses,
  setSelectedBonusId,
  setSelectedUserId,
  setSelectedStartDate,
  setSelectedEndDate,
  setBonusesByStatus,
  setStatus,
  clearBonuses,
  setInitialBonusState
} = bonusSlice.actions;

export default bonusSlice.reducer;
