import { ReactElement } from "react";
import SuggestionInfo from "./SuggestionInfo";

import styles from "./SuggestionsDashboard.module.scss";
import { IDashboardSuggestionsListProps } from "../types/SuggestionsProps.types";

const DashboardSuggestionsList = (
  { suggestionsList, suggestionsListClassName, filtersFlag, filtersClassName, readOnly } 
  : IDashboardSuggestionsListProps) : ReactElement => {
  return (
    <div
      className={`${styles[suggestionsListClassName]} ${
        filtersFlag && styles[filtersClassName]
      }`}
    >
      {suggestionsList.map((suggestion) => (
        <SuggestionInfo key={suggestion.suggestion_id} readOnly={readOnly} {...suggestion} />
      ))}
    </div>
  );
};

export default DashboardSuggestionsList;
