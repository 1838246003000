import { createSlice } from "@reduxjs/toolkit";

import { reportModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: reportModels.ReportState = {
  errorMessage: "",
  url: "",
};

const reportSlice = createSlice({
  name: SliceNames.REPORT,
  initialState,
  reducers: {
    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setReportURL: (state, { payload }) => {
      state.url = payload;
    },
    setReportInitialState: () => initialState,
  },
});

export default reportSlice.reducer;

export const { 
  setErrorMessage, 
  setReportURL,
  setReportInitialState 
} = reportSlice.actions;
