import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminThunk, suggestionThunk } from ".";
import { migrationModels } from "../models";
import { migrationActions } from "../slices";
import { migrationService } from "./services";

import { ThunkNames } from "../../data/config";

const { MIGRATION } = ThunkNames;

export const migrateDeactivatingEvaluatorSuggestions = createAsyncThunk(
  MIGRATION.MIGRATE_SUGGESTIONS_THUNK,
  (payload: migrationModels.SuggestionMigrationPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
      return migrationService.migrateSuggestions(payload).then(() => {
        dispatch(suggestionThunk.getSuggestionsInfo());
        dispatch(adminThunk.fetchUsers());
    });
  }
);
  
export const createTemporaryManagerForSubstitution = createAsyncThunk(
  MIGRATION.CREATE_TEMP_MANAGER_THUNK,
  (payload: migrationModels.SuggestionMigrationPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
      return migrationService.createTemporaryManager(payload).then(() => {
        dispatch(suggestionThunk.getSuggestionsInfo());
        dispatch(adminThunk.fetchUsers());
    });
  }
);
  
export const migrateDeactivatingEvaluatorSuggestionsToTemporaryManager = createAsyncThunk(
  MIGRATION.MIGRATE_SUGGESTIONS_TO_TEMP_THUNK,
  (payload: migrationModels.SuggestionMigrationPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
      return migrationService.migrateSuggestionToTempManager(payload).then(() => {
        dispatch(suggestionThunk.getSuggestionsInfo());
        dispatch(adminThunk.fetchUsers());
    });
  }
);
  
export const revertReactivatingEvaluatorSuggestions = createAsyncThunk(
  MIGRATION.REVERT_SUGGESTIONS_THUNK,
  (payload: migrationModels.SuggestionRevertPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
      return migrationService.revertSuggestions(payload).then(() => {
        dispatch(suggestionThunk.getSuggestionsInfo());
        dispatch(adminThunk.fetchUsers());
    });
  }
);
  
export const revertSuggestionsFromTemporaryEvaluator = createAsyncThunk(
  MIGRATION.REVERT_SUGGESTIONS_FROM_TEMP_THUNK,
  (payload: migrationModels.SuggestionRevertPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
      return migrationService.revertSuggestionsFromTempManager(payload).then(() => {
        dispatch(suggestionThunk.getSuggestionsInfo());
        dispatch(adminThunk.fetchUsers());
    });
  }
);
  
export const getDepartmentWorkersForTemporaryEvaluator = createAsyncThunk(
  MIGRATION.GET_WORKERS_FOR_TEMP_THUNK,
  (userId: number, thunkAPI) => {
    const { dispatch } = thunkAPI;
    return migrationService.fetchDepartmentWorkersForMigrations(userId).then((response) => {
      dispatch(migrationActions.setDepartmentWorkers(response.data.workers));
    });
  }
);
  
export const getParentSupervisorsToDeactivateEvaluator = createAsyncThunk(
  MIGRATION.GET_PARENT_SUPERVISORS_THUNK,
  (userId: number, thunkAPI) => {
    const { dispatch } = thunkAPI;
    return migrationService.fetchParentSupervisorForMigrations(userId).then((response) => {
      dispatch(migrationActions.setParentSupervisors(response.data.supervisors));
    });
  }
);
