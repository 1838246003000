import { ReactElement } from "react";

import { DepartmentItem } from ".";

import styles from "./DepartmentsList.module.scss";
import { IDepartmentsListProps } from "../../types/AdminProps.types";
import { adminData } from "../../../../data/constants";

const { departmentsListHeader} = adminData;

function DepartmentsList({
  departments,
  supervisorDepartment,
  selectedDepartmentId,
  handleClick,
}: IDepartmentsListProps):ReactElement {
  return (
    <div className={styles["departments"]}>
      <p className={styles["departments__header"]}>{departmentsListHeader}</p>
      <ul className={styles["departments__supervisor-department"]}>
        <DepartmentItem
          handleClick={handleClick}
          department={supervisorDepartment}
          selectedDepartmentId={selectedDepartmentId}
        />
      </ul>
      <ul
        className={styles["departments__list"]}
        style={{ height: `calc(100vh - ${270}px)` }}
      >
        {departments.map((department, i) => (
          <DepartmentItem
            key={department.department_id}
            handleClick={handleClick}
            department={department}
            selectedDepartmentId={selectedDepartmentId}
          />
        ))}
      </ul>
    </div>
  );
}

export default DepartmentsList;
