import { FunctionComponent, ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { adminThunk } from "../../../__store/thunks";
import { modalActions } from "../../../__store/slices";

import styled from './PopupYesNo.module.scss';

import { suggestionsData } from "../../../data/constants";
const { popupYesNoButtons } = suggestionsData;

export interface IPopupYesNoProps {
  text: string;
  type: string;
};
  
export interface IPopupYesNoMapType {
  [key: string]: any;
};

export const PopupYesNoMap: IPopupYesNoMapType = {
  'remove-user': adminThunk.removeUser,
};

const PopupYesNo: FunctionComponent<IPopupYesNoProps> = ({text, type}):ReactElement => {
  const dispatch = useAppDispatch()
  const user_id = useAppSelector((state) => state.users.user_id);
  const handleOnClose = () => {
    dispatch(modalActions.closeModal());
  } 

  const handleOnClick = () => {
    dispatch(PopupYesNoMap[type]({user_id: user_id}));
  }

  return (
    <div className={`${styled["popup"]}`}>
      <div className={`${styled["popup__header"]}`}>
      <span>{text}</span>
      </div>
      <div className={`${styled["popup__buttons"]}`}>
        <div className={`${styled["popup__buttons__button"]}`}>
          <button onClick={handleOnClose} className={`${styled["button"]}`}>{popupYesNoButtons.REJECT_BUTTON}</button>
        </div>
        <div className={`${styled["popup__buttons__button"]}`}>
          <button onClick={handleOnClick} className={`${styled["button"]}`}>{popupYesNoButtons.APPROVE_BUTTON}</button>
        </div>
      </div>
    </div>
  );
}
 
export default PopupYesNo;