import { useAssessmentDisplayData } from "../hooks/useAssessmentDisplayData";
import styles from "./AssessmentsHeaderList.module.scss";

const AssessmentsHeaderList = () => {
  const { assessmentListHeaderValue, location } = useAssessmentDisplayData();

  return (
    <ul className={styles["assessment-list"]}>
      {location.pathname !== "/review-assessment" ? (
        assessmentListHeaderValue.map((el) => (
          <li key={el} className={styles["assessment-list__item"]}>
            {el}
          </li>
        ))
      ) : (
        <>
          <li className={styles["assessment-list__items--container"]}>
            {
              <li
                key={assessmentListHeaderValue[0]}
                className={styles["item--container__item"]}
              >
                {assessmentListHeaderValue[0]}
              </li>
            }
            <li className={styles["assessment-list__items--container--sm"]}>
              {assessmentListHeaderValue.map((el, index) => {
                return (
                  index > 0 && (
                    <li key={el} className={styles["items--container__item"]}>
                      {el}
                    </li>
                  )
                );
              })}
            </li>
          </li>
        </>
      )}
    </ul>
  );
};

export default AssessmentsHeaderList;
