import { adminModels } from "../../__store/models";
import { usersData } from "../constants";

const { 
  LOGIN_MIN_LENGTH, 
  MIN_PASSWORD_LENGTH, 
  passwordValidationMessages, 
  addUserFormMessages 
} = usersData;

class AddUserValidationForm {
  isEmailValid(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  isAllFieldFilled(state: adminModels.UserForm): boolean {
    let isValid = true;
    for (const values of Object.values(state)) {
      if (values === null || values === 0) {
        isValid = false;
      } else if (typeof values !== "number") {
        if (!values.length) {
          isValid = false;
        }
      }
    }
    return isValid;
  }

  isMatchedPassword(password: string, repeatedPassword: string): boolean {
    return password === repeatedPassword;
  }

  isPasswordValid(password: string) {
    let isValid = true;
    let message = "";

    if (password.length < MIN_PASSWORD_LENGTH) {
      isValid = false;
      message = passwordValidationMessages.MIN_LENGTH_MESSAGE;
    } else if (password.search(/[a-z]/) < 0) {
      isValid = false;
      message = passwordValidationMessages.MIN_LOWER_LETTER_MESSAGE;
    } else if (password.search(/[A-Z]/) < 0) {
      isValid = false;
      message = passwordValidationMessages.MIN_UPPER_LETTER_MESSAGE;
    } else if (password.search(/[0-9]/) < 0) {
      isValid = false;
      message = passwordValidationMessages.MIN_DIGIT_MESSAGE;
    }

    return { isValid, message };
  }

  isLoginValid(login: string) {
    let isValid = true;
    let message = "";

    if (login.length < LOGIN_MIN_LENGTH) {
      isValid = false;
      message = addUserFormMessages.LOGIN_TOO_SHORT_ERROR;
    }

    return { isValid, message };
  }
}

export default AddUserValidationForm;
