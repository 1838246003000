import { appHeaders } from "../../../data/constants";
import { NavBar } from "../../components/ui";
import NavTopBar from "../../components/ui/NavTopBar";
import { DashboardUserSettings } from "../components";

import styles from "./UserPanelPage.module.scss";

const UserPanelPage = () => {
  const { SETTINGS_HEADER } = appHeaders;

  return (
    <div className={styles["page"]}>

      <NavBar />

      <div className={styles["page__dashboard"]}>
        <NavTopBar topBarHeader={SETTINGS_HEADER} />
        <DashboardUserSettings />
      </div>
    </div>
  );
};

export default UserPanelPage;
