import { ReactElement } from "react";

import { Icon } from "../../../components/ui";

import styles from "./Department.module.scss";
import { IDepartmentProps } from "../../types/AdminProps.types";
import { IconTypes } from "../../../../data/config";

function Department({ department, handleClick, supervisor }: IDepartmentProps):ReactElement {
  return (
    <li
      key={department.department_id}
      className={`${styles["department"]} ${
        supervisor && styles["supervisor"]
      } `}
      onClick={() =>
        handleClick({
          departmentId: department.department_id,
          breadcrumbs: [],
        })
      }
    >
      <div className={styles["department__icon"]}>
        <Icon type={IconTypes.BUSINESS} />
      </div>
      <span className={styles["department__name"]}>{department.name}</span>
    </li>
  );
}

export default Department;
