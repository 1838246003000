import { ReactElement } from "react";

import styled from "../UpdateUserForm.module.scss";
import { Radio } from "antd";
import { MultiUsersSelect } from ".";

import { activationData } from "../../../../data/constants";
import { IDecisionRadioBox } from "./activation.types";

const { ACTIVATION_MESSAGES, ActivationDecisions } = activationData;
const { Group } = Radio;

const DecisionRadioBox = ({
  userID,
  workersList,
  parentsList,
  decisionSection,
  decisionHandler,
  isDisabled,
  selectedSupervisor,
  selectedWorkerToTempManager,
  selectSupervisorHandler,
  selectWorkerToTempHandler,
}: IDecisionRadioBox): ReactElement => {
  if (parentsList?.length > 0 || workersList?.length > 0) {
    return (
      <Group
        className={styled["activation-box__decision"]}
        name="decision-section"
        defaultValue={decisionSection}
        size="large"
        onChange={decisionHandler}
        disabled={isDisabled}
      >
        {parentsList?.length > 0 && (
          <div
            className={
              decisionSection === ActivationDecisions.REDIRECT
                ? styled["decision__section"]
                : styled["decision__section--inactive"]
            }
          >
            <Radio
              className={styled["radio-wrapper"]}
              key={ActivationDecisions.REDIRECT}
              value={ActivationDecisions.REDIRECT}
            >
              <h3 className={styled["subtitle"]}>
                {ACTIVATION_MESSAGES.REDIRECT_TO_PARENTS}{" "}
                {parentsList.length === 1 && (
                  <strong>
                    - {parentsList[0].profile.name}{" "}
                    {parentsList[0].profile.surname}.
                  </strong>
                )}
              </h3>
            </Radio>
            <div className={styled["content"]}>
              {parentsList?.length > 1 && (
                <MultiUsersSelect
                  userID={userID}
                  usersArray={parentsList}
                  selectedUser={selectedSupervisor}
                  selectHandler={selectSupervisorHandler}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </div>
        )}

        {workersList?.length > 0 && (
          <div
            className={
              decisionSection === ActivationDecisions.REPLACEMENT
                ? styled["decision__section"]
                : styled["decision__section--inactive"]
            }
          >
            <Radio
              className={styled["radio-wrapper"]}
              key={ActivationDecisions.REPLACEMENT}
              value={ActivationDecisions.REPLACEMENT}
            >
              <h3 className={styled["subtitle"]}>
                {ACTIVATION_MESSAGES.REDIRECT_TO_TEMP}{" "}
                {workersList?.length === 1 && (
                  <strong>
                    - {workersList[0].name} {workersList[0].surname}.
                  </strong>
                )}
              </h3>
            </Radio>
            <div className={styled["content"]}>
              {workersList?.length > 1 && (
                <MultiUsersSelect
                  userID={userID}
                  usersArray={workersList}
                  selectedUser={selectedWorkerToTempManager}
                  selectHandler={selectWorkerToTempHandler}
                  isDisabled={isDisabled}
                />
              )}
            </div>
          </div>
        )}
      </Group>
    );
  } else
    return (
      <span>
        {ACTIVATION_MESSAGES.MANAGER_DEACTIVATION_NONE_DECISION_POSIBBLE}
      </span>
    );
};

export default DecisionRadioBox;
