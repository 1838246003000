
import { ReactElement, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { format } from "date-fns";
import { Avatar } from "antd";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";
import {
  modalActions,
  suggestionActions,
  formsActions,
} from "../../../__store/slices";

import { ModalClassnames, MainStatuses, UserRoles } from "../../../data/config";
import { adminData, suggestionsData } from "../../../data/constants";

import styles from "./SuggestionInfo.module.scss";
import { ISuggestionsWithReadability } from "../types/SuggestionsProps.types";
import SuggestionActionButtons from "./SuggestionActionButtons";
import { DATE_OPTIONS } from "../../../data/constants/suggestions.data";

const { avatarStyles } = adminData;

// const { SUGGESTION_INFO_RONLY, EVALUATE_FORM, EVALUATE_APPEALED } =
//   ModalClassnames;

const {
  DATE_FORMAT,
  DEPARTMENT_VISIBLE_CHARS_MAX,
  TITLE_VISIBLE_CHARS_MAX,
  SuggestionTypes,
  SuggestionTypesTranslated,
} = suggestionsData;

const SuggestionInfo = (props: ISuggestionsWithReadability): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRole = useAppSelector((state) => state.auth.role);
  const userID = useAppSelector((state) => state.auth.user_id);
  const date = format(new Date(props.date), DATE_FORMAT, DATE_OPTIONS);

  const allCommentaries = useAppSelector((state) => state.suggestions.allCommentaries);
  const selectedSuggestionCommentaries = allCommentaries?.filter(
    commentary => commentary.suggestion_id === props.suggestion_id
  ).filter(commentary => commentary.content !== "");

  const openSuggestionInfo = () => {
    // dispatch(modalActions.openModal());

    if (props.readOnly) {
      navigate(`/suggestion-preview/${props.suggestion_id}?readOnly=${props.readOnly}`);
    } else {
      navigate(`/suggestion-preview/${props.suggestion_id}`);
    }

    dispatch(formsActions.setFormInfoData(props));
    dispatch(formsActions.setSuggestionComment(selectedSuggestionCommentaries));
    dispatch(formsActions.setPickedSuggestionId(props.suggestion_id));

    dispatch(formsActions.setSuggestionPath(props.suggestionPath));
    dispatch(formsActions.setResultPath(props.resultPath));
    dispatch(formsActions.setSolutionPath(props.solutionPath));
    dispatch(formsActions.setVerificationPath(props.verificationPath));
    dispatch(formsActions.setCorrectionPath(props.correctionPath));
    dispatch(formsActions.setEndResultPath(props.endResultPath));

    dispatch(formsActions.setSolutionFilesNames(props.soultionFilesName));
    dispatch(formsActions.setResultFilesNames(props.resultFilesName));
    dispatch(formsActions.setSuggestionFilesNames(props.suggestionFilesName));
    dispatch(formsActions.setVerificationFilesNames(props.verificationFilesName));
    dispatch(formsActions.setCorrectionFilesNames(props.correctionFilesName));
    dispatch(formsActions.setEndResultFilesNames(props.endResultFilesName));

    // if ((userRole === UserRoles.MANAGER || UserRoles.BOSS) && userID === props.evaluating_user?.user_id) {
    //   if (props.status === MainStatuses.REPORT) {
    //     dispatch(modalActions.setModalClassName(EVALUATE_FORM));
    //   } else if (props.status === MainStatuses.APPEALED) {
    //     dispatch(modalActions.setModalClassName(EVALUATE_APPEALED));
    //   }
    //   } else {
    //     dispatch(modalActions.setModalClassName(SUGGESTION_INFO_RONLY));
    //   }
    // } else {
    //   dispatch(modalActions.setModalClassName(SUGGESTION_INFO_RONLY));
    // }
  };

  useEffect(() => {
    dispatch(
      suggestionActions.setIsSuggestionSecondReported(props.is_second_reported)
    );
  }, []);

  const isReportingUser = props.reporting_user;
  const { isActive } = isReportingUser;

  const AvatarComponent = () => (
    <Avatar style={avatarStyles(isActive)} size="large">
      {`${props.reporting_user.profile.name.charAt(0)}
          ${props.reporting_user.profile.surname.charAt(0)}`}
    </Avatar>
  );

  return (
    <div
      className={styles["suggestion-info"]}
      onClick={openSuggestionInfo}
      title={
        isReportingUser
          ? `${props.reporting_user.profile.name} ${props.reporting_user.profile.surname}`
          : "Brak pracownika"
      }
    >
      <div className={styles["suggestion-info__date"]}>
        <span className={styles["date__span"]}>{date}</span>
      </div>

      <div
        className={`${styles["suggestion-info__user-info"]} ${
          !isReportingUser && styles["no-employee"]
        }`}
      >
        <div className={styles["user-info__username"]}>
          {isReportingUser ? (
            <AvatarComponent />
          ) : (
            <span className={styles["no-employee"]}>Brak pracownika</span>
          )}
        </div>
      </div>

      <div className={styles["suggestion-info__department"]}>
        <p className={styles["problems__problem"]}>
          {
            props.evaluating_user &&
            props.evaluating_user.department.name.length >
            DEPARTMENT_VISIBLE_CHARS_MAX
              ? `${props.evaluating_user?.department.name.slice(
                  0,
                  DEPARTMENT_VISIBLE_CHARS_MAX
                )} ...`
              : props.evaluating_user?.department.name
          }
        </p>
      </div>

      <div className={styles["suggestion-info__problems--container"]}>
        <p className={styles["suggestion-info__problems"]}>
          {props.title.length > TITLE_VISIBLE_CHARS_MAX
            ? `${props.title.slice(0, TITLE_VISIBLE_CHARS_MAX)} ...`
            : props.title}
        </p>
      </div>

      <div className={styles["suggestion-info__problems--tags-container"]}>
        <p
          className={styles["suggestion-info__tag"]}
          data-type={props.suggestion_type}
        >
          {SuggestionTypesTranslated.get(
            props.suggestion_type as suggestionsData.SuggestionTypes
          )
            ?.substring(0, 3)
            .toUpperCase()}
        </p>
      </div>
      <div
        className={styles["suggestion-info__buttons"]}
        onClick={(e) => e.stopPropagation()}
      >
        {/* {renderCorrectButtons[userRole]} */}

        <SuggestionActionButtons
          status={props.status as MainStatuses}
          substatus={props.substatus}
          suggestionData={props}
          suggestionID={+props.suggestion_id}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
};

export default SuggestionInfo;
