import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { departmentActions, modalActions } from "../slices";
import { departmentService } from "./services";
import { departmentModels } from "../models";

import { ModalClassnames, ServerStatuses, ThunkNames } from "../../data/config";

const {
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAIL,
  EDIT_AREA_SUCCESS,
  EDIT_AREA_FAIL,
  REMOVE_AREA,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAIL,
  REMOVE_DEPARTMENT_POSITION,
  CREATE_JOB_POSITION_SUCCESS,
  CREATE_JOB_POSITION_FAIL,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_FAIL,
  REMOVE_JOB_POSITION,
  EDIT_JOB_POSITION_SUCCESS,
  EDIT_JOB_POSITION_FAIL,
} = ModalClassnames;

const { DEPARTMENTS } = ThunkNames;

export const getDepartmentData = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .getAreas()
      .then((data) => dispatch(departmentActions.setAreas(data.data)));

    departmentService.getFormInfo().then((data) => {
      dispatch(departmentActions.setDepartments(data.data.departments));
      dispatch(departmentActions.setSuperVisors(data.data.supervisor));
      dispatch(departmentActions.setFormUsers(data.data.users));
    });
  }
);

export const getDepartmentPosition = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_POSITION,
  (payload: departmentModels.DepartmentId, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .getDepartmentList(payload)
      .then((data) => {
        dispatch(departmentActions.setPositionsList(data.data.positionList));
      })
      .catch((err) => err);
  }
);

export const getDepartmentPositionByName = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_POSITION_BY_NAME_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .getDepartmentListByName(payload)
      .then((data) => {
        dispatch(departmentActions.setPositionsList(data.data.positionList));
      })
      .catch((err) => err);
  }
);

export const createArea = createAsyncThunk(
  DEPARTMENTS.POST_AREA_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .postArea({ data: payload })
      .then((data) => {
        if (data.status === ServerStatuses.CREATED) {
          dispatch(modalActions.setModalClassName(CREATE_AREA_SUCCESS));
          dispatch(getDepartmentData());
          dispatch(getMainDepartments());
          dispatch(getSuperVisorDepartment());

          departmentService
            .getAreas()
            .then((data) => dispatch(departmentActions.setAreas(data.data)));
        } else {
          dispatch(modalActions.setModalClassName(CREATE_AREA_FAIL));
        }
      })
      .catch((err) => {
        dispatch(modalActions.setModalClassName(CREATE_AREA_FAIL));
      });
  }
);

export const getAreaById = createAsyncThunk(
  DEPARTMENTS.GET_SELECTED_AREA_THUNK,
  (payload: any, thunkAPI) =>
    departmentService
      .getSelectedArea(payload)
      .then((data) => {
        thunkAPI.dispatch(departmentActions.setSelectedArea(data.data));
      })
      .catch((err) => err)
);

export const editArea = createAsyncThunk(
  DEPARTMENTS.EDIT_AREA_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    departmentService
      .patchArea({ data: payload })
      .then((data) => {
        if (data.status) {
          const store = getState() as RootState;
          dispatch(getAreaById(store.departments.selectedArea));
       
          departmentService
            .getAreas()
            .then((data) => dispatch(departmentActions.setAreas(data.data)));
       
          dispatch(modalActions.setModalClassName(EDIT_AREA_SUCCESS));
        }
      })
      .catch(() => {
        dispatch(modalActions.setModalClassName(EDIT_AREA_FAIL));
    });
  }
);

export const deleteArea = createAsyncThunk(
  DEPARTMENTS.DELETE_AREA_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.removeArea({ data: payload })
      .then((data) => {
        if (data.status) {
          dispatch(modalActions.setModalClassName(REMOVE_AREA));
          dispatch(modalActions.openModal());

          departmentService
            .getAreas()
            .then((data) => dispatch(departmentActions.setAreas(data.data)));
        }
    });
  }
);

export const createDepartment = createAsyncThunk(
  DEPARTMENTS.POST_DEPARTMENT_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .postDepartment(payload)
      .then((data) => {
        if (data.status === ServerStatuses.CREATED) {
          dispatch(modalActions.setModalClassName(CREATE_DEPARTMENT_SUCCESS));
          dispatch(getDepartmentData());
          dispatch(getMainDepartments());
          dispatch(getSuperVisorDepartment());
          dispatch(getStructureDepartments(payload.parent_id));
          dispatch(getStructureChildDepartments(payload.parent_id));
          dispatch(getDepartmentById(payload.parent_id));
        } else {
          dispatch(modalActions.setModalClassName(CREATE_DEPARTMENT_FAIL));
        }
      })
      .catch((err) => {
        dispatch(modalActions.setModalClassName(CREATE_DEPARTMENT_FAIL));
      });
  }
);

export const getStructureDepartments = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_BY_PARENT_THUNK,
  (payload: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .fetchDepartmentsByParentId(payload)
      .then((data) => {
        dispatch(departmentActions.setStructureDepartment(data.data));
      })
      .catch((err) => {
        return err;
      });
  }
);

export const getStructureChildDepartments = createAsyncThunk(
  DEPARTMENTS.GET_CHILDREN_DEPARTMENT_BY_PARENT_THUNK,
  (payload: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .fetchDepartmentsByParentId(payload)
      .then((data) => {
        dispatch(departmentActions.setStructureChildDepartments(data.data));
      })
      .catch((err) => err);
  }
);

export const deleteDepartment = createAsyncThunk(
  DEPARTMENTS.DELETE_DEPARTMENT_THUNK,
  (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.removeDepartment(id).then((data) => {
      if (data.status) {
        dispatch(modalActions.setModalClassName(REMOVE_DEPARTMENT_POSITION));
        dispatch(modalActions.openModal());
        dispatch(getDepartmentPosition({ id: id }));
      }
    });
  }
);

export const getMainDepartments = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_BY_PARENT_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .fetchDepartmentsByParentId(1)
      .then((data) => {
        dispatch(departmentActions.setMainDepartments(data.data));
      })
      .catch((err) => err);
  }
);

export const getSuperVisorDepartment = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_BY_ID_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService
      .fetchDepartmentById(1)
      .then((data) => {
        dispatch(departmentActions.setSuperVisorDepartment(data.data));
      })
      .catch((err) => err);
  }
);

export const getDepartmentById = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_BY_ID_THUNK,
  (payload: number, thunkAPI) =>
    departmentService
      .fetchDepartmentById(payload)
      .then((data) => {
        thunkAPI.dispatch(departmentActions.setDepartment(data.data));
      })
      .catch((err) => err)
);

export const changeParentDepartment = createAsyncThunk(
  DEPARTMENTS.CHANGE_PARENT_DEPARTMENT_THUNK,
  (payload: departmentModels.UpdateParentDepartmentProps, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.updateDepartmentParentId(payload).then((data) => {
      dispatch(departmentActions.setDepartment(data.data));

      dispatch(getSuperVisorDepartment());
      dispatch(getMainDepartments());
    });
  }
);

export const moveJobPosiiton = createAsyncThunk(
  DEPARTMENTS.MOVE_JOB_POSITION_THUNK,
  (payload: departmentModels.MoveJobPositionPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.patchJobPosition(payload).then((data) => {
      dispatch(getSuperVisorDepartment());
      dispatch(getDepartmentById(payload.department_id));
      dispatch(getMainDepartments());
    });
  }
);

export const createJobPosition = createAsyncThunk(
  DEPARTMENTS.CREATE_JOB_POSITION_THUNK,
  (payload: departmentModels.PostJobPositionModel, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    departmentService
      .postJobPosition(payload)
      .then((data) => {
        const store = getState() as RootState;

        if (data.status === ServerStatuses.CREATED) {
          dispatch(modalActions.setModalClassName(CREATE_JOB_POSITION_SUCCESS));
          dispatch(getDepartmentById(store.departments.selectedDepartment));
        }
        dispatch(departmentActions.clearFormError());
      })
      .catch((err) => {
        const error = err.response;
        if (error.status === ServerStatuses.BAD_REQUEST) {
          dispatch(departmentActions.setFormError(error.data.errorMessage));
        } else {
          dispatch(modalActions.setModalClassName(CREATE_JOB_POSITION_FAIL));
        }
      });
  }
);

export const editDepartment = createAsyncThunk(
  DEPARTMENTS.EDIT_DEPARTMENT_DETAILS_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    departmentService
      .patchDepartmentDetails(payload)
      .then((data) => {
        const store = getState() as RootState;
        if (data.status === ServerStatuses.OK) {
          dispatch(modalActions.setModalClassName(EDIT_DEPARTMENT_SUCCESS));
          dispatch(getDepartmentById(store.departments.selectedDepartment));
          const breadcrumbs = store.departments.breadcrumbs;
          const newDepartments = [...breadcrumbs];

          newDepartments[newDepartments.length - 1] = {
            name: data.data.name,
            department_id: data.data.department_id,
            parent_id: data.data.parent_id,
            area_id: data.data.area_id,
          };

          dispatch(departmentActions.setBreadcrumbs(newDepartments));
          dispatch(getMainDepartments());
        }

        dispatch(departmentActions.clearFormError());
      })
      .catch((err) => {
        dispatch(modalActions.setModalClassName(EDIT_DEPARTMENT_FAIL));
      });
  }
);

export const deleteJobPosition = createAsyncThunk(
  DEPARTMENTS.DELETE_JOB_POSITION_THUNK,
  (payload: departmentModels.DeleteJobPosition, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.removeJobPosition(payload.id).then((data) => {
      if (data.status) {
        dispatch(modalActions.setModalClassName(REMOVE_JOB_POSITION));
        dispatch(modalActions.openModal());
        dispatch(getDepartmentPosition({ id: payload.departmentid }));
      }
    });
  }
);

export const editJobPosition = createAsyncThunk(
  DEPARTMENTS.EDIT_JOB_POSITION_THUNK,
  (payload: departmentModels.PatchJobPosition, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    departmentService
      .patchJobPositionDetails(payload)
      .then((data) => {
        if (data.status) {
          const store = getState() as RootState;
          dispatch(getDepartmentById(store.departments.selectedDepartment));
          dispatch(modalActions.setModalClassName(EDIT_JOB_POSITION_SUCCESS));
        }
      })
      .catch(() => {
        dispatch(modalActions.setModalClassName(EDIT_JOB_POSITION_FAIL));
      });
  }
);

export const getDepartmentsWithSuperVisors = createAsyncThunk(
  DEPARTMENTS.GET_DEPARTMENT_WITH_SUPERVISORS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    departmentService.fetchDepartmentsWithSupervisors().then((data) => {
      dispatch(departmentActions.setDepartmentsWithSupervisors(data.data));
    });
  }
);
