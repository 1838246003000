import { createAsyncThunk } from "@reduxjs/toolkit";

import { bonusActions, modalActions } from "../slices";
import { bonusService } from "./services";
import { bonusModels } from "../models";

import { 
  ServerStatuses, 
  ThunkNames, 
  BonusStatuses,
  ModalClassnames
} from "../../data/config";

const { BONUS } = ThunkNames;

const {
  ACCEPT_PAYMENT_SUCCESS,
  DONE_PAYMENT_SUCCESS,
  REJECT_PAYMENT_SUCCESS,
  GENERATE_PAYMENT_SUCCESS,
  GENERATE_PAYMENT_EMPTY,
  GENERATE_PAYMENTS_FAIL,
  ACCEPT_PAYMENTS_SUCCESS,
  DONE_PAYMENTS_SUCCESS,
} = ModalClassnames;

export const fetchAllBonuses = createAsyncThunk(
  BONUS.FETCH_ALL_BONUSES_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return bonusService.getAllBonuses().then((data) => {
      if (data.status === ServerStatuses.OK) {
        dispatch(bonusActions.setAllBonuses(data.data));
      }
    });
  }
);

export const fetchUserBonuses = createAsyncThunk(
  BONUS.FETCH_BONUSES_USER_THUNK,
  (payload: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return bonusService.getBonusesByUser(payload).then((data) => {
      if (data.status === ServerStatuses.OK) {
        dispatch(bonusActions.setUserBonuses(data.data));
      }
    });
  }
);

export const fetchBonusesByStatus = createAsyncThunk(
  BONUS.FILTER_BONUSES_STATUS_THUNK,
  (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return bonusService.getBonusesByStatus(payload).then((data) => {
      dispatch(bonusActions.setBonusesByStatus(data.data));
    });
  }
);

export const generateBonusesForPoints = createAsyncThunk(
  BONUS.GENERATE_BONUSES_POINTS_THUNK,
  (payload: { beneficiaryUserIds: number[], generatingUserId: number }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    payload.beneficiaryUserIds.forEach(async beneficiaryUserId => {
      return bonusService.generateBonusesForPoints(payload.generatingUserId, beneficiaryUserId)
        .then((data) => {
          if (data.status === ServerStatuses.CREATED) {
            if (data.data.length === 0) {
              console.log("Brak premii do wygenerowania");
              dispatch(modalActions.setModalClassName(GENERATE_PAYMENT_EMPTY));
            } else {
              console.log("Wygenerowano premie");
              dispatch(modalActions.setModalClassName(GENERATE_PAYMENT_SUCCESS));
            }
          } else {
            console.log("Generowanie premii zakończyło się niepowodzeniem");
            dispatch(modalActions.setModalClassName(GENERATE_PAYMENTS_FAIL));

          }
          dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
        })
        .catch((error) => {
          console.error("Wystąpił błąd generowania premii: " + error);
          dispatch(modalActions.setModalClassName(GENERATE_PAYMENTS_FAIL));
        });
      })
    }
);

export const updateMultipleStatuses = createAsyncThunk(
  BONUS.UPDATE_MULTIPLE_STATUSES_THUNK,
  (payload: { 
    beneficiaryUserIds: number[], 
    updatingUserId: number, 
    newStatus: string 
  }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    payload.beneficiaryUserIds.forEach(async beneficiaryUserId => {
      try {
        const data = await bonusService.updateMultipleBonusStatuses(
          payload.updatingUserId,
          payload.newStatus,
          beneficiaryUserId
        );
        if (data.status === ServerStatuses.OK) {
          switch (payload.newStatus) {
            case BonusStatuses.GENERATED:
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(fetchBonusesByStatus(BonusStatuses.SUSPENDED));
              dispatch(modalActions.openModal());
              dispatch(modalActions.setModalClassName(GENERATE_PAYMENT_SUCCESS));
              break;

            case BonusStatuses.AWARDED:
              dispatch(fetchBonusesByStatus(BonusStatuses.AWARDED));
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(modalActions.openModal());
              dispatch(modalActions.setModalClassName(ACCEPT_PAYMENTS_SUCCESS));
              break;

            case BonusStatuses.PAID:
              dispatch(fetchBonusesByStatus(BonusStatuses.PAID));
              dispatch(fetchBonusesByStatus(BonusStatuses.AWARDED));
              dispatch(modalActions.openModal());
              dispatch(modalActions.setModalClassName(DONE_PAYMENTS_SUCCESS));
              break;

            case BonusStatuses.SUSPENDED:
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(fetchBonusesByStatus(BonusStatuses.SUSPENDED));
              dispatch(modalActions.openModal());
              dispatch(modalActions.setModalClassName(REJECT_PAYMENT_SUCCESS));
              break;

            default:
              break;
          }
        } else {
          console.log("Bonus status updating failed");
        }
      } catch (error) {
        console.log("Something went wrong with updating bonus status." + error);
      }
    })
  }
)

export const updateBonusStatus = createAsyncThunk(
  BONUS.UPDATE_BONUS_STATUS_THUNK,
  (payload: { bonusId: number, userId: number, status: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return bonusService.updateBonusStatus(
        payload.bonusId, 
        payload.userId, 
        payload.status
      ).then((data) => {
        if (data.status === ServerStatuses.OK) {
          switch (payload.status) {
            case BonusStatuses.GENERATED:
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(fetchBonusesByStatus(BonusStatuses.SUSPENDED));
              dispatch(modalActions.setModalClassName(ACCEPT_PAYMENT_SUCCESS));
              break;

            case BonusStatuses.AWARDED:
              dispatch(fetchBonusesByStatus(BonusStatuses.AWARDED));
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(modalActions.setModalClassName(ACCEPT_PAYMENT_SUCCESS));
              break;

            case BonusStatuses.PAID:
              dispatch(fetchBonusesByStatus(BonusStatuses.PAID));
              dispatch(fetchBonusesByStatus(BonusStatuses.AWARDED));
              dispatch(modalActions.setModalClassName(DONE_PAYMENT_SUCCESS));
              break;

            case BonusStatuses.SUSPENDED:
              dispatch(fetchBonusesByStatus(BonusStatuses.SUSPENDED));
              dispatch(fetchBonusesByStatus(BonusStatuses.GENERATED));
              dispatch(modalActions.setModalClassName(REJECT_PAYMENT_SUCCESS));
              break;

            default:
              break;
          }

        } else {
          console.log("Bonus status updating failed");
        }
      })
      .catch((error) => {
        console.log("Something went wrong with updating bonus status." + error);
    });
  }
);
