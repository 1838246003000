import { departmentModels } from ".";

export interface EvalutaionState {
  personalRates: {
    negativeRates: number;
    positiveRates: number;
  };
  evaluationType: EvaluationType;
  reviewingEvaluationId: number;
  evalautingUserId: number;
  usersToEvaluate?: Array<departmentModels.FormUser>;
  apperialUserStatuses?: Array<EvaluationUserStatuses>;
  userRates?: Array<UserRates>;
  searchPlaceholder?: string;
}

export type UserRates = Pick<
  EvaluationPayload,
  | "category"
  | "content"
  | "custom_category"
  | "evaluation_for_date"
  | "type"
  | "evaluated_user_id"
> & {
  evaluating_user_id: number;
  evaluation_id: number;
  evaluation_comments?: Array<EvaluationComment>;
  evaluationFileName?: String[];
  evaluationFilePath?: string[];
};

export enum EvaluationType {
  UNSPECIFIED = "UNSPECIFIED",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

export type EvalautaionMapType = {
  [key: string]: string;
};

export interface EvaluationUserStatuses {
  user_id: number;
  name: string;
  surname: string;
  count: {
    UNSPECIFIED: number;
    POSITIVE: number;
    NEGATIVE: number;
  };
}

export enum EvaluationCategory {
  // Positive
  INITIATIVE = "INITIATIVE",
  HELP_OTHERS = "HELP_OTHERS",
  PERFORMING_THE_TASK = "PERFORMING_THE_TASK",
  STREAMLINING_THE_PROCESS = "STREAMLINING_THE_PROCESS",
  COMMUNICATION = "COMMUNICATION",
  POSITIVE_FEEDBACK = "POSITIVE_FEEDBACK",
  RESPONSIBILITY_FOR_THE_WORK_RESULT = "RESPONSIBILITY_FOR_THE_WORK_RESULT",

  TEACHING_OTHERS = "TEACHING_OTHERS",
  EXCEEDING_THE_TARGET = "EXCEEDING_THE_TARGET",
  REPORTING_DANGER = "REPORTING_DANGER",
  // Negative
  TASK_NOT_DELIVERED = "TASK_NOT_DELIVERED",
  BREAKING_VALUES = "BREAKING_VALUES",
  FAILURE_TO_EXECUTE_THE_COMMAND = "FAILURE_TO_EXECUTE_THE_COMMAND",
  NO_RESPONSIBILITY_FOR_THE_RESULT_WORK = "NO_RESPONSIBILITY_FOR_THE_RESULT_WORK",
  NEGATIVE_FEEDABCK = "NEGATIVE_FEEDABCK",
  ERRORS_IN_THE_TASK = "ERRORS_IN_THE_TASK",
  LATENESS = "LATENESS",
  ABSENCE = "ABSCENCE",
  LATE = "LATE",
  HEALTH_AND_SAFETY_VIOLATION = "HEALTH_AND_SAFETY_VIOLATION",
  MISTAKES_AT_WORK = "MISTAKES_AT_WORK",
  FAILURE_TO_REACH_THE_GOAL = "FAILURE_TO_REACH_THE_GOAL",
  // Other
  OTHER = "OTHER",
}

export interface EvaluationPayload {
  category: EvaluationCategory | undefined;
  content: string;
  custom_category?: string;
  type: EvaluationType;
  evaluation_for_date: Date;
  user_id: number;
  evaluated_user_id: number;
}

export interface CreateEvaluationCommentData {
  content: string;
  evaluation_id: number;
}

export interface EvaluationComment {
  content: string;
  commenter_id: number;
  created_at: string;
  commenter: {
    profile: {
      name: string;
      role: string;
      surname: string;
      department: {
        name: string;
        department_id: number;
      };
    };
  };
}

export interface FilterEvaluationParams extends FilterEvaluationState {
  user_id: number;
}

export interface FilterEvaluationState {
  type?: "POSITIVE" | "NEGATIVE";
  search: string;
}
