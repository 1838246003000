import { AssessmentArray } from "../../types/AssessmentList.types";
import { AssessmentsList, AssessmentsHeaderList } from ".";

const AssessmentDashboard = ({ assessmentList }: AssessmentArray) => {
  return (
    <div>
      <AssessmentsHeaderList />
      <AssessmentsList assessmentList={assessmentList} />
    </div>
  );
};

export default AssessmentDashboard;
