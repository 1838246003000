export const ACTIVATION_MESSAGES = {
  ADMIN_DEACTIVATION_FORBIDDEN: "Deaktywacja konta Administratora jest niedozwolona ze względów bezpieczeństwa.",
  WORKER_DEACTIVATION: "Deaktywacja wybranego konta poskutkuje tym, że pracownik nie będzie mógł korzystać z aplikacji do czasu jego reaktywacji.",
  WORKER_REACTIVATION: "Reaktywacja wybranego konta przywróci pracownikowi możliwość korzystania z aplikacji.",
  ONLY_MANAGER_DEACTIVATION: "Dezaktywacja tego konta spowoduje, że wszystkie sugestie przypisane mu do oceny, zostaną przekazane - według twojej decyzji - managerowi nadrzędnemu (opcja domyślna) lub tymczasowemu zastępcy wyznaczonemu spośród pracowników działu dezaktywowanego użytkownika.",
  MANAGER_DEACTIVATION_WITH_SECOND_EXISTS: "Dezaktywacja tego konta spowoduje, że wszystkie sugestie, dla których użytkownik jest aktualnie oceniającym, zostaną przekazane do oceny drugiemu kierownikowi tego działu.",
  MANAGER_DEACTIVATION_WITH_MANY_OTHER_EXIST: "Dezaktywacja tego konta spowoduje, że wszystkie sugestie, dla których uzytkownik jest aktualnie oceniającym, zostaną przekazane do oceny wybranemu spośród kierowników tego działu.",
  MANAGER_DEACTIVATION_NONE_DECISION_POSIBBLE: "W związku z brakiem potencjalnych zastępców w tym dziale działu oraz nadrzędnych, pomimo dezaktywacji sugestie pozostaną przypisanego do dezaktywowanego konta.",
  MANAGER_VANILLA_REACTIVATION: "Reaktywacja tego konta spowoduje, że wszystkie sugestie, dla których użytkownik był oceniającym przed deaktywacją, zostaną mu z powrotem przekazane do oceny.",
  MANAGER_REACTIVATION_TEMP_MANAGER_DECISION: "W przypadku reaktywacji wybranego kierownika działu musisz zadecydować - za pomocą drugiego suwaka - czy jego tymczasowy zastępca (o ile został wyznaczony) powinien wrócić na dotychczasowe stanowisko - wtedy wszystkie przypisane do niego sugestie trafią do reaktywowanego kierownika - czy pozostać jednym z kierowników działu z uprawnieniami do oceny sugestii.",
  OPERATION_NOT_HANDLED: "Operacja nieobsługiwana dla wybranej roli. Zaniechaj dalszych operacji.",
  REDIRECT_SUGGESTIONS: "Przekieruj do: ",
  REDIRECT_SUGGESTIONS_RADIO: "Przekierowanie: ",
  REDIRECT_TO_PARENTS: "Przekaż wyżej",
  REDIRECT_TO_TEMP: "Wyznacz zastępcę",
  REVERT_TEMP_MANAGER: "Przywróć zastępcę reaktywowanego kierownika na stanowisko pracownicze",
  KEEP_TEMP_MANAGER: "Pomimo reaktywacji pozostaw zastępcę na stanowisku kierowniczym",
  ACCOUNT_ACTIVE: "Aktywny",
  ACCOUNT_NON_ACTIVE: "Nieaktywny",
  ACCOUNT_FIRED: "Zwolniony",
  TEMP_MANAGER_REVERTING_CHOICE: "Tymczasowy zastępca - po reaktywacji kierownika"
};

export enum ActivationTags {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FIRED = "FIRED",
};

export enum EmploymentStatus {
  WORKING = "WORKING",
  SICKNESS = "SICKNESS",
  FIRED = "FIRED"
}

export enum ActivationDecisions {
  NONE = "",
  REDIRECT = "REDIRECT",
  REPLACEMENT = "REPLACEMENT"
};
