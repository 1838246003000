import { CLIENT_NAME } from "../../data/config/globalContentVariables.data";

export const PASSWORD_NOT_UNIQUE_ERROR =
  "Podane hasło zostało już wykorzystane, należy wprowadzić unikatowe hasło";

export const PASSWORD_INCORRECT_ERROR =
  "Hasło nieprawidłowe. Proszę spróbuj ponownie";

// GENERAL DATA

export const LOGO_ALT_DESC = "logo";
export const ACCESS_DENIED_HEADER = "Odmowa dostępu";

// LOGIN DATA

export const loginTexts = {
  LOGIN_ERROR_MESSAGE: "Nieprawidłowy login lub hasło. Spróbuj ponownie.",
  LOGIN_HEADER: "Zaloguj się",
  LOGIN_BUTTON: "Zaloguj",
};

export const loginUsernameInputData = {
  name: "username",
  placeholder: "Wpisz login",
  type: "text",
  class: "full-width",
};

export const loginPasswordInputData = {
  name: "password",
  placeholder: "Hasło",
  typeText: "text",
  typePassword: "password",
  class: "full-width",
};

// CHANGE PASSWORD DATA

export const changePasswordTexts = {
  CHANGE_PASSWORD_ERROR_MESSAGE: "Hasła nie są identyczne.",
  CHANGE_PASSWORD_HEADER:
    "Upłynął termin ważności twojego ostatnio podanego hasła.",
  CHANGE_PASSWORD_DESCRIPTION:
    "Upłynęło 60 dni od ostatniej zmiany hasła. \n Z względów bezpieczeństwa zmień hasło \n na nowe. Nikomu go nie podawaj.",
  CHANGE_PASSWORD_BUTTON: "Potwierdź i zaloguj się",
  PASSWORDS_NOT_THE_SAME_ERROR: "Podane hasła nie są takie same",
  SOMETHING_IS_NOT_OK_WITH_PASSWORD_ERROR: "Podane hasło nie spełnia wymagań",
  SOMETHING_IS_NOT_OK_WITH_PASSWORD_WARNING:
    " Hasło nie spełnia wszystkich wymagań",
};

export const changePasswordInputsData = {
  name: "password",
  repeatName: "repeatPassword",
  placeholder: "Hasło",
  repeatPlaceholder: "Powtórz hasło",
  typeText: "text",
  typePassword: "password",
  class: "full-width",
};

// NEW PASSWORD DATA

export const newPasswordTexts = {
  NEW_PASSWORD_ERROR_MESSAGE: "Hasła nie są identyczne.",
  NEW_PASSWORD_HEADER: "Zmień hasło",
  NEW_PASSWORD_DESCRIPTION:
    "Ze względu bezpieczeństwa zmień hasło  \n tymczasowe do pierwszego logowania na \n na własne. Nikomu go nie podawaj.",
  NEW_PASSWORD_BUTTON: "Potwierdź i zaloguj się",
};

export const newPasswordInputsData = {
  name: "password",
  repeatName: "repeatPassword",
  placeholder: "Hasło",
  repeatPlaceholder: "Powtórz hasło",
  typeText: "text",
  typePassword: "password",
  class: "full-width",
};

// RECOVERY PASSWORD DATA

export const PASSWORD_VALIDATION_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const recoveryTexts = {
  EMAIL_ERROR_MESSAGE:
    "Twój adres e-mail był nieprawidłowy. Proszę spróbuj ponownie.",
  RESET_PASSWORD_HEADER: "Sprawdź swoją \n skrzynkę e-mail!",
  RESET_PASSWORD_DESCRIPTION:
    "Jeśli Twoje konto jest powiązane z tym adresem e-mail, \n otrzymasz wiadomość umożliwiającą zresetowanie hasła.",
  FORGET_PASSWORD_HEADER: "Zapomniałeś hasła?",
  FORGET_PASSWORD_DESCRIPTION:
    "Zweryfikuj swój adres e-mail. \n Gdy to zrobisz, wyślemy instrukcję resetowania hasła",
  BACK_LINK_LABEL: "Wróć do logowania",
  RESET_PASSWORD_BUTTON: "Zresetuj hasło",
};

export const recoveryInputData = {
  name: "email",
  placeholder: "Wpisz adres e-mail",
  type: "text",
  class: "full-width",
};

// INACTIVE ACCOUNT DATA

export const INACTIVE_ACCOUNT_TITLE = "Konto nieaktywne";
export const INACTIVE_ACCOUNT_LOGOUT_BUTTON = "Wyloguj";

// CONSENT DATA

export const consentTextData = {
  header: "Zgody",
  companyName:
    CLIENT_NAME === "dimograf"
      ? "Drukarnię Dimograf Sp. z o.o."
      : "Sky Vision Sp. z o.o.",
  programName: CLIENT_NAME === "dimograf" ? "Drukarnia pomysłów" : "CreAction",
  linkName: "regulamin",
  submitButton: "Dalej",
  errorMessage:
    "Wyrażenie zgód oznaczonych jako obowiązkowe (*) jest niezbędne do korzystania z aplikacji.",
};

export enum ConsentTypes {
  PERSONAL_DATA = "personal-data",
  INFO_CLAUSE = "information-clause",
}
