import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions, departmentActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button, NavBar, InactiveAccount } from "../../components/ui";
import { DashboardStructure } from "../components/structure";

import styles from "./StructurePage.module.scss";

import { adminData } from "../../../data/constants";
import { ModalClassnames } from "../../../data/config";

const { 
  CREATE_AREA, 
  CREATE_DEPARTMENT, 
  CREATE_JOB_POSITION 
} = ModalClassnames;

const { structurePageButtons, structurePageHeader} = adminData;

function StructurePage():ReactElement {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);

  useEffect(() => {
    dispatch(departmentThunk.getSuperVisorDepartment());
    dispatch(departmentThunk.getStructureDepartments(1));
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  function openAreaForm() {
    dispatch(modalActions.setModalClassName(CREATE_AREA));
    dispatch(modalActions.openModal());
  }

  function openDepartmentForm() {
    dispatch(modalActions.setModalClassName(CREATE_DEPARTMENT));
    dispatch(modalActions.openModal());
  }

  function openJobPositionForm() {
    dispatch(departmentActions.clearFormError());
    dispatch(modalActions.setModalClassName(CREATE_JOB_POSITION));
    dispatch(modalActions.openModal());
  }

  return (
    <div className={styles["structure-page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["dashboard"]}>
        <div className={styles["header"]}>
          <p className={styles["header__text"]}>{structurePageHeader}</p>

          <div className={styles["header__buttons"]}>
          <Button
              textValue={structurePageButtons.ADD_AREA_BUTTON}
              hiddenStyles=""
              buttonClassName="button"
              buttonFunction={openAreaForm}
            />
            <Button
              textValue={structurePageButtons.ADD_DEPARTMENT_BUTTON}
              hiddenStyles=""
              buttonClassName="button"
              buttonFunction={openDepartmentForm}
            />
            <Button
              textValue={structurePageButtons.ADD_POSITION_BUTTON}
              hiddenStyles=""
              buttonClassName="button"
              buttonFunction={openJobPositionForm}
            />
          </div>
        </div>

        <DashboardStructure />
      </div>) : <InactiveAccount />}
    </div>
  );
}

export default StructurePage;
