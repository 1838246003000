import { useEffect } from "react";
import { useAppDispatch } from "../../../__store/tools/hooks";
import { departmentThunk } from "../../../__store/thunks";

import styles from "./CreateSuggestion.module.scss";
import { NavBar } from "../../components/ui";
import { CreateSuggestionForm } from "../forms";

const CreateSuggesdtionPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentData());
  }, []);
  
  return (
    <div className={styles["page"]}>
      <NavBar />
      <CreateSuggestionForm />
    </div>
  );
};

export default CreateSuggesdtionPage;
