import { useState, useEffect, ReactElement, FunctionComponent } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { authActions, usersActions, modalActions } from "../../../__store/slices";
import { adminThunk, authThunk } from "../../../__store/thunks";

import { Icon } from "../../components/ui";

import AddUserValidationForm from "../../../data/validation/addUserValidation";

import styled from "./PasswordLoginChangeForm.module.scss";

import { InputTypes, usersData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";
const { 
  LOGIN_MIN_LENGTH, 
  UserDataChangeType, 
  passwordLoginChangeMessages, 
  passwordLoginChangeFormLabels, 
  passwordLoginChangeFormInputNames, 
  passwordLoginChangeFormButtons 
} = usersData;

interface IPasswordLoginChangeProps {
  type: string;
  text: string;
}

type FormState = {
  login: string;
  password: string;
  repeatPassword: string;
  passwordToVerify: string;
};

const PasswordLoginChange: FunctionComponent<
  IPasswordLoginChangeProps
> = ({ type, text }):ReactElement => {
  const validation = new AddUserValidationForm();
  const dispatch = useAppDispatch();

  const login = useAppSelector((state) => state.users.authUser?.user.login);
  const user_id = useAppSelector((state) => state.auth.user_id);
  const passwordVerification = useAppSelector(
    (state) => state.auth.passwordVerification
  );
  const errorMessage = useAppSelector((state) => state.users.errorMessage);
  const [formState, setFormState] = useState<FormState>({
    login: "",
    password: "",
    repeatPassword: "",
    passwordToVerify: "",
  });
  const [formError, setFormError] = useState("");

  useEffect(() => {
    setFormError(errorMessage);
  }, [errorMessage]);

  const handleOnChange = (e: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = () => {
    if (type === UserDataChangeType.CHANGE_PASSWORD) {
      if (formState.password !== formState.repeatPassword) {
        setFormError(passwordLoginChangeMessages.PASSWORDS_NOT_EQUAL);
        return;
      }

      const passwordValid = validation.isPasswordValid(formState.password.trim());
      if (!passwordValid.isValid) {
        setFormError(passwordValid.message);
        return;
      }

      dispatch(adminThunk.updateUser({ 
        password: formState.password.trim(), 
        user_id 
      }));
      
      dispatch(
        authActions.setPasswordVerification({
          verified: false,
          message: "",
        })
      );
    } else {
      if (formState.login?.trim().length < LOGIN_MIN_LENGTH) {
        setFormError(passwordLoginChangeMessages.LOGIN_TOO_SHORT);
        return;
      }
      dispatch(adminThunk.UpdateYourInfo({ 
        login: formState.login.trim(), 
        user_id 
      }));
    }
    setFormError("");
  };

  const handleOnVerify = () => {
    dispatch(
      authThunk.verifyPassword({
        user_id,
        login: login as string,
        password: formState.passwordToVerify,
      })
    );
  };

  const handleOnClose = () => {
    dispatch(usersActions.clearErrorMessage());
    setFormError("");
    dispatch(modalActions.closeModal());
    dispatch(
      authActions.setPasswordVerification({
        verified: false,
        message: "",
      })
    );
  };

  return (
    <div className={styled["form"]}>
      <div className={styled["form__header"]}>
        <div className={styled["form__header__text"]}>
          <div className={styled["form__header__text__icon"]}>
            <Icon type={IconTypes.AVATAR} width={"100%"} height={"100%"} />
          </div>
          <span>{text}</span>
        </div>
        <div className={styled["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={handleOnClose} />
        </div>
      </div>
      <div className={styled["form__context"]}>
        <div className={styled["form__context__section-1"]}>
          {type === UserDataChangeType.CHANGE_LOGIN && (
            <div className={styled["form__context__section-1__top"]}>
              <span>{`${passwordLoginChangeFormLabels.YOUR_LOGIN} ${login}`}</span>
            </div>
          )}
          <div className={styled["form__context__section-1__bottom"]}>
            {passwordVerification?.verified ? (
              type === UserDataChangeType.CHANGE_PASSWORD ? (
                <>
                  <div
                    className={styled["form__context__input-container__label"]}
                  >
                    <span>{passwordLoginChangeFormLabels.NEW_PASSWORD}</span>
                  </div>
                  <div className={styled["form__context__input-container"]}>
                    <input
                      name={passwordLoginChangeFormInputNames.INPUT_NEW_PASSWORD}
                      type={InputTypes.PASSWORD}
                      value={formState.password}
                      className={styled["input"]}
                      placeholder={passwordLoginChangeFormInputNames.INPUT_NEW_PASSWORD}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div
                    className={`${styled["form__context__input-container__label"]} ${styled["second"]}`}
                  >
                    <span>{passwordLoginChangeFormLabels.REPEAT_PASSWORD}</span>
                  </div>
                  <div className={styled["form__context__input-container"]}>
                    <input
                      name={passwordLoginChangeFormInputNames.INPUT_NEW_PASSWORD_REPEAT}
                      type={InputTypes.PASSWORD}
                      value={formState.repeatPassword}
                      className={styled["input"]}
                      placeholder={passwordLoginChangeFormLabels.INPUT_NEW_PASSWORD_REPEAT}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div className={styled["form__context__error-container"]}>
                    {formError && (
                      <span className={styled["message"]}>{formError}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={styled["form__context__input-container__label"]}
                  >
                    <span>{passwordLoginChangeFormLabels.NEW_LOGIN}</span>
                  </div>
                  <div className={styled["form__context__input-container"]}>
                    <input
                      name={passwordLoginChangeFormInputNames.INPUT_NEW_LOGIN}
                      value={formState.login}
                      type={InputTypes.TEXT}
                      className={styled["input"]}
                      placeholder={passwordLoginChangeFormLabels.INPUT_NEW_LOGIN}
                      onChange={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div className={styled["form__context__error-container"]}>
                    {formError && (
                      <span className={styled["message"]}>{formError}</span>
                    )}
                  </div>
                </>
              )
            ) : (
              <>
                <div
                  className={styled["form__context__input-container__label"]}
                >
                  <span>{passwordLoginChangeFormLabels.ACTUAL_PASSWORD}</span>
                </div>
                <div className={styled["form__context__input-container"]}>
                  <input
                    name={passwordLoginChangeFormInputNames.INPUT_ACTUAL_PASSWORD}
                    type={InputTypes.PASSWORD}
                    value={formState.passwordToVerify}
                    className={styled["input"]}
                    placeholder={passwordLoginChangeFormLabels.INPUT_ACTUAL_PASSWORD}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
                <span className={styled["error-message"]}>
                  {passwordVerification.message}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styled["form__footer"]}>
        {passwordVerification?.verified ? (
          <>
            <div className={styled["form__footer__button-container"]}>
              <button
                className={
                  styled["form__footer__button-container__button-back"]
                }
                onClick={handleOnClose}
              >
                {passwordLoginChangeFormButtons.CANCEL_BUTTON}
              </button>
            </div>
            <div className={styled["form__footer__button-container"]}>
              <button
                className={
                  styled["form__footer__button-container__button-submit"]
                }
                onClick={handleOnSubmit}
              >
                {passwordLoginChangeFormButtons.SAVE_BUTTON}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={styled["form__footer__button-container"]}>
              <button
                className={
                  styled["form__footer__button-container__button-back"]
                }
                onClick={handleOnClose}
              >
                {passwordLoginChangeFormButtons.CANCEL_BUTTON}
              </button>
            </div>
            <div className={styled["form__footer__button-container"]}>
              <button
                className={
                  styled["form__footer__button-container__button-submit"]
                }
                onClick={handleOnVerify}
              >
                {passwordLoginChangeFormButtons.VERIFY_BUTTON}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordLoginChange;
