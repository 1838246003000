const labelValues = {
  SETTINGS_LABELS: {
    LOGIN: "Login:",
    EMAIL: "E-mail:",
    PASSWORD: "Hasło:",
    DEPARTMENT: "Dział:",
    AREA: "Obszar:",
    ROLE: "Rola:",
    POSITION: "Stanowisko:",
    CONTRACT_TYPE: "Rodzaj umowy:",
    STATUS: "Status:",
    EMAIL_NOTIFICATIONS_SWITCH: "Powiadomienia email:",
    SUGGESTION_NOTIFICATIONS_CHECKBOX: "O zmianach statusu sugestii",
    BONUSES_NOTIFICATIONS_CHECKBOX: "O premiach",
    POINTS_NOTIFICATIONS_CHECKBOX: "O zdobytych punktach",
    RANKING_NOTIFICATIONS_CHECKBOX: "Podsumowanie rankingu",
    WORKER_EVALUATION_SWITCH: "Możliowść oceniania pracowników",
    ESTIMATED_COST_SWITCH: (useEstimationCostInput: boolean|undefined) => `Szacowany koszt jako ${useEstimationCostInput ? "INPUT" : "SELECT"}`,
    CONSULTATION_FIELD_SWITCH: "Stosowanie pola konsultacji"
  },
};

export default labelValues;
