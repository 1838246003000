import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { useFormik } from "formik";
import * as Yup from "yup";
import { serialize } from "object-to-formdata";
import { useNavigate } from "react-router-dom";

import styles from "./CreateSuggestionForm.module.scss";

import { Button, Input, InputNumber, Select, Upload, UploadFile, UploadProps } from "antd";
import { Icon } from "../../components/ui/";
import { IconTypes, RoutesPaths, UserRoles } from "../../../data/config";
import { suggestionsData } from "../../../data/constants";
import { departmentThunk, suggestionThunk } from "../../../__store/thunks";
import { MainStatuses } from "../../../data/config/suggestionStatuses.data";

const CreateSuggestionForm = () => {
  const {
    ESTIMATION_BREAK_POINT,
    EstimatedCostOptionsMap,
    INPUT_MAX_LENGTH,
    TEXT_AREA_MAX_LENGTH,
    SuggestionTypes,
    topBarSuggestionsHeaders,
    createSuggestionData,
    createSuggestionValidationMessages
  } = suggestionsData;

  const createSuggestionSchema = Yup.object().shape({
    title: Yup.string()
      .required(createSuggestionValidationMessages.title)
      .max(INPUT_MAX_LENGTH, `Tytuł jest za długi, maksymalnie ${INPUT_MAX_LENGTH} znaków`),
    evaluating_user: Yup.number().required(createSuggestionValidationMessages.evaluating_user),
    department_id: Yup.number().required(createSuggestionValidationMessages.department_id),
    area_id: Yup.number().required(createSuggestionValidationMessages.area_id),
    estimated_cost: Yup.number().min(0).required(createSuggestionValidationMessages.estimated_cost),
    solutionDescription: Yup.string()
      .required(createSuggestionValidationMessages.solutionDescription)
      .max(TEXT_AREA_MAX_LENGTH, `Opis jest za długi, maksymalnie ${TEXT_AREA_MAX_LENGTH} znaków`),
    problemDescription: Yup.string()
      .required(createSuggestionValidationMessages.problemDescription)
      .max(TEXT_AREA_MAX_LENGTH, `Opis jest za długi, maksymalnie ${TEXT_AREA_MAX_LENGTH} znaków`),
    resultDescription: Yup.string()
      .required(createSuggestionValidationMessages.resultDescription)
      .max(TEXT_AREA_MAX_LENGTH, `Opis jest za długi, maksymalnie ${TEXT_AREA_MAX_LENGTH} znaków`),
  });

  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [resolveFiles, setResolveFiles] = useState<UploadFile[]>([]);
  const [solutionFiles, setSolutionFiles] = useState<UploadFile[]>([]);
  const navigate = useNavigate();

  const departmentsWithSupervisors = useAppSelector((state) => state.departments.departmentsWithSupervisors);
  const useEstimationCostInput = useAppSelector((state) => state.applicationSettings.useEstimatedCostAsInput);
  const useConsultationField = useAppSelector((state) => state.applicationSettings.useConsultationField);

  const { SUGGESTIONS } = RoutesPaths;

  const userRole = useAppSelector((state) => state.auth.role);
  const areas = useAppSelector((state) => state.departments.areas);
  const userId = useAppSelector((state) => state.auth.user_id);
  const supervisors = useAppSelector((state) => state.departments.superVisor.supervisors);

  const { authUser } = useAppSelector((state) => state.users);

  const [initialValuesChanged, setInitialValuesChanged] = useState(false);
  const emptyCachedData = () => {
    Object.entries(values).map(([key, value]) => {
      localStorage.removeItem(key);
    });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      isConsulted: false,
      isDoItYourself: false,
      problemDescription: "",
      solutionDescription: "",
      resultDescription: "",
      helpDescription: "",
      area_id: supervisors[0] 
        ? supervisors[0].profile.area_id
        : authUser?.area_id,
      reporting_user: userId as number,
      submitting_user: userId as number,
      evaluating_user: supervisors[0]
        ? supervisors[0].user_id
        : null,
      department_id: supervisors[0]
        ? supervisors[0].profile.department_id
        : authUser?.department_id,
      status: MainStatuses.REPORT,
      userRole: userRole,
      suggestion_type: SuggestionTypes.MICROIMPROVEMENT,
      estimated_cost: 0,
      title: "",
    },
    validationSchema: createSuggestionSchema,
    onSubmit: () => {
      const data = {
        ...values,
        isConsulted: Boolean(values.isConsulted),
        isDoItYourself: Boolean(values.isDoItYourself),
        area_id: values.area_id && parseInt(values.area_id.toString()),
        department_id: values.department_id ? parseInt(values.department_id.toString()) : values.department_id,
        evaluating_user: values.evaluating_user ? parseInt(values.evaluating_user.toString()) : values.evaluating_user,
        reporting_user: parseInt(values.reporting_user.toString()),
        estimated_cost: parseInt(values.estimated_cost.toString()),
        suggestion_type: parseInt(values.estimated_cost.toString()) > ESTIMATION_BREAK_POINT
          ? SuggestionTypes.INVESTMENT
          : SuggestionTypes.MICROIMPROVEMENT,
        submitting_user: parseInt(values.submitting_user.toString()),
        title: values.title.trim(),
        problemDescription: values.problemDescription.trim(),
        solutionDescription: values.solutionDescription.trim(),
        resultDescription: values.resultDescription.trim(),
        helpDescription: values.helpDescription.trim(),
      };

      const object = {
        files: fileList,
        resolveFiles: resolveFiles,
        solutionFiles: solutionFiles,
        data: JSON.stringify(data),
      };

      const formData = serialize(object);

      dispatch(suggestionThunk.addSuggestion(formData));
      if (isSubmitting) {
        Object.entries(values).map(([key, value]) => {
          emptyCachedData();
        });
        navigate(SUGGESTIONS.MY_SUGGESTIONS_PATH);
      }
    },
  });

  const sortedDepartments = Object.values(departmentsWithSupervisors)
    .filter((department) => department.name !== "Brak stanowiska")
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const findKeyInMap = (map: Map<string, number>, searchValue: number) => {
    // @ts-ignore
    for (let [key, value] of map.entries()) {
      if (value == searchValue) {
        return key;
      }
    }

    return null;
  };

  useEffect(() => {
    if (initialValuesChanged) {
      Object.entries(values).map(([key, value]) => {
        localStorage.setItem(key, value as string);
      });
    }
  }, [values]);

  useEffect(() => {
    setInitialValuesChanged(true);
    Object.entries(values).map(([key, value]) => {
      const parsedValue = localStorage.getItem(key);
      if (parsedValue) {
        setFieldValue(key, parsedValue);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentsWithSuperVisors());
  }, [])

  useEffect(() => {
    if (
      userRole === UserRoles.BOSS &&
      values.department_id === authUser?.department_id
    ) {
      setFieldValue("evaluating_user", authUser?.user_id);

      localStorage.setItem("evaluating_user", String(authUser?.user_id));
    } else if (
      userRole === UserRoles.MANAGER &&
      values.department_id === supervisors[0].profile.department_id 
      && supervisors.length > 0
    ) {
      setFieldValue("evaluating_user", supervisors[0].user_id);
      setFieldValue("department_id", supervisors[0].profile.department_id);
    
      localStorage.setItem("evaluating_user", String(supervisors[0].user_id));
      localStorage.setItem("department_id", String(supervisors[0].profile.department_id));
    } else if (
      userRole !== UserRoles.MANAGER &&
      userRole !== UserRoles.BOSS &&
      Object.keys(departmentsWithSupervisors).length > 0 &&
      authUser?.department_id
    ) {
      if (
        departmentsWithSupervisors[authUser?.department_id].supervisors.length >
          0 &&
        authUser.department_id === values.department_id
      ) {
        setFieldValue("evaluating_user", departmentsWithSupervisors[authUser?.department_id].supervisors[0].user_id);
        setFieldValue("area_id", departmentsWithSupervisors[authUser?.department_id].area.area_id);

        localStorage.setItem("evaluating_user", String(departmentsWithSupervisors[authUser?.department_id].supervisors[0].user_id));
        localStorage.setItem("area_id", String(departmentsWithSupervisors[authUser?.department_id].area.area_id));

      } else if (
        departmentsWithSupervisors[authUser?.department_id].parent_department &&
        departmentsWithSupervisors[authUser?.department_id].parent_department
          .supervisors.length > 0 &&
        authUser.department_id === values.department_id
      ) {
        setFieldValue("evaluating_user", departmentsWithSupervisors[authUser.department_id].parent_department.supervisors[0].user_id);
        setFieldValue("department_id", departmentsWithSupervisors[authUser.department_id].parent_department.department_id);
        setFieldValue("area_id", departmentsWithSupervisors[authUser.department_id].parent_department.area_id);
      
        localStorage.setItem("evaluating_user", String(departmentsWithSupervisors[authUser.department_id].parent_department.supervisors[0].user_id));
        localStorage.setItem("department_id", String(departmentsWithSupervisors[authUser.department_id].parent_department.department_id));
        localStorage.setItem("area_id", String(departmentsWithSupervisors[authUser.department_id].parent_department.area_id));
      }
    }

    dispatch(departmentThunk.getDepartmentsWithSuperVisors());
  }, [supervisors, userRole, authUser?.department_id, values]);

  const props: UploadProps = {
    onRemove: (file: UploadFile) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const propsForResolve: UploadProps = {
    onRemove: (file: UploadFile) => {
      const index = resolveFiles.indexOf(file);
      const newFileList = resolveFiles.slice();
      newFileList.splice(index, 1);
      setResolveFiles(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      setResolveFiles([...resolveFiles, file]);

      return false;
    },
    fileList: resolveFiles,
  };

  const propsForSolution: UploadProps = {
    onRemove: (file: UploadFile) => {
      const index = solutionFiles.indexOf(file);
      const newFileList = solutionFiles.slice();
      newFileList.splice(index, 1);
      setSolutionFiles(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      setSolutionFiles([...solutionFiles, file]);

      return false;
    },
    fileList: solutionFiles,
  };

  const validateInput =
    (errors.department_id && touched.department_id) ||
    !values.department_id ||
    (errors.evaluating_user && touched.evaluating_user) ||
    !values.evaluating_user ||
    (errors.title && touched.title) ||
    !values.title ||
    !values.evaluating_user ||
    (errors.solutionDescription && touched.solutionDescription) ||
    !values.solutionDescription ||
    (errors.problemDescription && touched.problemDescription) ||
    !values.problemDescription ||
    (errors.resultDescription && touched.resultDescription) ||
    !values.resultDescription ||
    (errors.estimated_cost && touched.estimated_cost) ||
    !values.estimated_cost;

  const localArea = areas.find((area) => area.area_id == values.area_id);

  const { TextArea } = Input;

  function findSupervisor(departmentId:number) {
    const department = departmentsWithSupervisors[departmentId];
    
    if (department.supervisors.length > 0) {
      return {
        departmentId: department.department_id,
        areaId: department.area.area_id,
        userId: department.supervisors[0].user_id
      };
    } else if (department.parent_department) {
      return findSupervisor(department.parent_department.department_id);
    } else {
      return null;
    }
  }

  return (
    <form className={styles["create-suggestion"]} onSubmit={handleSubmit}>
      <header className={styles["create-suggestion__header"]}>
        <h3> {topBarSuggestionsHeaders.NewSuggestionHeader}</h3>
        <div className={styles["buttons-section"]}>
          <Button
            size="large"
            type="primary"
            htmlType="reset"
            onClick={() => {
              resetForm();
              emptyCachedData();
            }}
            className={styles["create-suggestion__header__reset-button"]}
          >
            Wyczyść
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!!validateInput}
            className={styles["create-suggestion__header__sbmt-button"]}
          >
            Zgłoś
          </Button>
        </div>
      </header>
      <div className={styles["create-suggestion__section"]}>
        <h4>{createSuggestionData.headers.data}</h4>
        <hr />
        <div>
          {" "}
          <div className={styles["create-suggestion__section--labels"]}>
            <label id="title" className={styles["label"]}>
              {createSuggestionData.labels.title}
              <span>*</span>
            </label>

            <p>* Pola obowiązkowe</p>
          </div>
          <Input
            id="title"
            value={values.title}
            onBlur={handleBlur}
            onChange={handleChange}
            prefix={<Icon type={IconTypes.MESSAGE_ICON} />}
            name="title"
            className={
              errors.title && touched.title
                ? styles["create-suggestion__section__input--error"]
                : styles["create-suggestion__section__input"]
            }
            placeholder={createSuggestionData.placeholders.enterTitle}
          />
          {errors.title && touched.title ? (
            <p className={styles["error"]}>{errors.title}</p>
          ) : null}
          <div className={styles["create-suggestion__section--selects"]}>
            <div className={styles["selects__section"]}>
              <label id="department" className={styles["label"]}>
                {createSuggestionData.labels.department}
                <span>*</span>
              </label>{" "}
              <Select
                status={
                  errors.department_id && touched.department_id ? "error" : ""
                }
                className={styles["select"]}
                id={"department_id"}
                size="large"
                value={
                  localStorage.getItem("department_id")
                    ? sortedDepartments.find(
                        (deparment) =>
                          deparment.department_id == values.department_id
                      )?.name
                    : (values.department_id && departmentsWithSupervisors[values.department_id]?.name)
                      ? departmentsWithSupervisors[values.department_id].name 
                      : undefined
                }
                placeholder={
                  <span className={styles["select__placeholder"]}>
                    <Icon type={IconTypes.GRAPH_ICON} />
                    {createSuggestionData.placeholders.selectDepartment}
                  </span>
                }
                onBlur={handleBlur}
                onChange={(value) => {
                  const supervisorInfo = findSupervisor(+value);
                  if (supervisorInfo) {
                    setFieldValue("department_id", supervisorInfo.departmentId);
                    setFieldValue("area_id", supervisorInfo.areaId);
                    setFieldValue("evaluating_user", supervisorInfo.userId);
                  } else {
                    console.log("Brak aktywnego oceniające w wybranym pionie organizacji.");
                  }
                }}
                options={sortedDepartments.map((option) => ({
                  value: +option.department_id,
                  label: (
                    <span className={styles["select__placeholder"]}>
                      {" "}
                      {option.name}
                    </span>
                  ),
                }))}
              />
              {errors.department_id && touched.department_id ? (
                <p className={styles["error"]}>{"Podaj dział"}</p>
              ) : null}
            </div>

            <div className={styles["selects__section"]}>
              <label id="sendTo" className={styles["label"]}>
                {createSuggestionData.labels.area}
                <span>*</span>
              </label>

              <Select
                className={styles["select"]}
                size="large"
                id={"area_id"}
                status={errors.area_id && touched.area_id ? "error" : ""}
                value={
                  localStorage.getItem("area_id")
                    ? localArea?.area_name
                    : (values.area_id && values.department_id) 
                      ? departmentsWithSupervisors[values.department_id]?.area?.area_name
                      : undefined
                }
                options={areas.map((area) => ({
                  value: +area.area_id,
                  label: (
                    <span className={styles["select__placeholder"]}>
                      {" "}
                      {area.area_name}
                    </span>
                  ),
                }))}
                onBlur={handleBlur}
                onChange={(value) => {
                  setFieldValue("area_id", value);
                }}
                placeholder={
                  <span className={styles["select__placeholder"]}>
                    <Icon type={IconTypes.GRAPH_ICON} />
                    {createSuggestionData.placeholders.selectArea}
                  </span>
                }
              />
              {errors.area_id && touched.area_id ? (
                <p className={styles["error"]}>{"Podaj obszar"}</p>
              ) : null}
            </div>

            <div className={styles["selects__section"]}>
              <label id="area" className={styles["label"]}>
                {createSuggestionData.labels.sendTo}
                <span>*</span>
              </label>
              <Select
                id="evaluating_user"
                status={
                  errors.evaluating_user && touched.evaluating_user
                    ? "error"
                    : ""
                }
                className={styles["select"]}
                size="large"
                onBlur={handleBlur}
                onChange={(value) => {
                  setFieldValue("evaluating_user", value);
                }}
                value={
                  Number(localStorage.getItem("evaluating_user")) &&
                  values.department_id &&
                  departmentsWithSupervisors[values.department_id]?.supervisors &&
                  departmentsWithSupervisors[values.department_id].supervisors.find((supervisor: any) => supervisor.user_id === values.evaluating_user)
                    ? `${departmentsWithSupervisors[values.department_id]
                        .supervisors.find((supervisor: any) => supervisor.user_id === values.evaluating_user)?.profile?.name} 
                      ${departmentsWithSupervisors[values.department_id]
                        .supervisors.find((supervisor: any) => supervisor.user_id === values.evaluating_user)?.profile?.surname}`
                    : undefined
                }                        
                placeholder={
                  <span className={styles["select__placeholder"]}>
                    <Icon type={IconTypes.USER_ICON} />
                    {createSuggestionData.placeholders.selectSupervisor}
                  </span>
                }
                options={
                (values.department_id !== undefined) 
                  ? departmentsWithSupervisors[values.department_id]?.supervisors?.map(
                  (supervisor) =>
                    ({
                      value: +supervisor.user_id,
                      label: (
                        <span className={styles["select__placeholder"]}>
                          {supervisor.profile.name} {supervisor.profile.surname}
                        </span>
                      )
                    })
                ) : undefined
              }
              />
              {errors.evaluating_user && touched.evaluating_user ? (
                <p className={styles["error"]}>{"Podaj osobę oceniającą z wybranego działu"}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={styles["create-suggestion__section"]}>
        <h4>{createSuggestionData.headers.suggestionDescription}</h4>
        <hr />

        <div className={styles["create-suggestion__section--textareas"]}>
          <div className={styles["textarea__section"]}>
            <label id="suggestion" className={styles["label"]}>
              {createSuggestionData.labels.suggestion}
              <span>*</span>
            </label>
            <TextArea
              id="problemDescription"
              autoSize={{ minRows: 5, maxRows: 7 }}
              name="problemDescription"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.problemDescription}
              className={
                errors.problemDescription && touched.problemDescription
                  ? styles["textarea--error"]
                  : styles["textarea"]
              }
              placeholder={createSuggestionData.placeholders.passSugestion}
            />
            {errors.problemDescription && touched.problemDescription ? (
              <p className={styles["error"]}>{errors.problemDescription}</p>
            ) : null}
            <Upload
              {...props}
              className={styles["upload"]}
              itemRender={(originNode, file) => (
                <span className={styles["upload__file"]}>
                  {" "}
                  <span className={styles["upload__file--names"]}>
                    <Icon type={IconTypes.ATTACH} /> {file.name}{" "}
                  </span>
                  <Icon
                    type={IconTypes.CROSS}
                    onClick={() => {
                      if (props.onRemove) props.onRemove(file);
                    }}
                  />
                </span>
              )}
            >
              <Button
                className={styles["upload__button"]}
                icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
              />
            </Upload>
          </div>
          <div className={styles["textarea__section"]}>
            <label id="solution" className={styles["label"]}>
              {createSuggestionData.labels.solution}
              <span>*</span>
            </label>
            <TextArea
              id="solutionDescription"
              name="solutionDescription"
              autoSize={{ minRows: 5, maxRows: 7 }}
              value={values.solutionDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.solutionDescription && touched.solutionDescription
                  ? styles["textarea--error"]
                  : styles["textarea"]
              }
              placeholder={createSuggestionData.placeholders.suggestSolution}
            />
            {errors.solutionDescription && touched.solutionDescription ? (
              <p className={styles["error"]}>{errors.solutionDescription}</p>
            ) : null}
            <Upload
              {...propsForSolution}
              className={styles["upload"]}
              itemRender={(originNode, file) => (
                <span className={styles["upload__file"]}>
                  {" "}
                  <span className={styles["upload__file--names"]}>
                    <Icon type={IconTypes.ATTACH} /> {file.name}{" "}
                  </span>
                  <Icon
                    type={IconTypes.CROSS}
                    onClick={() => {
                      if (propsForSolution.onRemove)
                        propsForSolution.onRemove(file);
                    }}
                  />
                </span>
              )}
            >
              <Button
                className={styles["upload__button"]}
                icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
              />
            </Upload>
          </div>
          <div className={styles["textarea__section"]}>
            <label id="result" className={styles["label"]}>
              {createSuggestionData.labels.result}
              <span>*</span>
            </label>
            <TextArea
              id={"resultDescription"}
              onChange={handleChange}
              value={values.resultDescription}
              autoSize={{ minRows: 5, maxRows: 7 }}
              onBlur={handleBlur}
              name="resultDescription"
              className={
                errors.resultDescription && touched.resultDescription
                  ? styles["textarea--error"]
                  : styles["textarea"]
              }
              placeholder={createSuggestionData.placeholders.expectedResult}
            />
            {errors.resultDescription && touched.resultDescription ? (
              <p className={styles["error"]}>{errors.resultDescription}</p>
            ) : null}
            <Upload
              {...propsForResolve}
              className={styles["upload"]}
              itemRender={(originNode, file) => (
                <span className={styles["upload__file"]}>
                  {" "}
                  <span className={styles["upload__file--names"]}>
                    <Icon type={IconTypes.ATTACH} /> {file.name}{" "}
                  </span>
                  <Icon
                    type={IconTypes.CROSS}
                    onClick={() => {
                      if (propsForResolve.onRemove)
                        propsForResolve.onRemove(file);
                    }}
                  />
                </span>
              )}
            >
              <Button
                className={styles["upload__button"]}
                icon={<Icon type={IconTypes.SUGGESTION_BOX} />}
              />
            </Upload>
          </div>
        </div>
      </div>
      <div className={styles["create-suggestion__section"]}>
        <h4>{createSuggestionData.headers.additionalInformation}</h4>
        <hr />
        <div className={styles["additional-informations__section"]}>
          <div className={styles[useConsultationField ? "additional-information" : "additional-information--customized"]}>
            <label
              id="cost"
              className={styles["additional-information__label"]}
            >
              {createSuggestionData.labels.cost}
              <span>*</span>
            </label>
          {useEstimationCostInput 
          ? (
            <InputNumber 
              id={"estimated_cost"}
              min={0} 
              max={500000} 
              style={{
                marginLeft: 10,
                width: "100%",
              }}
              value={localStorage.getItem("estimated_cost") as unknown as number}
              size="large"
              onBlur={handleBlur}
              prefix={
                <Icon type={IconTypes.DOLAR_ICON} />
              }
              suffix={
                "PLN"
              }
              status={
                errors.estimated_cost && touched.estimated_cost ? "error" : ""
              }
              onChange={(value) => {
                setFieldValue("estimated_cost", value);
              }} 
            />
          ) : (
            <Select
              className={styles["select"]}
              status={
                errors.estimated_cost && touched.estimated_cost ? "error" : ""
              }
              size="large"
              id={"estimated_cost"}
              onBlur={handleBlur}
              onChange={(value) => {
                setFieldValue("estimated_cost", value);
              }}
              value={
                (localStorage.getItem("estimated_cost") as unknown as number) > 0
                  ? `${findKeyInMap(
                      EstimatedCostOptionsMap,
                      values.estimated_cost
                    )} zł`
                  : undefined
              }
              placeholder={
                <span className={styles["select__placeholder"]}>
                  <Icon type={IconTypes.DOLAR_ICON} />
                  {createSuggestionData.placeholders.selectRange}
                </span>
              }
              options={Array.from(
                EstimatedCostOptionsMap,
                ([label, value]: [string, number]) => ({
                  value: value,
                  label: (
                    <span className={styles["select__placeholder"]}>
                      {`${label} zł`}
                    </span>
                  ),
                })
              )}
            />)}
            {errors.estimated_cost && touched.estimated_cost ? (
              <p className={styles["error"]}> {"Podaj szacowany koszt"}</p>
            ) : null}
          </div>
        {useConsultationField && (
          <div className={styles["additional-information"]}>
            <label
              id="consultWithOtherWorker"
              className={styles["additional-information__label"]}
            >
              {createSuggestionData.labels.consultWithOtherWorker}
              <span>*</span>
            </label>
            <div className={styles["additional-information__radio-group"]}>
              <label>
                <input
                  id="yes"
                  className={styles["radio"]}
                  checked={Boolean(values.isConsulted)}
                  onChange={() => {
                    setFieldValue("isConsulted", true);
                  }}
                  name="consultedWithAnotherEmployee"
                  type="radio"
                />{" "}
                Tak
              </label>
              <label>
                <input
                  id="no"
                  checked={Boolean(!values.isConsulted)}
                  type="radio"
                  onChange={() => {
                    setFieldValue("isConsulted", false);
                  }}
                  name="noConsultedWithAnotherEmployee"
                  className={styles["radio"]}
                />{" "}
                Nie
              </label>
            </div>
          </div>
        )}
          <div className={styles[useConsultationField ? "additional-information" : "additional-information--customized"]}>
            <label
              id="consultWithOtherWorker"
              className={styles["additional-information__label"]}
            >
              {createSuggestionData.labels.needsExtraHelp}
              <span>*</span>
            </label>
            <label>
              <input
                id="yes"
                type="radio"
                checked={Boolean(values.isDoItYourself)}
                onChange={() => {
                  setFieldValue("isDoItYourself", true);
                }}
                name={"noExtraHelpNeeded"}
                className={styles["radio"]}
              />{" "}
              Tak
            </label>
            <label>
              <input
                id="no"
                checked={Boolean(!values.isDoItYourself)}
                className={styles["radio"]}
                onChange={() => {
                  setFieldValue("isDoItYourself", false);
                }}
                name="extraHelpNeeded"
                type="radio"
              />{" "}
              Nie
            </label>
            <Input
              disabled={values.isDoItYourself}
              onChange={handleChange}
              value={values.helpDescription}
              id="helpDescription"
              name="helpDescription"
              placeholder={!values.isDoItYourself ? createSuggestionData.placeholders.extraHelp : ""}
              className={styles["additional-information__input--bg"]}
            />
          </div>
        </div>
      </div>
      <header className={styles["create-suggestion__header--mobile"]}>
        <div className={styles["buttons-section"]}>
          <Button
            size="large"
            type="primary"
            htmlType="reset"
            onClick={() => {
              resetForm();
              emptyCachedData();
            }}
            className={styles["create-suggestion__header__reset-button"]}
          >
            Wyczyść
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!!validateInput}
            className={styles["create-suggestion__header__sbmt-button"]}
          >
            Zgłoś
          </Button>
        </div>
      </header>
    </form>
  );
};

export default CreateSuggestionForm;
