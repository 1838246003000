import { useAssessmentDisplayData } from "../hooks/useAssessmentDisplayData";

import { useTopBar } from "./useTopBar";
import { Checkbox } from "antd";
import { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../__store/tools/hooks";

import { evaluationThunk } from "../../../../__store/thunks";
import { evaluationModels } from "../../../../__store/models";

import { Icon } from "../../../components/ui";

import styles from "./TopBar.module.scss";
import { IconTypes } from "../../../../data/config";

const TopBar = () => {
  const { assessmentTopBarValue, inputPlaceholder, params } =
    useAssessmentDisplayData();
  const { setAssessmentHeaderTopBarValue } = useTopBar();
  const dispatch = useAppDispatch();

  const headerValue = setAssessmentHeaderTopBarValue(assessmentTopBarValue);
  const user_id = params.id
    ? parseInt(params.id)
    : (useAppSelector((state) => state.auth.user_id) as number);

  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );

  const [query, setType] = useState<evaluationModels.FilterEvaluationState>({
    search: "",
  });
  const onChange = (
    e: CheckboxChangeEvent | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.target.name === "POSITIVE" || e.target.name === "NEGATIVE"
      ? setType((prevstate) => ({
          search: prevstate.search,
          type: e.target.value,
        }))
      : setType((prevstate) => ({
          type: prevstate?.type,
          search: e.target.value,
        }));
  };

  const filteringData = () => {
    if (assessmentTopBarValue === "CREATE_ASSESSMENT") {
      dispatch(evaluationThunk.filterUsersToEvaluate(query.search));
    } else if (assessmentTopBarValue === "WORKERS_WORK") {
      dispatch(evaluationThunk.filterAppraisalStatuses(query.search));
    } else {
      dispatch(evaluationThunk.filterEvaluations({ ...query, user_id }));
    }
  };

  useEffect(() => {
    filteringData();
  }, [query]);

  const debouncedOnChange = debounce(onChange, 50);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <>
      <div className={`${styles["top-bar"]}`}>
        <p className={`${styles["top-bar__assessment-header"]}`}>
          {headerValue}
        </p>

        <div
          className={
            assessmentTopBarValue === "REVIEW_USER" ||
            assessmentTopBarValue === "YOUR_WORK"
              ? `${styles["top-bar__right-area--bigger"]}`
              : `${styles["top-bar__right-area"]}`
          }
        >
          {assessmentTopBarValue === "REVIEW_USER" ||
          assessmentTopBarValue === "YOUR_WORK" ? (
            <div className={styles["top-bar__right-area--bigger__checkboxes"]}>
              <Checkbox
                value={
                  !query.type || query.type === "NEGATIVE"
                    ? "POSITIVE"
                    : undefined
                }
                name="POSITIVE"
                checked={query.type === "POSITIVE"}
                onChange={debouncedOnChange}
              >
                {themeName &&
                ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
                  ? "Do wyróżnienia"
                  : "Pozytywna"}
              </Checkbox>
              <Checkbox
                value={
                  !query.type || query.type === "POSITIVE"
                    ? "NEGATIVE"
                    : undefined
                }
                checked={query.type === "NEGATIVE"}
                name="NEGATIVE"
                onChange={debouncedOnChange}
              >
                {themeName &&
                ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
                  ? "Do poprawy"
                  : "Negatywna"}
              </Checkbox>
            </div>
          ) : (
            <></>
          )}

          <div className={styles["top-bar__search-container"]}>
            <div className={styles["search-placeholder"]} onClick={handleClick}>
              <Icon type={IconTypes.SEARCH} className={styles["search-icon"]} />
              {!query.search && <span>{inputPlaceholder}</span>}
            </div>
            <input ref={inputRef} type="text" onChange={debouncedOnChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
