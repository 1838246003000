import React, { useRef } from "react";
import styles from "./InputField.module.scss";
import { useAppSelector } from "../../../__store/tools/hooks";

import { IconTypes, UserRoles } from "../../../data/config";

import { Icon } from ".";

export interface InputFieldProps {
  placeholder: string;
  inputClassName: string;
  name: string;
  onChangeHandler(e: React.FormEvent<HTMLInputElement>): void;
  type: string;
  hiddenStyles: string;
  value? : string;
  search?: boolean;
}

const InputField = ({
  placeholder,
  inputClassName,
  onChangeHandler,
  name,
  type,
  hiddenStyles,
  value,
  search
}: InputFieldProps) => {
  const userRole = useAppSelector((state) => state.auth.role);
  const userRoleClass = userRole === UserRoles.WORKER ? "worker-possition" : "";

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };
 
  if (search) return (
      <div className={styles["search-container"]}>
        <div className={styles["search-placeholder"]} onClick={handleClick}>
          <Icon type={IconTypes.SEARCH} className={styles["search-icon"]} />
          <span>{placeholder}</span>
        </div>
      <input
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        className={` ${styles[inputClassName]} ${styles[hiddenStyles]} ${styles[userRoleClass]}`}
        onChange={(e) => onChangeHandler(e)}
      />
    </div>
  )
  else return (
    <div>
      <input
        type={type}
        name={name}
        className={` ${styles[inputClassName]} ${styles[hiddenStyles]} ${styles[userRoleClass]}`}
        placeholder={placeholder}
        onChange={(e) => onChangeHandler(e)}
      />
    </div>
  );
};

export default InputField;
