import { configureStore } from "@reduxjs/toolkit";

import {
  modalReducer,
  authReducer,
  bonusReducer,
  departmentReducer,
  rankingReducer,
  reportReducer,
  usersReducer,
  suggestionReducer,
  formsReducer,
  helpersReducer,
  dashboardReducer,
  evaluationReducer,
  applicationSettingsReducer,
  vacationReducer,
  migrationReducer,
  uiReducer,
} from "./slices";

const store = configureStore({
  reducer: {
    applicationSettings: applicationSettingsReducer,
    vacation: vacationReducer,
    modal: modalReducer,
    auth: authReducer,
    bonus: bonusReducer,
    departments: departmentReducer,
    suggestions: suggestionReducer,
    forms: formsReducer,
    ranking: rankingReducer,
    reports: reportReducer,
    users: usersReducer,
    helpers: helpersReducer,
    dashboard: dashboardReducer,
    evaluation: evaluationReducer,
    migrations: migrationReducer,
    ui: uiReducer,
  },
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
