import React, { FunctionComponent, ReactElement, SVGProps } from "react";

import * as Icons from "../../../assets/icons";
import { IconTypes } from "../../../data/config";

interface IconMap {
  [key: string]: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const iconMap: IconMap = {
  [IconTypes.ACCEPTANCE]: Icons.AcceptanceIcon,
  [IconTypes.ADMIN]: Icons.AdminIcon,
  [IconTypes.ALERT_VACATION]: Icons.AlertVacationIcon,
  [IconTypes.ARROW]: Icons.Arrow2Icon,
  [IconTypes.ARROW_DOWN]: Icons.ArrowDown,
  [IconTypes.ATTACH]: Icons.AttachIcon,
  [IconTypes.ASSESSMENT]: Icons.AssessmentIcon,
  [IconTypes.ASSESSMENT_CROSS]: Icons.AssessmentCrossIcon,
  [IconTypes.AVATAR]: Icons.AvatarIcon,
  [IconTypes.AVATAR_BLACK]: Icons.AvatarBlackIcon,
  [IconTypes.BIN]: Icons.BinIcon,
  [IconTypes.BLUE_BELL]: Icons.BlueBellIcon,
  [IconTypes.BUSINESS]: Icons.BusinessIcon,
  [IconTypes.CALENDAR]: Icons.CalendarIcon,
  [IconTypes.CANCEL]: Icons.Cancel,
  [IconTypes.CHEVRON_LEFT]: Icons.ChevronLeftIcon,
  [IconTypes.CHEVRON_RIGHT]: Icons.ChevronRightIcon,
  [IconTypes.CIRCLE]: Icons.Circle,
  [IconTypes.CLOCK_ICON]: Icons.ClockIcon,
  [IconTypes.CROSS]: Icons.CrossIcon,
  [IconTypes.CHECKMARK]: Icons.CheckmarkIcon,
  [IconTypes.CHECK_WHITE]: Icons.CheckWhiteIcon,
  [IconTypes.CHECK_VIOLET]: Icons.CheckVioletIcon,
  [IconTypes.DASHBOARD]: Icons.DashboardIcon,
  [IconTypes.DELETE_VACATION]: Icons.DeleteVacationIcon,
  [IconTypes.DENIED]: Icons.DeniedIcon,
  [IconTypes.DOWNLOAD]: Icons.DownloadIcon,
  [IconTypes.DOWNLOAD_WHITE]: Icons.DownloadWhiteIcon,
  [IconTypes.DOWNLOAD_VIOLET]: Icons.DownloadVioletIcon,
  [IconTypes.EDIT]: Icons.EditIcon,
  [IconTypes.EDIT_VACATION]: Icons.EditVacationIcon,
  [IconTypes.EXPAND_LESS]: Icons.ExpandLessIcon,
  [IconTypes.EXPAND_MORE]: Icons.ExpandMoreIcon,
  [IconTypes.EXCLAMATION]: Icons.ExclamationIcon,
  [IconTypes.EYE]: Icons.EyeSolidIcon,
  [IconTypes.EYE_SLASH]: Icons.EyeSlashSolidIcon,
  [IconTypes.FAIL_ICON]: Icons.FailIcon,
  [IconTypes.INFO]: Icons.Info,
  [IconTypes.INFO_ICON]: Icons.InfoIcon,
  [IconTypes.KEY]: Icons.KeyIcon,
  [IconTypes.LOADING]: Icons.LoadingIcon,
  [IconTypes.MENU]: Icons.MenuIcon,
  [IconTypes.MESSAGE]: Icons.MessageIcon,
  [IconTypes.MONEY_BOX]: Icons.MoneyBoxIcon,
  [IconTypes.OPEN_ASSESSMENT]: Icons.OpenAssessmentIcon,
  [IconTypes.RANKING]: Icons.RankingIcon,
  [IconTypes.REPORT]: Icons.ReportIcon,
  [IconTypes.REPORT_WHITE]: Icons.ReportWhiteIcon,
  [IconTypes.RED_BELL]: Icons.RedBellIcon,
  [IconTypes.SEARCH]: Icons.SearchIcon,
  [IconTypes.STRUCTURE]: Icons.StructureIcon,
  [IconTypes.SUGGESTION]: Icons.SuggestionIcon,
  [IconTypes.THUMB]: Icons.ThumbIcon,
  [IconTypes.TRASH]: Icons.TrashIcon,
  [IconTypes.UPLOAD]: Icons.UploadIcon,
  [IconTypes.USER]: Icons.User,
  [IconTypes.USER_ICON]: Icons.UserIcon,
  [IconTypes.VACATION]: Icons.VacationIcon,
  [IconTypes.WAIT]: Icons.WaitIcon,
  [IconTypes.WORKER]: Icons.WorkerIcon,
  [IconTypes.YELLOW_BELL]: Icons.YellowBellIcon,
  [IconTypes.LOGOUT]: Icons.LogoutIcon,
  [IconTypes.HELP]: Icons.HelpIcon,
  [IconTypes.GEAR]: Icons.GearIcon,
  [IconTypes.MESSAGE_ICON]: Icons.Message,
  [IconTypes.GRAPH_ICON]: Icons.GraphIcon,
  [IconTypes.DOLAR_ICON]: Icons.DolarIcon,
  [IconTypes.SUGGESTION_BOX]: Icons.SuggestionBox,
  [IconTypes.SUCCESS_ICON]: Icons.SuccessIcon,
  [IconTypes.STAR]: Icons.StarIcon,
  [IconTypes.GO_BACK]: Icons.GoBackIcon,
  [IconTypes.BURGER_ICON]: Icons.BurgerIcon,
  [IconTypes.CHECK_CIRCLE_ICON]: Icons.CheckCircleIcon,
  [IconTypes.CANCEL_CIRCLE_ICON]: Icons.CancelCircleIcon,
  [IconTypes.BIG_CHEVRON_LEFT]: Icons.BigChevronLeft,
  [IconTypes.PENCIL_ICON]: Icons.PencilIcon,
  [IconTypes.PAPER_ICON]: Icons.PaperIcon,
  [IconTypes.SUIT_CASE_ICON]: Icons.SuitCase,
  [IconTypes.MONKEY_ICON]: Icons.MonkeyIcon,
  [IconTypes.INPUT_MAN_ICON]: Icons.InputManIcon,
  [IconTypes.PEOPLE_GROUP_ICON]: Icons.PeopleGroup,
  [IconTypes.KEY_INPUT_ICON]: Icons.KeyInputIcon,
  [IconTypes.AREA_ICON]: Icons.AreaIcon,
  [IconTypes.INFO_STRUCTURE_ICON]: Icons.InfoStructureIcon,
  [IconTypes.EMPTY_DOT_ICON]: Icons.EmptyDotIcon,
  [IconTypes.PREVIEW_EYE_ICON]: Icons.PreviewEyeIcon
};

const Icon = ({
  type,
  ...props
}: { type: string } & SVGProps<SVGSVGElement>): ReactElement => {
  const IconComponent = iconMap[type] || Icons.CrossIcon;

  return <IconComponent {...props} />;
};

export default Icon;
