import { useAppDispatch } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";

import { Button } from ".";

import styles from "./UserPanelTopBar.module.scss";

import { ModalClassnames } from "../../../data/config";

const { ADD_USER_FORM } = ModalClassnames;

interface TopBarProps {
  selectedButtons: "ADMIN" | "USER" | "HR" | "STRUCTURE";
  selectedDashboard: "ADMIN" | "USER" | "HR" | "STRUCTURE";
  handleOnChangeDashboard?: (e: any) => void;
  topBarRef?: any;
}

const BUTTONS = {
  USER: [],
  ADMIN: [],
  HR: [
    { name: "Użytkownik", value: "USER", },
    { name: "HR", value: "HR", },
  ],
  STRUCTURE: [
    { name: "Użytkownik", value: "USER", },
    { name: "Struktura", value: "STRUCTURE", },
  ],
};

const HEADER_TEXT = {
  ADMIN: "Administrator",
  USER: "Panel Użytkownika",
  HR: "Akcpetacja Przelewów",
  STRUCTURE: "Struktura organizacji",
};

function UserPanelTopBar({
  selectedButtons,
  selectedDashboard,
  handleOnChangeDashboard,
  topBarRef,
}: TopBarProps) {
  const dispatch = useAppDispatch();

  const handleOnAddUser = () => {
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(ADD_USER_FORM));
  };

  return (
    <div className={styles["top-bar"]} ref={topBarRef}>
      <div className={styles["top-bar__left"]}>
        <p className={styles["header"]}>
          {HEADER_TEXT[selectedDashboard]}
        </p> 
      </div>
      <div className={styles["topbar-right"]}>
        {selectedButtons === "ADMIN" && (
          <Button
            textValue="+ Dodaj pracownika"
            buttonClassName="button"
            hiddenStyles=""
            buttonFunction={handleOnAddUser}
          />
        )}
      </div>
    </div>
  );
}

export default UserPanelTopBar;
