import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import { evaluationThunk } from "../../../__store/thunks";
import { evaluationActions } from "../../../__store/slices";

import { Icon, InactiveAccount, NavBar } from "../../components/ui";

import styles from "./MyAssessment.module.scss";
import { Table } from "../../components/ui/Table";
import { IconTypes } from "../../../data/config";
import {
  MY_ASSESSMENT_ROW_CLASSES,
  MY_ASSESSMENT_HEADER_CLASSES,
} from "./data";
import NavTopBar from "../../components/ui/NavTopBar";
import appHeaders from "../../../data/constants/appHeaders.data";
import { UserRates } from "../../../__store/models/evaluation.models";

const MyAssessment = () => {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector(
    (state) => state.users.authUser?.user.isActive
  );
  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );

  const { ASSESSMENT } = appHeaders;

  const userId = useAppSelector((state) => state.auth.user_id) as number;
  const userRates = useAppSelector((state) => state.evaluation.userRates);
  const { negativeRates, positiveRates } = useAppSelector(
    (state) => state.evaluation.personalRates
  );

  useEffect(() => {
    dispatch(evaluationThunk.getUserEvaluations(userId));
    dispatch(evaluationActions.setEvaluatingUserId(userId));
  }, []);

  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
        <div className={styles["page__dashboard"]}>
          <NavTopBar topBarHeader={ASSESSMENT.ASSESSMENT_HEADER} />
          <div className={styles["container"]}>
            <div className={styles["container__rates-section"]}>
              <div className={styles["rate"]}>
                <h4 className={styles["rate__header"]}>Wyróżnienia</h4>
                <h2 className={styles["rate__header--bg"]}>{positiveRates}</h2>
                <Icon
                  type={IconTypes.CHECK_CIRCLE_ICON}
                  className={styles["rate__icon"]}
                />
              </div>

              <div className={styles["rate"]}>
                <h4 className={styles["rate__header"]}>Do poprawy</h4>
                <h2 className={styles["rate__header--bg"]}>{negativeRates}</h2>
                <Icon
                  type={IconTypes.CANCEL_CIRCLE_ICON}
                  className={styles["rate__icon"]}
                />
              </div>
            </div>
            <div className={styles["container__table-section"]}>
              <Table
                items={userRates as UserRates[]}
                withFilters={true}
                checkboxLabels={
                  themeName &&
                  ["pgf", "cisowianka", "gobarto"].includes(themeName)
                    ? ["Filtruj:", "Wyróżnienie", "Do poprawy"]
                    : ["Filtruj:", "Pozytywne", "Negatywne"]
                }
                rowClass={MY_ASSESSMENT_ROW_CLASSES}
                placeholderFilterValue="Wyszukaj temat..."
                headerValue={["Nr.", "Data", "Rodzaj", "Temat", "Opis", ""]}
                renderHeaderItem={(item) => (
                  <th className={MY_ASSESSMENT_HEADER_CLASSES}>{item}</th>
                )}
              />
            </div>
          </div>

          {/* <AssessmentDashboard assessmentList={userRates} /> */}
        </div>
      ) : (
        <InactiveAccount />
      )}
    </div>
  );
};

export default MyAssessment;
