import { ReactElement } from "react";

import styled from "../UpdateUserForm.module.scss";
import { IMultiUsersSelect } from "./activation.types";

export const MultiUsersSelect = ({
  userID,
  usersArray, 
  selectedUser, 
  selectHandler,
  isDisabled,
} : IMultiUsersSelect): ReactElement => {
  return (
    <select
      name="supervisor"
      className={styled["form__context__select-container"]}
      onChange={(e) => selectHandler(e)}
      value={selectedUser}
      disabled={isDisabled}
      >
        {usersArray ? usersArray
          .filter(user => user.user_id != userID)
          .map(user => 
            <option
              key={user.user_id}
              value={user.user_id}>
                {user.profile 
                  ? `${user.profile.name} ${user.profile.surname}` 
                  : `${user.name} ${user.surname}`}
            </option>
          ) : null}
    </select>)
  };

export default MultiUsersSelect;
