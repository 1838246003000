import React, { useEffect, useRef, ReactElement } from "react";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { adminThunk, departmentThunk } from "../../../__store/thunks";

import styles from "../views/SuggestionPreview.module.scss";

import { AttachmentModal } from "../../components/Modal";
import { AttachmentComponent, AttachmentView, Icon } from "../../components/ui";

import { IconTypes, MainStatuses, ModalClassnames } from '../../../data/config';
import { suggestionsData } from '../../../data/constants';
import { AllStatuses, CreationSubstatuses } from '../../../data/config/suggestionStatuses.data';
import { Button, Divider, Timeline, Typography } from "antd";

import { SuggestionStatusTag } from ".";
import { Commentary } from "../../../__store/models/suggestion.models";
import { modalActions, formsActions } from "../../../__store/slices";

const { Title } = Typography;

const {
  SuggestionTypes,
  SuggestionTypesTranslated,
  DATE_FORMAT,
  DATE_OPTIONS,
  EstimatedCostOptionsMap,
  suggestionStatusInfoButtons,
  suggestionStatusInfoLabels,
} = suggestionsData;

const { NOTE_FORM } = ModalClassnames;

const SuggestionStatusInfo = React.forwardRef(({ suggestionData, suggestionId }: { suggestionData: any, suggestionId: string }, ref): ReactElement => {
  const dispatch = useAppDispatch();

  const userID = useAppSelector((state) => state.auth.user_id);

  const suggestionDataRef = useRef<HTMLDivElement>(null);

  const attachmentPath = useAppSelector((state) => state.modal.attachmentPath);
  const attachmentDescription = useAppSelector((state) => state.modal.attachmentDescription);

  const useEstimationCostInput = useAppSelector((state) => state.applicationSettings.useEstimatedCostAsInput);
  const useConsultationField = useAppSelector((state) => state.applicationSettings.useConsultationField);


  const openAddNoteForm = () => {
    dispatch(modalActions.setModalClassName(NOTE_FORM));
    dispatch(formsActions.setFormInfoData(suggestionData));
    dispatch(formsActions.setPickedSuggestionId(suggestionData.suggestion_id));
    dispatch(modalActions.openModal());
  }

  const handlePrintSuggestionData = useReactToPrint({
    content: () => suggestionDataRef.current,
    documentTitle: `${suggestionData?.department.name} - ${suggestionData?.title}`,
  });

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentsWithSuperVisors());
    dispatch(
      adminThunk.fetchUser(suggestionData?.evaluating_user?.user_id as number)
    );
  }, []);

  const endResultComment = suggestionData.commentaries?.filter(
    (commentary:Commentary) => commentary.substatus === CreationSubstatuses.RESULT
  );

  const SUGGESTION_PREVIEW_LABELS = {
    AUTHOR: "Zgłaszający: ",
    EVALUATOR: "Przełożony: ",
    TYPE: "Rodzaj: ",
    AREA: "Obszar: ",
    DEPARTMENT: "Dział: ",
    DATE: "Data: ",
    TIME: "Godzina: ",
    ESTIMATED_COST: "Szacowany koszt: ",
    CONSULTATION: "Skonsultowane: ",
    TODO_YOURSELF: "Możliwe do samodzielnego wdrożenia: "
  }

  const formattedDate = format(new Date(suggestionData.date), DATE_FORMAT, DATE_OPTIONS);
  const dateParts = formattedDate.split(', ');

  const datePart = dateParts[0];
  const timePart = dateParts[1];

  const checkAndRenderAttachmentsByStatus = (
    { status, substatus } : { status: MainStatuses, substatus: AllStatuses }) => {
    
    const statusAttachmentsData = suggestionData.attachments?.filter((attachment: any) => attachment.status === status && attachment.substatus === substatus);
      
    if (statusAttachmentsData.length > 0) {
      return (
        <AttachmentComponent
          attachmentsData={statusAttachmentsData}
          description="Załącznik do statusu"
        />
      )
    } else return null;
  }

  return (
    <div className={styles["content-section"]} ref={suggestionDataRef}>
      <AttachmentModal>
        <AttachmentView
          path={attachmentPath}
          description={attachmentDescription}
        />
      </AttachmentModal>
      <div className={styles["suggestion-preview"]} >
        <Title level={3} style={{ 
          marginBottom: 0, 
          fontSize: 24, 
          fontWeight: 700,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          Dane

          <div className={styles["suggestion-preview__buttons"]} >
            <Button 
              type="primary" 
              className={styles["primary-button"]} 
              onClick={openAddNoteForm}
              icon={<Icon type={IconTypes.PENCIL_ICON} />}
            >
              Notatka
            </Button>
            <Button 
              type="primary" 
              className={styles["primary-button"]} 
              onClick={handlePrintSuggestionData}
              icon={<Icon type={IconTypes.UPLOAD} />}
            >
              Eksportuj
            </Button>
          </div>
        </Title>
        <Divider style={{ 
          borderBlockStart: "2px solid #9CCEFC" 
        }} />
        <div className={styles["content-table"]}>
          <div className={styles["row__content--col"]}>
            {userID !== suggestionData.reporting_user_id 
              && (<div className={styles["content__column"]}>
                    <label className={styles["content__label"]}>
                      <Icon type={IconTypes.PEOPLE_GROUP_ICON} />
                        {SUGGESTION_PREVIEW_LABELS.AUTHOR}
                    </label>
                      {suggestionData.reporting_user.profile.name}
                      {" "}
                      {suggestionData.reporting_user.profile.surname}
                  </div>)}
            {userID !== suggestionData.evaluating_user_id
              && (<div className={styles["content__column"]}>
                    <label className={styles["content__label"]}>
                      <Icon type={IconTypes.PEOPLE_GROUP_ICON} />
                        {SUGGESTION_PREVIEW_LABELS.EVALUATOR}
                    </label>
                      {suggestionData.evaluating_user.profile.name}
                      {" "}
                      {suggestionData.evaluating_user.profile.surname}
                  </div>)}
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.PEOPLE_GROUP_ICON} />
                {SUGGESTION_PREVIEW_LABELS.TYPE}
              </label>
              {SuggestionTypesTranslated.get(
                suggestionData.suggestion_type as suggestionsData.SuggestionTypes
              )}
            </div>
          </div>
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.GRAPH_ICON} />
                {SUGGESTION_PREVIEW_LABELS.AREA}
              </label>
              {suggestionData.area.area_name}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.GRAPH_ICON} />
                {SUGGESTION_PREVIEW_LABELS.DEPARTMENT}
              </label>
              {suggestionData.department.name}
            </div>
          </div>
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.CALENDAR} />
                {SUGGESTION_PREVIEW_LABELS.DATE}
              </label>
              {datePart}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.CLOCK_ICON} />
                {SUGGESTION_PREVIEW_LABELS.TIME}
              </label>
              {timePart}
            </div>
          </div>
        </div>
      </div>

      <div className={styles["suggestion-preview"]}>
        <Title level={3} style={{ 
          marginBottom: 0, 
          fontSize: 24, 
          fontWeight: 700
        }}>
          Opis sugestii
        </Title>
        <Divider style={{ 
          borderBlockStart: "2px solid #9CCEFC" 
        }} />
        <div className={styles["content-table"]}>
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                Sugestia
              </label>
              </div>
              <div className={styles["content__column"]}>
                {suggestionData.problem_description}
              </div>
              {suggestionData.attachments?.filter((attachment:any) => attachment.attachment_type === "PROBLEM").length > 0 && (
                <AttachmentComponent
                  attachmentsData={suggestionData.attachments.filter((attachment:any) => attachment.attachment_type === "PROBLEM")}
                  description={
                    suggestionStatusInfoLabels.ATTACHMENT_SUGGESTION_DESCRIPTION
                  }
                />
              )}
            </div>
            <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                Rozwiązanie
              </label>
              </div>
              <div className={styles["content__column"]}>
                {suggestionData.solution_description}
              </div>
              {suggestionData.attachments?.filter((attachment:any) => attachment.attachment_type === "SOLUTION").length > 0 && (
                <AttachmentComponent
                  attachmentsData={suggestionData.attachments.filter((attachment:any) => attachment.attachment_type === "SOLUTION")}
                  description={
                    suggestionStatusInfoLabels.ATTACHMENT_SUGGESTION_DESCRIPTION
                  }
                />
              )}
            </div>
            <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                Rezultat
              </label>
              </div>
              <div className={styles["content__column"]}>
                {suggestionData.result_description}
              </div>
              {suggestionData.attachments?.filter((attachment:any) => attachment.attachment_type === "RESULT").length > 0 && (
                <AttachmentComponent
                  attachmentsData={suggestionData.attachments.filter((attachment:any) => attachment.attachment_type === "RESULT")}
                  description={
                    suggestionStatusInfoLabels.ATTACHMENT_SUGGESTION_DESCRIPTION
                  }
                />
              )}
            </div>
          </div>
      </div>

      <div className={styles["suggestion-preview"]}>
        <Title level={3} style={{ 
          marginBottom: 0, 
          fontSize: 24, 
          fontWeight: 700
        }}>
          Informacje dodatkowe
        </Title>
        <Divider style={{ 
          borderBlockStart: "2px solid #9CCEFC" 
        }} />
        <div className={styles["content-table"]}>
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                <Icon type={IconTypes.DOLAR_ICON} />
                  {SUGGESTION_PREVIEW_LABELS.ESTIMATED_COST}
              </label>
                {useEstimationCostInput
                  ? `${suggestionData.estimated_cost} zł`
                  : suggestionData.estimated_cost || suggestionData?.estimated_cost === 0
                    ? `${
                      Array.from(
                        EstimatedCostOptionsMap,
                        ([label, cost]: [string, number]) => {
                          return cost > suggestionData.estimated_cost ? label : null;
                        }
                      ).find((item) => item !== null) || "powyżej 2000"
                    } zł`
                  : null}
            </div>
          </div>
          <div className={styles["row__content--col"]}>
            {useConsultationField && (
              <div className={styles["content__column"]}>
                <label className={styles["content__label"]}>
                  <Icon type={IconTypes.USER_ICON} />
                  {SUGGESTION_PREVIEW_LABELS.CONSULTATION}
                </label>
                {suggestionData.is_consulted ? "tak" : "nie"}
              </div>)}
          </div>
          <div className={styles["row__content--col"]}>
            <div className={styles[suggestionData.is_do_it_yourself ? "content__column" : "content__column--multi"]}>
              <div className={styles["content__row"]}>
                <label className={styles["content__label"]}>
                  <Icon type={IconTypes.HELP} />
                  {SUGGESTION_PREVIEW_LABELS.TODO_YOURSELF}
                </label>
                <span>
                  {suggestionData.is_do_it_yourself ? "tak" : "nie"}
                </span>
              </div>
              <div className={styles["content__row"]}>
              {!suggestionData.is_do_it_yourself && (
                <span>
                  {suggestionData.help_description}
                </span>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(endResultComment && endResultComment.length) > 0 && (<div className={styles["suggestion-preview"]}>
        <Title level={3} style={{ 
          marginBottom: 0, 
          fontSize: 24, 
          fontWeight: 700
        }}>
          Rezultat końcowy
        </Title>
        <Divider style={{ 
          borderBlockStart: "2px solid #9CCEFC" 
        }} />
        <div className={styles["content-table"]}>
          <div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                {suggestionStatusInfoLabels.DATE}
              </label>
                {format(
                  new Date(endResultComment[endResultComment.length - 1].created_date as Date),
                  DATE_FORMAT, DATE_OPTIONS
                )}
            </div>
            <div className={styles["content__column"]}>
              <label className={styles["content__label"]}>
                {suggestionStatusInfoLabels.AUTHOR}
              </label>
              {endResultComment[endResultComment.length - 1].author.name}
              {" "}
              {endResultComment[endResultComment.length - 1].author.surname}
            </div>
          </div>
          <div className={styles["row__content--col"]}>
            {useConsultationField && (
              <div className={styles["content__column"]}>
                <label className={styles["content__label"]}>
                  Opis
                </label>
                {endResultComment[endResultComment.length - 1].content}
              </div>)}
          </div>

          {suggestionData.attachments?.filter((attachment:any) => attachment.substatus === AllStatuses.RESULT).length > 0 && (<div className={styles["row__content--col"]}>
            <div className={styles["content__column"]}>
              <div className={styles["content__row"]}>
                <label className={styles["content__label"]}>
                  Załączniki
                </label>
                <AttachmentComponent
                  attachmentsData={suggestionData.attachments.filter((attachment:any) => attachment.substatus === "RESULT")}
                  description="Załącznik do rezultatu końcowego sugestii"
                />
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    )}

      {suggestionData.commentaries.length > 0 
        && (<div className={styles["suggestion-preview"]}>
          <Title level={3} style={{ 
            marginBottom: 0, 
            fontSize: 24, 
            fontWeight: 700
          }}>
            Statusy i komentarze
          </Title>
          <Divider style={{ 
            borderBlockStart: "2px solid #9CCEFC" 
          }} />
          <Timeline
            reverse
            items={
              [{
                color: 'gray', 
                children: (
                  <div className={styles["timeline"]}>
                    <div className={styles["timeline__heading"]}>
                      <SuggestionStatusTag status={suggestionData.history[0].status} substatus={suggestionData.history[0].substatus} />
                      <span className={styles["timeline__date"]}>{format(new Date(suggestionData.history[0].date), DATE_FORMAT, DATE_OPTIONS)}</span>
                    </div>
                    <div className={styles["timeline__content"]}>
                      <p className={styles["timeline__user"]}>{suggestionData.history[0].user.name} {suggestionData.history[0].user.surname}</p>
                      <p className={styles["timeline__description"]}>Sugestia została utworzona</p>
                    </div>
                  </div>
                 )
              }].concat(
              suggestionData.commentaries
                .filter((commentary:any) => commentary.substatus !== AllStatuses.NOTE)
                .map((commentary:any) => {
              return {
                color: 'gray',
                children: (
                  <div className={styles["timeline"]}>
                    <div className={styles["timeline__heading"]}>
                      <SuggestionStatusTag status={commentary.status} substatus={commentary.substatus} />
                      <span className={styles["timeline__date"]}>{format(new Date(commentary.created_date), DATE_FORMAT, DATE_OPTIONS)}</span>
                    </div>
                    <div className={styles["timeline__content"]}>
                      <p className={styles["timeline__user"]}>{commentary.author.name} {commentary.author.surname}</p>
                      <p className={styles["timeline__description"]}>{commentary.content}</p>
                    </div>
                    
                    {checkAndRenderAttachmentsByStatus({ 
                      status: commentary.status, 
                      substatus: commentary.substatus 
                    })}
                  </div>
                )
              }
            })
          )}
          />
        </div>)}
    </div>
    );
  }
);

export default SuggestionStatusInfo;
