// import { bonusModels } from "../../models";
import axiosInstance from "../../tools/axios";

// import { ServerPaths } from "../../../data/config";

// const {} = ServerPaths;

export const getBonusById = async (bonusId: number) => {
  return await axiosInstance.get(`/bonus/single?bonusId=${bonusId}`);
};

export const createBonus = async (payload: any) => {
  return await axiosInstance.post("/bonus/create", payload);
};

export const updateBonusData = async (payload: any) => {
  return await axiosInstance.put("/bonus/update", { bonusData: payload });
};

export const updateBonusStatus = async (
  bonusId: number, 
  userId: number, 
  newStatus: string
  ) => {
    return await axiosInstance.put(`/bonus/updateStatus?bonusId=${bonusId}&userId=${userId}&newStatus=${newStatus}`);
};

export const deleteBonus = async (bonusId: number) => {
  return await axiosInstance.delete(`/bonus/delete?bonusId=${bonusId}`);
};

export const getAllBonuses = async () => {
  return await axiosInstance.get("/bonus/all");
};

export const getBonusesByStatus = async (status: string) => {
  return await axiosInstance.get(`/bonus/byStatus?status=${status}`);
};

export const getBonusesByUser = async (beneficiaryUserId: number) => {
  return await axiosInstance.get(`/bonus/byUser?beneficiaryUserId=${beneficiaryUserId}`);
};

export const generateBonusesForPoints = async (generatingUserId: number, beneficaryUserId: number) => {
  return await axiosInstance.post(`/bonus/generatePoints?generatingUserId=${generatingUserId}&beneficiaryUserId=${beneficaryUserId}`);
};

export const generateBonusesForUser = async (generatingUserId: number, beneficiaryUserId: number) => {
  return await axiosInstance.post(`/bonus/generateUser?generatingUserId=${generatingUserId}&beneficaryUserId=${beneficiaryUserId}`);
};

export const generateBonusesForDateRange = async (generatingUserId: number, startDate: Date, endDate: Date) => {
  return await axiosInstance.post(`/bonus/generateDate?generatingUserId=${generatingUserId}&startDate=${startDate}&endDate=${endDate}`);
};

export const updateMultipleBonusStatuses = async (updatingUserId: number, newStatus: string, beneficaryUserId: number) => {
  return await axiosInstance.put(`/bonus/updateMultipleStatuses?updatingUserId=${updatingUserId}&newStatus=${newStatus}&beneficiaryUserId=${beneficaryUserId}`);
};
