export enum RecoveryStatus {
  EMPTY = "",
  SENT = "SENT",
  ERROR = "ERROR"
};

export type Permissions = {
  [key in Permission]: boolean;
};
  
export enum Permission {
  hr_permission = "hr_permission",
  admin_permission = "admin_permission",
  finance_permission = "finance_permission",
  structure_permission = "structure_permission",
  payment_permission = "payment_permission",
  all_suggestions_preview_permission = "all_suggestions_preview_permission",
  external_comments_permission = "external_comments_permission"
};
  
// in config file you'll find UserRoles enum and some permissions' arrays
export enum Role {
  WORKER = "WORKER",
  MANAGER = "MANAGER",
  BOSS = "BOSS",
  ADMIN = "ADMIN",
};
  