import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { useAssessmentDisplayData } from "../components/hooks/useAssessmentDisplayData";

import { evaluationActions } from "../../../__store/slices";
import { evaluationThunk } from "../../../__store/thunks";

import { InactiveAccount, NavBar } from "../../components/ui";
import { AssessmentDashboard } from "../components/AssessmentDashboard";
import { TopBar } from "../components";

import styles from "./CreateAssessment.module.scss";

const ReviewUserAssessment = () => {
  const dispatch = useAppDispatch();

  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);

  const { params } = useAssessmentDisplayData();
  const userRates = useAppSelector((state) => state.evaluation.userRates);
  const evaluatingUserId = parseInt(params.id as string);

  useEffect(() => {
    dispatch(evaluationThunk.getUsersToEvaluate());
    dispatch(evaluationThunk.getUserEvaluations(evaluatingUserId));
    dispatch(evaluationActions.setEvaluatingUserId(evaluatingUserId));
  }, []);

  return (
    <div className={styles["page"]}>
      <NavBar />
      {isActive ? (
      <div className={styles["page__dashboard"]}>
        <TopBar />

        <AssessmentDashboard assessmentList={userRates} />
      </div>) : <InactiveAccount />}
    </div>
  );
};
export default ReviewUserAssessment;
