import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

import { authThunk } from "../../../__store/thunks";
import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";

import styles from "./InactiveAccount.module.scss";

import { SpinnerModal } from "../Modal";

import { RoutesPaths } from "../../../data/config";
import { authData } from "../../../data/constants";

const { AUTHORIZATION } = RoutesPaths;
const { INACTIVE_ACCOUNT_TITLE, INACTIVE_ACCOUNT_LOGOUT_BUTTON } = authData;

const InactiveAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const user_id = useAppSelector((state) => state.auth.user_id);
  const authUser = useAppSelector((state) => state.users.authUser)

  const [isLoading, setIsLoading] = useState(true);

  const logoutHandler = () => {
    dispatch(authThunk.logout(user_id as number));
  };

  useEffect(() => {
    if (authUser) {
      setIsLoading(false);
    }
  })

  if (isLoading) {
    return <SpinnerModal />
  }

  return (
    <Result
      className={styles["inactive-info"]}
      status="warning"
      title={INACTIVE_ACCOUNT_TITLE}
      extra={
        <Link
        onClick={logoutHandler}
        to={AUTHORIZATION.LOGOUT_PATH}
      >
        <Button type="primary" key="console">
          {INACTIVE_ACCOUNT_LOGOUT_BUTTON}
      </Button>
      </Link>

      }
    />
  )
}

export default InactiveAccount;
