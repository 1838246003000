import { useDrop } from "react-dnd";
import { useEffect, useState, useRef, useLayoutEffect, ReactElement } from "react";

import { NestedDepartment } from ".";
import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";

// not sure if that's a good practise to use fetch services directly in components //
import { departmentService } from "../../../../__store/thunks/services";
import { departmentThunk } from "../../../../__store/thunks";
import { departmentModels } from "../../../../__store/models";

import { structureData } from "../../../../data/constants";

import styles from "./DepartmentItem.module.scss";
import { IDepartmentItemProps } from "../../types/AdminProps.types";

const { DragType } = structureData;

function DepartmentItem({
  handleClick,
  department,
  selectedDepartmentId,
}: IDepartmentItemProps):ReactElement {
  const [childDepartmentHeight, setChildDepartmentHeight] = useState(0);
  const [distanceFromTop, setDistanceFromTop] = useState<number>(0);
  const departmentRef = useRef<HTMLSpanElement>(null);
  const childDepartmentsRef = useRef<HTMLUListElement>(null);
  const dispatch = useAppDispatch();
  const draggedDepartmentId = useAppSelector(
    (state) => state.departments.draggedDepartment
  );

  const [childList, setChildList] = useState<departmentModels.StructureDepartment[]>([]);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [showNested, setShowNested] = useState<boolean>(false);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "box",
      drop: (
        item: { type: structureData.DragType; id: number; selectedId: number },
        monitor
      ) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          if (item.type === DragType.job) {
            dispatch(
              departmentThunk.moveJobPosiiton({
                job_position_id: item.id,
                department_id: item.selectedId,
                new_department_id: department.department_id,
              })
            );
          } else if (item.type === DragType.department) {
            dispatch(
              departmentThunk.changeParentDepartment({
                department_id: item.id,
                new_parent_id: department.department_id,
              })
            );
          }
        }
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    ["ANY"]
  );

  useLayoutEffect(() => {
    if (childDepartmentsRef.current?.clientHeight) {
      setChildDepartmentHeight(childDepartmentsRef.current?.clientHeight);
    }

    if (departmentRef.current?.offsetTop) {
      setDistanceFromTop(departmentRef.current?.getBoundingClientRect().top);
    }
  });

  useEffect(() => {
    getChildDepartment();
  }, [department]);

  useEffect(() => {
    if (isOver && canDrop) {
      setIsHover(true);
      setShowNested(true);
    } else {
      setIsHover(false);
    }
  }, [isOver, canDrop]);

  async function getChildDepartment() {
    try {
      const res = await departmentService.fetchDepartmentsByParentId(department.department_id);
      setChildList(res.data);
    } catch (error) {
      setChildList([]);
    }
  }

  useEffect(() => {
    if (!isOver) {
      setShowNested(false);
    }
  }, [isOver]);

  function hideNested() {
    setShowNested(false);
  }

  return (
    <li
      ref={drop}
      onClick={() =>
        handleClick({
          departmentId: department.department_id,
          breadcrumbs: [],
        })
      }
      onMouseLeave={hideNested}
      key={department.department_id}
      className={`${styles["department"]} ${
        department.department_id === selectedDepartmentId && styles["active"]
      } ${
        isHover &&
        department.department_id !== draggedDepartmentId &&
        styles["hover"]
      }`}
    >
      <span className={styles["department__name"]} ref={departmentRef}>
        {department.name}
      </span>

      {childList.length > 0 && department.department_id !== 1 && (
        <div
          className={styles["child-departments"]}
          style={{
            top:
              window.innerHeight - distanceFromTop < childDepartmentHeight
                ? `${distanceFromTop - childDepartmentHeight - 50}px`
                : `${distanceFromTop - 100 + "px"}`,
          }}
        >
          <ul
            className={`${styles["child-departments__list"]} ${
              showNested && styles["active"]
            }`}
            ref={childDepartmentsRef}
          >
            {childList
              .filter(
                (department) => department.department_id !== draggedDepartmentId
              )
              .map((childDepartment) => (
                <NestedDepartment
                  department={childDepartment}
                  key={childDepartment.department_id}
                  onMouseLeave={hideNested}
                />
              ))}
          </ul>
        </div>
      )}
    </li>
  );
}

export default DepartmentItem;
