import { createAsyncThunk } from "@reduxjs/toolkit";

import { reportModels } from "../models";
import { reportService } from "./services";

import { ThunkNames, GlobalVariables, ServerStatuses, ModalClassnames } from "../../data/config";
import { modalActions } from "../slices";

const { REPORT_GENERATE_THUNK } = ThunkNames;
const { REPORTS_FILENAME, ARCHIVE_EXTENSION } = GlobalVariables;

export const postReportsData = createAsyncThunk(
  REPORT_GENERATE_THUNK,
  async (payload: reportModels.ReportInputData, thunkAPI) => {
    
    const { dispatch } = thunkAPI;
    
    try {
      const response = await reportService.generateReportsData(payload);
      const { status, data } = response;

      if (status === ServerStatuses.OK) {
        const blob = data;
        const url = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.style.display = "none";
        downloadLink.href = url;
        downloadLink.download = `${REPORTS_FILENAME}.${ARCHIVE_EXTENSION}`;
        document.body.appendChild(downloadLink);

        downloadLink.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(downloadLink);

        dispatch(modalActions.openModal());
        dispatch(modalActions.setModalClassName(ModalClassnames.REPORT_GENERATE_SUCCESS))
      } else if (status === ServerStatuses.NO_CONTENT) {
        dispatch(modalActions.openModal());
        dispatch(modalActions.setModalClassName(ModalClassnames.REPORT_GENERATE_EMPTY))
      } else {
        dispatch(modalActions.openModal());
        dispatch(modalActions.setModalClassName(ModalClassnames.REPORT_GENERATE_FAIL))
      }
    } catch (error) {
      dispatch(modalActions.openModal());
      dispatch(modalActions.setModalClassName(ModalClassnames.REPORT_GENERATE_FAIL))
    }
  }
);
