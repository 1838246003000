import React, { useState, useEffect, ReactElement, FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";
import { suggestionModels } from "../../../__store/models";
import { modalActions } from "../../../__store/slices";
import { departmentThunk, suggestionThunk } from "../../../__store/thunks";

import styles from "./AcceptSuggestionForm.module.scss";

import { IconTypes, ModalClassnames, RoutesPaths, UserRoles } from "../../../data/config";

import MainStatuses, { ReportSubstatuses } from "../../../data/config/suggestionStatuses.data";

import { Button, Select } from "antd";
import { redirectionMessages } from "../../../data/constants/suggestions.data";
import { Icon } from "../../components/ui";

import { ISuggestionForm } from "../types/SuggestionsProps.types";

const { SUGGESTION_REDIRECTED } = ModalClassnames;

const RedirectSuggestionForm: FunctionComponent<ISuggestionForm> = ({
  text,
  mainStatus,
  secondStatus,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const suggestion = useAppSelector((state) => state.forms.formInfoData);
  const pickedSuggestionId = useAppSelector((state) => state.forms.pickedSuggestionId);
  const departmentsWithSupervisors = useAppSelector((state) => state.departments.departmentsWithSupervisors);

  const [redirectionError, setRedirectionError] = useState<string>("");

  const [updateSuggestion, setUpdateData] =
    useState<suggestionModels.SuggestionUpdatePayload>({
      comment: "",
      suggestion_id: pickedSuggestionId,
      status: mainStatus,
      substatus: secondStatus,
      estimated_cost: suggestion?.estimated_cost,
      suggestion_type: suggestion?.suggestion_type,
      userRole: "",
      department_id: suggestion?.department_id,
      evaluating_user: Number(suggestion?.evaluating_user?.profile.user_id),
    });

  const sortedDepartments = Object.values(departmentsWithSupervisors)
    .filter((department) => department.name !== "Brak stanowiska")
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    setUpdateData({
      ...updateSuggestion,
      suggestion_id: pickedSuggestionId,
      status: mainStatus,
      substatus: secondStatus,
    });
  }, [pickedSuggestionId]);

  useEffect(() => {
    dispatch(departmentThunk.getDepartmentsWithSuperVisors());
  }, []);

  const onDepartmentHandler = (value: string): void => {
    const selectedDepartmentID = +value;

    if (departmentsWithSupervisors[selectedDepartmentID].supervisors[0]) {
      const isRedirectionAddressedToSuggestionAuthor =
        suggestion?.reporting_user?.profile.role === UserRoles.MANAGER &&
        selectedDepartmentID ===
          suggestion?.reporting_user.profile.department.department_id;

      if (isRedirectionAddressedToSuggestionAuthor) {
        setRedirectionError(redirectionMessages.REDIRECTION_TO_AUTHOR);
      } else if (suggestion?.evaluating_user) {
        const isRedirectedAutomatically =
          departmentsWithSupervisors[selectedDepartmentID].supervisors[0]
            .user_id === Number(suggestion?.evaluating_user?.profile.user_id);

        if (isRedirectedAutomatically) {
          setRedirectionError(redirectionMessages.REDIRECTED_AUTOMATICALLY);
        } else {
          setRedirectionError("");
          setUpdateData((prev) => ({
            ...prev,
            department_id: selectedDepartmentID,
            evaluating_user:
              departmentsWithSupervisors[selectedDepartmentID].supervisors[0]
                .user_id,
          }));
        }
      }
    } else {
      setRedirectionError(redirectionMessages.MANAGER_NOT_DEFINED);
    }
  };

  const onManagerChange = (value: string) => {
    const selectedManagerID = +value;

    setUpdateData((prev) => ({
      ...prev,
      evaluating_user: selectedManagerID
    }));
  };

  const onSubmitHandler = (): void => {
    dispatch(
      suggestionThunk.updateSuggestionStatus({
        comment: updateSuggestion.comment,
        suggestion_id: pickedSuggestionId,
        status: MainStatuses.REPORT,
        substatus: ReportSubstatuses.REDIRECTED,
        suggestion_type: updateSuggestion.suggestion_type,
        estimated_cost: updateSuggestion.estimated_cost,
        department_id: updateSuggestion.department_id,
        evaluating_user: updateSuggestion.evaluating_user,
        userRole: "",
      })
    );
    
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(SUGGESTION_REDIRECTED));

    navigate(RoutesPaths.SUGGESTIONS.WORKER_SUGGESTIONS_PATH);
  };

  const handleOnClose = (): void => {
    dispatch(modalActions.closeModal());
  };


  return (
    <form className={styles["evaluate"]} onSubmit={onSubmitHandler}>
      <div className={styles["evaluate__section"]}>
        <h4>Przekieruj sugestię</h4> 
        <hr />

        <div className={styles["redirection"]}>

        <Select
          className={styles["select"]}
          size="large"
          id="department_id"
          options={
            Object.keys(sortedDepartments).map((key) => ({
              value: sortedDepartments[+key].department_id,
              label: (
                <span className={styles["select__placeholder"]}>
                  {sortedDepartments[+key].name}
                </span>
              ),
              disabled: !sortedDepartments[+key].supervisors.length 
                || sortedDepartments[+key].department_id === suggestion?.department_id
            })
          )}
          onChange={onDepartmentHandler}
          placeholder={
            <span className={styles["select__placeholder"]}>
              <Icon type={IconTypes.GRAPH_ICON} />
              Wybierz dział
            </span>
          }
          />

        <Select
          className={styles["select"]}
          id="evaluating_user"
          size="large"
          options={
            (updateSuggestion.department_id !== undefined 
              && updateSuggestion.department_id !== suggestion?.department_id) 
            ? departmentsWithSupervisors[updateSuggestion.department_id]?.supervisors?.map(
              (supervisor: any) =>
                ({
                  value: +supervisor.user_id,
                  label: (
                    <span className={styles["select__placeholder"]}>
                      {supervisor.profile.name} {supervisor.profile.surname}
                    </span>
                  )
                })
            ) : undefined
          }

          value={
            updateSuggestion.department_id &&
            departmentsWithSupervisors[updateSuggestion.department_id]?.supervisors &&
            departmentsWithSupervisors[updateSuggestion.department_id].supervisors.find((supervisor: any) => supervisor.user_id === updateSuggestion.evaluating_user)
              ? `${departmentsWithSupervisors[updateSuggestion.department_id]
                  .supervisors.find((supervisor: any) => supervisor.user_id === updateSuggestion.evaluating_user)?.profile?.name} 
                ${departmentsWithSupervisors[updateSuggestion.department_id]
                  .supervisors.find((supervisor: any) => supervisor.user_id === updateSuggestion.evaluating_user)?.profile?.surname}`
              : undefined
          }

          onChange={onManagerChange}                      
          placeholder={
            <span className={styles["select__placeholder"]}>
              <Icon type={IconTypes.USER_ICON} />
              Wybierz kierownika
            </span>
          }
        />

        </div>

        <p className={styles["redirection-error"]}>{redirectionError}</p>

        <div className={styles["buttons-section"]}>
          <Button
            size="large"
            type="primary"
            htmlType="reset"
            onClick={handleOnClose}
            className={styles["secondary-button"]}
          >
            Anuluj
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={updateSuggestion.department_id === suggestion?.department_id 
              || updateSuggestion.evaluating_user === suggestion?.evaluating_user_id}
            className={styles["primary-button"]}
          >
            Przekieruj
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RedirectSuggestionForm;
