import { FunctionComponent, ReactElement } from "react";
import { useAppSelector } from "../../../__store/tools/hooks";

import { rankingModels } from "../../../__store/models";

import styles from "./DashboardSmallRanking.module.scss";
import { IDashboardSmallRankingProps } from "../types/ProfileProps.types";

import { profileData } from "../../../data/constants";

const { dashboardSmallRankingLabels } = profileData;

const DashboardSmallRanking: FunctionComponent<IDashboardSmallRankingProps> = ({ users, login }):ReactElement => {
  const user_id = useAppSelector((state) => state.auth.user_id);

  const renderPositions = (ranking: rankingModels.SmallRanking) => {
    return ranking.map((user, index) => (
      <div
        key={index}
        className={`${styles["position"]} ${
          user.user.login === login && styles["logged"]
        }`}
      >
        <div className={styles["position__left"]}>
          <div className={styles["position__user-position"]}>
            <span>#{user.position}</span>
          </div>
          <span className={styles["position__dot"]}></span>
          <div
            className={`${styles["position__user-name"]} ${
              user.user.login && styles["logged"]
            } `}
          >
            <span>
              {user.user.profile.name
                ? `${user.user.profile.name} ${user.user.profile.surname}`
                : dashboardSmallRankingLabels.HIDDEN_USER}
            </span>
          </div>
        </div>
        <div className={styles["position__right"]}>
          <span>{user.user.profile.score} pkt.</span>
        </div>
      </div>
    ));
  };

  const getUserScore = (ranking: rankingModels.SmallRanking): number => {
    const user: rankingModels.RankingUser | undefined = ranking.find(
      (user) => user.user.user_id === user_id
    );
    return user ? user.user.profile.score : 0;
  };

  return (
    <div className={styles["user-ranking-container"]}>
      <div className={styles["user-ranking-container__info"]}>
        <div className={styles["user-ranking-container__points"]}>
          <div className={styles["header"]}>
            <span>{dashboardSmallRankingLabels.POINTS_SUM}</span>
          </div>
          <div className={styles["score"]}>
            <span>{getUserScore(users)}</span>
          </div>
        </div>
        <div className={styles["user-ranking-container__ranking"]}>
          <div className={styles["header"]}>
            <span>{dashboardSmallRankingLabels.YOUR_POSITION}</span>
          </div>
          <div className={styles["positions-container"]}>
            <span className={styles["positions-header"]}>{dashboardSmallRankingLabels.RANKING}</span>
            <div className={styles["positions"]}>
              {users && renderPositions(users)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSmallRanking;
