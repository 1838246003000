const ServerPaths = {
  BASE: '/api',
  BONUS: "/bonus",
  REPORTS: '/reports',
  USER: '/user',
  APPLICATION_SETTINGS: '/application-settings/show-evaluations',
  ASSIGN_MANAGER: "/assignManagerToEvaluate",
  ATTACHMENT: "/attachment",
  ATTACHMENT_MUTLIPLE_UPLOAD: "/attachment/multipleUpload",
  EVALUATIONS: "/evaluations",
  EVALUATION_USERS: "/get-users-to-evaluate",
  EVALUATION_COMMENTS: "/evaluation-comments",
  EVALUATION_STATUSES: "/get-user-appraisal-statuses",
  PASSWORD_CHECK: "/check-password-id",
  PASSWORD_RESET: "/reset-password",
  PASSWORD_VERIFY: "/verify-password",
  PASSWORD_RECOVERY: "/recovery",
  PASSWORD_CHANGE: "/change-password",
  PASSWORD_NEW: "/new-password",
  RULES_ACCEPTANCE: "/rules",
  LOGIN: "/login",
  LOGOUT: "/logout",
  SETTINGS: "/settings",
  FORM: "/form",
  DEPARTMENT: "/department",
  DEPARTMENTS: "/departments",
  DEPARTMENT_SUPERVISORS: "/department-supervisors",
  JOB_POSITION: "/job-position",
  PARENT: "/parent",
  PARENTS: "/parents",
  MIGRATE: "/migrate",
  MOVE: "/move",
  DOWNLOAD: "/download",
  GUIDE: "/Guide",
  PROGRAM: "/Program",
  RANKING: '/ranking',
  REVERT: "/revert",
  REVERT_FROM_TEMP: "/revertFromTempManager",
  SUGGESTION: "/suggestion",
  SUGGESTIONS: "/suggestions",
  MY_SUGGESTIONS: "/my-suggestions",
  WORKERS: "/workers",
  WORKERS_TO_OTHERS_SUGGESTIONS: "/worker-suggestions-other-departments",
  WORKERS_SUGGESTIONS: "/worker-suggestions",
  OTHER_DEPARTMENTS_SUGGESTIONS: "/other-departments-suggestions",
  ALL_SUGGESTIONS_PREVIEW: "/all-suggestions-preview",
  COUNT: "/count",
  NOTIFICATION: "/notification",
  TOKEN: "/token",
  TEMP_POSITION: "/temporaryPosition",
  HOLIDAYS: "/holidays",
  AREA_ALL: "/area/all",
  AREA_SELECTED: "/area/selected",
  AREA_ADD: "/area/add",
  AREA_UPDATE: "/area/update",
  AREA_REMOVE: "/area/remove"
};

export default ServerPaths;
