import { createSlice } from "@reduxjs/toolkit";

import { suggestionModels } from "../models";
import { SliceNames, MainStatuses } from "../../data/config";

const initialState: suggestionModels.SuggestionState = {
  workerSuggestions: [],
  mySuggestions: [],
  sendingSuggestion: false,
  isSuggeastionSecondReported: false,
  filterSuggestionQuery: "",
  searchPlaceholder: "",
  searchValue: "",
  departmentDirectorSuggestions: [],
  suggestionKey: MainStatuses.REPORT,
  showReported: true,
  showImplemented: false,
  showRejected: false,
  showAppealed: false,
  showApproved: false,
  showDone: false,
  showWorkerSuggestion: true,

  suggestions: [],
  allCommentaries: [],

  yourSuggestions: [],
  departmentSuggestions: [],
  workersToOtherSuggestions: [],

  myReportedSuggestions: [],
  myRejectedSuggestions: [],
  myApprovedSuggestions: [],
  myAppealedSuggestions: [],
  myDoneSuggestions: [],
  myImplementedSuggestions: [],

  workerReportedSuggestions: [],
  workerImplementedSuggestions: [],
  workerDoneSuggestions: [],
  workerApprovedSuggestions: [],
  workerAppealedSuggestions: [],
  workerRejectedSuggestions: [],

  workersToOtherReportedSuggestions: [],
  workersToOtherImplementedSuggestions: [],
  workersToOtherDoneSuggestions: [],
  workersToOtherApprovedSuggestions: [],
  workersToOtherAppealedSuggestions: [],
  workersToOtherRejectedSuggestions: [],

  departmentReportedSuggestions: [],
  departmentImplementedSuggestions: [],
  departmentDoneSuggestions: [],
  departmentApprovedSuggestions: [],
  departmentAppealedSuggestions: [],
  departmentRejectedSuggestions: [],

  allSuggestionsPreviewReported: [],
  allSuggestionsPreviewImplemented: [],
  allSuggestionsPreviewDone: [],
  allSuggestionsPreviewApproved: [],
  allSuggestionsPreviewAppealed: [],
  allSuggestionsPreviewRejected: [],

  suggestionPath: [],
  solutionPath: [],
  resultPath: [],

  mySuggestionsCount: {
    report: 0,
    realization: 0,
    verification: 0,
    creation: 0,
    rejection: 0,
    appealed: 0,
    all: 0,
  },

  workersToOtherSuggestionsCount: {
    report: 0,
    realization: 0,
    verification: 0,
    creation: 0,
    rejection: 0,
    appealed: 0,
  },

  workerSuggestionsCount: {
    report: 0,
    realization: 0,
    verification: 0,
    creation: 0,
    rejection: 0,
    appealed: 0,
  },

  otherDepartmentSuggestionsCount: {
    report: 0,
    realization: 0,
    verification: 0,
    creation: 0,
    rejection: 0,
    appealed: 0,
  },

  allSuggestionsPreviewCount: {
    report: 0,
    realization: 0,
    verification: 0,
    creation: 0,
    rejection: 0,
    appealed: 0,
  },

  report: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  realization: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  verification: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  creation: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  rejection: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  appealed: { countedSuggestion: 0, countedStatisticSuggestion: 0 },
  all: { countedSuggestion: 0, countedStatisticSuggestion: 0 },

  superVisorSuggestions: {
    countedSuggestion: 0,
    countedStatisticSuggestion: 0,
  },

  selectedSuggestion: undefined
};

const suggestionsSlice = createSlice({
  name: SliceNames.SUGGESTIONS,
  initialState,
  reducers: {
    setMySuggestions: (state, { payload }) => {
      state.mySuggestions = [].concat(
        payload.appealed,
        payload.report,
        payload.verification,
        payload.rejection,
        payload.creation,
        payload.realization
      );
    },
    setWorkersToOtherSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestions = [].concat(
        payload.appealed,
        payload.report,
        payload.verification,
        payload.rejection,
        payload.creation,
        payload.realization
      );
    },
    setWorkerSuggestions: (state, { payload }) => {
      state.workerSuggestions = [].concat(
        payload.appealed,
        payload.report,
        payload.verification,
        payload.rejection,
        payload.creation,
        payload.realization
      );
    },

    setSuggestionKey: (state, { payload }) => {
      state.suggestionKey = payload;
    },
    setSuperVisorSuggestion: (state, { payload }) => {
      state.superVisorSuggestions = payload;
      state.all = payload;
    },

    setAllSugestions: (state, { payload }) => {
      state.suggestions = payload;
    },
    setAllCommentaries: (state, { payload }) => {
      state.allCommentaries = payload;
    },

    setSendingSuggestion: (state, { payload }) => {
      state.sendingSuggestion = payload;
    },

    setReportedCount: (state, { payload }) => {
      state.report = payload;
    },
    setRejectedCount: (state, { payload }) => {
      state.rejection = payload;
    },
    setImplementedCount: (state, { payload }) => {
      state.realization = payload;
    },
    setDoneCount: (state, { payload }) => {
      state.verification = payload;
    },
    setApprovedCount: (state, { payload }) => {
      state.creation = payload;
    },
    setAppealedCount: (state, { payload }) => {
      state.appealed = payload;
    },
    setShowWorkerSuggestion: (state, { payload }) => {
      state.showWorkerSuggestion = payload;
    },
    setYourSuggestion: (state, { payload }) => {
      state.yourSuggestions = payload;
    },
    setDepartmentSuggestions: (state, { payload }) => {
      state.departmentSuggestions = payload;
    },
    setMyReportedSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.report = payload.length;
      state.myReportedSuggestions = payload;
    },

    setMyAllSuggestionsCount: (state) => {
      state.mySuggestionsCount.all =
        state.mySuggestionsCount.report +
        state.mySuggestionsCount.realization +
        state.mySuggestionsCount.verification +
        state.mySuggestionsCount.creation +
        state.mySuggestionsCount.rejection +
        state.mySuggestionsCount.appealed;
    },
    setMyImplementedSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.realization = payload.length;
      state.myImplementedSuggestions = payload;
    },
    setMyDoneSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.verification = payload.length;
      state.myDoneSuggestions = payload;
    },
    setMyApprovedSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.creation = payload.length;
      state.myApprovedSuggestions = payload;
    },
    setMyAppealedSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.appealed = payload.length;
      state.myAppealedSuggestions = payload;
    },
    setMyRejectedSuggestions: (state, { payload }) => {
      state.mySuggestionsCount.rejection = payload.length;
      state.myRejectedSuggestions = payload;
    },

    setWorkerReportedSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.report = payload.length;
      state.workerReportedSuggestions = payload;
    },
    setWorkerImplementedSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.realization = payload.length;
      state.workerImplementedSuggestions = payload;
    },
    setWorkerDoneSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.verification = payload.length;
      state.workerDoneSuggestions = payload;
    },
    setWorkerApprovedSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.creation = payload.length;
      state.workerApprovedSuggestions = payload;
    },
    setWorkerAppealedSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.appealed = payload.length;
      state.workerAppealedSuggestions = payload;
    },
    setWorkerRejectedSuggestions: (state, { payload }) => {
      state.workerSuggestionsCount.rejection = payload.length;
      state.workerRejectedSuggestions = payload;
    },

    setWorkersToOtherReportedSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.report = payload.length;
      state.workersToOtherReportedSuggestions = payload;
    },
    setWorkersToOtherImplementedSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.realization = payload.length;
      state.workersToOtherImplementedSuggestions = payload;
    },
    setWorkersToOtherDoneSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.verification = payload.length;
      state.workersToOtherDoneSuggestions = payload;
    },
    setWorkersToOtherApprovedSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.creation = payload.length;
      state.workersToOtherApprovedSuggestions = payload;
    },
    setWorkersToOtherAppealedSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.appealed = payload.length;
      state.workersToOtherAppealedSuggestions = payload;
    },
    setWorkersToOtherRejectedSuggestions: (state, { payload }) => {
      state.workersToOtherSuggestionsCount.rejection = payload.length;
      state.workersToOtherRejectedSuggestions = payload;
    },

    setDepartmentReportedSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.report = payload.length;
      state.departmentReportedSuggestions = payload;
    },
    setDepartmentImplementedSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.realization = payload.length;
      state.departmentImplementedSuggestions = payload;
    },
    setDepartmentDoneSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.verification = payload.length;
      state.departmentDoneSuggestions = payload;
    },
    setDepartmentApprovedSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.creation = payload.length;
      state.departmentApprovedSuggestions = payload;
    },
    setDepartmentAppealedSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.appealed = payload.length;
      state.departmentAppealedSuggestions = payload;
    },
    setDepartmentRejectedSuggestions: (state, { payload }) => {
      state.otherDepartmentSuggestionsCount.rejection = payload.length;
      state.departmentRejectedSuggestions = payload;
    },

    setAllSuggestionsPreviewReported: (state, { payload }) => {
      state.allSuggestionsPreviewCount.report = payload.length;
      state.allSuggestionsPreviewReported = payload;
    },
    setAllSuggestionsPreviewImplemented: (state, { payload }) => {
      state.allSuggestionsPreviewCount.realization = payload.length;
      state.allSuggestionsPreviewImplemented = payload;
    },
    setAllSuggestionsPreviewDone: (state, { payload }) => {
      state.allSuggestionsPreviewCount.verification = payload.length;
      state.allSuggestionsPreviewDone = payload;
    },
    setAllSuggestionsPreviewApproved: (state, { payload }) => {
      state.allSuggestionsPreviewCount.creation = payload.length;
      state.allSuggestionsPreviewApproved = payload;
    },
    setAllSuggestionsPreviewAppealed: (state, { payload }) => {
      state.allSuggestionsPreviewCount.appealed = payload.length;
      state.allSuggestionsPreviewAppealed = payload;
    },
    setAllSuggestionsPreviewRejected: (state, { payload }) => {
      state.allSuggestionsPreviewCount.rejection = payload.length;
      state.allSuggestionsPreviewRejected = payload;
    },

    setFilterQuery: (state, { payload }) => {
      state.filterSuggestionQuery = payload;
    },
    setSearchPlaceholder : (state, { payload }) => {
      state.searchPlaceholder = payload;
    },
    setSearchValue : (state, { payload }) => {
      state.searchValue = payload;
    },
    setIsSuggestionSecondReported: (state, { payload }) => {
      state.isSuggeastionSecondReported = payload;
    },
    setSelectedSuggestion: (state, { payload }) => {
      state.selectedSuggestion = payload;
    },
    setSuggestionInitalState: () => initialState,
  },
});

export const {
  setWorkerSuggestions,
  setFilterQuery,
  setSearchPlaceholder,
  setSearchValue,
  setSuggestionKey,
  setSuperVisorSuggestion,
  setAllSugestions,
  setAllCommentaries,
  setReportedCount,
  setRejectedCount,
  setImplementedCount,
  setDoneCount,
  setApprovedCount,
  setAppealedCount,
  setShowWorkerSuggestion,

  setYourSuggestion,
  setDepartmentSuggestions,
  setWorkersToOtherSuggestions,

  setMyReportedSuggestions,
  setMyImplementedSuggestions,
  setMyDoneSuggestions,
  setMyApprovedSuggestions,
  setMyAppealedSuggestions,
  setMyRejectedSuggestions,

  setWorkersToOtherReportedSuggestions,
  setWorkersToOtherImplementedSuggestions,
  setWorkersToOtherDoneSuggestions,
  setWorkersToOtherApprovedSuggestions,
  setWorkersToOtherAppealedSuggestions,
  setWorkersToOtherRejectedSuggestions,

  setWorkerReportedSuggestions,
  setWorkerImplementedSuggestions,
  setWorkerDoneSuggestions,
  setWorkerApprovedSuggestions,
  setWorkerAppealedSuggestions,
  setWorkerRejectedSuggestions,

  setDepartmentReportedSuggestions,
  setDepartmentImplementedSuggestions,
  setDepartmentDoneSuggestions,
  setDepartmentApprovedSuggestions,
  setDepartmentAppealedSuggestions,
  setDepartmentRejectedSuggestions,

  setAllSuggestionsPreviewReported,
  setAllSuggestionsPreviewImplemented,
  setAllSuggestionsPreviewDone,
  setAllSuggestionsPreviewApproved,
  setAllSuggestionsPreviewAppealed,
  setAllSuggestionsPreviewRejected,

  setIsSuggestionSecondReported,
  setSuggestionInitalState,
  setMySuggestions,
  setSendingSuggestion,
  setMyAllSuggestionsCount,
  setSelectedSuggestion
} = suggestionsSlice.actions;

export default suggestionsSlice.reducer;
