import { ReactElement, useEffect, useState } from "react";
import styles from "./YourProfileDashboard.module.scss";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";

import { suggestionThunk } from "../../../__store/thunks";

import { DashboardAllStatsInfo, DashboardStatusCard } from ".";
import { Notification, Icon } from "../../components/ui";

import "antd/dist/reset.css";
import { Collapse } from "antd";

import { IconTypes, MainStatuses } from "../../../data/config";
import { profileData } from "../../../data/constants";

const { 
  NOTIFICATIONS_BREAK_POINT, 
  NOTIFICATIONS_HEADER,
  NOTIFICATIONS_REST_MESSAGE, 
  workerDashboardHeader 
} = profileData;

const WorkerDashboard = ():ReactElement => {
  const { Panel } = Collapse;
  const dispatch = useAppDispatch();

  const workerSuggestions = useAppSelector(
    (state) => state.suggestions.workerSuggestions
  );

  const unreadDepartmentSuggestions = workerSuggestions.filter(
    (suggestion) =>
      !suggestion.is_read &&
      suggestion.status !== MainStatuses.REJECTION &&
      suggestion.status !== MainStatuses.VERIFICATION &&
      suggestion.status !== MainStatuses.REALIZATION
  );

  const [restArrays, setRestArray] = useState<{
    restDepartmentNotifications: any;
  }>({
    restDepartmentNotifications: [],
  });
  useEffect(() => {
    setRestArray({
      restDepartmentNotifications: unreadDepartmentSuggestions.slice(6),
    });

    dispatch(suggestionThunk.getWorkerSuggestions());
    dispatch(suggestionThunk.getSuggestionsInfo());
  }, []);

  const renderRestDepartmentNotifications = () => {
    return restArrays.restDepartmentNotifications.map(
      (suggestion: any, index: any) => (
        <Notification
          key={index}
          suggestion_id={suggestion.suggestion_id}
          status={suggestion.status}
          hidePosition={index}
          hide={index >= 3}
          username={suggestion.reporting_user.profile.name}
          usersurname={suggestion.reporting_user.profile.surname}
        />
      )
    );
  };

  const renderDepartmentNotifications = () => {
    return unreadDepartmentSuggestions.map((suggestion, index) =>
      index < NOTIFICATIONS_BREAK_POINT ? (
        <Notification
          key={index}
          suggestion_id={suggestion.suggestion_id}
          status={suggestion.status}
          hidePosition={index}
          hide={index >= 3}
          username={suggestion.reporting_user.profile.name}
          usersurname={suggestion.reporting_user.profile.surname}
        />
      ) : null
    );
  };

  const statusCards: JSX.Element[] = [
    { name: "Nowe", icon: <Icon type={IconTypes.ARROW} /> },
    { name: "Weryfikacja", icon: <Icon type={IconTypes.MESSAGE} /> },
    { name: "Odwołane", icon: <Icon type={IconTypes.THUMB} /> },
    { name: "W trakcie wdrożenia", icon: <Icon type={IconTypes.WAIT} /> },
    { name: "Zrealizowane", icon: <Icon type={IconTypes.CIRCLE} /> },
    { name: "Odrzucone", icon: <Icon type={IconTypes.CANCEL} /> },
  ].map((status) => (
    <DashboardStatusCard
      isWorkerSuggestion={true}
      key={status.name}
      value={status.name}
      icon={status.icon}
    />
  ));
  return (
    <>
      {renderDepartmentNotifications().length > 0 ? (
        <Collapse
          className={`${styles["your-profile__notification-container"]}`}
        >
          <Panel header={NOTIFICATIONS_HEADER} key="1" className={`${styles["panel"]}`}>
            {renderDepartmentNotifications()}

            {renderRestDepartmentNotifications().length > 0 ? (
              <Collapse>
                <Panel
                  header={NOTIFICATIONS_REST_MESSAGE}
                  key="2"
                  className={`${styles["panel"]}`}
                >
                  {renderRestDepartmentNotifications()}
                </Panel>
              </Collapse>
            ) : (
              <></>
            )}
          </Panel>
        </Collapse>
      ) : (
        <></>
      )}
      <div className={styles["your-profile__your-stats"]}>
        <h3 className={styles["your-profile__your-stats__header"]}>
          {workerDashboardHeader}
        </h3>
        <div className={styles["your-profile__global-stats"]}>
          <>
            <div className={styles["your-profile__list"]}>{statusCards}</div>
            <div className={styles["all"]}>
              <DashboardAllStatsInfo isSupperVisor={true} />
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default WorkerDashboard;
