import axiosInstance from "../../tools/axios";

import { ServerPaths } from "../../../data/config";
import { vacationModels } from "../../models";

const { HOLIDAYS } = ServerPaths;

export const postHolidays = async (payload: vacationModels.VacationPayload) => {
  return await axiosInstance.post(HOLIDAYS, { data: payload });
};

export const getHolidaysForUser = async (user_id: number) => {
  return await axiosInstance.get(`${HOLIDAYS}/${user_id}`);
};

export const deleteHolidaysForUser = async (payload: {
  user_id: number;
  userHoliday_id: number;
}) => {
  return await axiosInstance.delete(
    `${HOLIDAYS}/${payload.user_id}?userHoliday_id=${payload.userHoliday_id}`
  );
};

export const patchHolidaysForUser = async (
  payload: vacationModels.VacationPayload & { userHoliday_id: number }
) => {
  return await axiosInstance.patch(
    `${HOLIDAYS}/${payload.user_id}?userHoliday_id=${payload.userHoliday_id}`,
    {
      data: {
        startHoliday_at: payload.startHoliday_at,
        endHoliday_at: payload.endHoliday_at,
      },
    }
  );
};
