import * as React from "react";

import { useAppDispatch } from "../../../__store/tools/hooks";
import { suggestionThunk } from "../../../__store/thunks";

import styled from "./Notification.module.scss";

import { Icon } from ".";
import { IconTypes } from "../../../data/config";

interface NotificationProps {
  status: string;
  suggestion_id: number;
  hide: boolean;
  hidePosition: number;
  username?: string;
  usersurname?: string;
}

type TranslateStatusMap = {
  [key: string]: string;
};

type ButtonStatusMap = {
  [key: string]: JSX.Element;
};

const translateMap: TranslateStatusMap = {
  realization: "w trakcie wdrożenia",
  rejection: "odrzucony",
  verification: "weryfikacja",
  creation: "zrealizowana",
  report: "zgłoszona",
  appealed: "odwołana",
};

const statusClasses: TranslateStatusMap = {
  realization: "implemented-status",
  rejection: "rejected-status",
  verification: "done-status",
  creation: "approved-status",
  report: "reported-status",
  appealed: "appealed-status",
};

const Notification: React.FunctionComponent<NotificationProps> = ({
  status,
  suggestion_id,
  username,
  usersurname,
}) => {

  const bells: ButtonStatusMap = {
    realization: <Icon type={IconTypes.YELLOW_BELL} key={1} className={styled["notification__bell"]} />,
    rejection: <Icon type={IconTypes.RED_BELL} key={2} className={styled["notification__bell"]} />,
    verification: <Icon type={IconTypes.YELLOW_BELL} key={3} className={styled["notification__bell"]} />,
    creation: <Icon type={IconTypes.BLUE_BELL} key={4} className={styled["notification__bell"]} />,
    report: <Icon type={IconTypes.RED_BELL} key={6} className={styled["notification__bell"]} />,
    appealed: <Icon type={IconTypes.RED_BELL} key={7} className={styled["notification__bell"]} />,
  };

  const dispatch = useAppDispatch();

  const handleOnRead = () => {
    dispatch(suggestionThunk.updateSuggestionReadStatus({ suggestion_id }));
  };

  return (
    <div className={`${styled["notification"]}`}>
      {bells[status]}
      <div className={styled["notification__context"]}>
        <div className={styled["notification__context__text"]}>
          <span>
            {username
              ? `Sugestia ${username} ${usersurname} otrzymała status: `
              : `Twoja suggestia otrzymała status: `}
            <span className={`${styled[statusClasses[status]]}`}>
              "{translateMap[status]}"
            </span>
          </span>
        </div>
        <div className={styled["notification__context__close-icon"]}>
          <Icon 
            type="cross"
            fill="#E0E6E9"
            style={{ cursor: "pointer" }}
            onClick={handleOnRead}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
