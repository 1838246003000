import { useState, useEffect, ReactElement } from "react";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";
import { adminThunk, bonusThunk } from "../../../__store/thunks";

import { InactiveAccount, NavBar } from "../../components/ui";

import styles from "./BonusWorkerPage.module.scss";

import { 
  BonusMenu, 
  BonusTitle, 
  BonusWorkerCards, 
  BonusContent  
} from "../components";

import { bonusData } from "../../../data/constants";
import { MenuProps } from "antd";

const menuItems: MenuProps['items'] = [
  { label: 'Punkty', key: bonusData.BonusSections.WORKER_BONUSES },
  // { label: 'Wypłaty', key: bonusData.BonusSections.WORKER_PAYMENTS },
  // { label: 'Zasady wypłacania', key: bonusData.BonusSections.INSTRUCTIONS },
];

const BonusWorkerPage = ():ReactElement => {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => state.users.authUser?.user.isActive);

  const user = useAppSelector((state) => state.users.authUser);
  const user_id = useAppSelector((state) => state.auth.user_id);

  const [selectedSection, setSelectedSection] = useState(bonusData.BonusSections.WORKER_BONUSES);

  useEffect(() => {
    if (user == null && user_id) {
      dispatch(adminThunk.fetchUser(user_id));
    }
  }, [user, user_id, dispatch]);

  useEffect(() => {
    if (user_id) {
      dispatch(bonusThunk.fetchUserBonuses(user_id))
    }
  }, [user, user_id, dispatch]);

  return (
    <div className={styles["bonus-page"]}>
      <NavBar /> 
      {isActive ? (
        <div className={styles["dashboard"]}>
          <div className={styles["header"]}>
            <BonusTitle title="Premie" />
            <BonusMenu
              menuData={menuItems} 
              selectedSection={selectedSection} 
              setSelectedSection={setSelectedSection} 
            />
          </div>
          <div className={styles["container"]}>
            <BonusWorkerCards userPointsData={user} />
            <BonusContent selectedSection={selectedSection} />
          </div>
        </div>) : <InactiveAccount />}
    </div>
  );
}

export default BonusWorkerPage;
