
export enum DashboardOptions {
  WORKER_DASHBOARD = "Pulpit pracowników",
  YOUR_DASHBOARD = "Twój pulpit",
};

export const NOTIFICATIONS_BREAK_POINT = 6;
export const NOTIFICATIONS_HEADER = "Powiadomienia"
export const NOTIFICATIONS_REST_MESSAGE = "Pozostałe powiadomienia";

export const yourProfileHeader = "Twój pulpit";
export const workerProfileHeader = "Pulpit pracowników";
export const workerDashboardHeader = "Statystyki pracowników";

export const yourProfileDashboardHeader = "Statystyki";

export const yourProfileDashboardBossLabels = {
  REALIZATION: "W trakcie wdrożenia",
  CREATION: "Zrealizowane",
  ALL: "Wszystkie zgłoszenia",
};

export const dashboardAllStatsInfoLabels = {
  ALL_SUGGESTIONS: "Wszystkie zgłoszenia"
};

export const dashboardSmallRankingLabels = {
  HIDDEN_USER: "Ukryty",
  POINTS_SUM: "Łączna ilość punktów",
  YOUR_POSITION: "Twoje miejsce w rankingu",
  RANKING: "Ranking",
};
