import { ReactElement } from "react";

import { useAppSelector } from "../../../__store/tools/hooks";

import { Button, Divider, Table, Typography } from 'antd';

import type { ColumnsType, TableProps } from 'antd/es/table';

import styles from "../views/BonusWorkerPage.module.scss";

import { BonusStatuses, IconTypes } from "../../../data/config";
import { bonusData } from "../../../data/constants";
import { Icon } from "../../components/ui";

const { BonusStatusesTranslated } = bonusData;
const { Title } = Typography;

interface IBonusData {
  key: React.Key;
  number: number;
  date: Date;
  points: number;
  amount: {
    amount: number;
    type: string;
  };
  status: string;
  dateAndAmountMobile: {
    date: Date;
    amount: number;
    amountType: string;
  },
  pointsAndStatusMobile: {
    status: string
    points: number
  }
}

const bonusColumns: ColumnsType<IBonusData> = [
  {
    title: 'Nr',
    dataIndex: "number",
    render: (number: number) => `${number}.`,
    responsive: ['lg'],
  },
  {
    title: 'Data',
    dataIndex: 'date',
    render: (date: Date) => {
      const bonusDate = new Date(date);

      const dateOptions: Intl.DateTimeFormatOptions = { 
        day: 'numeric', month: 'long', year: 'numeric' 
      };

      return `${bonusDate.toLocaleDateString('pl-PL', dateOptions)}`;
    },
    responsive: ['sm']
  },
  {
    title: 'Data',
    dataIndex: 'dateAndAmountMobile',
    render: (dateAndAmountMobile: { date: Date, amount: number, amountType: string }) => {
      const bonusDate = new Date(dateAndAmountMobile.date);
  
      const dateOptions: Intl.DateTimeFormatOptions = { 
        day: 'numeric', month: 'long', year: 'numeric' 
      };

      return (
          <div className={styles["date-mobile"]}>
            <span className={styles["date-mobile__date"]}>{bonusDate.toLocaleDateString('pl-PL', dateOptions)}</span>
            <span className={styles["date-mobile__amount"]}>{dateAndAmountMobile.amount} zł {dateAndAmountMobile.amountType === "gross" ? "brutto" : "netto"}</span>
          </div>
      )
    },
    responsive: ['xs']
  },
  {
    title: 'Punkty',
    dataIndex: 'points',
    render: (points: number) => `${points} pkt.`,
    responsive: ['sm']
  },
  {
    title: 'Kwota',
    dataIndex: 'amount',
    render: ({ amount, type } : {amount: number, type: string}) => `${amount} zł ${type === "gross" ? "brutto" : "netto"}`,
    responsive: ['sm']
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: "center",
    render: (status: BonusStatuses) => {
      const translatedStatus = BonusStatusesTranslated.get(status) || "";
      return <span className={styles["status"]}>{translatedStatus}</span>;
    },
    responsive: ['sm']
  },
  {
    title: 'Status',
    dataIndex: 'pointsAndStatusMobile',
    render: (pointsAndStatusMobile: { status: BonusStatuses, points: number}) => {
      const translatedStatus = BonusStatusesTranslated.get(pointsAndStatusMobile.status) || "";
      
      return (
        <div className={styles["points-mobile"]}>
        <span className={styles["points-mobile__status"]}>
          <span className={styles["status"]}>{translatedStatus}</span>
        </span>
        <span className={styles["points-mobile__points"]}>{pointsAndStatusMobile.points} pkt.</span>
      </div>
      )
    },
    responsive: ['xs']
  }
];

const BonusWorkerTable = (): ReactElement => {
  const userBonuses = useAppSelector((state) => state.bonus.userBonuses);

  const bonusData: IBonusData[] = userBonuses.map((bonus, index) => {
    const theLastHistoryRecord = bonus.bonus_history[bonus.bonus_history.length - 1];
      return {
        key: bonus.bonus_id,
        number: index,
        date: new Date(theLastHistoryRecord.date),
        points: bonus.point.value,
        amount: {
          amount: bonus.bonus_amount,
          type: bonus.point.amount_type
        },
        status: theLastHistoryRecord.status,
        dateAndAmountMobile: {
          date: new Date(theLastHistoryRecord.date),
          amount: bonus.point.value,
          amountType: bonus.point.amount_type
        },
        pointsAndStatusMobile: {
          status: theLastHistoryRecord.status,
          points: bonus.point.value
        }
      };
  }).filter(bonus => bonus.status === BonusStatuses.AWARDED || bonus.status === BonusStatuses.PAID);

  const bonusOnChange: TableProps<IBonusData>['onChange'] = 
    (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className={styles["content-section"]}>
      <div className={styles["worker-table"]}>
        <Table 
          columns={bonusColumns} 
          dataSource={bonusData}
          onChange={bonusOnChange} 
          pagination={{ 
            showSizeChanger: false,
            pageSize: 5, 
            position: ["bottomCenter"], 
            prevIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <Icon type={IconTypes.CHEVRON_LEFT} />
                <span>Poprzednia</span>
              </Button>), 
            nextIcon: (
              <Button 
                type="link" 
                className={styles["pagination-button"]}
              >
                <span>Następna</span>
                <Icon type={IconTypes.CHEVRON_RIGHT} />
              </Button>)
            }}
          showSorterTooltip={false}
          locale={{ 
            emptyText() {
              return (
                <p className={styles["table-empty"]}>
                  Premie jeszcze nie zostały Ci przyznane. Zgłaszaj i wdrażaj sugestie, aby zdobywać punkty i otrzymywać premie.
                </p>
              )
            },
          }}
          title={() => (
            <>
              <Title 
                level={3} 
                style={{ marginBottom: 0, fontSize: 24, fontWeight: 700 }}>
                  Lista wypłaconych premii
              </Title>
              <Divider style={{ borderBlockStart: "2px solid #9CCEFC", margin: "4px 0 0 0" }} />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default BonusWorkerTable;
