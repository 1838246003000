import { ReactElement } from 'react';

import { Menu } from 'antd';
import type { MenuProps } from 'antd';

const { Item } = Menu;

interface IBonusWorkerMenu {
  menuData: MenuProps['items'];
  selectedSection: string;
  setSelectedSection: (e:any) => void;
}

const BonusMenu = (
  { menuData, selectedSection, setSelectedSection } : IBonusWorkerMenu): ReactElement => {

  const menuOnClick: MenuProps['onClick'] = (e) => {
    setSelectedSection(e.key);
  };
  
  return (
    <Menu 
      onClick={menuOnClick} 
      selectedKeys={[selectedSection]} 
      mode="horizontal" 
    >
    {menuData?.map((item: any) => (
      <Item 
        key={item.key} 
        style={{ 
          paddingLeft: 0,
          paddingRight: "1.5rem",
          fontWeight: selectedSection === item.key ? 'bold' : 'normal'
        }}
      >
        {item.label}
      </Item>
      ))}
    </Menu>
  );
};

export default BonusMenu;
