import { suggestionModels } from "../../../__store/models";
import { MainStatuses } from "../../../data/config";

// TYPES and INTERFACES for Suggestions

export interface IStatusButton {
  name: string;
  status: MainStatuses;
}

export interface IDashboardColumns {
  header: string;
  className: string;
}

export interface IExtendedStatusButtonObj {
  boss: IStatusButton[];
  worker: IStatusButton[];
}

export interface ISuggestionsCount {
  report: number;
  realization: number;
  verification: number;
  creation: number;
  appealed: number;
  rejection: number;
}

export type TCategoriedSuggestions = Map<
  MainStatuses,
  suggestionModels.Suggestions[]
>;

export type TButtonsList = Array<{ name: string; status: MainStatuses }>;

export type THeadersList = Array<{ header: string; className: string }>;

// EXPORTING DATA to Suggestions

export const statusButtons: IExtendedStatusButtonObj = {
  boss: [
    { name: "W trakcie wdrożenia", status: MainStatuses.REALIZATION },
    { name: "Zrealizowane", status: MainStatuses.CREATION },
  ],
  worker: [
    { name: "Nowe", status: MainStatuses.REPORT },
    { name: "W trakcie wdrożenia", status: MainStatuses.REALIZATION },
    { name: "Weryfikacja", status: MainStatuses.VERIFICATION },
    { name: "Zrealizowane", status: MainStatuses.CREATION },
    { name: "Odwołane", status: MainStatuses.APPEALED },
    { name: "Odrzucone", status: MainStatuses.REJECTION },
  ],
};

export const dashboardColumns: IDashboardColumns[] = [
  { header: "Data", className: "suggestions-dashboard__listHeader__date" },
  {
    header: "Zgłaszający",
    className: "suggestions-dashboard__listHeader__person",
  },
  {
    header: "Dział",
    className: "suggestions-dashboard__listHeader__department",
  },
  { header: "Sugestia", className: "suggestions-dashboard__listHeader__issue" },
  {
    header: "Rodzaj | Akcje",
    className: "suggestions-dashboard__listHeader__issue",
  },
];

// interfaces for buttons
export interface IButtonData {
  name: string;
  status: MainStatuses;
}

export interface IButtonActions {
  [key: string]: () => void;
}
export interface IDisplayButtonsForRole {
  [key: string]: JSX.Element;
}

export interface IRadioButtonProps {
  name: string;
  value: string;
  count?: number;
}

export interface ITranslateRadioButtonValue {
  [key: string]: string;
}

