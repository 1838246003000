import { ReactElement } from "react";
import { ICardProps } from "../types/RanksProps.types";

import styles from "./BonusPointCard.module.scss";

import { Icon } from "../../components/ui";
import { IconTypes } from "../../../data/config";

const BonusPointCard = ({ title, value, valueDesc }: ICardProps): ReactElement => {
  return (
    <div className={styles.card}>
      <div className={styles["point-container"]}>
        <p className={styles.title}>{title}</p>
        <p className={styles.points}>{value}{valueDesc}</p>
      </div>
      <div className={styles["icon-container"]}>
        <div className={styles["icon-wrapper"]}>
          <Icon type={IconTypes.STAR} className={styles.icon} />
          {/* <span className={styles.position}>1</span>  toDo after building Ranking 2.0 */}
        </div>
      </div>
    </div>
  );
};

export default BonusPointCard;
