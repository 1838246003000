import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import axiosInstance from "../tools/axios";

import { authModels } from "../models";
import { authActions } from "../slices";
import { authService } from "./services";

import { ServerStatuses, ThunkNames, LocalStorageKeys, authStatuses } from "../../data/config";
import { authData } from "../../data/constants";

const { AUTH } = ThunkNames;
const { RecoveryStatus } = authStatuses;
const { PASSWORD_INCORRECT_ERROR} = authData;

const { ACCESS_TOKEN, REFRESH_TOKEN } = LocalStorageKeys;

export const login = createAsyncThunk(
    AUTH.LOGIN_THUNK,
    (credentials: authModels.Credentials, thunkAPI) => {
      const { username } = credentials;
      const { dispatch } = thunkAPI;


      return authService.postUserLoginData(credentials).then(
        (data: AxiosResponse<any, any>) => {
          if (data.data.firstlogin) {
            dispatch(authActions.setFirstLogin(true));
            dispatch(
              authActions.setFirstLoginCredentials({
                login: username,
                password: credentials.password,
              })
            );
          } else if (data.data.anotherPassword) {
            dispatch(
              authActions.setFirstLoginCredentials({
                login: username,
                password: credentials.password,
              })
            );

            if (data.data.hasRulesAccepted) {
              dispatch(authActions.setRulesAcceptance(data.data.hasRulesAccepted));
            }
            dispatch(authActions.setFirstLogin(false));
            thunkAPI.dispatch(authActions.setChangePassword(true));
          } else {
            localStorage.setItem(ACCESS_TOKEN, JSON.stringify(data.data.accessToken));
            localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.data.refreshToken));
            axiosInstance.defaults.headers["Authorization"] = `Bearer ${data.data.accessToken}`;
  
            dispatch(authActions.setUserRole(authService.generateUserRoleFromJWT()));
            dispatch(authActions.setUserLogin(username));
            dispatch(authActions.setLoginSucces());
          }
        }
      );
    }
);
  
export const checkResetPasswordId = createAsyncThunk(
    AUTH.CHECK_RESET_PASSWORD_THUNK,
    (passwordId: string) => {
      authService.postResetPasswordId(passwordId);
    }
);
  
export const logout = createAsyncThunk(AUTH.LOGOUT_THUNK, (user_id: number) => {
    return authService.postUserLogoutData(user_id).then(() => {});
});
  
export const patchSendMailNotifications = createAsyncThunk(
    AUTH.MAIL_NOTIFICATION_THUNK,
    (payload: authModels.PatchSendMailNotificationsPayload, thunkAPI) => {
      const { dispatch } = thunkAPI;

      authService.patchMailNotifications(payload).then((data) => {
        const { sendMailNotifications } = data.data;

        dispatch(authActions.setSendMailNotifications(sendMailNotifications));
      })
    }
);

export const acceptRules = createAsyncThunk(
  AUTH.RULES_ACCEPTANCE_THUNK,
  (payload: authModels.AcceptRulesPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    authService.updateRulesAcceptance(payload).then((data) => {
      const { hasRulesAccepted } = data.data;

      dispatch(authActions.setRulesAcceptance(hasRulesAccepted));
    })
  }
);
  
export const resetPassword = createAsyncThunk(
    AUTH.RESET_PASSWORD_THUNK,
    (payload: authModels.ResetPasswordPayload, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return authService.postResetPassword(payload).then((data: AxiosResponse<any, any>) => {
        if (data.data.firstlogin) {
          dispatch(authActions.setFirstLogin(true));
          dispatch(
            authActions.setFirstLoginCredentials({
              login: data.data.login,
              password: payload.password,
            })
          );
        } else {
          localStorage.setItem(ACCESS_TOKEN, JSON.stringify(data.data.accessToken));
          localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.data.refreshToken));
          axiosInstance.defaults.headers["Authorization"] = `Bearer ${data.data.accessToken}`;
  
          dispatch(authActions.setUserRole(authService.generateUserRoleFromJWT()));
          dispatch(authActions.setUserLogin(data.data.login));
          dispatch(authActions.setLoginSucces());
        }
      });
    }
);
  
export const verifyPassword = createAsyncThunk(
    AUTH.VERIFY_PASSWORD_THUNK,
    (payload: authModels.VerifyPassword, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return authService.postVerifyPassword(payload)
        .then((data: AxiosResponse<any, any>) => {
          if (data.status === ServerStatuses.OK) {
            dispatch(
              authActions.setPasswordVerification({
                verified: true,
                message: "",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            authActions.setPasswordVerification({
              verified: false,
              message: PASSWORD_INCORRECT_ERROR,
            })
          );
        });
    }
);
  
export const recovery = createAsyncThunk(
    AUTH.RECOVERY_PASSWORD_THUNK,
    (email: string, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return authService.postRecoveryPassword(email)
        .then(() => {
          dispatch(authActions.setRecoveryStatus(RecoveryStatus.SENT));
        })
        .catch(() => {
          dispatch(authActions.setRecoveryStatus(RecoveryStatus.SENT));
        });
    }
);
  
export const newPassword = createAsyncThunk(
    AUTH.NEW_PASSWORD_THUNK,
    (payload: authModels.NewPasswordModel, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return authService.postNewPassword(payload).then((data) => {
        if (data.status === ServerStatuses.OK) {
          dispatch(authActions.setFirstLogin(false));
          dispatch(authActions.setFirstLogin(true));
          dispatch(
            authActions.setFirstLoginCredentials({
              login: "",
              password: "",
            })
          );
          dispatch(
            login({ username: payload.login, password: payload.newPassword })
          );
        }
      });
    }
);
  
export const changePasswordMethod = createAsyncThunk(
    AUTH.CHANGE_PASSWORD_THUNK,
    (payload: authModels.NewPasswordModel, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return authService.changePostPassword(payload).then((data) => {
        if (data.status === ServerStatuses.OK) {
          dispatch(authActions.setFirstLogin(false));
          dispatch(authActions.setFirstLogin(true));
          dispatch(
            authActions.setFirstLoginCredentials({
              login: "",
              password: "",
            })
          );
          dispatch(
            login({ username: payload.login, password: payload.newPassword })
          );
        }
      });
    }
);