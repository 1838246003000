import { ReactElement } from "react";

import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";
import { modalActions } from "../../../../__store/slices";

import { Icon } from "../../../components/ui";

import styles from "./AreaDetails.module.scss";
import { IconTypes } from "../../../../data/config";

function AreaDetails(): ReactElement {
  const dispatch = useAppDispatch();

  const selectedArea = useAppSelector((state) => state.departments.area);
  const departmentList = useAppSelector((state) => state.departments.departments);
  const filteredDepartments = departmentList.filter(
    department => department.area_id === selectedArea?.area_id
  );

  function onCancelHandler() {
    dispatch(modalActions.closeModal());
  }

  return (
    <div className={styles["area-details"]}>
      <div className={styles["area-details__header"]}>
        <div className={styles["area-details__header__text"]}>
          <span>{selectedArea?.area_name}</span>
        </div>
        <div className={styles["area-details__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={onCancelHandler} />
        </div>
      </div>

      <div className={styles["area-details__body"]}>
        <div className={styles["area-content"]}>
          {filteredDepartments.length 
            ? (
              <>
                <h3 className={styles["area-content__header"]}>Lista działów</h3>
                <ul className={styles["area-content__list"]}>
                  {filteredDepartments.map(department => (
                    <li 
                      className={styles["area-content__item"]} 
                      key={department.department_id}>
                        {department.name}
                    </li>
                  ))}
                </ul>
              </>
            ):(
              <span className={styles["departments-list__empty"]}>
                Brak działów przypisanych do wybranego obszaru
              </span>
            )}
        </div>
      </div>
    </div>
  );
}

export default AreaDetails;
