import { ReactElement } from "react";

import styles from "./Breadcrumb.module.scss";
import { IBreadcrumbProps } from "../../types/AdminProps.types";

function Breadcrumb({ breadcrumb, breadcrumbs, selectDepartment }: IBreadcrumbProps):ReactElement {
  function changeDepartment() {
    const index = breadcrumbs.findIndex(
      (item) => item.department_id === breadcrumb.department_id
    );

    if (index > 0) {
      const updatedBreadcrumbs = breadcrumbs.slice(0, index + 1);

      selectDepartment({
        departmentId: breadcrumb.department_id,
        breadcrumbs: updatedBreadcrumbs,
      });
    } else {
      selectDepartment({
        departmentId: breadcrumb.department_id,
        breadcrumbs: [breadcrumb],
      });
    }
  }

  return (
    <p
      key={breadcrumb.department_id}
      className={styles["breadcrumb"]}
      onClick={changeDepartment}
    >
      <span className={styles["arrow"]}>&gt;</span>
      {breadcrumb.name}
    </p>
  );
}

export default Breadcrumb;
