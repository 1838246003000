import { StatusAcceptanceInfo } from "../config/suggestionStatuses.data";

const modalMessages = {
  ACCEPT_PAYMENTS_EMPTY: "Brak premii pracowników oczekujących na przyznanie",
  ACCEPT_PAYMENTS_FAIL: "Wystąpił błąd podczas przyznawania premii pracownikom",
  ACCEPT_PAYMENTS_SUCCESS: "Premie zostały przyznane pracownikom",
  ACCEPT_PAYMENT_EMPTY: "Brak premii pracownika oczekującej na przyznanie",
  ACCEPT_PAYMENT_SUCCESS: "Premia została przyznana pracownikowi",
  DONE_PAYMENTS_EMPTY: "Brak premii przyznanych pracownikom do wypłacenia",
  DONE_PAYMENTS_SUCCESS: "Przyznane premie zostały wypłacone",
  DONE_PAYMENT_SUCCESS: "Przyznana premia została wypłacona",
  REJECT_PAYMENT_EMPTY: "Brak premii do wstrzymania",
  REJECT_PAYMENT_SUCCESS: "Wypłata premii przyznanej pracownikowi została wstrzymana",

  ADDED_USER: "Pracownik został zarejestrowany",
  ADD_SUGGESTION_ERROR: "Sugestia nie została wysłana, spróbuj jeszcze raz",
  ADD_SUGGESTION_SUCCESS: "Sugestia została zgłoszona",
  ADD_USER_ERROR: "Nie udało się dodać użytkownika",
  // ADD_USER_FORM: "",
  ADMIN_CHANGE_CREDENTIAL: "Zmiana danych logowania",
  APPEALED_SUGGESTION: "Odwołanie zostało zgłoszone",
  APPROVED_SUCCESS: "Wdrożenie sugestii zostało zatwierdzone",
  // APPROVED_SUGGESTION: "",
  // ASSESSMENT_INFO: "",
  // AREA_INFO: "",
  CANNOT_REMOVE_AREA: "Nie można usunąć obszaru, do którego przypisane są działy lub pracownicy",
  CANNOT_REMOVE_DEPARTMENT: "Nie można usunąć działu, do którego przypisani są pracownicy",
  CHANGE_LOGIN: "Zmień login",
  CHANGE_PASSWORD: "Zmień hasło",
  CLOSED_SUCCESS: "Sugestia została zamknięta",
  CORRECTED_SUGGESTION: "Poprawiona sugestia została przekazana do oceny",
  CORRECT_SUGGESTION_REALIZATION: "Sugestia została przekazana pracownikowi do poprawy",
  COMMENTED_SUGGESTION: "Komentarz został dodany",
  WITHDRAWN: "Sugestia została cofnięta do pracownika",
  // CORRECT_SUGGESTION_FORM: "",
  CREATE_COMMENT_TO_EVALUATION: "Komentarz do oceny został dodany",
  CREATE_COMMENT_TO_EVALUATION_ERROR: "Komentarz do oceny nie został dodany",
  CREATE_ASSESSMENT: "Pracownicza ocena została wystawiona",
  CREATE_ASSESSMENT_ERROR: "Pracownicza ocena nie została wystawiona",
  // CREATE_AREA: "",
  CREATE_AREA_FAIL: "Błąd podczas tworzenia obszaru",
  CREATE_AREA_SUCCESS: "Obszar został stworzony",
  // CREATE_DEPARTMENT: "",
  CREATE_DEPARTMENT_FAIL: "Błąd podczas tworzenia działu",
  CREATE_DEPARTMENT_SUCCESS: "Dział został stworzony",
  // CREATE_JOB_POSITION: "",
  CREATE_JOB_POSITION_FAIL: "Błąd podczas tworzenia stanowiska",
  CREATE_JOB_POSITION_SUCCESS: "Stanowisko zostało utworzone",
  DONE_SUGGESTION: "Sugestia została przekazana do sprawdzenia",
  // EDIT_AREA: "",
  EDIT_AREA_FAIL: "Błąd podczas edytowania obszaru",
  EDIT_AREA_SUCCESS: "Obszar został zaktualizowany",
  // EDIT_DEPARTMENT: "",
  EDIT_DEPARTMENT_FAIL: "Błąd podczas edytowania działu",
  EDIT_DEPARTMENT_SUCCESS: "Dział został zaktualizowany",
  // EDIT_JOB_POSITION: "",
  EDIT_JOB_POSITION_FAIL: "Błąd podczas edytowania stanowiska",
  EDIT_JOB_POSITION_SUCCESS: "Stanowisko zostało zaktualizowane",
  // END_RESULT: "",
  END_RESULT_SUCCESS: "Rezultat został dodany",
  EVALUATE_APPEALED: "Rozpatrz odwołanie",
  EVALUATE_CLOSED: StatusAcceptanceInfo["closed"],
  EVALUATE_WITHDRAWN: StatusAcceptanceInfo["withdrawn"],
  EVALUATE_FORM: "Oceń sugestię",
  EVALUATE_IMPLEMENTED: StatusAcceptanceInfo["realization"],
  EVALUATE_REJECTED: StatusAcceptanceInfo["rejection"],
  EVALUATE_REDIRECTED: StatusAcceptanceInfo["redirected"],
  EXTERNAL_COMMENT_SUCCESS: "Komentarz zewnętrzny został skutecznie dodany",
  GENERATE_PAYMENTS_FAIL: "Wystąpił błąd podczas generowania wybranych płatności",
  GENERATE_PAYMENT_EMPTY: "Brak płatności do wygenerowania",
  GENERATE_PAYMENT_FAIL: "Wystąpił błąd podczas generowania wybranej płatności",
  GENERATE_PAYMENT_SUCCESS: "Płatności zostały wygenerowane",
  IMPLEMENTED_SUCCESS: "Sugestia została zaakceptowana",
  IMPLEMENTED_SUGGESTION: "Sugestia została zaakceptowana",
  IMPLEMENT_SUGGESTION: "",
  // LOADING: "",
  // LOGIN_PAGE: "",
  // MAKE_ASSESSMENT: "",
  // NOTE_FORM: "",
  PAYMENT_FAIL: "Operacja się nie powiodła",
  // PAYMENT_INFO_MODAL: "",
  REJECTED_SUGGESTION: "Sugestia została odrzucona",
  REMOVED_USER: "Pracownik został usunięty",
  REMOVED_USER_ERROR: "Nie udało się usunąć użytkownika",
  REMOVE_AREA: "Obszar został usunięty",
  REMOVE_DEPARTMENT_POSITION: "Dział został usunięty",
  REMOVE_EVALUATING_USER_ERROR: "Nie można usunąć użytkownika, który jest oceniającym.",
  REMOVE_JOB_POSITION: "Stanowisko zostało usunięte",
  REMOVE_USER: "Czy na pewno chcesz usunąć pracownika?",
  // REPEAL_FORM: "",
  // SPINNER_MODAL: "",
  // SUGGESTION_FORM: "",
  // SUGGESTION_INFO: "",
  // SUGGESTION_INFO_RONLY: "",
  SUGGESTION_REDIRECTED: "Sugestia została przekierowana",
  SUPERVISOR_NOTE_SUCCESS: "Notatka została dodana",
  UPDATED_MAIL_NOTIFICATIONS: "Informacje o wysyłaniu notyfikacji e-mail zostały zmienione. Wyloguj się i zaloguj ponownie, aby przeładować zmiany.",
  UPDATED_USER: "Dane pracownika zostały zaktualizowane",
  UPDATE_USER_ERROR: "Nie udało się zaktualizować danych pracownika",
  UPDATE_USER_FORM: "Edytuj dane użytkownika",
  USER_ACTIVITY: "Zarządzaj statusem aktywności",
  // USER_INFO: "",
  WITHDRAW_POINTS: "Punkty zostały wysłane do weryfikacji",
  REMOVE_VACATION_SUCCESS: "Poprawnie usunięto zgłoszenie urlopu",
  CREATE_VACATION_SUCCESS: "Poprawnie stworzono zgłoszenie",
  CREATE_VACATION_ERROR: "Wystąpił błąd podczas zgłaszania urlopu",
  UPDATE_VACATION_ERROR: "Wystąpił błąd podczas aktualizacji urlopu",
  UPDATE_VACATION_SUCCESS: "Poprawnie zaktualizowano urlop",
  REMOVE_VACATION_ERROR: "Nie poprawnie usunięto zgłoszenie urlopu",

  REPORT_GENERATE_SUCCESS: "Raport został wygenerowany",
  REPORT_GENERATE_FAIL: "Błąd - raport nie został wygenerowany",
  REPORT_GENERATE_EMPTY: "Błąd - brak danych w podanym zakresie"
}

export default modalMessages;
