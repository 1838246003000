import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../__store/tools/hooks';

import { Button, Checkbox } from 'antd';

import styles from "../components/LoginForm.module.scss";
import { authThunk } from '../../../__store/thunks';

import { RoutesPaths, UserRoles } from '../../../data/config';
import { authData } from '../../../data/constants';

import { CLIENT_NAME } from "../../../data/config/globalContentVariables.data";
const Program = require(`../../../assets/files/${CLIENT_NAME}/Program.pdf`).default;
const Clause = require(`../../../assets/files/${CLIENT_NAME}/Clause.pdf`).default

const { WORKER, ADMIN } = UserRoles;
const { PROFILE } = RoutesPaths;

const { WORKER_PROFILE_PATH, YOUR_PROFILE_PATH } = PROFILE;

const { consentTextData, ConsentTypes } = authData;

interface IConsentElement {
  type?: string;
  companyName?: string;
  programName?: string;
  link?: string;
  linkName?: string;
  required?: boolean;
}

const ConsentElement = (
  { type, companyName, programName, link, linkName, required }:IConsentElement):ReactElement => {

  let conentToRender;

  switch (type) {
    case ConsentTypes.PERSONAL_DATA:
      conentToRender = (
        <>
          Wyrażam zgodę na przetwarzanie moich danych osobowych przez {companyName} w związku ze zgłoszeniem wniosku do programu sugestii pracowniczych {programName}. Jestem świadomy, że podane przeze mnie dane osobowe będą przetwarzane w związku z oceną i realizacją wniosku w ramach programu sugestii pracowniczych {programName}.
        </>
      );
      break;

    case ConsentTypes.INFO_CLAUSE:
      conentToRender = (
        <>
          Potwierdzam, że zapoznałem się z {<a href={link} target="_blank" rel="noreferrer">Klauzulą informacyjną</a>} programu {programName}.
        </>
      );
      break;

    default:
      conentToRender = (
        <>Akceptuję {<a href={link} target="_blank" rel="noreferrer">{linkName}</a>}.</>
      );
      break;
  }

  return (
    <span className={styles["login__checkbox-description"]}>
      {conentToRender}{required ? <span className={styles["required"]}>*</span> : null}
    </span>
  )
}

const ConsentForm = ():ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userId = useAppSelector((state) => state.auth.user_id);
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const role = useAppSelector((state) => state.auth.role);
  const hasRulesAccepted = useAppSelector((state) => state.auth.hasRulesAccepted);

  const [personalDataConsent, setPersonalDataConsent] = useState(false);
  const [informationClauseConsent, setinformationClauseConsent] = useState(false);
  const [rulesConsent, setRulesConsent] = useState(false);

  const [consentError, setConsentError] = useState("");

  const handlePersonalDataCheck = () => {
    setPersonalDataConsent(!personalDataConsent);
  }

  const handleInformationClauseCheck = () => {
    setinformationClauseConsent(!informationClauseConsent);
  }

  const handleRulesCheck = () => {
    setRulesConsent(!rulesConsent);
  }

  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (userId && personalDataConsent && informationClauseConsent && rulesConsent) {
      setConsentError("");
      dispatch(authThunk.acceptRules({ userId, hasRulesAccepted: true }));
    } else {
      setConsentError(consentTextData.errorMessage);
    }
  }

  useEffect(() => {
    if (isAuth && hasRulesAccepted) {
      role === WORKER || role === ADMIN
      ? navigate(YOUR_PROFILE_PATH)
      : navigate(WORKER_PROFILE_PATH);
    }
  }, [navigate, hasRulesAccepted]);

  return (
    <form
      className={styles["login"]}
      onSubmit={(e) => handleSubmit(e)}
    >
      <h2 className={styles["login__header"]}>{consentTextData.header}</h2>

      <div className={styles["login__checkbox"]}>
        <Checkbox 
          onChange={handlePersonalDataCheck} 
          checked={personalDataConsent}
          data-testid="consent__personal-data"
        >
          <ConsentElement 
            type={ConsentTypes.PERSONAL_DATA}
            companyName={consentTextData.companyName}
            programName={consentTextData.programName}
            required
          />
        </Checkbox>
      </div>

      <div className={styles["login__checkbox"]}>
        <Checkbox 
          onChange={handleInformationClauseCheck} 
          checked={informationClauseConsent}
          data-testid="consent__information-clause"
        >
          <ConsentElement 
            type={ConsentTypes.INFO_CLAUSE}
            programName={consentTextData.programName}
            link={Clause}
            required
          />
        </Checkbox>
      </div>

      <div className={styles["login__checkbox"]}>
        <Checkbox 
          onChange={handleRulesCheck} 
          checked={rulesConsent}
          data-testid="consent__rules"
        >
          <ConsentElement 
            link={Program}
            linkName={consentTextData.linkName}
            required
          />
        </Checkbox>
      </div>

      <Button
          size="large"
          type="primary"
          htmlType="submit"
          className={styles["login__submit-button"]}
          data-testid="consent__submit-button"
        >
          {consentTextData.submitButton}
        </Button>

        <p className={styles["login__error-pargraph"]}>{consentError}</p>
    </form>
  );
};

export default ConsentForm;
