import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../store";
import { suggestionModels } from "../models";
import { formsActions, modalActions, suggestionActions } from "../slices";
import { suggestionService } from "./services";
import { rankingThunk } from ".";

import { 
  ModalClassnames, 
  UserRoles, 
  ServerStatuses, 
  MainStatuses, 
  ThunkNames 
} from "../../data/config";
import { 
  AllStatuses, 
  RealizationSubstatuses, 
  RejectionSubstatuses, 
  ReportSubstatuses 
} from "../../data/config/suggestionStatuses.data";

const {
  IMPLEMENTED_SUCCESS,
  ADD_SUGGESTION_SUCCESS,
  SUGGESTION_REDIRECTED,
  REJECTED_SUGGESTION,
  WITHDRAWN,
  END_RESULT_SUCCESS,
  SUPERVISOR_NOTE_SUCCESS,
  IMPLEMENTED_SUGGESTION,
  CORRECT_SUGGESTION_REALIZATION,
  CORRECTED_SUGGESTION,
  APPEALED_SUGGESTION,
  APPROVED_SUCCESS,
  DONE_SUGGESTION,
  CLOSED_SUCCESS,
  COMMENTED_SUGGESTION,
  EXTERNAL_COMMENT_SUCCESS,
} = ModalClassnames;

const { SUGGESTION } = ThunkNames;

export const getSelectedSuggestion = createAsyncThunk(
  SUGGESTION.GET_SELECTED_SUGGESTION_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getSuggestionById(payload).then((data:any) => {
      dispatch(suggestionActions.setSelectedSuggestion(data.data));

      dispatch(formsActions.setFormInfoData(data.data));
      dispatch(formsActions.setSuggestionComment(data.commentaries));
      dispatch(formsActions.setPickedSuggestionId(payload));

      dispatch(formsActions.setSuggestionPath(data.suggestionPath));
      dispatch(formsActions.setResultPath(data.resultPath));
      dispatch(formsActions.setSolutionPath(data.solutionPath));
      dispatch(formsActions.setVerificationPath(data.verificationPath));
      dispatch(formsActions.setCorrectionPath(data.correctionPath));
      dispatch(formsActions.setEndResultPath(data.endResultPath));

      dispatch(formsActions.setSolutionFilesNames(data.soultionFilesName));
      dispatch(formsActions.setResultFilesNames(data.resultFilesName));
      dispatch(formsActions.setSuggestionFilesNames(data.suggestionFilesName));
      dispatch(formsActions.setVerificationFilesNames(data.verificationFilesName));
      dispatch(formsActions.setCorrectionFilesNames(data.correctionFilesName));
      dispatch(formsActions.setEndResultFilesNames(data.endResultFilesName));
    });
  }
);

export const addSuggestion = createAsyncThunk(
  SUGGESTION.ADD_SUGGESTION_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    dispatch(modalActions.closeModal());
    dispatch(suggestionActions.setSendingSuggestion(true));
    return suggestionService.postSuggestion(payload).then((data) => {
      dispatch(modalActions.closeModal());

      if (data.status === ServerStatuses.CREATED) {
        const store = getState() as RootState;

        if (store.users.authUser?.role === UserRoles.BOSS) {
          dispatch(suggestionActions.setSendingSuggestion(false));
          dispatch(modalActions.closeModal());
          dispatch(modalActions.openModal());
          dispatch(modalActions.setModalClassName(IMPLEMENTED_SUCCESS));
        } else {
          dispatch(suggestionActions.setSendingSuggestion(false));
          dispatch(modalActions.closeModal());
          dispatch(modalActions.openModal());
          dispatch(modalActions.setModalClassName(ADD_SUGGESTION_SUCCESS));
        }
      }

      dispatch(getMySuggestions());
    });
  }
);

export const uploadAttachments = createAsyncThunk(
  SUGGESTION.ATTACHMENTS_THUNK,
  (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService
      .uploadAttachments(payload)
      .then((data) => {
        if (data.status === (ServerStatuses.CREATED || ServerStatuses.NO_CONTENT)) {
          dispatch(getMySuggestions());
          dispatch(getWorkerSuggestions());
          dispatch(getOtherDepartmentsSuggestions());
        } else {
          dispatch(modalActions.closeModal());
        }
      });
  }
);

export const updateSuggestionStatus = createAsyncThunk(
  SUGGESTION.UPDATE_SUGGESTION_STATUS_THUNK,
  (payload: suggestionModels.SuggestionUpdatePayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const store = getState() as RootState;

    return suggestionService.updateStatus(payload).then((data) => {
      dispatch(getCommentaries());

      if (data.status === ServerStatuses.OK) {
        if (payload.substatus === AllStatuses.NOTE) {
          dispatch(getWorkerSuggestions());
          dispatch(getOtherDepartmentsSuggestions());
          dispatch(modalActions.setModalClassName(SUPERVISOR_NOTE_SUCCESS));
        } else if (payload.substatus === AllStatuses.EXTERNAL_COMMENT) {
          dispatch(getAllSuggestionsPreview());
          dispatch(modalActions.setModalClassName(EXTERNAL_COMMENT_SUCCESS));
        } else {
        switch (payload.status) {
          case MainStatuses.REPORT:
            if (payload.substatus === ReportSubstatuses.REDIRECTED) {
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(SUGGESTION_REDIRECTED));
            } else if (payload.substatus === ReportSubstatuses.WITHDRAWN) {
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(WITHDRAWN));
            } else if (payload.substatus === ReportSubstatuses.CORRECTED) {
              dispatch(getMySuggestions());
              dispatch(modalActions.setModalClassName(CORRECTED_SUGGESTION));
            }
            break;

          case MainStatuses.REJECTION:
            if (payload.substatus === RejectionSubstatuses.REVOCABLE) {
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(REJECTED_SUGGESTION));
            } else if (payload.substatus === RejectionSubstatuses.CLOSED) {
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(CLOSED_SUCCESS));
            }
            break;

          case MainStatuses.REALIZATION:
            if (payload.substatus === RealizationSubstatuses.RETURNED_FROM_VERIFICATION) {
              dispatch(getWorkerSuggestions());
              dispatch(getMySuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(CORRECT_SUGGESTION_REALIZATION));
            } else {
              if (store.forms.internalVerificationComment) {
                dispatch(modalActions.setModalClassName(COMMENTED_SUGGESTION));
                dispatch(formsActions.setInternalVerificationComment(""));
              } else {
                dispatch(getWorkerSuggestions());
                dispatch(getOtherDepartmentsSuggestions());
                dispatch(getMySuggestions());
                dispatch(modalActions.setModalClassName(IMPLEMENTED_SUGGESTION));
              }
            }
            break;

          case MainStatuses.APPEALED:
            dispatch(getMySuggestions());
            dispatch(modalActions.setModalClassName(APPEALED_SUGGESTION));
            break;

          case MainStatuses.VERIFICATION:
            if (payload.userRole === UserRoles.BOSS) {
              dispatch(modalActions.setModalClassName(APPROVED_SUCCESS));
            } else {
              dispatch(modalActions.setModalClassName(DONE_SUGGESTION));
            }
            dispatch(getMySuggestions());
            break;

          case MainStatuses.CREATION:
             if (payload.substatus === AllStatuses.RESULT) {
              dispatch(getMySuggestions());
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(END_RESULT_SUCCESS));

            } else {
              dispatch(getWorkerSuggestions());
              dispatch(getOtherDepartmentsSuggestions());
              dispatch(modalActions.setModalClassName(APPROVED_SUCCESS));
            }
            break;

          default:
            break;
        }}} else {
          dispatch(modalActions.closeModal());
        }
    });
  }
);

export const updateSuggestionReadStatus = createAsyncThunk(
  SUGGESTION.UPDATE_SUGGESTION_READ_THUNK,
  (payload: suggestionModels.SuggestionReadStatusUpdatePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    return suggestionService.updateReadStatus(payload).then(() => {
      dispatch(getSuggestionsInfo());
    });
  }
);

export const getSuggestionsInfo = createAsyncThunk(
  SUGGESTION.GET_SUGGESTIONS_INFO_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(rankingThunk.fetchRanking());

    dispatch(getReportedCount());
    dispatch(getRejectedCount());
    dispatch(getImplementedCount());
    dispatch(getDoneCount());
    dispatch(getAppealedCount());
    dispatch(getApprovedCount());
    dispatch(getSuperVisorSuggestions());
  }
);

export const getCommentaries = createAsyncThunk(
  SUGGESTION.GET_ALL_COMMENTARIES_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getAllCommentaries().then((data) => {
      dispatch(suggestionActions.setAllCommentaries(data.data));
    })
  }
)

export const getMySuggestions = createAsyncThunk(
  SUGGESTION.GET_MY_SUGGESTIONS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.fetchMySuggestions().then((data) => {
      const suggestions: suggestionModels.AllSuggestions = data.data;

      dispatch(suggestionActions.setMyReportedSuggestions(suggestions.report));
      dispatch(suggestionActions.setMyImplementedSuggestions(suggestions.realization));
      dispatch(suggestionActions.setMyDoneSuggestions(suggestions.verification));
      dispatch(suggestionActions.setMyApprovedSuggestions(suggestions.creation));
      dispatch(suggestionActions.setMyAppealedSuggestions(suggestions.appealed));
      dispatch(suggestionActions.setMyRejectedSuggestions(suggestions.rejection));
      dispatch(suggestionActions.setMySuggestions(suggestions));
      dispatch(suggestionActions.setMyAllSuggestionsCount());

      dispatch(getCommentaries());
    });
  }
);

export const getWorkersSuggestionsToOtherDepartments = createAsyncThunk(
  SUGGESTION.GET_WORKERS_TO_OTHERS_SUGGESTIONS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService
      .fetchWorkersSuggestionsToOtherDepartments()
      .then((data) => {
        const suggestions: suggestionModels.AllSuggestions = data.data;

      dispatch(suggestionActions.setWorkersToOtherReportedSuggestions(suggestions.report));
      dispatch(suggestionActions.setWorkersToOtherImplementedSuggestions(suggestions.realization));
      dispatch(suggestionActions.setWorkersToOtherDoneSuggestions(suggestions.verification));
      dispatch(suggestionActions.setWorkersToOtherApprovedSuggestions(suggestions.creation));
      dispatch(suggestionActions.setWorkersToOtherAppealedSuggestions(suggestions.appealed));
      dispatch(suggestionActions.setWorkersToOtherRejectedSuggestions(suggestions.rejection));
      dispatch(suggestionActions.setWorkersToOtherSuggestions(suggestions));

      dispatch(getCommentaries());
    });
  }
);

export const getWorkerSuggestions = createAsyncThunk(
  SUGGESTION.GET_WORKERS_SUGGESTIONS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.fetchWorkerSuggestions().then((data) => {
      const suggestions: suggestionModels.AllSuggestions = data.data;

      dispatch(suggestionActions.setWorkerReportedSuggestions(suggestions.report));
      dispatch(suggestionActions.setWorkerImplementedSuggestions(suggestions.realization));
      dispatch(suggestionActions.setWorkerDoneSuggestions(suggestions.verification));
      dispatch(suggestionActions.setWorkerApprovedSuggestions(suggestions.creation));
      dispatch(suggestionActions.setWorkerAppealedSuggestions(suggestions.appealed));
      dispatch(suggestionActions.setWorkerRejectedSuggestions(suggestions.rejection));
      dispatch(suggestionActions.setWorkerSuggestions(suggestions));

      dispatch(getCommentaries());
    });
  }
);

export const getOtherDepartmentsSuggestions = createAsyncThunk(
  SUGGESTION.GET_OTHER_DEPARTMENTS_SUGGESTIONS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.fetchOtherDepartmentsSuggestions().then((data) => {
      const suggestions: suggestionModels.AllSuggestions = data.data;

      dispatch(suggestionActions.setDepartmentReportedSuggestions(suggestions.report));
      dispatch(suggestionActions.setDepartmentImplementedSuggestions(suggestions.realization));
      dispatch(suggestionActions.setDepartmentDoneSuggestions(suggestions.verification));
      dispatch(suggestionActions.setDepartmentApprovedSuggestions(suggestions.creation));
      dispatch(suggestionActions.setDepartmentAppealedSuggestions(suggestions.appealed));
      dispatch(suggestionActions.setDepartmentRejectedSuggestions(suggestions.rejection));

      dispatch(getCommentaries());
    });
  }
);

export const getAllSuggestionsPreview = createAsyncThunk(
  SUGGESTION.GET_ALL_SUGGESTIONS_PREVIEW_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.fetchAllSuggestionsPreview().then((data) => {
      const suggestions: suggestionModels.AllSuggestions = data.data;
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewReported(suggestions.report));
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewImplemented(suggestions.realization));
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewDone(suggestions.verification));
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewApproved(suggestions.creation));
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewAppealed(suggestions.appealed));
      thunkAPI.dispatch(suggestionActions.setAllSuggestionsPreviewRejected(suggestions.rejection));

      dispatch(getCommentaries());
    });
  }
);

export const getReportedCount = createAsyncThunk(
  SUGGESTION.COUNT_REPORTED_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.REPORT).then((data) =>
      dispatch(suggestionActions.setReportedCount(data.data))
    );
  }
);

export const getRejectedCount = createAsyncThunk(
  SUGGESTION.COUNT_REJECTED_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.REJECTION).then((data) =>
      dispatch(suggestionActions.setRejectedCount(data.data))
    );
  }
);

export const getImplementedCount = createAsyncThunk(
  SUGGESTION.COUNT_IMPLEMENTED_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.REALIZATION).then((data) =>
      dispatch(suggestionActions.setImplementedCount(data.data))
    );
  }
);

export const getDoneCount = createAsyncThunk(
  SUGGESTION.COUNT_DONE_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.VERIFICATION).then((data) =>
      dispatch(suggestionActions.setDoneCount(data.data))
    );
  }
);

export const getApprovedCount = createAsyncThunk(
  SUGGESTION.COUNT_APPROVED_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.CREATION).then((data) =>
      dispatch(suggestionActions.setApprovedCount(data.data))
    );
  }
);

export const getAppealedCount = createAsyncThunk(
  SUGGESTION.COUNT_APPEALED_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService.getCount(MainStatuses.APPEALED).then((data) =>
      dispatch(suggestionActions.setAppealedCount(data.data))
    );
  }
);

export const getSuperVisorSuggestions = createAsyncThunk(
  SUGGESTION.GET_SUPERVISOR_SUGGESTIONS_THUNK,
  (_, thunkAPI) => {
    const { dispatch } = thunkAPI;

    return suggestionService
      .getCount("")
      .then((data) =>
        dispatch(suggestionActions.setSuperVisorSuggestion(data.data))
      );
  }
);
