export const rankingListHeader = "Lista rankingowa";

export const avatarStyles = { backgroundColor: "#2256bb", verticalAlign: "middle" };

export const FILTERS_DATE_FORMAT = "dd-MM-yyyy";

export const filtersLabels = {
  USERS_FILTERS: "Filtry uzytkowników",
  START_DATE: "Data początkowa",
  END_DATE: "Data końcowa",
};

export const filtersButtons = {
  FILTER_RESET_BUTTON: "Resetuj",
  FILTER_SEARCH_BUTTON: "Wyszukaj",
};

export const RANKING_BREAK_POINT = 3;
  
export const dashboardRankingLabels = {
  MONTH: "Miesiąc",
  DEPARTMENT: "Dział",
  FILTERS: "Filtry",
  POSITION_COLUMN: "Pozycja",
  WORKER_COLUMN: "Pracownik",
  DEPARTMENT_COLUMN: "Dział",
  POINTS_COLUMN: "Punkty"
};
  
export const dashboardRankingButtons = {
  FILTER_RESET_BUTTON: "Resetuj",
  FILTER_SEARCH_BUTTON: "Filtruj",
};
