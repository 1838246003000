import React, { ReactElement, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../__store/tools/hooks";
import { modalActions } from "../../../__store/slices";
import { departmentThunk } from "../../../__store/thunks";

import { Button, Icon, SubmitButton } from "../../components/ui";

import styles from "./CreateDepartmentForm.module.scss";
import InputTypes from "../../../data/constants/inputTypes.data";
import { structureData } from "../../../data/constants";
import { IconTypes } from "../../../data/config";

const {
  DEPARTMENT_NAME_MIN_LENGTH,
  DEPARTMENT_NAME_MAX_LENGTH,
  departmentMessages,
  createDepartmentLabels,
  createDepartmentButtons,
  departmentInputNames,
} = structureData;

interface CreateDepartmentFormValues {
  name: string;
  parent_id: number;
  area_id: number;
}

function CreateDepartmentForm(): ReactElement {
  const dispatch = useAppDispatch();

  const departmentsList = useAppSelector(
    (state) => state.departments.departments
  );

  const areasList = useAppSelector(
    (state) => state.departments.areas
  );

  const [formValues, setFormValues] = useState<CreateDepartmentFormValues>({
    name: "",
    parent_id: 0,
    area_id: 0,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function onSubmitHandler(event: React.FormEvent) {
    event.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (formValues.name.length < DEPARTMENT_NAME_MIN_LENGTH) {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_TOO_SHORT;
    } else if (formValues.name.length > DEPARTMENT_NAME_MAX_LENGTH) {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_TOO_LONG;
    } else if (formValues.name.trim() === "") {
      newErrors[departmentInputNames.NAME] = departmentMessages.NAME_REQUIRED;
    }

    if (formValues.parent_id === 0) {
      newErrors[departmentInputNames.PARENT_ID] =
        departmentMessages.PARENT_REQUIRED;
    }

    if (formValues.area_id === 0) {
      newErrors[departmentInputNames.AREA_ID] =
        departmentMessages.AREA_REQUIRED;
    }

    if (Object.keys(newErrors).length === 0) {
      const { name, parent_id, area_id } = formValues;
      dispatch(
        departmentThunk.createDepartment({ name: name.trim(), parent_id, area_id })
      );
    } else {
      setErrors(newErrors);
    }
  }

  function onCancelHandler() {
    dispatch(modalActions.closeModal());
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  return (
    <div className={styles["form"]}>
      <div className={styles["form__header"]}>
        <div className={styles["form__header__text"]}>
          <span>{createDepartmentLabels.TITLE}</span>
        </div>
        <div className={styles["form__header__button"]}>
          <Icon type={IconTypes.CROSS} onClick={onCancelHandler} />
        </div>
      </div>

      <form className={styles["form__body"]} onSubmit={onSubmitHandler}>
        <div className={styles["form-group"]}>
          <input
            type={InputTypes.TEXT}
            name={departmentInputNames.NAME}
            placeholder={createDepartmentLabels.NAME}
            className={styles["form-group__input"]}
            value={formValues.name}
            onChange={handleInputChange}
          />
          {errors[departmentInputNames.NAME] && (
            <div className={styles["form-group__error"]}>
              {errors[departmentInputNames.NAME]}
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <select
            name={departmentInputNames.PARENT_ID}
            className={styles["form-group__select"]}
            value={formValues.parent_id}
            onChange={handleInputChange}
          >
            <option value="">{createDepartmentLabels.PARENT}</option>
            {departmentsList.map((department) => (
              <option
                key={department.department_id}
                value={+department.department_id}
              >
                {department.name}
              </option>
            ))}
          </select>
          {errors[departmentInputNames.PARENT_ID] && (
            <div className={styles["form-group__error"]}>
              {errors[departmentInputNames.PARENT_ID]}
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <select
            name={departmentInputNames.AREA_ID}
            className={styles["form-group__select"]}
            value={formValues.area_id}
            onChange={handleInputChange}
          >
            <option value="">{createDepartmentLabels.AREA}</option>
            {areasList.map((area) => (
              <option
                key={area.area_id}
                value={+area.area_id}
              >
                {area.area_name}
              </option>
            ))}
          </select>
          {errors[departmentInputNames.AREA_ID] && (
            <div className={styles["form-group__error"]}>
              {errors[departmentInputNames.AREA_ID]}
            </div>
          )}
        </div>

        <div className={styles["form-buttons"]}>
          <Button
            textValue={createDepartmentButtons.CANCEL_BUTTON}
            buttonClassName="cancel"
            buttonFunction={onCancelHandler}
            hiddenStyles=""
            type="button"
          />
          <SubmitButton 
            buttonClassName="submit-button" 
            value={createDepartmentButtons.SEND_BUTTON}
          />
          </div>
      </form>
    </div>
  );
}

export default CreateDepartmentForm;
