import { createSlice } from "@reduxjs/toolkit";

import { modalModels } from "../models";

import { SliceNames } from "../../data/config";

const initialState: modalModels.ModalState = {
  isModalOpen: false,
  isAttachmentModalOpen: false,
  modalClassName: "",
  backdropClassname: "",
  attachmentPath: "",
  attachmentDescription: "",
};

const modalSlice = createSlice({
  name: SliceNames.MODAL,
  initialState,
  reducers: {
    openAttachmentModal: (state) => {
      state.isAttachmentModalOpen = true;
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.modalClassName = "";
      state.backdropClassname = "";
    },
    closeAttachmentModal: (state) => {
      state.isAttachmentModalOpen = false;
    },
    setModalClassName: (state, { payload }) => {
      state.modalClassName = payload;
    },
    setBackdropClassName: (state, { payload }) => {
      state.backdropClassname = payload;
    },
    setAttachmentPath: (state, { payload }) => {
      state.attachmentPath = payload;
    },
    setAttachmentDescription: (state, { payload }) => {
      state.attachmentDescription = payload;
    }
  },
});

export const {
  openModal,
  closeModal,
  setModalClassName,
  setBackdropClassName,
  openAttachmentModal,
  closeAttachmentModal,
  setAttachmentPath,
  setAttachmentDescription
} = modalSlice.actions;

export default modalSlice.reducer;
