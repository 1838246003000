import { useState } from "react";
import { RoutesPaths } from "../../../../data/config";
import { ITEMS_PER_PAGE } from "../data";
import { UserRates } from "../../../../__store/models/evaluation.models";

export const useTable = () => {
  const {
    ROOT_PATH,
    ADMIN,
    ASSESSMENT,
    AUTHORIZATION,
    PROFILE,
    RANKS,
    SUGGESTIONS,
  } = RoutesPaths;
  type TableHeaderValue = {
    [key: string]: string;
  };

  const [paginationValues, setPaginationValues] = useState<{
    selectedPage: number;
  }>({
    selectedPage: 0,
  });

  const pagesVisted = paginationValues.selectedPage * ITEMS_PER_PAGE;

  const tableHeader: TableHeaderValue = {
    [ASSESSMENT.MY_ASSESSMENT_PATH]: "Otrzymane informacje zwrotne",
  };

  return { tableHeader, pagesVisted, setPaginationValues, paginationValues };
};
