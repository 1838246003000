import { createAsyncThunk } from "@reduxjs/toolkit";

import { rankingActions } from "../slices";
import { rankingService } from "./services";

import { ThunkNames } from "../../data/config";

const { RANKING_FETCH_THUNK } = ThunkNames;

export const fetchRanking = createAsyncThunk(
    RANKING_FETCH_THUNK,
    (_, thunkAPI) => {
      const { dispatch } = thunkAPI;

      return rankingService.getRanking().then((data) => {
        dispatch(rankingActions.setSmallRanking(data.data.smallRanking));
        dispatch(rankingActions.setDepartmentRanking(data.data.departmentRanking));
      });
    }
);
  