import React, { useState, useEffect, useRef, ReactElement, useMemo } from "react";

import { useAppSelector, useAppDispatch } from "../../../__store/tools/hooks";

import { adminModels } from "../../../__store/models";
import { modalActions, usersActions } from "../../../__store/slices";
import { departmentThunk, adminThunk } from "../../../__store/thunks";

import { Icon } from "../../components/ui";

import styles from "./DashboardAdmin.module.scss";

import { IDashboardAdminProps } from "../types/AdminProps.types";

import { Button } from "../../components/ui";
import { SpinnerModal } from "../../components/Modal";

import { IconTypes, ModalClassnames } from "../../../data/config";
import { activationData, adminData } from "../../../data/constants";

import { Avatar, Tag } from "antd";
import UserRoles, { UserRolesTranslated } from "../../../data/config/userRoles.data";

const { 
  UPDATE_USER_FORM,
  ADMIN_CHANGE_CREDENTIAL,
  REMOVE_USER,
  USER_ACTIVITY,
  USER_INFO,
} = ModalClassnames;

const { ACTIVATION_MESSAGES, EmploymentStatus } = activationData;

const { 
  avatarStyles, 
  iconStyles, 
  dashboardAdminLabels, 
  dashboardAdminButtons
} = adminData;

const PAGE_SIZE = 50;

const DashboardAdmin = ({ topBarHeight, usersList }: IDashboardAdminProps):ReactElement => {
  const dispatch = useAppDispatch();
  const [selectedDepartment, setSelectedDepatment] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const [selectedJob, setSelectedJob] = useState("all");
  const [filteredUsers, setFilteredUsers] = useState<adminModels.Users>([]);
  const [isMenuOpen, setIsMenuOpen] = useState({
    status: false,
    id: -1,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const totalPages = Math.ceil(
    filteredUsers.filter(
      user => usersList === "active" 
        ? user.user.isActive
        : usersList === "fired"
          ? (!user.user.isActive && user.employmentStatus === "FIRED")
          : (!user.user.isActive && !(user.employmentStatus === "FIRED"))
        ).length / PAGE_SIZE
      );

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  const paginatedUsers = filteredUsers.filter(
    user => usersList === "active" 
      ? user.user.isActive 
      : usersList === "fired"
        ? (!user.user.isActive && user.employmentStatus === "FIRED")
        : (!user.user.isActive && !(user.employmentStatus === "FIRED"))
    ).slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } 
  };

  useEffect(() => {
    if (paginatedUsers.length > 0) {
      setIsLoading(false);
    } else {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [paginatedUsers]);

  const filtersRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const listHeaderRef = useRef<HTMLDivElement>(null);

  const users = useAppSelector((state) => state.users.users) || [];
  const positionsList = useAppSelector(
    (state) => state.departments.positionList
  );
  const departmentList = useAppSelector(
    (state) => state.departments.departments
  );
  const isFiltersVisible = useAppSelector(
    (state) => state.forms.showAdminPanelFilters
  );

  useEffect(() => {
    dispatch(adminThunk.fetchUsers());
    dispatch(departmentThunk.getDepartmentData());
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    if (departmentList.length > 0) {
      dispatch(departmentThunk.getDepartmentPosition({ id: departmentList[0].department_id }));
      setSelectedDepatment(departmentList[0].department_id);
    }
  }, [departmentList]);

  useEffect(() => {
    if (positionsList.length > 0) {
      setSelectedJob("all");
    }
  }, [positionsList]);

  useEffect(() => {
    const headerElement = headerRef.current;
    const filtersElement = filtersRef.current;
    const listHeaderElement = listHeaderRef.current;

    if (
      headerElement &&
      filtersElement &&
      listHeaderElement &&
      !isFiltersVisible
    ) {
      setListHeight(
        topBarHeight +
          headerElement.clientHeight +
          listHeaderElement.clientHeight
      );
    }
  });

  useEffect(() => {
    if (isFiltersVisible) {
      setListHeight((prev) => prev + 70);
    }
  }, [isFiltersVisible]);

  const handleOnResetFilter = () => {
    setFilteredUsers(users);
    setCurrentPage(1);

    setSelectedDepatment(departmentList[0].department_id);
    dispatch(departmentThunk.getDepartmentPosition({ id: departmentList[0].department_id }));
  };

  const handleOnFilter = () => {
    let usersToFilter = [...users];

    usersToFilter = usersToFilter.filter(
      (user) => user.department.department_id === +selectedDepartment
    );

    if (selectedJob !== "all") {
      usersToFilter = usersToFilter.filter(
        (user) => user.job_position.name === selectedJob
      );
    }

    setFilteredUsers(usersToFilter);
    setCurrentPage(1);
  };

  const handleActivationBoxOpening = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(USER_ACTIVITY));
  }

  const handleOnEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(UPDATE_USER_FORM));
  };

  const handleOnEditCredential = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(ADMIN_CHANGE_CREDENTIAL));
  };

  const handleOnDelete = (e: React.MouseEvent<SVGElement>, user_id: number) => {
    e.stopPropagation();
    closeMenu();
    dispatch(usersActions.setUserId(user_id));
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(REMOVE_USER));
  };

  const handleOnClick = (user_index: number) => {
    closeMenu();
    dispatch(usersActions.setUserIndex(user_index));
    dispatch(modalActions.openModal());
    dispatch(modalActions.setModalClassName(USER_INFO));
  };

  const toggleMenu = (e: React.MouseEvent, index: any, userId: number) => {
    e.stopPropagation();
    dispatch(usersActions.setUserId(userId));
    dispatch(adminThunk.fetchUser(userId));
    setIsMenuOpen((prevState) => ({
      status: !prevState.status,
      id: index,
    }));
  };

  const closeMenu = () => {
    setIsMenuOpen({ status: false, id: -1 });
  };

  const onDepartmentChange = (e: any) => {
    const id = e.target.value;

    dispatch(departmentThunk.getDepartmentPosition({ id }));
    setSelectedDepatment(id);
  };

  const handleChangePosition = (e: any) => {
    setSelectedJob(e.target.value);
  };

  const PaginationComponent = () => {
    return (
      <div className={styles["admin-panel__pagination"]}>
        <button disabled={currentPage === 1} onClick={handlePreviousPage}>
          Poprzednia
        </button>
        <span>
          Strona {currentPage} z {totalPages}
        </span>
        <button disabled={currentPage === totalPages} onClick={handleNextPage}>
          Następna
        </button>
      </div> 
    )
  }

  const renderWorkers = (users: adminModels.Users) => useMemo(() => {
    if (isLoading) {
      return <SpinnerModal />; 
    }

    const filteredUsersList = users &&
    users.filter(
      user => usersList === "active" 
      ? user.user.isActive 
      : usersList === "fired"
        ? (!user.user.isActive && user.employmentStatus === "FIRED")
        : (!user.user.isActive && !(user.employmentStatus === "FIRED"))
    );

    const renderedWorkers = filteredUsersList.map((user, index) => (
          <div
            key={user.user_id}
            className={styles["user"]}
            onClick={() => handleOnClick(user.user_id)}
            data-tip={`${user.name} ${user.surname} - ${user.job_position.name} w: ${user.department.name} (${UserRolesTranslated.get(user.role as UserRoles)})`}
          >
            <div className={styles["user__position"]}>
              <span>{index + 1 + (currentPage-1)*50}.</span>
            </div>
            <div className={styles["user__user"]}>
              <Avatar
                style={avatarStyles(user.user.isActive)}
                size="large"
              >{`${user.name.charAt(0)}${user.surname.charAt(0)}`}</Avatar>

              <div className={styles["user__user__name"]}>
                <span>{`${user.name} ${user.surname}`}</span>
              </div>
            </div>
            <div className={styles["user__department"]}>
              <span>{user.department.name || dashboardAdminLabels.NONE_DEPARTMENT}</span>
            </div>
            <div className={styles["user__department"]}>
              {/* <span>{user.user.isActive 
                ? <Tag color="green">{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Tag> 
                : <Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Tag>}
              </span>
              <span>{user.employmentStatus === EmploymentStatus.WORKING
                ? <Tag color="blue">Zatrudniony</Tag> 
                : user.employmentStatus === "SICKNESS" 
                  ? <Tag color="orange">L4</Tag> 
                  : <Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Tag>}
              </span> */}
              <span>
                {user.user.isActive
                  ? <Tag color="green">{ACTIVATION_MESSAGES.ACCOUNT_ACTIVE}</Tag>
                  : user.employmentStatus === "FIRED"
                    ? <Tag color="red">{ACTIVATION_MESSAGES.ACCOUNT_FIRED}</Tag>
                    : <Tag color="orange">{ACTIVATION_MESSAGES.ACCOUNT_NON_ACTIVE}</Tag> 
                }
              </span>
            </div>
            <div className={styles["user__actions"]}>
              <div className={styles["user__actions__icons"]} key={index}>
                <Icon 
                  type={IconTypes.EDIT}
                  width={30}
                  height={30}
                  onClick={(e:any) => toggleMenu(e, index, user.user_id)}
                  style={iconStyles}
                />
                <Icon
                  type={IconTypes.TRASH}
                  width={30}
                  height={30}
                  onClick={(e:any) => handleOnDelete(e, user.user_id)}
                  style={iconStyles}
                />
              </div>
              {index === isMenuOpen.id && isMenuOpen.status && (
                <div
                  className={`${styles["user__actions__menu"]} ${isMenuOpen && styles["open"]}`}
                >
                  <button
                    onClick={(e) => handleOnEdit(e)}
                    className={styles["first"]}
                  >
                    {dashboardAdminButtons.EDIT_USER_DATA_BUTTON}
                  </button>
                  <button onClick={(e) => handleOnEditCredential(e)}>
                    {dashboardAdminButtons.EDIT_USER_CREDENTIAL_BUTTON}
                  </button>
                  <button onClick={(e) => handleActivationBoxOpening(e)}>
                    {dashboardAdminButtons.SWITCH_ACTIVITY_BUTTON}
                  </button>
                </div>
              )}
            </div>
          </div>
        ));
    return (
      <>
        {renderedWorkers.length > 0 ? renderedWorkers : <div>Ta lista jest pusta</div>}         
        <PaginationComponent />
      </>)
  }, [filteredUsers, paginatedUsers]);

  return (
    <div className={styles["admin-panel"]}>
      <div
        className={`${styles["admin-panel__filters"]}  ${styles["show"]}`}
        ref={filtersRef}
        style={ {height: "70px"} }
      >
        <div className={styles["admin-panel__filters__container"]}>
          <div className={styles["admin-panel__filters__container__selectors"]}>
            <div
              className={styles["admin-panel__filters__container__selectors__select-container"]}
            >
              <select
                name="department"
                value={selectedDepartment}
                onChange={onDepartmentChange}
                className={styles["select"]}
              >
                {departmentList &&
                  departmentList.map((department, i) => (
                    <option key={i} value={department.department_id}>
                      {department.name}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className={styles["admin-panel__filters__container__selectors__select-container"]}
            >
              <select
                name="job_position"
                value={selectedJob}
                onChange={handleChangePosition}
                className={styles["select"]}
              >
                <option value="all">{dashboardAdminLabels.ALL_WORKERS}</option>          
                {positionsList
                  .filter((position, index, self) => self.findIndex(p => p.name === position.name) === index)
                  .map((position) => (
                    <option key={position.name} value={position.name}>
                      {position.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className={styles["admin-panel__filters__container__buttons"]}>
            <div
              className={styles["admin-panel__filters__container__buttons__button-container"]}
            >
              <Button
                hiddenStyles=""
                textValue={dashboardAdminButtons.FILTER_RESET_BUTTON}
                buttonClassName="filter-reset-button"
                buttonFunction={handleOnResetFilter}
              />
            </div>
            <div
              className={styles["admin-panel__filters__container__buttons__button-container"]}
            >
              <Button
                hiddenStyles=""
                textValue={dashboardAdminButtons.FILTER_SEARCH_BUTTON}
                buttonClassName="filter-search-button"
                buttonFunction={handleOnFilter}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["admin-panel__subtitle"]} ref={headerRef}>
        <span>{dashboardAdminLabels.WORKERS_LIST}</span>
      </div>
      <div
        className={`${styles["admin-panel__list"]} ${styles["resize"]}`}
      >
        <div className={styles["admin-panel__header"]} ref={listHeaderRef}>
          <div className={styles["admin-panel__header__position"]}>
            <span></span>
          </div>
          <div className={styles["admin-panel__header__worker"]}>
            <span>{dashboardAdminLabels.WORKER_COLUMN}</span>
          </div>
          <div className={styles["admin-panel__header__department"]}>
            <span>{dashboardAdminLabels.DEPARTMENT_COLUMN}</span>
          </div>
          <div className={styles["admin-panel__header__department"]}>
            <span>{dashboardAdminLabels.STATUS_COLUMN}</span>
          </div>
          <div className={styles["admin-panel__header__actions"]}>
            <span>{dashboardAdminLabels.ACTIONS_COLUMN}</span>
          </div>
        </div>
        <div className={styles["admin-panel__elements"]} style={{ height: `calc(98vh - ${listHeight}px)` }}>
          {renderWorkers(paginatedUsers)}
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
