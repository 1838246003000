import React from 'react';
import { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../../../__store/tools/hooks";
import { Icon } from "../../../components/ui";
import styles from "./Area.module.scss";
import { IconTypes } from "../../../../data/config";
import { departmentActions, modalActions } from "../../../../__store/slices";
import { ModalClassnames } from "../../../../data/config";
import { departmentThunk } from "../../../../__store/thunks";

const {
  EDIT_AREA,
  REMOVE_AREA,
  CANNOT_REMOVE_AREA,
  AREA_INFO
} = ModalClassnames;

function Area({ area, handleClick }: any): ReactElement {
  const departmentList = useAppSelector((state) => state.departments.departments);
  const usersList = useAppSelector((state) => state.users.users);

  const dispatch = useAppDispatch();

  const isAreaEmpty =
    !departmentList.find(
      department => department.area_id === area.area_id)
    && !usersList.find(
      user => user.area_id === area.area_id);

  const handleAreaEdition = ({ areaId }: { areaId: number }) => {
    dispatch(departmentActions.setSelectedArea(areaId));
    dispatch(modalActions.setModalClassName(EDIT_AREA));
    dispatch(modalActions.openModal());
  }

  const handleAreaDeletion = ({ areaId }: { areaId: number }) => {
    dispatch(departmentActions.setSelectedArea(areaId));
    if (isAreaEmpty) {
      dispatch(departmentThunk.deleteArea({ area_id: areaId }));
      dispatch(modalActions.setModalClassName(REMOVE_AREA));
      dispatch(modalActions.openModal());
    } else {
      dispatch(modalActions.setModalClassName(CANNOT_REMOVE_AREA));
      dispatch(modalActions.openModal());
    }
  }

  const handleAreaInfoPreview = (area: any) => {
    dispatch(departmentActions.setArea(area));
    dispatch(modalActions.setModalClassName(AREA_INFO));
    dispatch(modalActions.openModal());
  }

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <li
      key={area.area_id}
      className={styles["area"]}
      onClick={() => handleAreaInfoPreview(area)}
    >
      <div className={styles["area__actions"]}>
        <button
          className={styles["button"]}
          onClick={(event) => {
            stopPropagation(event);
            handleAreaEdition({ areaId: area.area_id });
          }}
        >
          <Icon type={IconTypes.PENCIL_ICON} />
        </button>
        <button
          className={styles["button"]}
          onClick={(event) => {
            stopPropagation(event);
            handleAreaDeletion({ areaId: area.area_id });
          }}
        >
          <Icon type={IconTypes.BIN} />
        </button>
        <button
          className={styles["button"]}
          onClick={(event) => {
            stopPropagation(event);
            handleAreaInfoPreview(area);
          }}
        >
          <Icon type={IconTypes.INFO_STRUCTURE_ICON} />
        </button>
      </div>
      <div className={styles["area__icon"]}>
        <Icon type={IconTypes.AREA_ICON} />
      </div>
      <span className={styles["area__name"]}>
        {area.area_name} {isAreaEmpty && "(pusty)"}
      </span>
    </li>
  );
}

export default Area;
