import { Axios, AxiosResponse } from "axios";
import axiosInstance from "../../tools/axios";
import axiosFilesInstance from "../../tools/axiosFiles";

import { suggestionModels } from "../../models";

import { ServerPaths } from "../../../data/config";

const {
  ATTACHMENT_MUTLIPLE_UPLOAD,
  SUGGESTION,
  SUGGESTIONS,
  MY_SUGGESTIONS,
  WORKERS_SUGGESTIONS,
  OTHER_DEPARTMENTS_SUGGESTIONS,
  WORKERS_TO_OTHERS_SUGGESTIONS,
  ALL_SUGGESTIONS_PREVIEW,
  COUNT,
  NOTIFICATION,
} = ServerPaths;

export const postSuggestion = async (
  payload: FormData
): Promise<AxiosResponse<any, any>> => {
  return await axiosFilesInstance.post(`${SUGGESTION}`, payload);
};

export const getAllCommentaries = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get("/commentary/");

export const getAllSuggestions = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${SUGGESTIONS}/`);

export const fetchMySuggestions = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${SUGGESTIONS}${MY_SUGGESTIONS}`);

export const fetchWorkersSuggestionsToOtherDepartments = (): Promise<AxiosResponse<any, any>> => 
  axiosInstance.get(`${SUGGESTIONS}${WORKERS_TO_OTHERS_SUGGESTIONS}`);

export const fetchWorkerSuggestions = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${SUGGESTIONS}${WORKERS_SUGGESTIONS}`);

export const fetchOtherDepartmentsSuggestions = (): Promise<
  AxiosResponse<any, any>
> => axiosInstance.get(`${SUGGESTIONS}${OTHER_DEPARTMENTS_SUGGESTIONS}`);

export const fetchAllSuggestionsPreview = (): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${SUGGESTIONS}${ALL_SUGGESTIONS_PREVIEW}`);

export const getCount = (count: string): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${COUNT}/${count}/`);

export const getSuggestionById = (id: string): Promise<AxiosResponse<any, any>> =>
  axiosInstance.get(`${SUGGESTION}/${id}`);

export const uploadAttachments = async (
  payload: any
): Promise<AxiosResponse<any, any>> => {
  return await axiosFilesInstance.post(ATTACHMENT_MUTLIPLE_UPLOAD, payload);
};
  
export const updateStatus = (
  updatePayload: suggestionModels.SuggestionUpdatePayload
): Promise<AxiosResponse<any, any>> => {
  const { 
    comment, 
    suggestion_id, 
    status, 
    substatus,
    estimated_cost, 
    suggestion_type, 
    real_cost, 
    is_consulted, 
    department_id, 
    evaluating_user
  } = updatePayload;
  
  return axiosInstance.patch(`${SUGGESTION}/`, {
    comment,
    suggestion_id,
    status,
    substatus,
    estimated_cost,
    suggestion_type,
    real_cost,
    is_consulted,
    department_id,
    evaluating_user
  });
};

export const updateReadStatus = (
  updatePayload: suggestionModels.SuggestionReadStatusUpdatePayload
): Promise<AxiosResponse<any, any>> => {
  const { suggestion_id } = updatePayload;
  return axiosInstance.patch(`${NOTIFICATION}/`, { suggestion_id });
};
