import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { evaluationModels } from "../../../../__store/models";
import { useAppSelector } from "../../../../__store/tools/hooks";

export type AssessmentTopBarValue =
  | "YOUR_WORK"
  | "WORKERS_WORK"
  | "CREATE_ASSESSMENT"
  | "REVIEW_USER";

export type AssessmentDisplayType = {
  assessmentTopBarValue: AssessmentTopBarValue;
  assessmentListHeaderValue: Array<string>;
  inputPlaceholder: string;
};

export const useAssessmentDisplayData = () => {
  const location = useLocation();
  const params = useParams();
  const themeName = useAppSelector(
    (state) => state.applicationSettings.themeName
  );

  const setAssessmentState = () => {
    switch (location.pathname) {
      case "/my-assessment":
        setAssessmentDisplayData({
          assessmentTopBarValue: "YOUR_WORK",
          inputPlaceholder: "Wyszukaj tytuł",
          assessmentListHeaderValue: ["Data", "Ocena", "Informacja Zwrotna"],
        });
        break;
      case "/create-assessment":
        setAssessmentDisplayData({
          assessmentTopBarValue: "CREATE_ASSESSMENT",
          inputPlaceholder: "Wyszukaj pracownika",
          assessmentListHeaderValue: ["Pracownik", "Ocena"],
        });

        break;

      case "/review-assessment":
        setAssessmentDisplayData({
          assessmentTopBarValue: "WORKERS_WORK",
          inputPlaceholder: "Wyszukaj pracownika",
          assessmentListHeaderValue: [
            "Pracownik",
            themeName &&
            ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
              ? "Do wyróżnienia"
              : "Pozytywne",
            themeName &&
            ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
              ? "Do poprawy"
              : "Negatywne",
          ],
        });
        break;
    }
  };
  const [assessmentDisplayData, setAssessmentDisplayData] =
    useState<AssessmentDisplayType>({
      assessmentTopBarValue: "YOUR_WORK",
      inputPlaceholder: "Wyszukaj",
      assessmentListHeaderValue: ["Data", "Rodzaj", "Informacja Zwrotna"],
    });

  useEffect(() => {
    if (params.id) {
      setAssessmentDisplayData({
        assessmentTopBarValue: "REVIEW_USER",
        inputPlaceholder: "Wyszukaj Tytuł",
        assessmentListHeaderValue: ["Data", "Ocena", "Informacja zwrotna"],
      });
    } else {
      setAssessmentState();
    }
  }, [location.pathname]);

  const evaluationTranslationMap: evaluationModels.EvalautaionMapType = {
    POSITIVE:
      themeName &&
      ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
        ? "Do wyróżnienia"
        : "Pozytywna",
    NEGATIVE:
      themeName &&
      ["pgf", "cisowianka", "gobarto", "filters"].includes(themeName)
        ? "Do poprawy"
        : "Negatywna",
  };

  const evaluationTitleTranslationMap: evaluationModels.EvalautaionMapType = {
    PERFORMING_THE_TASK: "Wykonanie zadania",
    HELP_OTHERS: "Pomoc innym",
    STREAMLINING_THE_PROCESS: "Usprawnienie procesu",
    COMMUNICATION: "Komunikacja",
    POSITIVE_FEEDBACK: "Pozytywny feedback",
    RESPONSIBILITY_FOR_THE_WORK_RESULT: "Odpowiedzialność za wynik pracy",
    TASK_NOT_DELIVERED: "Niedostarczenie zadania",
    LATENESS: "Nieterminowaść",
    BREAKING_VALUES: "Łamanie wartości",
    ERRORS_IN_THE_TASK: "Błędy w zadaniu",
    FAILURE_TO_EXECUTE_THE_COMMAND: "Niewykonanie polecenia",
    NO_RESPONSIBILITY_FOR_THE_RESULT_WORK:
      "Brak odpowiedzialność za wynik pracy",
    NEGATIVE_FEEDABCK: "Negatywny feedback",
    INITIATIVE: "Inicjatywa",
    TEACHING_OTHERS: "Uczenie innych",
    EXCEEDING_THE_TARGET: "Przekroczenie celu",
    REPORTING_DANGER: "Zgłoszenie zagrożenia",
    HEALTH_AND_SAFETY_VIOLATION: "Wykroczenie BHP",
    ABSENCE: "Nieobecność na stanowisku",
    LATE: "Spóźnienie",
    MISTAKES_AT_WORK: "Błędy w pracy",
    FAILURE_TO_REACH_THE_GOAL: "Brak realizacji celu",
    OTHER: "Inne",
  };
  const { assessmentListHeaderValue, assessmentTopBarValue, inputPlaceholder } =
    assessmentDisplayData;

  return {
    assessmentListHeaderValue,
    assessmentTopBarValue,
    inputPlaceholder,
    params,
    location,
    evaluationTranslationMap,
    evaluationTitleTranslationMap,
  };
};
